<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="420"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="itemCount && (itemCount.Count > 0 || fullName === 'total')"
        v-bind="attrs"
        v-on="on"
        fab
        color="primary"
        class="notification__point"
        :class="{ 'notification__point--active': notSmall }"
        dark
        x-small
        style="font-size: 0.8em"
      >
        {{ itemCount ? itemCount.Count : 0 }}
      </v-btn>
    </template>
    <v-card v-if="itemCount" max-width="450" class="mx-auto" :loading="loading">
      <v-toolbar>
        <m-icon icon="medexpert-notifications" active class="mr-4"></m-icon>

        <v-toolbar-title>Оповещения</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="menu = false">
          <m-icon icon="mdi-close"></m-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line>
        <v-divider v-if="false"></v-divider>

        <v-list-item
          v-for="item in items"
          :key="item.Id"
          @click="
            $store.dispatch('notification/clickNotif', {
              notificationItem: item,
              router: $router,
            })
          "
        >
          <v-list-item-avatar>
            <NotificationIcon
              :notificationItem="item"
              color="primary"
            ></NotificationIcon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title :class="{ 'font-weight-bold': !item.IsRead }">{{
              item.Header
            }}</v-list-item-title>
            <v-list-item-subtitle style="color: rgb(33, 33, 33) !important">{{
              item.Text
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="notification__date">{{
              item.Date | DateNear
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!loading && fullName === 'total'">
          <v-btn
            class="my-1"
            block
            depressed
            @click="more"
            :loading="loadingMore"
            >Показать еще</v-btn
          >
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
import NotificationIcon from "./components/notificationIcon.vue";
export default {
  components: {
    NotificationIcon,
  },

  props: {
    view: {
      type: String,
      default: null,
    },
    typeView: {
      type: String,
      default: null,
    },
    notSmall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      items: [],
      loading: false,
      loadingMore: false,
    };
  },

  computed: {
    ...mapGetters("notification", ["GET_COUNTS", "GET_TYPE"]),
    type() {
      return this.GET_TYPE(this.view);
    },
    fullName() {
      if (!this.view) {
        return "total";
      }
      let t = this.type?.pageListName;
      if (this.typeView) {
        t += ":" + this.typeView;
      }
      return t;
    },
    itemCount() {
      return this.GET_COUNTS?.find((e) => e.MenuItem === this.fullName);
    },
  },
  watch: {
    menu(val) {
      if (val) {
        this.init();
      }
    },
  },
  methods: {
    async more() {
      this.loadingMore = true;
      const newItems = await this.$store.dispatch(
        "notification/moreNotification",
        {
          fullName: this.fullName,
        }
      );
      this.items = [...this.items, ...newItems];

      this.loadingMore = false;
    },
    async init() {
      this.loading = true;
      this.items = await this.$store.dispatch("notification/loadNotification", {
        fullName: this.fullName,
      });
      this.loading = false;
    },

    async getIcon(item) {
      return await this.$store.dispatch("notification/getIcon", {
        num: item.ObjectType,
        pageType: item.MenuList.length
          ? item.MenuList[0].split(":")?.[1]
          : null,
      });
    },
  },
};
</script>
<style lang="scss">
.notification__date {
  position: absolute;
  right: 13px;
  bottom: 6px;
}
.notification__point:not(.notification__point--active) {
  transform: scale(0.3, 0.3);
  position: absolute;
  top: calc(50% - 16px);

  .v-btn__content {
    opacity: 0;
  }
  &:hover {
    transform: scale(1, 1);
    .v-btn__content {
      opacity: 1;
    }
  }
}
</style>
