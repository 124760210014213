<template>
  <div>
    <div
      class="analysis-tfoms__tabs"
      :style="
        tfomsParams &&
        (tfomsParams.loading ||
          tfomsParams.startLoading != tfomsParams.endLoading)
          ? 'min-height: 709px; max-height: 709px'
          : ''
      "
    >
      <m-tabs v-model="value" :slider-size="2" :with-route="false">
        <v-tab key="Valume">Объемы</v-tab>
        <v-tab key="Finance">Финансовое обеспечение</v-tab>
        <v-tab key="Sanction">Штрафные санкции (МЭЭ, ЭКМП)</v-tab>
        <v-tab key="Sanction2">Неоплата (МЭК)</v-tab>
        <v-tab key="Partions">Подразделения и сотрудники</v-tab>
      </m-tabs>

      <v-window :value="value">
        <v-window-item>
          <more1
            :data="data"
            :labels="labels"
            :tfomsParams="tfomsParams"
          ></more1>
        </v-window-item>
        <v-window-item :labels="labels">
          <more2 :data="data" :labels="labels"></more2>
        </v-window-item>
        <v-window-item :labels="labels"
          ><more3 :data="data" :labels="labels"></more3>
        </v-window-item>
        <v-window-item :labels="labels"
          ><more5 :data="data" :labels="labels"></more5>
        </v-window-item>
        <v-window-item :labels="labels">
          <more4 :data="data" :labels="labels"></more4>
        </v-window-item>
      </v-window>

      <!-- <v-window :value="value">
        <v-window-item>
          <more2></more2>
        </v-window-item>
        <v-window-item>
          <more3></more3>
        </v-window-item>
      </v-window> -->
    </div>
  </div>
</template>
<script>
import mTabs from "@/components/base/tabs/mTabs";

// import more2 from "../tabs/more/more2.vue";
// import more3 from "../tabs/more/more3.vue";
export default {
  components: {
    mTabs,
    more1: () => import("../tabs/more/more1.vue"),
    more2: () => import("../tabs/more/more2.vue"),
    more3: () => import("../tabs/more/more3.vue"),
    more4: () => import("../tabs/more/more4.vue"),
    more5: () => import("../tabs/more/more5.vue"),
    // more2,
    // more3,
  },
  provide() {
    const tfomsParams = {};

    Object.defineProperty(tfomsParams, "value", {
      enumerable: true,
      get: () => this.tfomsParams,
    });
    return {
      tfomsParams: tfomsParams,
    };
  },
  props: {
    tfomsParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      value: 0,
      data: null,
      labels: [],
    };
  },
  // Сборс некоторых глобальных параметров
  watch: {
    value(val) {
      this.$emit("updatedetailParams", {
        reasonId: null,
      });
    },
  },
  methods: {
    setData(val) {
      this.data = val;
    },
    setLabels(val) {
      this.labels = val;
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__more {
  &-tabs {
  }
  &-action {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5f647b;
    span {
      margin-left: 10px;
      font-size: 16px;
      color: var(--v-primary-base);
    }
  }
}
</style>
