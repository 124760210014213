<template>
  <div class="block__content">
    <v-form :value="valid" @input="$emit('validate', $event)">
      <v-container>
        <v-row>
          <v-col>
            <span class="subtitle">Выбрать чек-лист</span>
            <m-autocomplete
              label="Использовать шаблон"
              :items="systemCheckLists"
              :item-value="(e) => e"
              item-text="Name"
              @input="
                chSelect = $event;
                $emit('selectCheckList', $event);
              "
            ></m-autocomplete>
            <m-autocomplete
              label="Показатель оценки качества"
              v-model="indicator"
              item-text="Name"
              deletable-chips
              :items="AuditIndicators"
              @change="updateCheckLists"
            />
            <m-select
              :value="type"
              @change="changeType"
              :items="CheckListObjectTypes"
              item-text="value"
              item-value="id"
              label="Тип чек-листа"
            ></m-select>
            <m-autocomplete
              :items="checkLists"
              :item-value="(e) => e"
              :value="selectCheckList"
              :clearable="false"
              @change="change"
              label="Чек-листы"
              item-text="Name"
              multiple
              chip-select
              @chipChange="
                chSelect = $event;
                $emit('selectCheckList', $event);
              "
            >
            </m-autocomplete>
          </v-col>
          <v-col>
            <span class="subtitle">Новый чек-лист</span>
            <v-text-field
              v-model="newCheckList.Name"
              label="Название чек-листа"
              clearable
              :rules="[rules.required]"
              required
              v-up-first-symbol
            ></v-text-field>
            <m-autocomplete
              v-model="newCheckList.Indicators"
              multiple
              label="Показатель оценки качества"
              deletable-chips
              item-text="Name"
              :item-value="(e) => e"
              :items="AuditIndicators"
            ></m-autocomplete>
            <m-select
              v-model="newCheckList.Type"
              :items="CheckListObjectTypes"
              item-text="value"
              item-value="id"
              label="Тип чек-листа"
            ></m-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CheckListObjectTypes } from "../../../data/enums";
import CheckListService from "../../../services/CheckListService";
import mSelect from "../../base/inputs/mSelect.vue";
import mAutocomplete from "../../base/inputs/mAutocomplete";

import _ from "lodash";
import DataHelper from "../../../utils/DataHelper";

export default {
  components: {
    mSelect,
    mAutocomplete,
  },
  props: {
    newCheckList: Object,
    valid: Boolean,
  },
  data: function () {
    return {
      type: null,
      indicator: null,
      AuditIndicators: [],
      checkLists: [],
      systemCheckLists: [],
      selectCheckList: [],
      chSelect: null,
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  computed: {
    CheckListObjectTypes: () => {
      // без стандартов оснащения
      return CheckListObjectTypes.filter((c) => c.id !== 3);
    },
  },
  watch: {
    selectCheckList(val) {
      if (!val.find((e) => e.Id === this.chSelect.Id)) {
        this.$emit("selectCheckList", null);
      }
    },
  },
  async created() {
    this.AuditIndicators = await this.LOAD_AUDIT_INDICATORS();
    this.systemCheckLists = (await CheckListService.get([], [], true)).data;
    this.updateCheckLists();
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
    async changeType(_type) {
      if (this.newCheckList.Questions.length) {
        if (
          await this.$confirm(
            `Вопросы нового чек листа будут удалены. Вы уверены?`
          )
        ) {
          this.newCheckList.Questions = [];
        } else {
          const tmp = this.type;
          this.type = _type;
          this.$nextTick(() => {
            this.type = tmp;
          });
          return false;
        }
      }
      this.type = _type;
      this.updateCheckLists();
    },
    async change(val) {
      const tmp = _.xor(this.selectCheckList, val);
      const chengeItem = tmp.length ? tmp[0] : null;

      if (chengeItem && this.selectCheckList.find((e) => e === chengeItem)) {
        if (this.newCheckList.Questions) {
          const arrDel = [];
          DataHelper.treeListTraverse(
            this.newCheckList.Questions,
            (item, _, parent) => {
              if (item.CheckList === chengeItem.Id) {
                if (parent) {
                  arrDel.push([item, parent.Children]);
                } else {
                  arrDel.push([item, this.newCheckList.Questions]);
                }
              }
            }
          );
          if (
            arrDel.length &&
            (await this.$confirm(
              "Вопросы чек-листа используются. Количество вопросов: " +
                arrDel.length +
                ". Очистить вопросы?"
            ))
          ) {
            for (const elem of arrDel) {
              elem[1].splice(elem[1].indexOf(elem[0]), 1);
            }
          } else {
            if (arrDel.length) {
              val.push(chengeItem);
            }
          }
        }
      }

      this.selectCheckList = val;
    },
    async updateCheckLists() {
      try {
        const data = (
          await CheckListService.getByAuditIndicator(
            this.indicator ? [this.indicator] : []
          )
        ).data;
        this.checkLists = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
