var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-flex',{staticClass:"mx-1"},[_c('base-panel',_vm._g(_vm._b({attrs:{"loading":_vm.loading,"icon":_vm.getViewIcon},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter(
          function (e) { return e !== 'action'; }
        )),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'base-panel',_vm.$attrs,false),_vm.$listeners),[(_vm.$listeners['onRefresh'])?_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","title":_vm.tooltipRefresh,"icon":""},on:{"click":_vm.refresh},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-refresh","s16":""}})],1):_vm._e(),_vm._l((Object.keys(_vm.$scopedSlots).filter(
          function (slot) { return slot === 'action'; }
        )),function(slot){return _vm._t(slot,null,{"slot":slot})}),_c('template',{slot:"action"},[_vm._t("btn-close",function(){return [(_vm.$parent && (_vm.$parent.nested || _vm.$parent.simple))?_c('v-btn',{staticClass:"ml-3",attrs:{"slot":"action","outlined":"","color":"blue-grey"},on:{"click":function($event){return _vm.$store.dispatch('frame/SHOW_DIALOG', { show: false })}},slot:"action"},[_vm._v(" "+_vm._s(_vm.closeBtnTitle)+" ")]):_vm._e()]},{"click":function () {
              _vm.$store.dispatch('frame/SHOW_DIALOG', { show: false });
            }}),_vm._t("btn-save",function(){return [(_vm.showSave)?_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!_vm.isUnsaved || _vm.loading,"dark":"","color":"blue","title":"Сохранить"},on:{"click":function($event){return _vm.$emit('onSave')}}},[_c('m-icon',{attrs:{"icon":"tool-save","left":"","size":"18"}}),_vm._v(" Сохранить ")],1):_vm._e()]},{"click":function () {
              _vm.$emit('onSave');
            }})],2),_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }