var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-ganta",staticStyle:{"width":"100%","padding-bottom":"20px","padding-right":"15px"}},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"d-flex align-center mr-2"},[_vm._v("Фильтр:")]),_c('div',{staticClass:"d-flex"},[_vm._l((_vm.filterHeaders),function(item){return _c('filter-input',{key:item.value,staticClass:"ml-2",attrs:{"filter":_vm.filters[item.value],"dataSource":_vm.tasks,"header":item}})}),_c('m-select',{staticClass:"ml-5",attrs:{"outlined":"","placeholder":"Сортировать по","dense":"","hide-details":"","items":[
          { Id: 2, value: 'По возрастанию даты' },
          { Id: 3, value: 'По убыванию даты' } ]},model:{value:(_vm.selectSort),callback:function ($$v) {_vm.selectSort=$$v},expression:"selectSort"}}),_c('v-checkbox',{staticClass:"mt-0 ml-4 align-center",attrs:{"hide-details":"","label":"Отображать дочернии задачи"},model:{value:(_vm.showChildren),callback:function ($$v) {_vm.showChildren=$$v},expression:"showChildren"}})],2),_c('div',{staticClass:"ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('m-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"icon":"mdi-information-outline","color":"primary"}},'m-icon',attrs,false),on))]}}])},[_c('div',{staticClass:"task-ganta__info"},[_vm._v(" Цвета: "),_c('div',{staticClass:"task-ganta__color-wrap mb-4"},[_c('div',{staticClass:"task-ganta__color-item"},[_c('span',{style:('background-color:' + _vm.colors[2])}),_vm._v("Завершены ")]),_c('div',{staticClass:"task-ganta__color-item"},[_c('span',{style:('background-color:' + _vm.colors[3])}),_vm._v("Просрочены ")]),_c('div',{staticClass:"task-ganta__color-item"},[_c('span',{style:('background-color:' + _vm.colors[1])}),_vm._v("В процессе ")]),_c('div',{staticClass:"task-ganta__color-item"},[_c('span',{style:('background-color:' + _vm.colors[0])}),_vm._v("Ожидают ")])]),_vm._v(" Информация: "),_c('div',[_c('div',[_vm._v(" 1) Вы можете изменить срок задачи - перетаскивая блок, не завершенных задач ")]),_c('div',[_vm._v(" 2) Нажмите и тяните иконку ⮕ на блоке для установки периода ")]),_c('div',[_vm._v(" 3) Для изменение масштаба графика, нажмите ctrl + zoom(колесико мыши) ")]),_c('div',[_vm._v("4) Для применения изменений нажмите кнопку \"Сохранить\"")])])])])],1)]),_c('VueDjGantt',{ref:"ganta",attrs:{"locale":"ru","resizable":"","moveable":"","from":_vm.from,"to":_vm.to,"list":_vm.list,"rows":_vm.rows,"items":_vm.items,"height":_vm.height},on:{"moved":_vm.moved,"resized":_vm.resized,"row":_vm.itemClick},scopedSlots:_vm._u([{key:"data-item-prepend",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.unsavedItems.includes(item.row.row.Id)),expression:"unsavedItems.includes(item.row.row.Id)"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.unsavedItems.includes(item.row.row.Id)),expression:"unsavedItems.includes(item.row.row.Id)"}],staticClass:"task-ganta__unsaved"},on))]}}],null,true)},[_vm._v(" Задача изменена ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }