var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"selectedItems":_vm.selected,"disabledAdd":_vm.readOnly,"showDelete":!_vm.readOnly,"show-actions":"","pageEditName":"EmployeeEdit","title":"Сотрудники","label-text-search":"Поиск сотрудника","multiple-save":[
      { value: 'new', text: 'Добавить сотрудника' },
      { value: 'import', text: 'Загрузить из файла' } ],"item-class":function (item) {
        return !item.User.IsActive ? 'disabled' : '';
      }},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"add":_vm.add,"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"item.User.Picture",fn:function(ref){
      var item = ref.item;
return [_c('v-avatar',{staticClass:"my-3",staticStyle:{"background-color":"rgb(232, 236, 255)"}},[(item.User.Picture)?_c('v-img',{attrs:{"src":_vm.getMediaByExternalId(item.User.Picture)}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("PersonInitials")(item))+" ")])],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('report-activator',{attrs:{"report":"EmployeeReport","params":{ objectId: { name: 'pEmployee', value: item.Id } }}})]}}])},[(_vm.showUpdate)?_c('v-btn',{attrs:{"slot":"action","disabled":!_vm.isSelected,"icon":"","title":"Изменить"},on:{"click":function($event){_vm.dialogChange = true}},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-edit","s16":""}})],1):_vm._e(),_c('report-activator',{attrs:{"slot":"action","report":"EmployeeListReport"},slot:"action"})],1),_c('ImportEmployees',{attrs:{"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event},"update":_vm.init}}),_c('base-popup',{attrs:{"title":"Установить роли","icon":_vm.UtilGetIcon('view-Employees'),"width":'40vw',"height":'40vw',"buttonSaveText":"Применить"},on:{"save":_vm.saveRoles},model:{value:(_vm.dialogChange),callback:function ($$v) {_vm.dialogChange=$$v},expression:"dialogChange"}},[_c('v-container',{staticClass:"container-space-between"},[_c('base-list-view',{attrs:{"dataSource":_vm.roles,"headers":_vm.headersRole,"selectedItems":_vm.selectedRoles,"isNested":"","headerHidden":"","hide-default-footer":"","hide-edit":""},on:{"update:selectedItems":function($event){_vm.selectedRoles=$event},"update:selected-items":function($event){_vm.selectedRoles=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }