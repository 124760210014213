import { render, staticRenderFns } from "./QuestionEditPopup.vue?vue&type=template&id=007ccd8a&"
import script from "./QuestionEditPopup.vue?vue&type=script&lang=js&"
export * from "./QuestionEditPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VCheckbox,VForm,VTab,VTextarea,VWindow,VWindowItem})
