<template>
  <div class="custom-iframe" ref="wordPress" @scroll="handlerScroll">
    <iframe
      :id="'iframe-wordpress-id-' + postId"
      style="border: none; display: flex; width: 100%"
    ></iframe>
  </div>
</template>

<script>
import WordPressService from "../../services/WordPressService";
export default {
  name: "WordPressViewer",
  props: {
    postId: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      dataSourceWp: {},
      isIframeLoaded: false,
      scrollWordPress: 0,
    };
  },

  watch: {
    postId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.fetchData();
        }
      },
    },
  },
  methods: {
    async fetchData() {
      this.dataSourceWp = (
        await WordPressService.show(this.postId, {
          type: this.type,
        })
      ).data;

      const iframe = document.querySelector(
        `#iframe-wordpress-id-${this.postId}`
      );

      const documentEl = `
      <head>
        <style id="wp-style">
          ${this.dataSourceWp.allStyle[0]}
          body {
            background-color: transparent;
            overflow: hidden;
          }
        </style>
        <link rel="stylesheet" id="wp-block-library-css" href="https://static.medq-expert.ru/wp-includes/css/dist/block-library/style.min.css?ver=6.5" media="all">
        <link rel="stylesheet" id="generate-style-css" href="https://static.medq-expert.ru/wp-content/themes/generatepress/assets/css/main.min.css?ver=3.4.0" media="all">
      </head>
      <body class="instruction-template-default single single-instruction postid-525 single-format-standard logged-in admin-bar wp-custom-logo wp-embed-responsive right-sidebar nav-float-right separate-containers header-aligned-left dropdown-hover customize-support using-mouse">
        ${this.dataSourceWp.content?.rendered}
      </body>
      `;
      // iframe.style.visibility = "hidden"; // Дополнительно скрываем iframe до завершения всех операций

      if (iframe.srcdoc !== documentEl) {
        iframe.srcdoc = documentEl;

        // Устанавливаем высоту iframe равной высоте его содержимого
        iframe.onload = () => {
          // iframe.style.visibility = "hidden"; // Дополнительно скрываем iframe до завершения всех операций

          if (this.postId) {
            const iframeDoc = iframe.contentWindow.document;

            // Устанавливаем высоту iframe равной высоте его содержимого
            iframe.style.height = "0px"; // Сначала скрываем высоту

            iframe.style.height = iframeDoc.body.scrollHeight + "px"; // Устанавливаем высоту в зависимости от содержимого

            // Устанавливаем прокрутку
            this.$refs.wordPress.scrollTop = this.scrollWordPress;

            // Используем requestAnimationFrame для обеспечения выполнения прокрутки
            // requestAnimationFrame(() => {
            //   // Используем setTimeout для гарантии завершения прокрутки
            //   setTimeout(() => {
            //     this.$nextTick(() => {
            //       // this.isIframeLoaded = true;

            //       // Показываем iframe после прокрутки
            //       iframe.style.visibility = "visible";
            //     });
            //   }, 10000); // Задержка в 100 мс
            // });

            this.$nextTick(() => {
              // requestAnimationFrame(() => {
              //   this.isIframeLoaded = true;
              //   iframe.style.visibility = "visible"; // Показать iframe
              // });
              // this.isIframeLoaded = true;
              // iframe.style.visibility = "visible"; // После завершения всех операций показываем iframe
              // iframe.style.transition = "opacity 0.3s ease-in-out"; // Плавное появление
              // iframe.style.opacity = "1"; // Восстанавливаем видимость
            });
            // this.isIframeLoaded = true;

            this.updateLinkClickListeners(iframeDoc);
          }
        };
      }
      this.$emit("getData", this.dataSourceWp);
    },

    // Метод присваивания позиции скролла
    handlerScroll() {
      this.scrollWordPress = this.$refs.wordPress.scrollTop;
    },

    updateLinkClickListeners(doc) {
      const links = doc.querySelectorAll("a");
      links.forEach((link) => {
        // Удаляем старые обработчики
        link.removeEventListener("click", this.handleRouterLinkClick);
        link.removeEventListener("click", this.handleInstructionLinkClick);
        link.removeEventListener("click", this.handleAnchorLinkClick);
        link.removeEventListener("click", this.handleNormalLinkClick);

        // Добавляем обработчики для ссылок с data-router-link
        if (link.hasAttribute("data-router-link")) {
          link.addEventListener("click", this.handleRouterLinkClick.bind(this));
        }
        // Добавляем обработчики для ссылок с data-instruction-id
        else if (link.hasAttribute("data-instruction-id")) {
          link.addEventListener(
            "click",
            this.handleInstructionLinkClick.bind(this)
          );
        }
        // Добавляем обработчики для якорных ссылок
        else if (link.getAttribute("href").startsWith("#")) {
          link.addEventListener("click", this.handleAnchorLinkClick.bind(this));
        }
        // Обычные ссылки
        else {
          link.addEventListener("click", this.handleNormalLinkClick.bind(this));
        }
      });
    },

    handleRouterLinkClick(event) {
      event.preventDefault(); // Предотвращаем стандартное поведение браузера

      // Получаем данные вкладки из атрибута data
      const routeName = event.target.getAttribute("data-router-link");
      const params = JSON.parse(
        event.target.getAttribute("data-router-params") || "{}"
      );

      // Переходим на вкладку
      this.$router.push({ name: routeName, params });
    },

    handleInstructionLinkClick(event) {
      event.preventDefault(); // Предотвращаем стандартное поведение браузера

      // Получаем id инструкции из атрибута data
      const id = event.target.getAttribute("data-instruction-id");

      // Отправляем событие в родительский компонент
      this.$emit("openInstruction", id);
    },

    handleAnchorLinkClick(event) {
      event.preventDefault(); // Предотвращаем стандартное поведение браузера

      const targetId = event.target.getAttribute("href").substring(1);
      const iframe = document.querySelector(
        `#iframe-wordpress-id-${this.postId}`
      );
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      // Ищем элемент с данным id внутри iframe
      const targetElement = iframeDoc.getElementById(targetId);

      if (targetElement) {
        // Устанавливаем фокус на элемент
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    },

    handleNormalLinkClick(event) {
      // Не предотвращаем стандартное поведение, чтобы обычные ссылки открывались как обычно
      const href = event.target.getAttribute("href");

      if (href && href.startsWith("https://static.medq-expert.ru")) {
        event.preventDefault(); // Предотвращаем стандартное поведение браузера

        this.$alert("Переход по этой ссылке запрещен."); // Выводим сообщение
      } else if (href && href.startsWith("http")) {
        event.preventDefault(); // Предотвращаем стандартное поведение браузера

        // Если ссылка начинается с http, откроется в новой вкладке (по умолчанию)
        window.open(href, "_blank");
      }
    },
  },
};
</script>
<style>
.custom-iframe {
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
  height: calc(100vh - 210px) !important;
  overflow: auto;
}
</style>
