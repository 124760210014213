<template>
  <base-table
    :class="{ block__content: !nested, violations__recommendation: true }"
    :headers="headerRecommendation"
    :items="items"
    v-on="$listeners"
    id="recommendation--table"
    hide-default-footer
    :hide-default-header="nested"
    :expanded.sync="expanded"
    :single-expand="true"
    notShowSelect
    nested
    no-data-text=""
    showActions
    :showSettings="false"
  >
    <template v-slot:item.Description="{ item, index }">
      <v-textarea
        v-if="violationEditing && item.Id === violationEditing.Id"
        :value="violationEditing.Description"
        @input="changeDescription"
        @keyup.enter="edit(index, item)"
        :placeholder="textPlaceholder"
        flat
        background-color="transparent"
        rows="1"
        auto-grow
        autofocus
        maxlength="500"
        :rules="[(v) => !!v || 'Укажите значение.']"
        v-up-first-symbol
        @blur="edit(index, item)"
      ></v-textarea>
      <span v-else>{{ item.Description }}</span>
    </template>

    <template v-slot:item.IsChecked="{ item }">
      <v-checkbox
        v-model="item.IsChecked"
        @click.stop
        @change="changeChecked(item, $event)"
        hide-details
        :disabled="readOnly"
      ></v-checkbox>
    </template>

    <template v-slot:item.IsTypical="{ item }">
      <v-checkbox
        v-model="item.IsTypical"
        @click.stop
        on-icon="mdi-alpha-t-box-outline"
        off-icon="mdi-checkbox-blank-off-outline"
        hide-details
        :disabled="readOnly || !item.IsChecked"
        :title="getTooltip(item.IsTypical)"
        class="typical-violation__change"
      ></v-checkbox>
    </template>

    <template v-slot:item.expansionAction="{ item }">
      <slot
        name="expansionAction"
        v-bind:recm="1"
        v-bind:readOnly="readOnly"
        v-bind:item="item"
      ></slot>
    </template>

    <template v-slot:item.actions="{ item, index }">
      <div
        v-if="!violationEditing || item.Id === violationEditing.Id"
        style="display: flex"
        :class="{
          'table__column__actions-active':
            violationEditing && violationEditing.Id === item.Id,
        }"
      >
        <v-btn
          v-if="
            violationEditing && violationEditing.Id === item.Id && !item.IsNew
          "
          title="Не сохранять изменения"
          icon
          @click.stop.prevent="violationEditing = null"
        >
          <m-icon icon="mdi-close" small></m-icon>
        </v-btn>
        <v-btn
          @click.stop.prevent="edit(index, item)"
          :color="
            !(violationEditing && item.Id === violationEditing.Id)
              ? 'primary'
              : 'success'
          "
          :disabled="readOnly || (!item.IsChecked && !simple)"
          icon
          :title="
            !(violationEditing && item.Id === violationEditing.Id)
              ? 'Редактирование записи'
              : 'Cохранить изменения'
          "
        >
          <m-icon
            :icon="
              !(violationEditing && item.Id === violationEditing.Id)
                ? 'tool-edit'
                : 'mdi-check'
            "
            small
            s16
          ></m-icon>
        </v-btn>
        <v-btn
          @click.stop.prevent="del(index)"
          icon
          title="Удалить"
          :disabled="readOnly || (!item.IsChecked && !simple)"
          :color="'error'"
        >
          <m-icon icon="tool-delete" s16></m-icon>
        </v-btn>
      </div>
    </template>
    <template v-slot:body.append="{ headers }">
      <tr>
        <td v-if="!hideSelect"></td>
        <td :colspan="headers.length">
          <v-btn
            @click="add"
            text
            color="blue"
            large
            :disabled="readOnly"
            class="recommendation-table__add"
          >
            <m-icon
              icon="mdi-plus-circle"
              dark
              left
              style="font-size: 30px"
              class="mr-3"
            ></m-icon>
            {{ textAdd }}
          </v-btn>
        </td>
      </tr>
    </template>
  </base-table>
</template>

<script>
import BaseTable from "../../../base/BaseTable.vue";
import { defaultTask } from "../../../../data/defaultObjects";
import DataHelper from "@/utils/DataHelper";

export default {
  name: "view-recommendation-table",
  components: { BaseTable },
  inject: ["editingItems", "external"],
  props: {
    violation: [Object, Array],
    treeNames: Object,
    auditOperationNum: [String, Number],
    objectCheck: String,
    checkListName: String,
    items: {
      type: Array,
      default: () => [],
    },
    nested: Boolean,
    changeTypical: Boolean,
    readOnly: Boolean,
    hideSelect: Boolean,
    parentSelect: Boolean,
    isOffer: {
      type: Boolean,
      default: false,
    },
    textAdd: {
      type: String,
      default: "Добавить рекомендацию",
    },
    textPlaceholder: {
      type: String,
      default: "Введите рекомендацию",
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: null,
      expanded: [],
      violationEditing: null,
      violationEditingIndex: -1,
      headerRecommendation: [
        {
          text: "Предложение",
          value: "Description",
        },
        {
          text: "",
          value: "expansionAction",
          width: "1%",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: "1%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    treeNamesArray() {
      const array = [];
      DataHelper.treeListTraverse(this.treeNames, (item) => {
        array.push(item.Text.trim());
      });
      return array;
    },
  },
  watch: {
    parentSelect: function (val) {
      if (!val) for (const recom of this.items) recom.IsChecked = false;
    },
    violationEditing: function (val) {
      this.editingItems.pop();
      if (val) this.editingItems.push(this.violationEditing);
    },
  },
  created() {
    if (this.changeTypical) {
      const typicalHead = {
        text: "Типовое",
        align: "center",
        value: "IsTypical",
        width: "1%",
        sortable: false,
      };
      this.headerRecommendation.push(typicalHead);
    }

    if (!this.hideSelect) {
      const typicalHead = {
        text: "Применить",
        value: "IsChecked",
        width: "1%",
        sortable: false,
      };
      this.headerRecommendation.unshift(typicalHead);
    }

    if (this.nested) {
      this.headerRecommendation.unshift({
        text: "Clear",
        value: "Clear",
        width: "100%",
        sortable: false,
      });
    }
  },
  methods: {
    changeDescription(description) {
      this.violationEditing.Description = description;
      const inx = this.items.findIndex(
        (i) => i.Id === this.violationEditing.Id
      );
      if (inx !== -1 && this.items[inx].CheckTask) {
        this.items[inx].CheckTask.Task.Name = description + this.getTaskTitle();
        this.items[inx].CheckTask.Task.Content = description;
      }
    },
    chengeName(item) {
      if (
        item?.CheckTask?.Task &&
        item.CheckTask.Task.Name !== item.Description
      ) {
        this.$store.dispatch("frame/DESTROY_COMPONENT");
        item.CheckTask.Task.Name = item.Description + this.getTaskTitle();
        item.CheckTask.Task.Content = item.Description;
      }
    },
    changeChecked(item, val) {
      if (val && !item.CheckTask) {
        item.CheckTask = this.getTask(item.Description);
      }
    },
    getTask(object) {
      if (this.external?.value) return null;
      return {
        Id: -Math.round(Math.random() * 1000000),
        Task: {
          ...defaultTask(),
          Name: object ? object + this.getTaskTitle() : this.getTaskTitle(),
          Content: object,
          Priority: 2, // Средний
        },
      };
    },
    add() {
      if (this.violationEditing && !this.violationEditing.Description) return;
      this.items.push({
        CheckTask: this.getTask(this.violationEditing?.Description),
        Description: "",
        Id: -Math.round(Math.random() * 1000000),
        Images: [],
        IsChecked: true,
        IsTypical: false,
        TypicalRecommendationId: 0,
        Tasks: [],
        IsNew: true,
      });

      // При создании новой рекомендации добавление задачи

      this.violationEditing = this.items[this.items.length - 1];
      this.violationEditingIndex = this.items.length - 1;
    },
    del(index) {
      this.items.splice(index, 1);
      this.violationEditing = null;
      this.violationEditingIndex = -1;
    },
    edit(index, item) {
      if (!item.Description) return;
      const itemEdit = this._.cloneDeep(item);

      if (!this.violationEditing) {
        this.violationEditing = itemEdit;
        this.violationEditingIndex = index;
      } else {
        if (!item.CheckTask && !this.external?.value) {
          item.CheckTask = {
            Id: -Math.round(Math.random() * 1000000),
            Task: {
              ...defaultTask(),
              Name: item.Description + this.getTaskTitle(),
              Content: item.Description,
              Priority: 2, // Средний
              Files: this.violation ? this.violation.Images : [],
            },
          };
        } else this.chengeName(this.violationEditing);

        const ri = this.items.findIndex(
          (e) => e.Id === this.violationEditing.Id
        );
        this.$set(this.items, ri, { ...this.violationEditing, IsNew: false });

        this.violationEditing = null;
        this.violationEditingIndex = -1;
      }
    },
    getTooltip(isTypical) {
      if (isTypical)
        return "Типовая рекомендация по устранению нарушения. При нажатии рекомендация станет локальной. Локальная рекомендация относится только к данной проверке.";
      else
        return "Локальная рекомендация по устранению нарушений. При нажатии рекомендация станет типовой (часто встречающейся). Типовую рекомендацию можно выбрать в других проверках";
    },

    getContent(description) {
      let text = "";
      let objectCheck = "";

      if (this.objectCheck) objectCheck = `Объект: ${this.objectCheck}.\n`;

      if (this.isOffer) {
        text = `Предложение: ${description}`;
      } else {
        text =
          "Описание нарушения: " +
          this.violation?.Description +
          "\n" +
          "Предложенная рекомендация по устранению нарушения: " +
          description;
      }

      let requirement = "";

      const treeNamesArrayUntitled = this.treeNamesArray.slice(1);
      for (let i = 0; i < treeNamesArrayUntitled.length; i++) {
        requirement += `${this.calculateTab(i)}${treeNamesArrayUntitled[i]}\n`;
      }

      const content =
        `Задача создана из проверки №${this.auditOperationNum} по чек-листу "${this.checkListName}".\n` +
        objectCheck +
        `Нарушено следующее требование:\n` +
        `${requirement}\n` +
        text;

      return content;
    },

    getTaskTitle() {
      let objectCheck = "";
      if (this.objectCheck) objectCheck = ` (${this.objectCheck}).\n`;
      return objectCheck;
    },

    calculateTab(x) {
      if (!x) return "\t";
      let res = "";
      for (let i = 0; i <= x; i++) res += "\t";
      return res;
    },
  },
};
</script>
<style lang="scss">
.recommendation-table__add {
  margin-left: -12px;
}
</style>
