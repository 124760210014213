<template>
  <v-layout>
    <base-list-view
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      @onRefresh="init"
      @onDelete="del"
      @add="add"
      :selectedItems.sync="selected"
      :disabledAdd="readOnly"
      :showDelete="!readOnly"
      show-actions
      pageEditName="EmployeeEdit"
      title="Сотрудники"
      label-text-search="Поиск сотрудника"
      :multiple-save="[
        { value: 'new', text: 'Добавить сотрудника' },
        { value: 'import', text: 'Загрузить из файла' },
      ]"
      :item-class="
        (item) => {
          return !item.User.IsActive ? 'disabled' : '';
        }
      "
    >
      <v-btn
        slot="action"
        v-if="showUpdate"
        @click="dialogChange = true"
        :disabled="!isSelected"
        icon
        title="Изменить"
      >
        <m-icon icon="tool-edit" s16></m-icon>
      </v-btn>
      <report-activator slot="action" report="EmployeeListReport" />
      <template v-slot:item.User.Picture="{ item }">
        <v-avatar class="my-3" style="background-color: rgb(232, 236, 255)">
          <v-img
            v-if="item.User.Picture"
            :src="getMediaByExternalId(item.User.Picture)"
          ></v-img>
          <span v-else>
            {{ item | PersonInitials }}
          </span>
        </v-avatar>
      </template>
      <template v-slot:item.actions="{ item }">
        <report-activator
          report="EmployeeReport"
          :params="{ objectId: { name: 'pEmployee', value: item.Id } }"
        />
      </template>
    </base-list-view>
    <ImportEmployees :dialog.sync="dialog" @update="init"></ImportEmployees>
    <base-popup
      title="Установить роли"
      :icon="UtilGetIcon('view-Employees')"
      :width="'40vw'"
      :height="'40vw'"
      @save="saveRoles"
      v-model="dialogChange"
      buttonSaveText="Применить"
    >
      <v-container class="container-space-between">
        <base-list-view
          :dataSource="roles"
          :headers="headersRole"
          :selectedItems.sync="selectedRoles"
          isNested
          headerHidden
          hide-default-footer
          hide-edit
        >
        </base-list-view> </v-container
    ></base-popup>
  </v-layout>
</template>

<script>
import EmployeesService from "../../services/EmployeeService";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import ReportActivator from "../../components/report/ReportActivator";
import BasePopup from "../../layouts/BasePopup.vue";
import { mapActions } from "vuex";
import { sortBy } from "@/utils/Functions";
import { uniqBy } from "lodash";
import ImportEmployees from "./components/ImportEmployees.vue";

export default {
  name: "view-Employees",
  components: { BaseListView, ReportActivator, BasePopup, ImportEmployees },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,

      apiService: EmployeesService,
      headers: [
        {
          text: "Фото",
          value: "User.Picture",
          align: "center",
          sortable: false,
          notGroup: true,
          notFilter: true,
        },
        { text: "Фамилия", value: "LastName", notGroup: true },
        { text: "Имя", value: "FirstName", notGroup: true },
        { text: "Отчество", value: "MiddleName", notGroup: true },
        {
          text: "Должность",
          value: "AllPositionName",
        },
        {
          text: "Подразделение",
          value: "AllPlaceName",
        },
        { text: "Логин", value: "User.UserName", notGroup: true },
        {
          text: "Роли",
          value: "RoleNames",
        },
      ],
      headersRole: [{ text: "Название", value: "Name" }],
      dataSource: [],
      readOnly: !this.hasPermission(Permissions.EmployeesEdit),
      showUpdate: this.hasPermission(Permissions.UsersEdit),
      dialog: false,
      dialogChange: false,
      roles: [],
      selected: [],
      selectedRoles: [],
    };
  },
  computed: {
    isSelected() {
      return this.selected.length > 0;
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.file = null;
      }
    },
    dialogChange(visible) {
      if (visible) {
        this.selectedRoles = [];
      }
    },
  },
  async mounted() {
    await this.init();
    this.roles = await this.LOAD_ROLES();
  },
  methods: {
    ...mapActions(["LOAD_ROLES"]),
    DataFromServerNormalize(data) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        item.RoleNames = item?.User?.Roles
          ? sortBy(item.User.Roles, "Name")
              .map((r) => r.Name)
              .join(", ")
          : null;

        if (!item.WorkPlaces) continue;
        const wp = item.WorkPlaces.filter((e) => e !== null);
        if (!wp) continue;
        item.AllPositionName = uniqBy(
          wp.filter((e) => e.Position),
          "Position.Id"
        )
          .map((e) => e?.Position?.Name)
          .sort()
          .join(", ");

        item.AllPlaceName = uniqBy(
          wp.filter((e) => e.Place),
          "Place.Id"
        )
          .map((e) => e?.Place?.Name)
          .sort()
          .join(", ");
      }

      return data;
    },
    add(item) {
      if (item.value === "import") {
        this.dialog = true;
      }
    },

    async saveRoles() {
      if (!this.selectedRoles.length) return;

      await this.apiService.updateMultiple(
        this.selectedRoles.map((s) => s.Id),
        this.selected.map((s) => s.Id)
      );
      this.dialogChange = false;
    },
  },
};
</script>
<style lang="scss">
.disabled .text-start {
  color: rgba(0, 0, 0, 0.38);
}
</style>
