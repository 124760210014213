<template>
  <div v-if="!loading" class="analysis-tfoms__tfoms3">
    <tfoms-panel title="Условия оказания медицинской помощи">
      <tfoms-table-tree
        v-if="dataSource"
        style="margin-top: 0px"
        :data="dataSource.TreeValues"
        class="analysis-tfoms__tfoms3-table"
        v-model="selectItem"
      ></tfoms-table-tree>
    </tfoms-panel>
    <v-row>
      <v-col>
        <tfoms-bar
          v-if="selectItem"
          :item-name="selectItem ? selectItem.Name : ''"
          class="mt-5"
          title="Объемы предоставления медицинской помощи"
          :data="percentValues"
        ></tfoms-bar>
        <tfoms-progress
          v-if="dataSource.TreeValues"
          class="mt-5"
          title="Выполнение плана - объемы"
          icon="medexpert-users"
          :data="values"
        ></tfoms-progress>
        <tfoms-finance
          v-if="object && object.length"
          :item-name="selectItem ? selectItem.Name : ''"
          class="mt-5"
          :value="selectItem"
          :data="object.map((e) => [e.SanctionValue, e.FineValue])"
        ></tfoms-finance>
        <tfoms-lost-income
          v-if="object && object.length && selectItem"
          :item-name="selectItem ? selectItem.Name : ''"
          class="mt-5"
          :value="selectItem"
        ></tfoms-lost-income>
      </v-col>
      <v-col>
        <tfoms-bar
          v-if="selectItem"
          class="mt-5"
          title="Финансовое обеспечение"
          :data="percentFinances"
          icon="mdi-currency-rub"
          factColor="#00B050"
          :item-name="selectItem ? selectItem.Name : ''"
        >
        </tfoms-bar>
        <tfoms-progress
          class="mt-5"
          title="Выполнение плана - финансовое обеспечение "
          icon="mdi-currency-rub"
          factColor="#00B050"
          :data="finances"
        ></tfoms-progress>
        <tfoms-progress
          class="mt-5"
          title="Санкции и штрафы"
          icon="mdi-currency-rub"
          factColor="#F95050"
          :data="sanctions"
          background-color="#FF8761"
          showNum
        ></tfoms-progress>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import tfomsTab from "../mixins/tfomsTab";

import tfomsTableTree from "../components/tfomsTableTree.vue";
import tfomsPanel from "@/components/analysis/panel.vue";
// import tfomsProgress from "../components/tfomsProgress.vue";
import TFormService from "@/services/TFormService";
import tfomsBar from "../components/tfomsBar.vue";
import { sortBy } from "../../../../utils/Functions";
import tfomsProgress from "../components/tfomsProgress.vue";
import tfomsFinance from "../components/tfomsFinance.vue";
import tfomsLostIncome from "../components/tfomsLostIncome.vue";
import DataHelper from "../../../../utils/DataHelper";

export default {
  name: "view-AnalysisTform-tfoms2",
  components: {
    tfomsTableTree,
    tfomsPanel,
    tfomsBar,
    tfomsProgress,
    tfomsFinance,
    tfomsLostIncome,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin, tfomsTab],
  data() {
    return {
      // Api
      apiService: TFormService.tfoms2,
      getDefaultObject: {},

      loading: true,
      dataSource: {},

      selectItem: null,
      percentValues: [],
      percentFinances: [],
      finances2: [],
      object: [],
    };
  },
  computed: {
    values() {
      const func = (data) => {
        const ret = [];

        const maxValue = Math.max(
          ...data.map((e) => e.VolumeValue + e.VolumePlanValue)
        );
        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          ret.push({
            Name: item.Name,
            Value: item.VolumePlanValue
              ? Math.round((item.VolumeValue / item.VolumePlanValue) * 100)
              : 100,
            ValueNum: item.VolumeValue + item.VolumePlanValue,
            MaxValue: maxValue,
            Id: item.Id,
            Children: func(item.Children),
            Values: [item.VolumePlanValue, item.VolumeValue],
            Unit: this.unit.text,
          });
        }

        return sortBy(ret, "ValueNum", true);
      };

      return func(this.dataSource.TreeValues);
    },
    finances() {
      const func = (data) => {
        const ret = [];
        const maxValue = Math.max(
          ...data.map((e) => e.FinancialValue + e.FinancialPlanValue)
        );
        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          ret.push({
            Name: item.Name,
            Value: item.FinancialPlanValue
              ? Math.round(
                  (item.FinancialValue / item.FinancialPlanValue) * 100
                )
              : 100,
            ValueNum: item.FinancialValue + item.FinancialPlanValue,
            MaxValue: maxValue,
            Id: item.Id,
            Children: func(item.Children),
            Values: [item.FinancialPlanValue, item.FinancialValue],
            Unit: this.rub.text,
          });
        }
        return sortBy(ret, "ValueNum", true);
      };
      return func(this.dataSource.TreeValues);
    },
    sanctions() {
      const func = (data) => {
        const ret = [];
        const maxValue = Math.max(
          ...data.map((e) => e.FineValue + e.SanctionValue)
        );
        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          ret.push({
            Name: item.Name,
            Value: item.SanctionValue
              ? Math.round((item.FineValue / item.SanctionValue) * 100)
              : 100,
            ValueNum: item.FineValue + item.SanctionValue,
            MaxValue: maxValue,
            Id: item.Id,
            Children: func(item.Children),
            Values: [item.FineValue, item.SanctionValue],
            Unit: this.rub.text,
            Labels: ["Штрафы", "Санкции"],
          });
        }
        return sortBy(ret, "ValueNum", true);
      };
      return func(this.dataSource.TreeValues);
    },
  },
  watch: {
    async selectItem(val) {
      // Проценты
      this.object = [];
      setTimeout(async () => {
        const { data } = await TFormService.tfoms2.getObject({
          Id: val.Id,
          objectType: val.Type,
          year: 2024,
          ...this.queryParams.params,
        });

        this.percentValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.percentFinances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.object = [];
        for (let i = 0; i < data.ObjectValues.length; i++) {
          const item = data.ObjectValues[i];
          this.object.push({
            Name: item.Name,
            VolumePlanValue: this.getUnit(item.VolumePlanValue),
            VolumeValue: this.getUnit(item.VolumeValue),
            FinancialPlanValue: this.getRub(item.FinancialPlanValue),
            FinancialValue: this.getRub(item.FinancialValue),
            FineValue: this.getRub(item.FineValue),
            SanctionValue: this.getRub(item.SanctionValue),
            Id: item.Id,
          });
        }

        for (let i = 0; i < this.object.length; i++) {
          // Проценты
          this.percentValues[this.object[i].Id - 1] = {
            percent:
              Math.min(
                100,
                Math.round(
                  (this.object[i].VolumeValue /
                    this.object[i].VolumePlanValue) *
                    100
                )
              ) || 0,
            fact: this.object[i].VolumeValue,
            plan: this.object[i].VolumePlanValue,
          };

          this.percentFinances[this.object[i].Id - 1] = {
            percent:
              Math.min(
                100,
                Math.round(
                  (this.object[i].FinancialValue /
                    this.object[i].FinancialPlanValue) *
                    100
                )
              ) || 0,
            fact: this.object[i].FinancialValue,
            plan: this.object[i].FinancialPlanValue,
          };
        }
      }, 1);
    },
  },
  async mounted() {
    await this.init(this.queryParams.params);
  },
  methods: {
    DataFromServerNormalize(data) {
      DataHelper.treeListTraverse(data.TreeValues, (e) => {
        e.Id = e.TreeId;
        e.VolumePlanValue = this.getUnit(e.VolumePlanValue);
        e.VolumeValue = this.getUnit(e.VolumeValue);
        e.FinancialPlanValue = this.getRub(e.FinancialPlanValue);
        e.FinancialValue = this.getRub(e.FinancialValue);
        e.FineValue = this.getRub(e.FineValue);
        e.SanctionValue = this.getRub(e.SanctionValue);
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__tfoms3 {
  .v-data-table__wrapper {
    max-height: 40vh;
  }
}
</style>
