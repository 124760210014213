var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._g({staticStyle:{"border-radius":"10px"},attrs:{"height":"100%","ripple":false,"color":"white","elevation":_vm.elevation}},_vm.isOpenDetail ? { click: _vm.handle } : {}),[_c('v-card-actions',[_c('m-icon',{staticClass:"mr-2",class:_vm.StatisticIndicatorHelper.getColorDiff(
          _vm.indicator.DiffValue,
          _vm.indicator.RatingType
        ) + '--text',attrs:{"icon":"mdi-circle","small":""}}),_c('h4',{staticClass:"statistic-indicators-chart__name"},[_vm._v(" "+_vm._s(_vm.indicator.Name)+" ")]),_c('v-spacer'),_vm._t("action",function(){return [(_vm.showYear !== null)?_c('m-select',{staticStyle:{"max-width":"70px"},attrs:{"value":_vm.year,"items":_vm.years,"clearable":false,"dense":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":_vm.setYear}}):_vm._e()]},{"item":_vm.indicator})],2),_c('v-card-text',[_c('v-container',[(_vm.loading === false && _vm.indicator.DynamicValues)?_c('v-row',{staticClass:"statistic-indicators-row-chart"},[(!_vm.hideInfo)?_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex flex-column align-end"},[_c('p',{staticStyle:{"width":"100%","font-size":"15px","color":"black","text-align":"end"}},[(_vm.indicator.LastValueDate)?_c('span',[_vm._v(" "+_vm._s(_vm._f("DateShort")(_vm.indicator.LastValueDate))+" ")]):_c('span',[_vm._v(" - ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"statistic-indicators-chart-title-wrapper"},'div',attrs,false),on),[_c('span',{staticClass:"statistic-indicators-chart-title",class:_vm.StatisticIndicatorHelper.getColorByRefs(
                        [
                          _vm.indicator.RefValue1,
                          _vm.indicator.RefValue2,
                          _vm.indicator.RefValue3 ],
                        _vm.indicator.LastValue,
                        _vm.indicator.RatingType
                      ) + '--text'},[_vm._v(" "+_vm._s(_vm.indicator.LastValue)+" ")]),_c('span',{staticClass:"statistic-indicators-chart-title-label",attrs:{"title":_vm.indicator.Units}},[_vm._v(_vm._s(_vm.indicator.Units))])])]}}],null,false,3671717616)},[_c('span',[_vm._v(" "+_vm._s(Math.round(_vm.indicator.LastValue))+" "+_vm._s(_vm.indicator.Units)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"statistic-indicators-chart__wrap-result"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-content-center justify-end"},[(
                        _vm.indicator.DiffValue > 0 || _vm.indicator.DiffValue < 0
                      )?_c('m-icon',{class:_vm.StatisticIndicatorHelper.getColorDiff(
                          _vm.indicator.DiffValue,
                          _vm.indicator.RatingType
                        ) + '--text',attrs:{"icon":Math.sign(_vm.indicator.DiffValue) === -1
                          ? 'mdi-arrow-bottom-left'
                          : Math.sign(_vm.indicator.DiffValue) === 1
                          ? 'mdi-arrow-top-right'
                          : null}}):_vm._e(),_c('span',{staticClass:"statistic-indicators-chart-title",class:_vm.indicator.DiffValue !== null
                          ? _vm.StatisticIndicatorHelper.getColorDiff(
                              _vm.indicator.DiffValue,
                              _vm.indicator.RatingType
                            ) + '--text'
                          : ''},[_vm._v(" "+_vm._s(_vm.indicator.DiffValue === null ? "-" : Math.abs(_vm.indicator.DiffValue))+" ")])],1),_c('p',[_vm._v(" "+_vm._s(_vm.getPeriod(_vm.indicator.Period))+" ")])])]}}],null,false,4155787164)},[_c('span',[_vm._v(" "+_vm._s(_vm.indicator.DiffValue === null ? "-" : Math.abs(Math.round(_vm.indicator.DiffValue)))+" "+_vm._s(_vm.getPeriod(_vm.indicator.Period)))])])],1)]):_vm._e(),(!_vm.hideInfo)?_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticStyle:{"height":"100%","width":"1px","background-color":"#6e758d"}})]):_vm._e(),_c('v-col',{attrs:{"cols":!_vm.hideInfo ? 9 : 12}},[(!_vm.indicator.Groups.length)?_c('div',{staticClass:"statistic-indicators-chart__nodata text--disabled",staticStyle:{"height":"185px"}},[_vm._v(" Нет данных ")]):_c('bar-chart',{staticStyle:{"height":"185px"},attrs:{"chart-data":_vm.indicator.DynamicValues,"options":_vm.indicator.BarOptions}})],1)],1):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item, image"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }