<!-- Включает в себя фильтр -->
<!-- Поддерживает типы enum, object, array, numberBar -->
<!-- Действия "Редакирование" "Удаление" -->

<!-- ! Переработана логика отображения фильтров -->
<!-- ! По умолчанию фильтры отображены -->
<!-- ! Чтобы убрать фильтры в таблице необходимо в компоненте на base-list-view указать пропс not-filter (принимает булевое значение). -->
<!-- ! Чтобы отключить фильтр у определенного столбца таблицы в headers следует добавить свойство notFilter (принимает булевое значение).  -->
<template>
  <div>
    <base-table
      :headers="headersInternal"
      :items="filteredDataSource"
      :loading="loading"
      :searchText="searchText"
      v-on="$listeners"
      v-bind="$attrs"
      ref="base-table"
    >
      <template
        v-for="header in scopeHeaders"
        v-slot:[`header.${header.value}`]
      >
        <span :key="header.value" :title="header.tooltip">
          {{ header.text }}
        </span>
      </template>
      <template
        slot="body.prepend"
        v-if="isShowFilters && !$attrs.hasOwnProperty('nested')"
      >
        <tr class="base-table-full__filter">
          <!-- Когда активен силект-->
          <td
            v-if="
              !$attrs.hasOwnProperty('notShowSelect') ||
              $attrs['notShowSelect'] === false
            "
          ></td>
          <td v-if="$attrs.hasOwnProperty('show-expand')"></td>

          <td
            v-for="header in headersFilterInternal"
            :key="header.value"
            style="vertical-align: baseline"
          >
            <div v-if="filters.hasOwnProperty(header.value)">
              <filter-input
                v-if="!header.notFilter"
                :filter="filters[header.value]"
                :dataSource="dataSource"
                :header="header"
              >
              </filter-input>
            </div>
          </td>
        </tr>
      </template>
      <template
        v-for="slot in [
          ...Object.keys(getPrefixedScopedSlots('item.', $scopedSlots))
            .filter((e) => e !== 'actions')
            .map((e) => 'item.' + e),
        ]"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
          v-bind:rowEditing="rowEditing"
          v-bind:rowEditingIndex="rowEditingIndex"
          v-bind:closeEdit="closeEdit"
          v-bind:itemEdited="
            rowEditing && scope.item && scope.item.Id === rowEditing.Id
          "
        />
      </template>

      <!-- Слот для расширения header-->
      <template
        v-if="$scopedSlots['header']"
        :slot="'header'"
        slot-scope="scope"
      >
        <slot :name="'header'" v-bind="scope" />
      </template>

      <!-- Слот для расширения row -->
      <template
        v-if="$scopedSlots['expanded-item']"
        :slot="'expanded-item'"
        slot-scope="scope"
      >
        <slot :name="'expanded-item'" v-bind="scope" />
      </template>

      <!-- Слот для заголовка группы -->
      <template
        v-if="$scopedSlots['group.header']"
        :slot="'group.header'"
        slot-scope="scope"
      >
        <slot :name="'group.header'" v-bind="scope" />
      </template>

      <!-- Слот для продолжение заголовка группы -->
      <template
        v-if="$scopedSlots['group.header.after']"
        :slot="'group.header.after'"
        slot-scope="scope"
      >
        <slot :name="'group.header.after'" v-bind="scope" />
      </template>

      <!-- Слот для notif заголовка группы -->
      <template
        v-if="$scopedSlots['group.header.notif']"
        :slot="'group.header.notif'"
        slot-scope="scope"
      >
        <slot :name="'group.header.notif'" v-bind="scope" />
      </template>

      <template
        v-if="$scopedSlots['body.append']"
        :slot="'body.append'"
        slot-scope="scope"
      >
        <slot :name="'body.append'" v-bind="scope" />
      </template>

      <template
        v-for="head in scopeHeaders.filter(
          (e) =>
            (e.dataType &&
              (e.dataType === 'object' ||
                e.dataType === 'enum' ||
                e.dataType === 'boolean')) ||
            (e.displayText &&
              !Object.keys($scopedSlots).includes('item.' + e.value))
        )"
        :slot="`item.${head.value}`"
        slot-scope="{ item }"
      >
        <!-- Инициализация тела boolean -->
        <template v-if="head.dataType === 'boolean'">
          {{ columnBooleanText(head, item[head.value], item) }}</template
        >

        <!-- Инициализация тела object -->
        <template v-else-if="head.dataType === 'object' || head.displayText">
          {{ columnObjectText(head, item[head.value], item) }}</template
        >

        <!-- Инициализация тела enums -->
        <template v-else-if="head.dataType === 'enum'">
          {{ columnEnumText(head.options, item[head.value]) }}</template
        >
      </template>

      <!-- Инициализация тела numberBar -->
      <template
        v-for="head in scopeHeaders.filter(
          (e) => e.dataType && e.dataType === 'numberBar'
        )"
        :slot="'item.' + head.value"
        slot-scope="{ item }"
      >
        <v-progress-linear
          :key="head.value"
          :value="
            head.options && head.options.append === '%'
              ? item[head.value]
              : (item[head.value] /
                  Math.max(...filteredDataSource.map((e) => e[head.value]))) *
                  100 || 0
          "
          height="25"
          :color="numberBarGetBackground(head, item[head.value], item)"
          style="min-width: 100px"
        >
          <strong :class="numberBarGetColor(head, item[head.value])"
            >{{ item[head.value] | numberSplit
            }}{{
              head.options && head.options.append ? head.options.append : ""
            }}</strong
          >
        </v-progress-linear>
      </template>

      <!-- Инициализация тела array -->
      <!-- Для устранения конфликта переменных используется head1 вместо head. -->
      <template
        v-for="head1 in scopeHeaders.filter(
          (e) => e.dataType && e.dataType === 'array'
        )"
        :slot="'item.' + head1.value"
        slot-scope="{ item }"
      >
        {{ columnArrayText(head1, item[head1.value]) }}
      </template>

      <template v-if="isCustomEdit" v-slot:body.append="{}">
        <v-btn
          v-if="typeof showAddInTable === 'boolean'"
          @click="addInTable"
          color="blue"
          large
          fab
          :title="textCustomAdd"
          dark
          style="
            position: fixed;
            bottom: 16px;
            right: 16px;
            animation: showWithDelay 2s forwards;
            z-index: 2;
          "
        >
          <m-icon icon="mdi-plus"></m-icon>
        </v-btn>
        <v-menu offset-y v-else>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              color="blue"
              large
              fab
              :title="textCustomAdd"
              dark
              style="
                position: fixed;
                bottom: 16px;
                right: 16px;
                animation: showWithDelay 2s forwards;
                z-index: 2;
              "
            >
              <m-icon icon="mdi-plus"></m-icon>
            </v-btn>
          </template>
          <v-list class="base-table-full_add-list">
            <v-list-item
              v-for="item in showAddInTable"
              :key="item.key"
              @click="addInTable(item.key)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template slot="item.actions" slot-scope="scope">
        <div
          style="display: flex"
          :class="{
            'table__column__actions-active':
              rowEditing && rowEditing.Id === scope.item.Id,
          }"
        >
          <slot name="item.actions" v-bind="scope" />
          <template
            v-if="
              editable &&
              (typeof editable !== 'function' || editable(scope.item)) &&
              (!rowEditing || scope.item.Id === rowEditing.Id)
            "
          >
            <v-btn
              v-if="
                rowEditing && rowEditing.Id === scope.item.Id && cancelEnabled
              "
              title="Отмена"
              icon
              @click.stop.prevent="rowEditing = null"
            >
              <m-icon small icon="mdi-close"></m-icon>
            </v-btn>
            <v-btn
              v-if="
                !hideEdit ||
                (typeof hideEdit === 'function' && !hideEdit(scope.item))
              "
              :disabled="
                !(
                  !disabledEdit ||
                  (typeof disabledEdit === 'function' &&
                    !disabledEdit(scope.item))
                )
              "
              @click.stop.prevent="edit(scope.index, scope.item)"
              title="Изменить"
              :color="
                !(rowEditing && rowEditing.Id === scope.item.Id)
                  ? 'primary'
                  : 'success'
              "
              icon
            >
              <m-icon
                small
                s16
                :icon="
                  !(rowEditing && rowEditing.Id === scope.item.Id)
                    ? 'tool-edit'
                    : 'mdi-check'
                "
              ></m-icon>
            </v-btn>
            <v-btn
              v-if="
                !hideDelete ||
                (typeof hideDelete === 'function' && !hideDelete(scope.item))
              "
              @click.stop.prevent="del(scope.item)"
              icon
              :color="'error'"
              title="Удалить"
              :disabled="
                !(
                  !disabledDelete ||
                  (typeof disabledDelete === 'function' &&
                    !disabledDelete(scope.item))
                )
              "
            >
              <m-icon s16 icon="tool-delete"></m-icon>
            </v-btn>
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { getPrefixedScopedSlots } from "vuetify/lib/util/helpers";
import BaseTable from "./BaseTable.vue";
import DataHelper from "@/utils/DataHelper";
import EnumsHelper from "@/utils/EnumsHelper";
import { diff } from "deep-diff";
import { sortBy } from "@/utils/Functions";
import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import ItemsFilter from "@/mixins/ItemsFilter.js";
import FilterInput from "@/components/filter/filterInput.vue";

export default {
  name: "base-table-full",
  components: {
    BaseTable,
    FilterInput,
  },
  mixins: [ItemsFilter],
  props: {
    dataSource: Array,
    headers: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    editable: [Boolean, Function],
    searchText: {
      type: String,
      default: () => "",
    },
    defaultObject: Function,
    textCustomAdd: { type: String, default: "Добавить" },
    showAddInTable: [Boolean, Array],
    hideEdit: [Boolean, Function],
    hideDelete: [Boolean, Function],
    disabledDelete: [Boolean, Function],
    disabledEdit: [Boolean, Function],
    editIndex: Number,
    customInplaceEdit: [Function],
    fetchDelete: [Function],
  },
  data: () => {
    return {
      isShowFilters: true,
      filters: {},
      rowEditing: null,
      rowEditingIndex: -1,
      rowEditingItems: [],
      isAddNewItem: false,
      cancelEnabled: true,
      isAppliedFilter: false,
      headersFilterInternal: [],
    };
  },
  computed: {
    scopeHeaders() {
      const scopes = Object.keys(this.$scopedSlots).map((e) =>
        e.split(".").at(-1)
      );

      return this.headersInternal.filter((e) => !scopes.includes(e.value));
    },
    headersInternal() {
      if (!this.headers) return [];
      return this.headers.map((h) => {
        const header = { ...h };

        if (header.dataType === "Date") {
          header.sort = (a, b) => {
            const s1 = DataHelper.dateTimeNormalize(a);
            const s2 = DataHelper.dateTimeNormalize(b);

            const u1 = s1?.unix();
            const u2 = s2?.unix();

            const r1 = u1 > 0 ? u1 : 0;
            const r2 = u2 > 0 ? u2 : 0;
            return r1 < r2 ? -1 : r1 > r2 ? 1 : 0;
          };
        } else if (header.dataType === "Period") {
          header.sort = (a, b) => {
            const s1 = DataHelper.dateNormalize(a.DateIn);
            const s2 = DataHelper.dateNormalize(b.DateIn);

            const u1 = s1?.unix();
            const u2 = s2?.unix();

            const r1 = u1 > 0 ? u1 : 0;
            const r2 = u2 > 0 ? u2 : 0;
            return r1 < r2 ? -1 : r1 > r2 ? 1 : 0;
          };
        } else if (
          header.dataType === "object" &&
          !header.sort &&
          header.displayText
        )
          header.sort = (a, b) => {
            const s1 = header.displayText(a);
            const s2 = header.displayText(b);
            return this.sortStrings(s1, s2);
          };
        else if (
          header.dataType === "array" &&
          !header.sort &&
          header.displayText
        ) {
          header.sort = (a, b) => {
            let result = 0;
            let index = 0;
            while (!result) {
              let s1 =
                a && a.length > index ? header.displayText(a[index]) : null;
              let s2 =
                b && b.length > index ? header.displayText(b[index]) : null;
              if (!s1 && !s2) return 0;

              s1 = s1 ? s1.toLocaleLowerCase() : null;
              s2 = s2 ? s2.toLocaleLowerCase() : null;

              result = this.sortStrings(s1, s2);
              index++;
            }
            return result;
          };
          /* 
          TODO: Еще один способ сортировки
          header.sort = (a, b) => {
            const s1 = a
              .map((e) => header.displayText(e))
              .join(", ")
              .toLocaleLowerCase();
            const s2 = b
              .map((e) => header.displayText(e))
              .join(", ")
              .toLocaleLowerCase();
            console.log("STR", s1, "---", s2);
            return this.sortStrings(s1, s2);
          }; */
        }

        // Сортировка с учетом числа в начале строки
        if (header.customSort === "NumWithString") {
          header.sort = (a, b) => {
            let t1 = header.displayText ? header.displayText(a) : a;
            let t2 = header?.displayText ? header.displayText(b) : b;

            if (typeof a === "object" && a?.length)
              t1 = a.map((e) => header?.displayText(e)).join(", ");
            if (typeof b === "object" && b?.length)
              t2 = b.map((e) => header?.displayText(e)).join(", ");

            return DataHelper.sortNumWithString(t1, t2);
          };
        }

        // Сортировка с учетом процентного числа
        if (header.customSort === "NumWithPrecent") {
          header.sort = (a, b) => {
            const t1 = header.displayText ? header.displayText(a) : a;
            const t2 = header.displayText ? header.displayText(b) : b;

            return DataHelper.sortNumWithPrecent(t1, t2);
          };
        }
        return header;
      });
    },
    isCustomEdit() {
      return !!this.showAddInTable;
    },
    filteredDataSource() {
      return this.itemsfilter(this.dataSource);
    },
  },
  watch: {
    headersInternal() {
      this.updateHeadersFilterInternal();
    },
    "$store.state.itemEdited.rowEditingIdNew": {
      immediate: true,
      handler(id) {
        if (id === -1) return;
        // Рыскртыие контейнера в который добавлен новый элемента
        for (let i = 0; i < this.dataSource.length; i++) {
          if (this.dataSource[i].Children?.find((e) => e.Id === id)) {
            if (this.dataSource[i]?.Id) {
              this.$refs["base-table"].$children[0].expansion[
                this.dataSource[i]?.Id
              ] = true;
            }
          }
        }

        const index = this.dataSource.findIndex((e) => e.Id === id);
        if (index >= 0) {
          setTimeout(() => {
            this.edit(index, this.dataSource[index]);
            this.parentExpend();
            this.$store.commit("itemEdited/setIdEdit", 0);
          }, 100);
        }
      },
    },
    rowEditing() {
      this.$store.commit("itemEdited/setEdited", {
        rowEditing: this.rowEditing,
        rowEditingIndex: this.rowEditingIndex,
      });
    },
    filters: {
      deep: true,
      handler() {
        const f = () => {
          for (const i in this.filters) {
            const item = this.filters[i];

            const val = item.value;
            if (val === null || val === undefined) continue;

            if (typeof val === "string" && val === "") continue;

            if (typeof val === "object") {
              if (val?.length !== undefined && val?.length) return true;
              else if (val?.length === undefined && Object.keys(val).length)
                return true;
            } else if (item !== null) return true;
          }
          return false;
        };
        this.isAppliedFilter = f();
      },
    },
    isShowFilters: {
      immediate: true,
      handler() {
        if (Object.hasOwn(this.$attrs, "not-filter"))
          this.isShowFilters = false;
      },
    },
    dataSource: {
      immediate: true,
      handler() {
        if (this.loading) return;
        this.initFilter(this.headersInternal, this.dataSource);
        if (!this.isAddNewItem) this.rowEditing = null;
        else this.isAddNewItem = false;
      },
    },
    editIndex: function (value) {
      if (value === -1) {
        this.rowEditingIndex = -1;
        this.rowEditing = null;
      } else if (
        !this.rowEditing &&
        this.dataSource &&
        value >= 0 &&
        value < this.dataSource.length
      ) {
        this.rowEditingIndex = value;
        this.rowEditing = { ...this.dataSource[value] };
      }
    },
    rowEditingIndex: function (value) {
      this.$emit("update:editIndex", value);
    },
  },
  mounted() {
    this.updateHeadersFilterInternal();
  },
  methods: {
    getPrefixedScopedSlots,
    updateHeadersFilterInternal() {
      // Только для верхнего уровня таблиц
      if (this.$attrs.nested === undefined)
        this.$watch(
          () => {
            return this.$refs["base-table"].computedGroupBy;
          },
          function (val) {
            if (val)
              this.headersFilterInternal = this.headersInternal
                .filter((e) => !e.disabled)
                .filter((e) => {
                  return val !== e.value;
                });
            else
              this.headersFilterInternal = this.headersInternal.filter(
                (e) => !e.disabled
              );
          },
          { immediate: true }
        );
    },
    numberBarGetBackground(head, value, item) {
      if (value === null) return "rgb(187,187, 190)";
      // Ref value
      if (head.options && head.options.refs) {
        return StatisticIndicatorHelper.getColorHexByRefs(
          head.options.refs,
          value,
          true
        );
      } else if (
        head.options &&
        typeof head.options.background === "function"
      ) {
        return head.options.background(head, value, item);
      } else
        return head.options && head.options.background
          ? head.options.background
          : "primary";
    },
    numberBarGetColor(head, value) {
      if (value === null) return "caption";
      if (head.options && typeof head.options.color === "function") {
        return head.options.color(head, value);
      } else
        return (
          (head.options && head.options.color ? head.options.color : "white") +
          "--text"
        );
    },
    parentExpend() {
      this.$emit("expend");
    },
    async closeEdit(type) {
      if (type === 1) {
        this.edit();
        return;
      }
      const d = diff(this.dataSource[this.rowEditingIndex], this.rowEditing);
      if (!d) {
        this.rowEditing = null;
      } else {
        if (
          await this.$confirm(`Вы внесли изменения. Сохранить ?`, {
            buttonFalseText: "Отмена",
            buttonFalseColor: "gray",
            buttonTrueText: "Сохранить",
            buttonTrueColor: "primary",
          })
        ) {
          this.edit();
        }
      }
    },
    sortAlf(items, header) {
      items = sortBy(items, (e) => {
        return this.columnObjectText(header, e, e);
      });
      return items;
    },

    sortStrings(s1, s2) {
      if (!s1 && !s2) return 0;
      if (s1 && !s2) return 1;
      if (!s1 && s2) return -1;
      if (s1 > s2) return 1;
      if (s1 < s2) return -1;
      return 0;
    },
    addInTable(key) {
      if (!this.$listeners.customAdd) {
        this.dataSource.push(this.defaultObject());

        this.rowEditing = this._.cloneDeep(
          this.dataSource[this.dataSource.length - 1]
        );
        this.rowEditingIndex = this.dataSource.length - 1;
      } else this.$emit("customAdd", key);
      this.isAddNewItem = true;
      this.cancelEnabled = false;
    },
    chechItems: function (items, val) {
      const filter = this._.get(this.filters, val).value;

      if (filter?.length) {
        const deleteItems = filter.filter((e) => {
          return !items.find((e2) => e2 === e || e2?.Id === e);
        });
        deleteItems.forEach((element) => {
          filter.splice(filter.indexOf(element), 1);
        });
      }
    },

    columnBooleanText(header, object, item) {
      const value = this._.get(item, header.value);

      if (header?.options)
        return value ? header?.options.true : header?.options.false;
      return value ? "Да" : "Нет";
    },

    columnEnumText(options, value) {
      return EnumsHelper.getEnums(value, options);
    },
    columnObjectText(header, object, item) {
      // Если значение составное. Примерно value = Employee.Place
      if (object === undefined) {
        object = this._.get(item, header.value);
      }
      if (header?.displayText) {
        return header?.displayText(object, item);
      } else {
        return object.Id;
      }
    },
    columnArrayText(header, array) {
      if (!array) return null;
      return array.map((item) => header?.displayText(item)).join(", ");
    },
    async del(item) {
      if (this.$listeners.customDelete) {
        this.$listeners.customDelete(item);
        return;
      }

      if (this.fetchDelete && !(await this.fetchDelete(item))) return false;

      const index = this.dataSource.findIndex((e) => e.Id === item.Id);
      if (index >= 0) {
        this.dataSource.splice(index, 1);
        if (index === this.rowEditingIndex) {
          this.rowEditing = null;
          this.rowEditingIndex = -1;
        }
      }

      if (this.$listeners.afterDelete) {
        this.$listeners.afterDelete(item);
      }
    },
    async edit(index, item) {
      this.cancelEnabled = true;
      const itemEdit = this._.cloneDeep(item);
      if (this.$listeners.customEdit) {
        this.$listeners.customEdit(itemEdit);
        return;
      }

      if (!this.rowEditing) {
        this.rowEditing = itemEdit;
        this.rowEditingIndex = index;
      } else {
        let val = { ...this.rowEditing };
        let newVal = val;

        if (this.customInplaceEdit) {
          newVal = await this.customInplaceEdit(val);
          if (!newVal) return;
          else val = newVal;
        }
        const ri = this.dataSource.findIndex(
          (e) => e.Id === this.rowEditing.Id
        );
        this.$set(this.dataSource, ri, newVal);

        this.rowEditing = null;
        this.rowEditingIndex = -1;

        if (this.$listeners.afterEdit) {
          this.$listeners.afterEdit(itemEdit, newVal);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.base-table-full__filter {
  &:hover {
    background-color: unset !important;
  }
}

.base-table-full__filter .v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px;

  .v-input__append-inner {
    margin: 0 !important;
    align-self: center !important;
  }

  .v-select__slot .v-input__append-inner {
    margin-top: 3px !important;
    align-self: flex-start !important;
  }

  .v-select__selections {
    padding: 0 !important;
  }
}
</style>
