<template>
  <v-layout>
    <base-list-view
      title="Чек-листы"
      @onRefresh="init"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      :selectedItems.sync="selected"
      @add="add"
      @onDelete="del"
      pageEditName="CheckListEdit"
      :multiple-save="[
        { value: 'new', text: 'Создать чек-лист' },
        { value: 'clone', text: 'Создать на основе имеющегося' },
        { value: 'import', text: 'Импортировать готовый' },
      ]"
      show-actions
      ref="baseListView"
    >
      <v-btn
        slot="action"
        @click="dialog = true"
        :disabled="!isSelected"
        icon
        title="Изменить"
      >
        <m-icon icon="tool-edit" s16></m-icon>
      </v-btn>
      <template v-slot:item.actions="{ item }">
        <report-activator
          :report="['CheckListReport']"
          :params="{ objectId: { name: 'pCheckList', value: item.Id } }"
        />
      </template>
      <div row slot="action" class="align-center d-inline-flex">
        <label
          class="tasks__action-show-all v-label blue-grey--text mr-1"
          style="font-size: 14px"
          >Показать архивные</label
        >

        <v-checkbox
          v-model="showCompleted"
          @change="init"
          hide-details
        ></v-checkbox>
      </div>
    </base-list-view>
    <check-list-import
      v-model="dialogImport"
      @afterLoadFile="afterImport"
      :width="'80vw'"
      :height="'80vh'"
    />
    <base-popup
      title="Установить"
      :icon="UtilGetIcon('view-CheckLists')"
      :width="'40vw'"
      :height="'20vw'"
      @save="save"
      v-model="dialog"
      buttonSaveText="Применить"
    >
      <v-container>
        <v-row>
          <v-col>
            <base-date-picker
              v-model="archivedDate"
              label="Дата перевода чек-листов в архив"
              clearable
            ></base-date-picker>
          </v-col>
        </v-row>
      </v-container>
    </base-popup>
  </v-layout>
</template>

<script>
import ListEditMixin from "../../mixins/ListEditMixin";
// import { CheckListObjectTypes } from "../../data/enums";
import BaseListView from "../../layouts/BaseListView.vue";
import CheckListImport from "../../components/CheckList/CheckListImport.vue";
import CheckListService from "../../services/CheckListService";
import ReportActivator from "../../components/report/ReportActivator.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";

export default {
  name: "view-CheckLists",
  components: {
    BaseListView,
    CheckListImport,
    ReportActivator,
    BasePopup,
    BaseDatePicker,
  },
  mixins: [ListEditMixin],
  data: () => {
    return {
      showCompleted: false,
      apiService: CheckListService,
      loading: true,
      headers: [
        {
          text: "Название",
          value: "Name",
          notGroup: true,
          customSort: "NumWithString",
        },
        {
          text: "Показатель оценки качества",
          value: "IndicatorsNames",
          customSort: "NumWithString",
        },
        // {
        //   text: "Тип",
        //   value: "Type",
        //   dataType: "enum",
        //   options: CheckListObjectTypes.filter((c) => c.id !== 3),
        // },
        {
          text: "Номер версии",
          value: "VersionNum",
          align: "center",
        },
      ],
      dataSource: [],
      dialogImport: false,
      selected: [],
      dialog: false,
      archivedDate: null,
    };
  },
  computed: {
    isSelected() {
      return this.selected.length > 0;
    },
  },
  watch: {
    showCompleted: function (val) {
      if (val && !this.headers.find((e) => e.value === "Archived")) {
        this.headers.push({
          text: "Добавлено в архив",
          value: "Archived",
          dataType: "Date",
        });
      } else if (!val && this.headers.find((e) => e.value === "Archived")) {
        this.headers = this.headers.filter((e) => e.value !== "Archived");
      }
    },
    dialog(visible) {
      if (visible) {
        this.archivedDate = null;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;

        const res = await CheckListService.get(
          [],
          [0, 1, 2, 4],
          false,
          null,
          this.showCompleted
        );
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    DataFromServerNormalize(data) {
      for (const value of data)
        value.IndicatorsNames = value.Indicators
          ? value.Indicators.map((ind) => ind.Name).join(", ")
          : null;
      return data;
    },
    add(item) {
      if (item.value === "clone") {
        // todo: Говно код, исарвить
        this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
          listViewUpdateItems: this.$refs.baseListView.updateItems,
          listViewInit: this.$parent?.init,
        });
        this.$router.push({
          name: "CheckListClone",
        });
      } else if (item.value === "import") {
        this.dialogImport = true;
      }
    },
    afterImport() {
      this.dialogImport = false;
      this.init();
    },
    async save() {
      if (!this.archivedDate) return;
      await this.apiService.updateMultiple(
        this.archivedDate,
        this.selected.map((s) => s.Id)
      );
      this.dialog = false;
    },
  },
};
</script>
<style scoped></style>
