<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      @onRefresh="init"
      :show-save="false"
      not-spacer-action
      fixed-header
      :class="{ 'check-operation-run__title-color': bg }"
    >
      <template slot="title">
        <v-toolbar-title>
          Проверка по чек-листу
          <span class="caption ml-5">{{
            dataSource.objectCheck
              ? dataSource.objectCheck
              : dataSource.CheckList
              ? dataSource.CheckList.Name
              : ""
          }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-flex
          justify-end
          row
          align="start"
          class="check-operation-run__header__actions-many"
        >
          <div class="d-inline-flex">
            <v-btn
              :disabled="!tree.length || readOnly"
              @click.stop="manyAction(1)"
              text
            >
              <m-icon
                icon="check-a"
                size="18"
                left
                :color="!tree.length || readOnly ? 'inherit' : '#00c880'"
              />
              Да
            </v-btn>
          </div>
          <div class="d-inline-flex">
            <v-btn
              :disabled="!tree.length || readOnly"
              @click.stop="manyAction(2)"
              text
            >
              <m-icon
                icon="check-b"
                size="18"
                left
                :color="!tree.length || readOnly ? 'inherit' : '#fc6955'"
              />
              Нет
            </v-btn>
          </div>
          <div class="d-inline-flex">
            <v-btn
              :disabled="!tree.length || readOnly"
              @click.stop="manyAction(3)"
              text
            >
              <m-icon
                icon="check-c"
                size="18"
                left
                :color="!tree.length || readOnly ? 'inherit' : '#fcb955'"
              />
              Не применимо
            </v-btn>
          </div>
          <div class="d-inline-flex">
            <v-btn
              :disabled="!tree.length || readOnly"
              @click.stop="manyAction(0)"
              text
            >
              <m-icon icon="mdi-close" left />
              Очистить
            </v-btn>
          </div>
        </v-flex>
      </template>
      <v-treeview
        v-model="tree"
        :items="dataSource.Questions"
        :open.sync="open"
        :active="active"
        @update:active="
          active = $event.length && $event[0].Name ? null : $event
        "
        expand-icon="$ToolArrowDown"
        item-text="Text"
        item-key="Num"
        item-children="Children"
        activatable
        return-object
        selectable
        class="block__content"
      >
        <template v-slot:label="{ item }">
          <span
            :class="
              ['', 'success--text', 'error--text', 'warning--text'][item.Answer]
            "
          >
            <b
              v-if="
                (item.IsRequired && !item.Children.length) || isRequired(item)
              "
            >
              {{ item.Num + " " + item.Text }}
            </b>
            <span v-else> {{ item.Num + " " + item.Text }} </span>
          </span>
        </template>
        <template v-slot:append="{ item }">
          <template v-if="!item.Id">
            <div style="display: flex">
              <div
                class="answer__item"
                v-for="{
                  name,
                  nameOutline,
                  title,
                  value,
                  color,
                } in answerOptions"
                :key="name"
              >
                <v-btn
                  :disabled="readOnly"
                  @click.stop="action(item, value)"
                  icon
                  :title="title"
                >
                  <m-icon
                    :icon="value === item.Answer ? name : nameOutline"
                    :color="!readOnly ? color : 'inherit'"
                  ></m-icon>
                </v-btn>
              </div>
              <div class="answer_text">
                <div class="answer__item">
                  <template v-if="item.Children.length"
                    >{{ item.AnsweredQuestionCount }}/{{
                      item.QuestionCount
                    }}</template
                  >
                </div>
                <div
                  class="answer__item"
                  style="
                    width: 148px;
                    justify-content: left;
                    padding-left: 0.5rem;
                  "
                  v-html="item.Children.length ? getTotalProcent(item) : ''"
                ></div>
              </div>
            </div>
          </template>
          <div v-else style="display: flex">
            <div class="answer_text">
              <div
                class="answer__item"
                v-for="(item, i) in getColAnswers(item).slice(1)"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
            <div class="answer_text">
              <div class="answer__item">
                <template v-if="item.Children.length"
                  >{{ item.AnsweredQuestionCount }}/{{
                    item.QuestionCount
                  }}</template
                >
              </div>
              <div
                class="answer__item text-left"
                style="
                  width: 148px;
                  justify-content: left;
                  padding-left: 0.5rem;
                "
                v-html="item.Children.length ? getTotalProcent(item) : ''"
              ></div>
            </div>
          </div>
        </template>
      </v-treeview>
    </base-detail-view>
    <check-operation-question-notes
      :question="active[0]"
      :answerOptions="answerOptions"
      :treeNames="treeNames"
      :auditOperationNum="dataSource.AuditOperationNum"
      :checkListName="dataSource.CheckList ? dataSource.CheckList.Name : ''"
      :objectCheck="dataSource.objectCheck"
      @action="action"
      @close="active = []"
      :readonly="readOnly"
      :width="'80vw'"
      :height="'80vh'"
    />

    <informer v-if="dataSource.AuditOperationStatus === 3">
      Проверка завершена.<br />
      Изменение результатов завершенной проверки недопустимо!
    </informer>
    <informer v-else-if="!dataSource.CurrentUserIsAuditor">
      Проверка доступна только для просмотра. Проводить проверку и вносить
      изменения может только назначенный аудитор. Аудиторами для проведения
      проверки по данному чек-листу назначены:
      {{ auditors }}
    </informer>
    <informer v-else-if="dataSource.AuditOperationStatus === 0">
      Проверка доступна только для просмотра. Для проведения проверки по
      чек-листу закройте это окно и нажмите кнопку "Начать" в окне
      редактирования проверки
    </informer>
    <informer
      v-else-if="isAllSign && !(dataSource.Status === 3)"
      type="warning"
    >
      Проверка уже подписана. Внесение изменений запрещено.
    </informer>
    <informer v-else-if="dataSource.CurrentAuditorHasSign" type="warning">
      Проверка подписана. Внесение изменений невозможно.<br />
      Для внесения изменений отзовите подпись.
    </informer>
  </v-layout>
</template>

<script>
import Informer from "@/modules/VuetifyInformer/informer";
import CheckOperationService from "@/services/CheckOperationService";
import BaseDetailView from "../../layouts/BaseDetailView";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import CheckOperationQuestionNotes from "../../components/CheckOperationRun/CheckOperationQuestionNotes";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-CheckOperationRun",
  components: {
    BaseDetailView,
    CheckOperationQuestionNotes,
    Informer,
  },
  mixins: [CreateAndEditMixin],
  provide() {
    const external = {};

    Object.defineProperty(external, "value", {
      enumerable: true,
      get: () => this.dataSource.External,
    });
    return {
      external,
    };
  },
  data: () => {
    return {
      apiService: CheckOperationService,
      getObjectText: (obj) => "№" + obj.AuditOperationNum,
      checkOperationUpdateStatus: null,
      // View
      bg: false,
      answerOptions: [
        {
          name: "check-a",
          nameOutline: "check-d",
          color: "#00c880",
          title: "Да",
          value: 1,
        },
        {
          name: "check-b",
          nameOutline: "check-e",
          color: "#fc6955",
          title: "Нет",
          value: 2,
        },
        {
          name: "check-c",
          nameOutline: "check-f",
          color: "#fcb955",
          title: "Не применимо",
          value: 3,
        },
        {
          name: "mdi-close",
          nameOutline: "mdi-close",
          title: "Очистить",
          value: 0,
        },
      ],
      loading: true,

      tree: [],
      active: [],
      open: [],
      dataSource: {
        Questions: [],
        objectCheck: null,
      },
      activeNodeNames: [],
      treeNames: {},
    };
  },
  computed: {
    readOnly() {
      return (
        !this.dataSource.CurrentUserIsAuditor ||
        this.dataSource.CurrentAuditorHasSign ||
        this.dataSource.AuditOperationStatus !== 1
      );
    },
    auditors() {
      return this.dataSource.Auditors?.map((e) =>
        this.$options.filters.PersonShortName(e.Employee)
      ).join(", ");
    },
    isAllSign() {
      let result = false;
      if (this.dataSource.Auditors && this.dataSource.Auditors.length > 0) {
        result = this.dataSource.Auditors.every((auditor) => auditor.HasSign);
      }
      return result;
    },
  },

  watch: {
    active: function (val) {
      this.activeNodeNames = [];
      this.treeNames = null;
      let point = null;

      if (val[0]) {
        this.getNameParents(this.dataSource.Questions[0], val[0]);
        this.activeNodeNames.push(this.dataSource.Questions[0].Text);
        this.activeNodeNames.reverse();

        for (const name of this.activeNodeNames) {
          if (!point) {
            point = { Text: this.dataSource.Questions[0].Text, Children: [] };
            this.treeNames = point;
          } else {
            point.Children.push({ Text: name, Children: [] });
            point = point.Children[0];
          }
        }
      }
    },
  },
  async mounted() {
    await this.init();

    window.addEventListener("scroll", () => {
      this.changeColor();
    });

    // Регистрация функции для обновления элемента в списочном представлении
    this.checkOperationUpdateStatus =
      this.$store.state.pageTabs.transaction.auditOperation;
    this.$store.dispatch("pageTabs/TAKE_TRANSACTION_AUDIT_OPERATION");

    // Информер
    /* this.cmpInformer = null;
    this.$watch(
      () => this.dataSource.CurrentUserIsAuditor,
      async (val) => {
        console.log(val, this.cmpInformer);
        if (!val && !this.cmpInformer) {
          this.cmpInformer = await this.$informer({
            container: this.$el,
            route: this.$route,
            message:
              "Проверка доступна только для просмотра. Проводить проверку и вноситьизменения может только назначенный аудитор. Аудитором для проведенияпроверки по данному чек-листу назначен {Фамилия И.О. аудитора}",
            type: "warning",
          });
        } else if (val && this.cmpInformer) {
          this.cmpInformer.$destroy();
          delete this.cmpInformer;
        }
      }
    ); */
  },
  methods: {
    changeColor() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.bg = true;
      } else {
        this.bg = false;
      }
    },
    // getTotalAnswer(item) {
    //   const t = this.getColAnswers(item);
    //   return (
    //     t.slice(1).reduce((a, b) => a + b) + "/" + t.reduce((a, b) => a + b)
    //   );
    // },
    getTotalProcent(item) {
      const t = this.getColAnswers(item);
      const sum = t.slice(1).reduce((a, b) => a + b);
      const color =
        t[0] === 0 &&
        (sum === t[3]
          ? "orange--text"
          : sum === t[1] + t[3]
          ? "green--text"
          : "red--text");

      return (
        "<span class='" +
        color +
        "'> Соответствует " +
        item.AnsweredYesPercent +
        "%" +
        "</span>"
      );
    },
    isRequired(item) {
      return DataHelper.treeListTraverse(item, function (child, index, parent) {
        if (!child.Children.length && child.IsRequired) {
          return 1;
        }
      });
    },
    getNameParents(tree, item) {
      for (const child of tree.Children) {
        if (item.Num === child.Num) {
          this.activeNodeNames.push(child.Num + " " + child.Text);
          return true;
        } else {
          if (this.getNameParents(child, item)) {
            this.activeNodeNames.push(child.Num + " " + child.Text);
            return true;
          }
        }
      }
    },
    async changeAnswer(val) {
      let d = null;
      try {
        const { data } = await CheckOperationService.update(
          val,
          false,
          this.dataSource.External
        );
        d = data;
      } catch (error) {
        console.log(error);
      } finally {
        await this.init();
        if (d >= 0 && this.checkOperationUpdateStatus)
          this.checkOperationUpdateStatus(this.dataSource.Id, this.dataSource);
      }
    },
    DataFromServerNormalize(data) {
      const parent = data.CheckList;
      parent.Children = data.Questions;
      parent.Text = parent.Name;
      parent.Num = "";
      parent.Answer = 0;
      parent.IsRequired = false;
      parent.AnsweredYesPercent = data.AnsweredYesPercent;
      parent.AnsweredQuestionCount = data.AnsweredQuestionCount;
      parent.QuestionCount = data.QuestionCount;
      data.Questions = [];
      data.Questions.push(parent);

      // Установка рандомного айди нарушениям/рекомендациям
      var rec = (_parent) => {
        for (const child of _parent.Children) {
          if (!child.Children.length) {
            for (const child2 of child.Violations) {
              if (!child2.Id) child2.Id = Math.round(Math.random() * 1000000);
            }
          } else rec(child);
        }
      };
      rec(parent);

      // add objectCheck
      this.$set(
        data,
        "objectCheck",
        data.CustomObject
          ? data.CustomObject
          : data.EmployeeObject
          ? this.$options.filters.PersonShortName(data.EmployeeObject)
          : data.PartitionObject
          ? data.PartitionObject.Name
          : ""
      );

      this.open.push(data.Questions[0]);
      return data;
    },
    async init() {
      await this.baseInit();
    },
    getColAnswers(item, answers = [0, 0, 0, 0]) {
      // Установка родительским узлам Answer
      const childAnswers = item.Children.map((e) => e.Answer);

      // Если явно не указан ответ "Не выполняется", то ответ будет как вычислимое поле на основе дочерних
      if (item.Answer !== 2) {
        item.Answer = childAnswers.every((e) => e === 3)
          ? 3
          : childAnswers.every((e) => e === 1 || e === 3)
          ? 1
          : childAnswers.some((e) => e === 2)
          ? 2
          : 0;
      }

      for (const i in item.Children) {
        const element = item.Children[i];
        if (element.Children.length === 0) {
          answers[element.Answer]++;
        } else {
          this.getColAnswers(element, answers);
        }
      }
      return answers;
    },
    async action(item, answer) {
      if (!(await this.checkConfirm([item], answer))) return;
      item.Answer = answer;

      const t = DataHelper.findTreeById(this.tree, item.ResultId, "ResultId");
      if (t) {
        t.Answer = answer;
      }
      this.changeAnswer(item);
    },
    async manyAction(answer) {
      if (!(await this.checkConfirm(this.tree, answer))) return;

      const arr = this.tree.filter(
        (e) => e.Answer === 0 || (answer === 0 && e.Answer !== 0)
      );

      for (let i = 0; i < arr.length; i++) {
        if (answer !== 0 && arr[i].Answer !== 0) continue;
        arr[i].Answer = answer;
      }

      this.changeAnswer(arr);
    },
    colViolations(chechQuestions) {
      let c = chechQuestions.filter(
        (e) =>
          e.Violations.filter((e2) => e2.IsChecked).length > 0 && e.Answer === 2
      ).length;
      for (let i = 0; i < chechQuestions.length; i++) {
        c += this.colViolations(chechQuestions[i].Children);
      }
      return c;
    },
    colAnswerQuestion(chechQuestions) {
      let c = chechQuestions.filter((e) => e.Answer !== 0).length;

      for (let i = 0; i < chechQuestions.length; i++) {
        c += this.colAnswerQuestion(chechQuestions[i].Children);
      }
      return c;
    },
    async checkConfirm(chechQuestions, answer) {
      if (answer === 2) return true;

      const colViolations = this.colViolations(chechQuestions);

      let r = true;

      if (colViolations > 0) {
        if (chechQuestions.length === 1 && !chechQuestions[0].Children.length) {
          const colViolations = chechQuestions[0].Violations.filter(
            (e) => e.IsChecked
          ).length;
          const colRecommendations = chechQuestions[0].Violations.filter(
            (e) => e.IsChecked
          ).reduce((sum, b) => {
            return sum + b?.Recommendations.filter((e) => e.IsChecked).length;
          }, 0);
          if (colViolations > 0 || colRecommendations > 0)
            r = await this.$confirm(
              `Вопрос содержит ${colViolations} несоответствия, ${colRecommendations} рекомендации: Очистить результаты?`
            );
        } else {
          r = await this.$confirm(
            "Количество вопросов с несоответствиями: " +
              colViolations +
              ". Очистить несоответствия?"
          );
        }
      }
      return r;
    },
  },
};
</script>

<style lang="scss">
.check-operation-run__title-color {
  .v-toolbar {
    padding: 0.5rem;
    background-color: rgb(240, 242, 245) !important;

    height: 54px !important;
    padding: 0.5rem 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
</style>

<style lang="sass">
.answer_text
  padding-right: 48px

.answer__item
  width: 48px
  height: 24px
  display: inline-flex
  justify-content: center
  align-items: center

.check-operation-run__header__actions-many
  padding-left: 10px
  white-space: nowrap
  display: block
  text-align: right
  .v-btn
   padding: 0 5px !important
</style>
