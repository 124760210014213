<template>
  <div class="block__content statistic-indicator-setting-refs">
    <v-form :readonly="readonly">
      <v-row>
        <v-col class="statistic-indicator-setting-refs__refs">
          <div class="statistic-indicator-setting-refs__ref">
            <div class="statistic-indicator-setting-refs__ref-title">
              Референсное значение 1
            </div>
            <div class="statistic-indicator-setting-refs__ref-input">
              <v-text-field
                :value="RefValue1"
                :rules="[(value) => rules.ref1(value, RefValue2, RefValue3)]"
                @input="updateRef('update:RefValue1', $event)"
                type="number"
                placeholder="Нет"
                :clearable="!readonly"
              ></v-text-field>
            </div>
          </div>
          <div class="statistic-indicator-setting-refs__ref">
            <div class="statistic-indicator-setting-refs__ref-title">
              Референсное значение 2
            </div>
            <div class="statistic-indicator-setting-refs__ref-input">
              <v-text-field
                :value="RefValue2"
                :rules="[(value) => rules.ref2(value, RefValue1, RefValue3)]"
                @input="updateRef('update:RefValue2', $event)"
                :disabled="RefValue1 === null || RefValue1 === ''"
                type="number"
                placeholder="Нет"
                :clearable="!readonly"
              ></v-text-field>
            </div>
          </div>
          <div class="statistic-indicator-setting-refs__ref">
            <div class="statistic-indicator-setting-refs__ref-title">
              Референсное значение 3
            </div>
            <div class="statistic-indicator-setting-refs__ref-input">
              <v-text-field
                :value="RefValue3"
                :rules="[(value) => rules.ref3(value, RefValue2)]"
                @input="updateRef('update:RefValue3', $event)"
                :disabled="RefValue2 === null || RefValue2 === ''"
                type="number"
                placeholder="Нет"
                :clearable="!readonly"
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col>
          <div>
            <p class="statistic-indicator-setting-refs__ref-title text-center">
              Диапазоны референсных значений
            </p>
            <div class="statistic-indicator-setting-refs__labels">
              <!-- 1 блока -->
              <div
                v-if="
                  !(RefValue1 === null || RefValue1 === '') &&
                  !(
                    (RefValue3 === null || RefValue3 === '') &&
                    RatingType === 0
                  )
                "
                class="statistic-indicator-setting-refs__labels-label"
                :class="colors[0]"
              >
                <div
                  class="statistic-indicator-setting-refs__labels-range d-flex align-center justify-center"
                >
                  <span> X &#8924; {{ RefValue1 }}</span>
                </div>
                <div class="statistic-indicator-setting-refs__labels-content">
                  <div
                    class="statistic-indicator-setting-refs__labels-text d-flex align-center"
                    :title="RefDescriptions[0].text"
                  >
                    <template v-if="!refDescEdit[0]">
                      {{ RefDescriptions[0].text }}
                    </template>
                    <v-textarea
                      v-else
                      :value="RefDescriptions[0].text"
                      @change="changeRef(RefDescriptions[0], $event)"
                      placeholder="Введите текст"
                      flat
                      rows="2"
                      autofocus
                      :counter="100"
                      :maxlength="100"
                      v-up-first-symbol
                    ></v-textarea>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn
                      v-if="!readonly"
                      color="blue-grey"
                      icon
                      @click="$set(refDescEdit, 0, !refDescEdit[0])"
                    >
                      <m-icon icon="tool-edit" s16></m-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- 2 блока -->
              <div
                v-if="
                  !(RefValue1 === null || RefValue1 === '') &&
                  !(
                    (RefValue2 === null || RefValue2 === '') &&
                    RatingType === 1
                  )
                "
                class="statistic-indicator-setting-refs__labels-label"
                :class="colors[1]"
              >
                <div
                  class="statistic-indicator-setting-refs__labels-range d-flex align-center justify-center"
                >
                  <span
                    v-if="
                      (RefValue2 === null || RefValue2 === '') &&
                      RatingType === 0
                    "
                    >X &#8924; {{ RefValue1 }}
                  </span>
                  <span
                    v-else-if="
                      (RefValue3 === null || RefValue3 === '') &&
                      RatingType === 0
                    "
                    >X &#8924; {{ RefValue1 }}</span
                  >

                  <span v-else
                    >{{ RefValue1 }} &#60; X &#8924; {{ RefValue2 }}</span
                  >
                </div>
                <div class="statistic-indicator-setting-refs__labels-content">
                  <div
                    class="statistic-indicator-setting-refs__labels-text d-flex align-center"
                    :title="RefDescriptions[1].text"
                  >
                    <template v-if="!refDescEdit[1]">
                      {{ RefDescriptions[1].text }}
                    </template>
                    <v-textarea
                      v-else
                      :value="RefDescriptions[1].text"
                      @change="changeRef(RefDescriptions[1], $event)"
                      placeholder="Введите текст"
                      flat
                      rows="2"
                      autofocus
                      :counter="100"
                      :maxlength="100"
                      v-up-first-symbol
                    ></v-textarea>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn
                      v-if="!readonly"
                      color="blue-grey"
                      icon
                      @click="$set(refDescEdit, 1, !refDescEdit[1])"
                    >
                      <m-icon icon="tool-edit" s16></m-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- 3 блока -->
              <div
                v-if="
                  !(
                    (RefValue1 === null || RefValue1 === '') &&
                    RatingType === 1
                  ) &&
                  !(
                    (RefValue2 === null || RefValue2 === '') &&
                    RatingType === 0
                  )
                "
                class="statistic-indicator-setting-refs__labels-label"
                :class="colors[2]"
              >
                <div
                  class="statistic-indicator-setting-refs__labels-range d-flex align-center justify-center"
                >
                  <span
                    v-if="
                      (RefValue3 === null || RefValue3 === '') &&
                      RatingType === 0
                    "
                    >{{ RefValue1 }} &#60; X &#8924; {{ RefValue2 }}</span
                  >
                  <span v-else-if="RefValue2 === null || RefValue2 === ''"
                    >X &#62; {{ RefValue1 }}</span
                  >
                  <span v-else-if="RefValue3 === null || RefValue3 === ''"
                    >X &#62; {{ RefValue2 }}</span
                  >
                  <span v-else
                    >{{ RefValue2 }} &#60; X &#8924; {{ RefValue3 }}</span
                  >
                </div>
                <div class="statistic-indicator-setting-refs__labels-content">
                  <div
                    class="statistic-indicator-setting-refs__labels-text d-flex align-center"
                    :title="RefDescriptions[2].text"
                  >
                    <template v-if="!refDescEdit[2]">
                      {{ RefDescriptions[2].text }}
                    </template>
                    <v-textarea
                      v-else
                      :value="RefDescriptions[2].text"
                      @change="changeRef(RefDescriptions[2], $event)"
                      placeholder="Введите текст"
                      flat
                      rows="2"
                      autofocus
                      :counter="100"
                      :maxlength="100"
                      v-up-first-symbol
                    ></v-textarea>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn
                      v-if="!readonly"
                      color="blue-grey"
                      icon
                      @click="$set(refDescEdit, 2, !refDescEdit[2])"
                    >
                      <m-icon icon="tool-edit" s16></m-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- 4 блока -->
              <div
                v-if="
                  !(RefValue1 === null || RefValue1 === '') &&
                  !(
                    (RefValue3 === null || RefValue3 === '') &&
                    RatingType === 1
                  )
                "
                class="statistic-indicator-setting-refs__labels-label"
                :class="colors[3]"
              >
                <div
                  class="statistic-indicator-setting-refs__labels-range d-flex align-center justify-center"
                >
                  <span
                    v-if="
                      (RefValue2 === null || RefValue2 === '') &&
                      RatingType === 0
                    "
                    >X &#62; {{ RefValue1 }}
                  </span>
                  <span
                    v-else-if="
                      (RefValue3 === null || RefValue3 === '') &&
                      RatingType === 0
                    "
                    >X &#62; {{ RefValue2 }}
                  </span>
                  <span v-else> X &#62; {{ RefValue3 }}</span>
                </div>
                <div class="statistic-indicator-setting-refs__labels-content">
                  <div
                    class="statistic-indicator-setting-refs__labels-text d-flex align-center"
                    :title="RefDescriptions[3].text"
                  >
                    <template v-if="!refDescEdit[3]">
                      {{ RefDescriptions[3].text }}
                    </template>
                    <v-textarea
                      v-else
                      :value="RefDescriptions[3].text"
                      @change="changeRef(RefDescriptions[3], $event)"
                      placeholder="Введите текст"
                      flat
                      rows="2"
                      autofocus
                      :counter="100"
                      :maxlength="100"
                      v-up-first-symbol
                    ></v-textarea>
                  </div>
                  <div class="d-flex align-center">
                    <v-btn
                      v-if="!readonly"
                      color="blue-grey"
                      icon
                      @click="$set(refDescEdit, 3, !refDescEdit[3])"
                    >
                      <m-icon icon="tool-edit" s16></m-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <!-- Пустой блок -->
              <div
                v-for="i in [RefValue1, RefValue2, RefValue3].filter(
                  (e) => e === null || e === ''
                ).length + (RefValue1 === null || RefValue1 === '')"
                class="statistic-indicator-setting-refs__labels-label color5"
                :key="i"
              >
                <div
                  class="statistic-indicator-setting-refs__labels-range d-flex align-center justify-center"
                >
                  <span> Нет </span>
                </div>
                <div class="statistic-indicator-setting-refs__labels-content">
                  <div
                    class="statistic-indicator-setting-refs__labels-text d-flex align-center"
                  >
                    Не применяется
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <div></div>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    RefValue1: {
      type: [Number, String],
      default: null,
    },
    RefValue2: {
      type: [Number, String],
      default: null,
    },
    RefValue3: {
      type: [Number, String],
      default: null,
    },
    RefDescription1: {
      type: String,
      default: null,
    },
    RefDescription2: {
      type: String,
      default: null,
    },
    RefDescription3: {
      type: String,
      default: null,
    },
    RefDescription4: {
      type: String,
      default: null,
    },
    RatingType: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refs: { RefValue1: null, RefValue2: null, RefValue3: null },
      rules: {
        required: (value) =>
          this.isNumber(parseFloat(value)) || "Укажите значение.",
        ref1: (value, value2, value3) => {
          const val1 = parseFloat(value);
          const val2 = parseFloat(value2);
          const val3 = parseFloat(value3);

          if (!this.isNumber(val1)) return false;
          if (!this.isNumber(val2)) return true;

          if (val1 >= val2 || (this.isNumber(val3) && val1 >= val3))
            return (
              false ||
              "Каждое значение должно быть последовательно больше предыдущего"
            );
          else return true;
        },
        ref2: (value, value1, value3) => {
          const v1 = parseFloat(value1);
          const v2 = parseFloat(value);
          const v3 = parseFloat(value3);

          if (!this.isNumber(v2)) return true;

          if ((this.isNumber(v3) && v2 > v3) || v2 <= v1)
            return (
              false ||
              "Каждое значение должно быть последовательно больше предыдущего"
            );
          else return true;
        },

        ref3: (value, val2) => {
          const v = parseFloat(value);
          const v2 = parseFloat(val2);

          if (!this.isNumber(v)) return true;

          if (this.isNumber(v2) && v > v2) return true;
          else
            return (
              false ||
              "Каждое значение должно быть последовательно больше предыдущего"
            );
        },
      },
      refDescEdit: [false, false, false, false],
      refDescDefault: [
        "Значение статистического показателя требует принятия незамедлительных действий",
        "Значение статистического показателя вызывает тревогу",
        "Допустимое значение статистического показателя",
        "Очень хорошее значение статистического показателя",
      ],
      dcolors: ["color1", "color2", "color3", "color4"],
      colors: ["color1", "color2", "color3", "color4"],
    };
  },
  computed: {
    RefDescriptions() {
      let array = [
        { num: 1, text: this.RefDescription1 },
        { num: 2, text: this.RefDescription2 },
        { num: 3, text: this.RefDescription3 },
        { num: 4, text: this.RefDescription4 },
      ];
      if (this.RatingType === 0) array = array.reverse();
      return array;
    },
  },
  watch: {
    RatingType: {
      immediate: true,
      handler: function () {
        this.colors = this.RatingType
          ? [...this.dcolors]
          : [...this.dcolors].reverse();
      },
    },
  },
  methods: {
    changeRef(ref, value) {
      this.$emit("update:RefDescription" + ref.num, value);
    },
    resetEdit() {
      this.refDescEdit = [false, false, false, false];
    },
    updateRef(e, event) {
      this.$emit(e, event);
      if (e === "update:RefValue1" && event === null) {
        this.$emit("update:RefValue2", null);
        this.$emit("update:RefValue3", null);
      }

      if (e === "update:RefValue2" && event === null) {
        this.$emit("update:RefValue3", null);
      }
    },
    isNumber(value) {
      return typeof value === "number" && isFinite(value);
    },
  },
};
</script>
<style lang="scss">
.statistic-indicator-setting-refs {
  padding: 34px 10px;

  &__refs {
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 0;
  }

  &__ref {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 212px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &-title {
      font-weight: 500;
      font-size: 14px;
      color: rgba(33, 33, 33, 0.7);
    }
    &-input {
      width: 79px;
      .v-input {
        padding-top: 0;
      }
    }
  }
  &__labels {
    $self: &;
    &-label {
      height: 80px;
      display: flex;
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.color1 {
        #{ $self }-range {
          background: rgba(254, 125, 134, 0.5);
        }
        #{ $self }-content {
          background: #fe7d86;
        }
      }
      &.color2 {
        #{ $self }-range {
          background: rgba(247, 255, 125, 0.5);
        }
        #{ $self }-content {
          background: #f7ff7d;
        }
      }
      &.color3 {
        #{ $self }-range {
          background: rgba(12, 168, 93, 0.5);
        }
        #{ $self }-content {
          background: #0ca85d;
        }
      }
      &.color4 {
        #{ $self }-range {
          background: rgba(94, 194, 187, 0.5);
        }
        #{ $self }-content {
          background: #5ec2bb;
        }
      }
      &.color5 {
        #{ $self }-range {
          background: rgba(138, 144, 164, 0.5);
        }
        #{ $self }-content {
          background: #8a90a4;
        }
      }
    }
    &-range {
      padding-right: 55px;
      width: 188px;
      border-radius: 10px;
      background: rgba(254, 125, 134, 0.5);
      flex-grow: 0;
      flex-shrink: 0;
      span {
        color: rgb(33, 33, 33);
        font-weight: 800;
        font-size: 14px;
      }
    }
    &-text {
      flex-grow: 1;
      font-weight: 500;
      font-size: 14px;
      color: rgb(33, 33, 33);
    }
    &-content {
      padding-left: 23px;
      padding-right: 15px;
      position: relative;
      left: -55px;
      display: flex;
      flex-grow: 1;
      background: #fe7d86;
      border-radius: 10px;
    }
  }
}
</style>
