/*
  # Списочное представление может использоваться как выбор из списка для других страниц.
  Используются:
  RUN_COMPONENT() - Для вызова списка в попап
  nestedChoise() - Вызывает каллбэк фрейма и передает выбранные элементы списка
  * Если список открывается в фрейме через 'RUN_COMPONENT' - Он автоматический становиться списком для выбора
*/

export default {
  props: {
    nested: Boolean,
    // Передача гет параметров извне
    query: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showCompleted: null,
      currentRouter: this.nested ? null : { ...this.$route },
    };
  },
  watch: {
    showCompleted(val) {
      if (typeof val === "string") this.init();
    },
  },
  mounted() {
    // Регистрация функции destroy
    setTimeout(() => {
      this.$store.dispatch("pageTabs/REGISTER_COMPONENT", {
        destroy: this.destroy,
      });
    }, 0);
  },
  methods: {
    destroy() {
      this.$destroy();
    },
    nestedChoise() {},
    DataFromServerNormalize(data) {
      return data;
    },
    async init(args) {
      return this.baseInit(args);
    },
    async baseInit(args) {
      if (!this.apiService) return;
      try {
        this.loading = true;

        const params = args?.query ? args.query : {};

        if (this?.showCompleted === "notdone") {
          params.done = false;
        }

        const res = await this.apiService.get.apply(
          null,
          Object.keys(params).length > 0 ? [params] : []
        );
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async del(ids) {
      if (!this.apiService) return;

      if (!this.customConfirmDelete) {
        if (
          !(await this.$confirm(
            `Вы действительно хотите удалить ${ids.length} объектов?`
          ))
        )
          return;
      } else {
        if (!(await this.customConfirmDelete(ids))) return;
      }
      for (const id of ids) {
        await this.apiService.delete(id);
        const removed = this.dataSource.findIndex((el) => el.Id === id);
        if (removed >= 0) this.dataSource.splice(removed, 1);
      }
    },
  },
};
