var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',_vm._g({staticClass:"violations_violation",attrs:{"headers":_vm.headerViolations,"items":_vm.items,"expanded":_vm.expanded,"show-expand":"","hide-default-footer":"","id":"violations--table","expand-icon":"$ToolArrowDown","notShowSelect":"","single-expand":"","showActions":"","showSettings":false},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Description",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.violationEditing && item.Id === _vm.violationEditing.Id)?_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"},{name:"focusInput",rawName:"v-focusInput.noFocus.noEnter",value:(_vm.edit),expression:"edit",modifiers:{"noFocus":true,"noEnter":true}}],attrs:{"placeholder":"Введите несоответствие","autofocus":"","flat":"","background-color":"transparent","rows":"1","auto-grow":"","maxlength":"500","rules":[function (v) { return !!v || 'Укажите значение.'; }]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)},"blur":function($event){return _vm.edit(index, item)}},model:{value:(_vm.violationEditing.Description),callback:function ($$v) {_vm.$set(_vm.violationEditing, "Description", $$v)},expression:"violationEditing.Description"}}):_c('span',[_vm._v(_vm._s(item.Description))])]}},{key:"item.IsChecked",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","disabled":_vm.readOnly},on:{"click":function($event){$event.stopPropagation();}},model:{value:(item.IsChecked),callback:function ($$v) {_vm.$set(item, "IsChecked", $$v)},expression:"item.IsChecked"}})]}},{key:"item.IsTypical",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"typical-violation__change",attrs:{"on-icon":"mdi-alpha-t-box-outline","off-icon":"mdi-checkbox-blank-off-outline","hide-details":"","title":_vm.getTooltip(item.IsTypical),"disabled":_vm.readOnly || !item.IsChecked},on:{"click":function($event){$event.stopPropagation();}},model:{value:(item.IsTypical),callback:function ($$v) {_vm.$set(item, "IsTypical", $$v)},expression:"item.IsTypical"}})]}},{key:"item.expansionAction",fn:function(ref){
var item = ref.item;
return [_vm._t("expansionAction",null,{"readOnly":_vm.readOnly || !item.IsChecked,"recm":0,"item":item})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.violationEditing || item.Id === _vm.violationEditing.Id)?_c('div',{class:{
        'table__column__actions-active':
          _vm.violationEditing && _vm.violationEditing.Id === item.Id,
      },staticStyle:{"display":"flex"}},[(
          _vm.violationEditing && _vm.violationEditing.Id === item.Id && !item.IsNew
        )?_c('v-btn',{attrs:{"title":"Не сохранять изменения","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.violationEditing = null}}},[_c('m-icon',{attrs:{"icon":"mdi-close","small":""}})],1):_vm._e(),_c('v-btn',{attrs:{"color":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
            ? 'primary'
            : 'success',"disabled":_vm.readOnly || (!item.IsChecked && !_vm.simple),"title":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
            ? 'Редактирование записи'
            : 'Cохранить изменения',"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('m-icon',{attrs:{"icon":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
              ? 'tool-edit'
              : 'mdi-check',"small":"","s16":""}})],1),_c('v-btn',{attrs:{"icon":"","color":'error',"title":"Удалить","disabled":_vm.readOnly || (!item.IsChecked && !_vm.simple)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.del(index)}}},[_c('m-icon',{attrs:{"icon":"tool-delete","s16":""}})],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"px-0",staticStyle:{"height":"100% !important"},attrs:{"colspan":headers.length}},[_c('recommendation-table',{attrs:{"violation":item,"items":_vm.simple ? item.TypicalRecommendations : item.Recommendations,"changeTypical":_vm.changeTypical,"nested":"","parentSelect":item.IsChecked,"readOnly":_vm.readOnly || (!item.IsChecked && !_vm.simple),"treeNames":_vm.treeNames,"auditOperationNum":_vm.auditOperationNum,"checkListName":_vm.checkListName,"objectCheck":_vm.objectCheck,"simple":_vm.simple},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter(
            function (e) { return e === 'expansionAction'; }
          )),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)}),_vm._t("violation-expansion-td",null,null,item)],2)]}},{key:"header.IsChecked",fn:function(){return undefined},proxy:true},{key:"body.append",fn:function(ref){
          var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{staticClass:"typical-violation-table__add",attrs:{"text":"","color":"blue","large":"","disabled":_vm.readOnly},on:{"click":_vm.add}},[_c('m-icon',{staticClass:"mr-3",staticStyle:{"font-size":"30px"},attrs:{"icon":"mdi-plus-circle","dark":"","left":""}}),_vm._v(" Добавить несоответствие ")],1)],1)])]}}],null,true)},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }