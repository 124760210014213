import { render, staticRenderFns } from "./BaseLeftTabs.vue?vue&type=template&id=4237c1ed&"
import script from "./BaseLeftTabs.vue?vue&type=script&lang=js&"
export * from "./BaseLeftTabs.vue?vue&type=script&lang=js&"
import style0 from "./BaseLeftTabs.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCol,VItem,VItemGroup,VRow,VWindow})
