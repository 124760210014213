<template>
  <base-detail-view
    :loading="loading"
    title="Профиль сотрудника"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <template #action>
      <v-btn @click="showChangePassword" dark color="blue" class="ml-4">
        Изменить пароль
      </v-btn>
    </template>
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.LastName"
              label="Фамилия"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            >
            </v-text-field>
            <v-text-field
              v-model="dataSource.FirstName"
              label="Имя"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.MiddleName"
              label="Отчество"
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            ></v-text-field>
            <base-date-picker
              v-model="dataSource.BirthDate"
              label="Дата рождения"
              clearable
            ></base-date-picker>
          </v-col>
          <v-col>
            <input-load-file
              :files="picture"
              accept="image/*"
              label="Фото"
              prepend-icon=""
            >
            </input-load-file>
            <v-img :src="pictureSrc" max-height="200" max-width="200"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <workplaces
      :workplaces="dataSource.WorkPlaces"
      :readonly="readonlyWP"
      @edit="editWorkplaces"
      class="mt-2"
    ></workplaces>
    <base-popup
      title="Изменение пароля"
      :icon="UtilGetIcon('view-Profile')"
      v-model="dialogChangePassword"
      @save="changePassword()"
      :disabledSave="!validCP"
      :width="'60vw'"
      :height="'70vh'"
    >
      <v-form v-model="validCP">
        <v-text-field
          v-model="oldPassword"
          type="password"
          label="Старый пароль"
          :rules="[rules.required]"
          required
        />
        <v-text-field
          v-model="newPassword1"
          type="password"
          label="Новый пароль"
          :rules="[rules.required, rules.diffFromOld]"
          required
          @input="newPassword2 = null"
        />
        <v-text-field
          v-model="newPassword2"
          type="password"
          label="Повторите новый пароль"
          :rules="[rules.required, rules.equalToNew]"
          required
        />
      </v-form>
    </base-popup>
  </base-detail-view>
</template>

<script>
import ProfileService from "../../services/ProfileService";
import { defaultEmployee } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import InputLoadFile from "../../components/loadFiles/InputLoadFile.vue";
import Workplaces from "../../components/organization/Workplaces.vue";

import Cookies from "js-cookie";

export default {
  name: "view-Profile",
  components: {
    BaseDetailView,
    BaseDatePicker,
    BasePopup,
    InputLoadFile,
    Workplaces,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: ProfileService,
      loading: true,
      dataSource: defaultEmployee(),
      picture: [],
      pictureSrc: "",
      baseUrl: process.env.VUE_APP_SERVER_URL,
      dialogChangePassword: false,
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
      valid: false,
      validCP: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        diffFromOld: (value) =>
          value !== this.oldPassword ||
          "Новый пароль не должен совпадать со старым",
        equalToNew: (value) =>
          value === this.newPassword1 || "Пароли не совпадают",
      },
      readonlyFIO:
        !this.hasPermission(Permissions.EmployeesEdit) &&
        !this.hasPermission(Permissions.UserProfileFIOEdit),
      readonlyWP:
        !this.hasPermission(Permissions.EmployeesEdit) &&
        !this.hasPermission(Permissions.UserProfileWorkPlacesEdit),
    };
  },
  watch: {
    picture(value) {
      if (this.dataSource.User) {
        this.dataSource.User.Picture = value.length ? value[0] : null;
        if (!value.length || !value[0].ExternalId) this.pictureSrc = "";
      }
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async save() {
      const { data } = await this.baseSave();
      this.$store.commit("SET_PROFILE", data);

      const user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

      if (data.User) {
        user.Employee.User.Picture = data.User.Picture;
        user.Picture = data.User.Picture;
      }

      Cookies.set("user", JSON.stringify(user));
    },
    DataFromServerNormalize(data) {
      if (data.User && data.User.Picture) {
        this.picture.splice(0, 1);
        this.picture.push(data.User.Picture);
        this.pictureSrc = `${this.baseUrl}common/GetFile?externalId=${data.User.Picture.ExternalId}`;
      }
      return data;
    },
    editWorkplaces(value) {
      if (!value) return;
      if (value.Id < 0) this.dataSource.WorkPlaces.push(value);
      else {
        const index = this.dataSource.WorkPlaces.findIndex(
          (item) => item.Id === value.Id
        );
        if (index !== -1) this.$set(this.dataSource.WorkPlaces, index, value);
      }
    },
    showChangePassword() {
      this.oldPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";
      this.dialogChangePassword = true;
    },
    async changePassword() {
      await this.apiService.changePassword(this.oldPassword, this.newPassword1);
      this.dialogChangePassword = false;
      this.oldPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";
      this.$alert("Пароль успешно изменен", { type: "green" });
    },
  },
};
</script>
