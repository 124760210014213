var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-popup',_vm._b({attrs:{"title":"Добавить объекты","icon":_vm.UtilGetIcon('view-CheckLists'),"width":_vm.width,"height":_vm.height,"buttonSaveText":"Сохранить"},on:{"save":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'base-popup',_vm.$attrs,false),[(_vm.activeCheckList && _vm.activeCheckList.objects && _vm.activeCheckList.count)?_c('template',{slot:"subTitle"},[_c('span',{staticClass:"check-operation-add__header__number"},[_vm._v(" Выбрано "+_vm._s(_vm.activeCheckList.objects.length + " из " + _vm.activeCheckList.count)+" ")]),(_vm.selectedObjectType === 1)?_c('base-search',{staticClass:"ml-10",model:{value:(_vm.listObjectsSearch),callback:function ($$v) {_vm.listObjectsSearch=$$v},expression:"listObjectsSearch"}}):_vm._e()],1):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',[(_vm.checkList.length > 0)?_c('base-panel',{attrs:{"header-hidden":""}},[_c('base-table',{attrs:{"headers":_vm.headersСheckOperation,"items":_vm.addCheckList,"notShowSelect":"","notCheckSelect":"","showSettings":false,"hide-default-footer":""},on:{"click:row":function (value) {
                this$1.activeCheckList = value;
              }},scopedSlots:_vm._u([_vm._l((_vm.headersСheckOperation),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,attrs:{"title":header.tooltip}},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.count",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.count,"type":"number","min":"1","max":"99"},on:{"input":function($event){return _vm.handleCount($event, item)},"change":function($event){return _vm.changeCount(item)},"click":function($event){$event.preventDefault();$event.stopPropagation();},"focus":function () { return (_vm.activeCheckList = item); }}})]}}],null,true)})],1):_vm._e()],1),(_vm.activeCheckList)?_c('v-col',[_c('base-panel',{attrs:{"loading":_vm.loading,"headerHidden":""}},[(_vm.selectedObjectType === 1)?_c('div',{staticClass:"v-treeview theme--light block__content"},[_c('div',{staticClass:"v-treeview-node__root",staticStyle:{"height":"64px"}},[_c('div',{staticClass:"pl-2",staticStyle:{"color":"var(--v-blue-grey-base)"}},[_vm._v(" Подразделения ")])]),_c('v-treeview',{attrs:{"value":_vm.selectPartitions(),"items":_vm.listObjects,"search":_vm.listObjectsSearch,"selection-type":"independent","item-key":"Id","item-text":"Name","item-children":"Children","dense":"","selectable":"","open-all":"","return-object":""},on:{"input":function($event){_vm.activeCheckList.objects = $event}}})],1):(_vm.selectedObjectType === 2)?_c('base-table',{attrs:{"headers":_vm.headers,"items":_vm.listObjects,"loading":_vm.loading,"showSettings":false},scopedSlots:_vm._u([{key:"item.objectCheck",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PersonShortName")(item))+" ")]}}],null,false,3767698763),model:{value:(_vm.activeCheckList.objects),callback:function ($$v) {_vm.$set(_vm.activeCheckList, "objects", $$v)},expression:"activeCheckList.objects"}}):_vm._e()],1)],1):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }