<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="1200"
    min-width="800"
    z-index="100"
    offset-y
    @input="onMenuToggle"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        title="Отозвать подпись"
        :disabled="disabled"
        dark
        color="blue"
        class="ml-4"
      >
        Отозвать подпись
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <base-list-view
          :dataSource="auditors"
          :headers="headers"
          :showDelete="false"
          :notShowActions="['add', 'refresh']"
          title="Участники, имеющие подпись"
          hide-default-footer
          hide-default-header
          notShowSelect
          isNested
        >
          <template #item.Sign="{ item }">
            <v-btn
              title="Отозвать подпись"
              dark
              rounded
              color="blue"
              @click="setStatus(item)"
              :loading="loading"
              :disabled="item.disabled"
            >
              Отозвать подпись
            </v-btn>
          </template>
        </base-list-view>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
import AuditOperationService from "@/services/AuditOperationService";

export default {
  name: "view-MembersWithSign",
  components: { BaseListView },

  props: {
    documentId: Number,
    disabled: Boolean,
    members: Array,
    Id: Number,
  },
  data: function () {
    return {
      menu: false,
      loading: false, // true,
      dataLoaded: false,
      headers: [
        {
          text: "Сотрудник",
          value: "Employee",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        { text: "", value: "Sign", align: "end" },
      ],
      dataSource: [],
      editIndex: null,
    };
  },
  computed: {
    auditors() {
      return this.members;
    },
  },
  watch: {},
  methods: {
    onMenuToggle(opened) {
      if (opened) return;
      this.$emit("update-audit");
    },
    async setStatus(temp) {
      this.loading = true;
      if (this.$store.getters.GET_USER.Employee.Id !== temp.Employee?.Id)
        await AuditOperationService.updateStatus(this.Id, 4, temp.Employee?.Id);
      else await AuditOperationService.updateStatus(this.Id, 4);

      this.auditors.forEach((element) => {
        if (element.Employee?.Id === temp.Employee?.Id) element.disabled = true;
      });
      this.loading = false;
    },
  },
};
</script>
