<template>
  <v-card width="100%" height="20" elevation="0" color="#F7F7FA">
    <template v-if="value < 4">
      <slot>
        <div class="d-flex">
          <v-card
            elevation="0"
            :color="color"
            height="20"
            :width="value + '%'"
            class="d-flex justify-end align-items-end pr-1"
          >
          </v-card>
          <span style="margin-left: 1px">
            {{ Math.round(value) }}
          </span>
        </div>
      </slot>
    </template>
    <template v-else>
      <v-card width="100%" height="20" elevation="0" color="#F7F7FA">
        <v-card
          elevation="0"
          :color="color"
          height="20"
          :width="value + '%'"
          class="d-flex justify-end align-items-end pr-1"
        >
          <span :style="{ color: textColor }">{{ Math.round(value) }}</span>
        </v-card>
      </v-card>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    color: [String],
    textColor: [String],
  },
  data: () => ({
    chipSelectItem: null,
  }),
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
