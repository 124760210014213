<template>
  <div
    :class="{ block__content: !hideBorder }"
    :style="
      small
        ? 'padding: 15px 25px; padding-bottom: 12px'
        : 'padding: 20px 50px; padding-bottom: 20px'
    "
  >
    <div v-if="!headerHidden" class="d-flex justify-space-between">
      <div class="d-flex mb-3" style="flex: 1; min-height: 40px" v-if="title">
        <m-icon
          v-if="!hideIcon"
          :icon="icon"
          class="mr-3 align-start"
          :color="iconColor"
        />
        <div v-if="labels.length" class="d-flex flex-column" style="flex: 1">
          <div style="font-weight: bold; line-height: 1.2">
            {{ title }} <span class="caption ml-5">{{ caption }}</span>
          </div>
          <div style="line-height: 1.4" class="caption d-flex flex-nowrap">
            <div class="primary--text" style="">
              {{ subTitle }}
            </div>
            <div
              v-for="(item, i) in labels"
              :key="i"
              class="text-decoration-underline primary--text pl-3"
              style=""
              :style="isClickLabel ? ' cursor: pointer;' : ''"
              :title="getLabelText(item)"
              @click="clickLabel(item)"
            >
              {{ getLabelText(item) }}
            </div>
          </div>
        </div>
        <div v-else>
          <div style="font-weight: bold; line-height: 1.2">{{ title }}</div>
          <div style="line-height: 1.4" class="caption">
            <slot name="subTitle" v-bind:subTitle="subTitle">
              {{ subTitle }}</slot
            >
          </div>
        </div>
      </div>
      <slot name="titleAppend"></slot>
      <div v-if="values.length === 1" class="tfoms__panel-meta">
        <div
          v-if="values[0].value || values[0].value === 0"
          class="tfoms__panel-meta-value"
          :style="'color:' + iconColor"
        >
          <template v-if="values[0].type === 'percent'">
            {{ values[0].value }}
          </template>
          <template v-else>
            {{ values[0].value | numberSplit }}
          </template>
        </div>
        <div class="tfoms__panel-meta-unit">{{ values[0].label }}</div>
      </div>
      <div v-else-if="values.length > 1">
        <div
          class="tfoms__panel-values mb-3"
          v-for="(item, i) in values"
          :key="i"
        >
          <div class="tfoms__panel-values-name">{{ item.name }}</div>
          <div v-if="values[0].value || values[0].value === 0">
            <div class="tfoms__panel-values-value">
              <template v-if="item.type === 'percent'">
                {{ item.value }}
              </template>
              <template v-else>
                {{ item.value | numberSplit }}
              </template>
            </div>
            <div class="tfoms__panel-values-unit">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "view-analysis-panel",
  inject: {
    tfomsParams: {
      from: "tfomsParams",
      default: null,
    },
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: "",
    },
    caption: {
      type: String,
      default: "",
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "medexpert-statistic",
    },
    iconColor: {
      type: String,
      default: "#5379FB",
    },
    showMore: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: "",
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },

    headerHidden: {
      type: Boolean,
      default: false,
    },
    isClickLabel: { type: Boolean, default: true },
    hideBorder: { type: Boolean, default: false },
  },

  methods: {
    clickLabel(e) {
      if (this.isClickLabel && typeof e === "object") {
        this.tfomsParams.value.setLevel(e);
      }
    },
    getLabelText(label) {
      if (typeof label === "string") return label;
      else {
        return label?.Name;
      }
    },
  },
};
</script>
<style lang="scss">
.tfoms__panel-meta {
  line-height: 0.85;
  text-align: right;
  &-value {
    font-size: 32.615px;
  }
  &-unit {
    font-size: 14px;
    color: var(--v-blue-grey-lighten3);
  }
}

.tfoms__panel-values {
  line-height: 0.99;
  text-align: right;
  display: flex;
  justify-content: space-between;

  &-value {
    font-size: 20px;
    color: var(--v-blue-grey-lighten3);
  }
  &-unit {
    font-size: 12px;
    color: #8a90a4;
  }

  &-name {
    margin-right: 20px;
    font-size: 12px;
    line-height: 120%;
    color: var(--v-blue-grey-lighten3);
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
</style>
