var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-panel',{attrs:{"title":"Подразделения","icon":_vm.UtilGetIcon('view-Partitions')}},[_c('base-search',{attrs:{"slot":"action"},slot:"action",model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-btn',{attrs:{"slot":"action","icon":"","title":"Обновить"},on:{"click":_vm.init},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-refresh","s16":""}})],1),_c('v-btn',{staticClass:"ml-4",attrs:{"slot":"action","disabled":_vm.readOnly,"dark":"","color":"blue"},on:{"click":_vm.addTop},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-add-item","left":""}}),_vm._v(" Создать подразделение ")],1),_c('base-tree-list-edit',{ref:"treeListEdit",attrs:{"items":_vm.filteredItems,"item-text":"Name","search":_vm.searchText,"showActions":_vm.actions,"customInplaceEdit":_vm.customInplaceEdit,"group":"test","pageEditName":"PartitionEdit","caption":"название"},on:{"customDelete":_vm.customDelete,"afterEdit":function($event){return this.copyTree(this.dataSource)},"input":_vm.change},scopedSlots:_vm._u([{key:"input-edit",fn:function(ref){
var nodeEditing = ref.nodeEditing;
var caption = ref.caption;
var closeEdit = ref.closeEdit;
var itemText = ref.itemText;
var item = ref.item;
return [(nodeEditing && nodeEditing.Id === item.Id)?_c('v-text-field',{directives:[{name:"focusInput",rawName:"v-focusInput:tree",value:(closeEdit),expression:"closeEdit",arg:"tree"},{name:"up-first-symbol",rawName:"v-up-first-symbol"}],staticClass:"base-tree-list-edit__field",attrs:{"placeholder":'Введите' + ' ' + caption,"dense":"","background-color":"transparent","hide-details":"","solo":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(nodeEditing[itemText]),callback:function ($$v) {_vm.$set(nodeEditing, itemText, $$v)},expression:"nodeEditing[itemText]"}}):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" "+_vm._s(item.Address ? " (" + item.Address + ")" : "")+" ")]}},{key:"captureArea",fn:function(){return [_c('v-btn',{staticClass:"capture-area",attrs:{"icon":"","color":"blue-grey","title":"Переместить"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('m-icon',{attrs:{"icon":"mdi-menu","small":""}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }