<template>
  <div class="block__content statistic-indicator-detail">
    <div class="statistic-indicator-detail__title">
      <div class="statistic-indicator-detail__title-body">
        <div class="statistic-indicator-detail__title-date">
          {{ item.Date ? item.Date.substr(0, 10) : "Нет данных" }}
        </div>
        <div class="statistic-indicator-detail__title-value">
          <strong>
            <span v-if="type === 1 && item.PartitionObject">{{
              item.PartitionObject.Name
            }}</span>
            <span v-if="type === 2 && item.EmployeeObject">{{
              item.EmployeeObject | PersonShortName
            }}</span></strong
          >
        </div>

        <div />
      </div>
      <div class="statistic-indicator-detail__title-action">
        <v-btn icon title="Скрыть" @click="$emit('close')">
          <m-icon icon="mdi-close"></m-icon>
        </v-btn>
      </div>
    </div>
    <v-form
      class="statistic-indicator-detail__body"
      :readonly="
        !(!readonly || (typeof readonly === 'function' && !readonly(item)))
      "
    >
      <div v-if="item.ModifiedBy" class="">
        Сотрудник, изменивший показание:
        <strong>{{ item.ModifiedBy | PersonShortName }}</strong>
      </div>
      <v-textarea
        v-model="item.Comment"
        auto-grow
        rows="2"
        maxlength="2000"
        name="Сontent"
        label="Примечание"
      ></v-textarea>
    </v-form>
  </div>
</template>
<script>
export default {
  props: {
    item: null,
    type: null,
    readonly: {
      type: [Boolean, Function],
    },
  },
};
</script>

<style lang="scss">
.statistic-indicator-detail {
  padding: 5px 5px 4px 10px;

  &__body {
    padding: 0 7px;
  }
  &__title {
    display: flex;
    margin-bottom: 0.5rem;

    &-body {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding: 0.4rem 0.4rem 0 0.4rem;
    }
    &-date {
      font-weight: 500;
      font-size: 12px;
      line-height: 158%;
      color: #8a90a4;
      margin-top: 3px;
    }
    &-value {
      color: rgb(95, 100, 123);
    }
  }
}
</style>
