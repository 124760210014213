<template>
  <base-detail-view
    :loading="loading"
    title="Медицинское изделие"
    :subTitle="this.type === 'create' ? 'новое изделие' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              maxlength="200"
              :rules="[rules.required]"
              required
              :readonly="readonly"
              v-up-first-symbol
            ></v-text-field>
            <v-text-field
              v-model="dataSource.RegNum"
              label="Регистрационный номер"
              maxlength="50"
              :readonly="readonly"
              :clearable="!readonly"
            ></v-text-field>
            <base-date-picker
              v-model="dataSource.RegDate"
              label="Дата регистрации"
              :readonly="readonly"
              :clearable="!readonly"
            ></base-date-picker>
            <base-date-picker
              v-model="dataSource.RegEnd"
              label="Срок завершения РУ"
              :readonly="readonly"
              :clearable="!readonly"
            ></base-date-picker>
            <v-checkbox
              v-model="dataSource.IsServiceRequired"
              label="Подлежит ТО"
              :readonly="readonly"
              @change="updateTabs"
            ></v-checkbox>
          </v-col>
          <v-col>
            <m-tree-select
              v-model="dataSource.Partitions"
              :items="partitions"
              :multiple="true"
              selection-type="independent"
              allowSelectParents
              label="Места использования"
              :readonly="readonly"
              :clearable="!readonly"
              :dict="{ name: 'Partition', notItems: true }"
            ></m-tree-select>
            <v-text-field
              v-model="dataSource.Num"
              label="Инвентарный номер"
              maxlength="50"
              :readonly="readonly"
              :clearable="!readonly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.SerialNum"
              label="Серийный номер"
              maxlength="50"
              :readonly="readonly"
              :clearable="!readonly"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.Comment"
              label="Примечание"
              maxlength="100"
              :readonly="readonly"
              :clearable="!readonly"
              v-up-first-symbol
            ></v-text-field>
            <v-checkbox
              v-model="dataSource.IsValidationRequired"
              label="Подлежит поверке"
              :readonly="readonly"
              @change="updateTabs"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form v-model="valid">
      <base-panel class="mt-2" headerHidden>
        <m-tabs v-model="tab" v-if="!loading" ref="mtabs">
          <v-tab key="Documents">Документы</v-tab>
          <v-tab key="Validation" v-show="dataSource.IsValidationRequired">
            Поверка
          </v-tab>
          <v-tab key="Service" v-show="dataSource.IsServiceRequired">
            Техническое обслуживание
          </v-tab>
          <v-tab key="Standarts">Записи стандартов оснащения</v-tab>
        </m-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="Documents" class="mt-2">
            <applied-documents
              :headers="documentHeaders"
              :documents="dataSource.Documents"
              :objectType="3"
              @edit="editDocuments"
              :readonly="readonly"
            ></applied-documents>
          </v-tab-item>
          <v-tab-item key="Validation">
            <base-panel
              class="mt-2 block__content"
              headerHidden
              v-if="dataSource.IsValidationRequired"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="dataSource.ValidationPeriod"
                      type="Number"
                      label="Период поверки, мес."
                      :rules="[rules.required, rules.maxPeriodSymbol]"
                      required
                      :readonly="readonly"
                    ></v-text-field>
                    <m-select
                      v-model="dataSource.MeasuringType"
                      :items="measuringTypes"
                      item-text="Name"
                      label="Тип измерительного средства"
                      return-object
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></m-select>
                    <v-text-field
                      v-model="dataSource.MeasureAccuracy"
                      label="Класс точности"
                      maxlength="50"
                      :readonly="readonly"
                    ></v-text-field>
                    <equipment-document-edit
                      v-model="dataSource.ValidationBid"
                      :documentType="4"
                      label="Договор на поверку"
                      :readonly="readonly"
                    ></equipment-document-edit>
                  </v-col>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.ValidationDate"
                      label="Дата последней поверки"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                    <v-text-field
                      v-model="dataSource.MeasureRange"
                      label="Предел измерений (диапазон)"
                      maxlength="50"
                      :readonly="readonly"
                    ></v-text-field>
                    <base-date-picker
                      v-model="dataSource.IssueDate"
                      label="Дата выпуска"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                  </v-col>
                </v-row>
              </v-container>
            </base-panel>
          </v-tab-item>
          <v-tab-item key="Service">
            <base-panel
              class="mt-2 block__content"
              headerHidden
              v-if="dataSource.IsServiceRequired"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="dataSource.ServicePeriod"
                      type="Number"
                      label="Период обслуживания, мес."
                      :rules="[rules.required, rules.maxPeriodSymbol]"
                      required
                      :readonly="readonly"
                    ></v-text-field>
                    <equipment-document-edit
                      v-model="dataSource.ServiceContract"
                      :documentType="3"
                      label="Договор на тех. обслуживание"
                      :readonly="readonly"
                    ></equipment-document-edit>
                  </v-col>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.ServiceDate"
                      label="Дата последнего обслуживания"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                  </v-col>
                </v-row>
              </v-container>
            </base-panel>
          </v-tab-item>
          <v-tab-item key="Standarts" class="mt-2">
            <base-list-view
              :dataSource="dataSource.StandartRecords"
              :headers="standartHeaders"
              isNested
              headerHidden
              hide-default-footer
              notShowSelect
              hide-edit
            />
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
    </v-form>
  </base-detail-view>
</template>

<script>
import { mapActions } from "vuex";
import EquipmentService from "../../services/EquipmentService";
import { defaultEquipment } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import DataHelper from "../../utils/DataHelper";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import MTreeSelect from "../../components/base/inputs/mTreeSelect.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import BaseListView from "../../layouts/BaseListView.vue";
import AppliedDocuments from "../../components/organization/AppliedDocuments.vue";
import EquipmentDocumentEdit from "../../components/organization/EquipmentDocumentEdit.vue";
import mTabs from "@/components/base/tabs/mTabs";

export default {
  name: "view-EquipmentEdit",
  components: {
    BaseDetailView,
    BaseDatePicker,
    BasePanel,
    MTreeSelect,
    MSelect,
    BaseListView,
    AppliedDocuments,
    EquipmentDocumentEdit,
    mTabs,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      loading: true,
      valid: false,
      apiService: EquipmentService,
      getDefaultObject: defaultEquipment,
      getObjectText: (obj, isNew) => (isNew ? "новое" : obj.Name),
      dataSource: {
        ...defaultEquipment(),
      },
      partitions: [],
      measuringTypes: [],
      tab: null,
      documentHeaders: [
        { text: "Тип", value: "Type.Name" },
        { text: "Дата", value: "Date" },
        { text: "Дата завершения", value: "DateOut" },
        { text: "Номер", value: "Num" },
        { text: "Контрагент", value: "Contragent.Name" },
        { text: "Примечание", value: "Comment" },
      ],
      standartHeaders: [
        { text: "Стандарт", value: "StandartName" },
        { test: "Количество", value: "Quantity" },
      ],
      rules: {
        required: (value) => !!value || "Укажите значение.",
        maxPeriodSymbol: (value) =>
          String(value).length <= 4 || "Недопустимое значение",
      },
      readonly: !(
        this.hasPermission(Permissions.EquipmentsEditAll) ||
        this.hasPermission(Permissions.EquipmentsEdit)
      ),
    };
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async mounted() {
    this.partitions = await this.LOAD_PARTITIONS();
    this.measuringTypes = await this.LOAD_MEASURING_TYPES();
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_PARTITIONS", "LOAD_MEASURING_TYPES"]),
    DataFromServerNormalize(data) {
      // Подразделение по умолчанию для нового изделия
      const partition = Number(this.$router.currentRoute.query.partition);
      if (
        this.type === "create" &&
        partition &&
        !data.Partitions.some((p) => p.Id === partition)
      ) {
        const partitionDef = DataHelper.findTreeById(
          this.partitions,
          partition
        );
        if (partitionDef) data.Partitions.push(partitionDef);
      }

      // Дополнительные поля записей стандартов оснащения
      for (const record of data.StandartRecords)
        record.StandartName = record.Standart?.Name;

      // Описание специальных документов
      this.setDocumentText(data.ValidationBid);
      this.setDocumentText(data.ServiceContract);

      // Преобразование чисел для строковых редакторов (чтобы не фиксировались изменения при вводе)
      data.ValidationPeriod = data.ValidationPeriod?.toString();
      data.ServicePeriod = data.ServicePeriod?.toString();

      // Необходимо для работы v-tree-select
      for (let i = 0; i < data.Partitions.length; i++) {
        data.Partitions[i] = DataHelper.findTreeById(
          this.partitions,
          data.Partitions[i].Id
        );
      }

      return data;
    },
    CopyDataNormalize(data) {
      // Сбрасываем идентификаторы прикрепленных документов и их файлов.
      // Специальные документы (на поверку и ТО) остаются как есть, так как они самостоятельные.
      // Прикрепленные файлы документов копируются автоаматически.
      for (const doc of data.Documents) {
        doc.Id = -Math.round(Math.random() * 100000);
        doc.Equipment = null;
        doc.Equipments = [];
      }
      // Записи стандартов оснащения удаляем, так как они указываются в стандартах подразделения.
      data.StandartRecords = [];
      return data;
    },
    editDocuments(value) {
      if (!value) return;
      const index = this.dataSource.Documents.findIndex(
        (item) => item.Id === value.Id
      );
      if (index !== -1) this.$set(this.dataSource.Documents, index, value);
      else this.dataSource.Documents.push(value);
    },
    setDocumentText(doc) {
      if (doc) doc.Text = `${doc.Num} от ${doc.Date.substr(0, 10)}`;
    },
    updateTabs() {
      this.$refs.mtabs.$refs.tabs.callSlider();
    },
  },
};
</script>
