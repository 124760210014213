<!-- С дейтсвиями на строку -->
<!-- Унивирсальная группировка -->
<!-- Выбор по группировки -->
<template>
  <v-data-table
    :headers="headers.filter((e) => !e.disabled)"
    :items="items"
    :items-per-page="itemsPerPage"
    expand-icon="$ToolArrowDown"
    v-on="$listeners"
    v-bind="$attrs"
    :search="searchTextInner"
    :custom-filter="
      $attrs['custom-filter'] ? $attrs['custom-filter'] : filterOnlyCapsText
    "
    locale="ru"
    :item-key="$attrs['item-key'] ? $attrs['item-key'] : 'Id'"
    :class="{
      block__content: !nested,
      'base-tabele_hidden-nodata': !$attrs.hasOwnProperty('no-data-text'),
      'base-table__column--small': small,
    }"
    :show-select="!notShowSelect"
    :group-by="computedGroupBy"
    multi-sort
    fixed-header
    :no-data-text="$attrs['no-data-text']"
    ref="dTable"
    :customGroup="customGroup"
    class="fill-height"
  >
    <template
      v-for="slot in Object.keys($scopedSlots).filter(
        (e) => e !== 'item.actions'
      )"
      :slot="slot"
      slot-scope="scope"
      ><slot :name="slot" v-bind="scope" />
    </template>

    <template slot="item.actions" slot-scope="scope">
      <div
        class="table__column__actions"
        :style="isShowActions === false ? 'display: none;' : ''"
      >
        <slot name="item.actions" v-bind="scope" />
      </div>
    </template>

    <template v-if="!$attrs.hasOwnProperty('no-data-text')" v-slot:no-data>
      <slot name="no-data">Нет данных</slot>
    </template>

    <template
      #group.header="{ toggle, isOpen, headers, group, items }"
      v-if="$attrs['group-by'] === innerGroupBy || !innerGroupBy"
    >
      <td :colspan="headers.length + 2" style="white-space: nowrap">
        <div style="align-items: center" class="d-flex">
          <div style="padding-left: 2px; padding-right: 2px">
            <v-btn @click="toggle" icon>
              <m-icon :icon="isOpen ? 'mdi-minus' : 'mdi-plus'"></m-icon>
            </v-btn>
          </div>
          <div class="px-4" v-if="!notShowSelect">
            <v-simple-checkbox
              v-bind="groupCheckBoxBind(items)"
              :ripple="false"
              @input="groupCheckBoxClick($event, items)"
            ></v-simple-checkbox>
          </div>
          <div>
            <slot
              name="group.header"
              v-bind="{ toggle, isOpen, headers, group, items }"
            >
              <span class="subtitle-2">
                <span v-if="getHeader && getHeader.showGroupName"
                  >{{ getGroupTitle() }}:
                </span>
                <span v-html="getGroupValue(group)" />
              </span>
            </slot>
            <slot
              name="group.header.after"
              v-bind="{ toggle, isOpen, headers, group, items }"
            ></slot>

            <slot
              name="group.header.notif"
              v-bind="{ toggle, isOpen, headers, group, items }"
            ></slot>
          </div>
        </div>
      </td>
    </template>

    <template #group.header="{ toggle, isOpen, headers, group, items }" v-else>
      <td :colspan="headers.length + 2" style="white-space: nowrap">
        <div style="align-items: center" class="d-flex">
          <div>
            <v-btn @click="toggle" icon>
              <m-icon :icon="isOpen ? 'mdi-minus' : 'mdi-plus'"></m-icon>
            </v-btn>
          </div>
          <div v-if="!notShowSelect" class="pl-1">
            <v-simple-checkbox
              v-bind="groupCheckBoxBind(items)"
              :ripple="false"
              @input="groupCheckBoxClick($event, items)"
            ></v-simple-checkbox>
          </div>
          <div>
            <span class="subtitle-2">
              <span v-if="getHeader && getHeader.showGroupName"
                >{{ getGroupTitle() }}: </span
              ><span v-html="getGroupValue(group)" />
            </span>
            <slot
              name="group.header.after"
              v-bind="{ toggle, isOpen, headers, group, items }"
            ></slot>
            <slot
              name="group.header.notif"
              v-bind="{ toggle, isOpen, headers, group, items }"
            ></slot>
          </div>
        </div>
      </td>
    </template>

    <template slot="header.actions" v-if="showSettings">
      <div class="d-flex base-table__actions" style="justify-content: right">
        <!--<base-search
          v-model="defaultSearchText"
          title="Поиск в таблице"
          @changeOpen="$event && showFilter()"
        />-->

        <v-badge
          v-if="!notFilter"
          bordered
          bottom
          color="blue"
          dot
          offset-x="15"
          offset-y="15"
          :value="$parent.isAppliedFilter"
        >
          <v-btn title="Фильтр таблицы" icon @click="$event && showFilter()">
            <m-icon
              small
              icon="mdi-filter-variant"
              :color="isShowFilters ? 'blue' : 'blue-grey'"
            >
            </m-icon>
          </v-btn>
        </v-badge>

        <v-menu offset-y v-if="!notShowSettings">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              title="Настройки таблицы"
              :color="'blue-grey'"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <m-icon small icon="mdi-cog"></m-icon>
            </v-btn>
          </template>
          <v-list class="base-table__edit-table">
            <v-list-item
              @click="showEditTable = true"
              v-if="excludeSettingsActions.indexOf('table') === -1"
            >
              <v-list-item-title>Изменить таблицу</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="excludeSettingsActions.indexOf('group') === -1"
              @click="showEditGrouping = true"
              :groupBy.sync="innerGroupBy"
            >
              <v-list-item-title>Группировка</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="showExportTable = true"
              v-if="excludeSettingsActions.indexOf('export') === -1"
            >
              <v-list-item-title>Экспорт таблицы в excel </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template slot="top" v-if="showSettings">
      <edit-headers :items="headers" v-model="showEditTable"> </edit-headers>

      <edit-grouping
        v-if="excludeSettingsActions.indexOf('group') === -1"
        :items="headers"
        v-model="showEditGrouping"
        :group-by.sync="computedGroupBy"
      ></edit-grouping>
      <export-table-excel
        v-if="showExportTable"
        v-model="showExportTable"
        :headers="headers"
        :items="items"
        :selectedItems="$attrs['value']"
        :setOpenGroup="setOpenGroup"
      ></export-table-excel>
    </template>
  </v-data-table>
</template>

<script>
import DataHelper from "@/utils/DataHelper";
import { getObjectValueByPath } from "vuetify/lib/util/helpers";
import { sortBy } from "lodash";

export default {
  components: {
    editHeaders: () => import("@/components/table/editHeaders"),
    editGrouping: () => import("@/components/table/editGrouping"),
    exportTableExcel: () => import("@/components/table/exportTableExcel"),
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: -1,
    },
    items: Array,
    dataSource: Array,
    headers: Array,
    loading: Boolean,
    searchText: {
      type: String,
      default: () => "",
    },
    nested: Boolean,
    notShowSelect: Boolean,
    showActions: [Boolean, Function],
    expandAll: Boolean,
    groupKeyIds: Array,
    notCheckSelect: Boolean,
    groupOpenType: {
      type: String,
      default: "hide",
      validator: function (value) {
        return ["hide", "show", "showFirst", "showLast", "custom"].includes(
          value
        );
      },
    },
    showSearchTable: {
      type: Boolean,
      default: true,
    },
    // Настройки и фильтр
    showSettings: {
      type: Boolean,
      default: true,
    },
    // Показывать настройки
    notShowSettings: {
      type: Boolean,
      default: false,
    },
    fixedWidthActions: {
      type: Boolean,
      default: false,
    },
    notFilter: {
      type: Boolean,
      default: false,
    },
    excludeSettingsActions: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.every(
          (el) => ["table", "group", "export"].indexOf(el) !== -1
        );
      },
    },
  },
  data() {
    return {
      showEditTable: false,
      showEditGrouping: false,
      showExportTable: false,
      defaultSearchText: null,
      isShowFilters: true,
      innerGroupBy: null,
      isInitGroup: false,
    };
  },
  computed: {
    getHeader() {
      if (this.computedGroupBy) {
        return this.headers.find((e) => e.value === this.computedGroupBy);
      } else {
        return null;
      }
    },
    computedGroupBy: {
      get() {
        if (this.innerGroupBy && this.$attrs["group-by"] !== null) {
          return this.innerGroupBy;
        }
        return this.$attrs["group-by"];
      },
      set(val) {
        this.innerGroupBy = val;
      },
    },
    searchTextInner() {
      return this.defaultSearchText ?? this.searchText;
    },
    isShowActions() {
      if (typeof this.showActions === "function") {
        return !!this.showActions(this.items);
      } else {
        return this.showActions;
      }
    },
  },
  watch: {
    computedGroupBy() {
      this.initGroup();
    },
    headers: {
      immediate: true,
      handler() {
        if (
          (this.showSettings || this.isShowActions) &&
          !this.headers.find((e) => e.value === "actions")
        )
          this.headers.push({
            text: "",
            value: "actions",
            width: "1%",
            align: "end",
            sortable: false,
            cellClass:
              "text-no-wrap " +
              (this.fixedWidthActions
                ? "td-fixed-width-actions"
                : "td-minimum"),
          });
      },
    },
    // При изменении dataSource
    // Проверяект не актульные Select item
    items: function () {
      this.initGroup();

      if (!this.$attrs?.value?.length || this.notCheckSelect) return;

      const filteredItems = this.$attrs.value.filter((selectedItem) => {
        return this.items.some((item) => item?.Id === selectedItem?.Id);
      });

      this.$attrs.value = filteredItems;
    },
    groupOpenType: {
      immediate: true,
      handler() {
        this.initGroup();
      },
    },
  },
  mounted() {
    if (this.expandAll)
      for (let i = 0; i < this.items.length; i += 1) {
        this.$refs.dTable.expanded.push(this.items[i]);
      }

    if (Object.hasOwn(this.$attrs, "not-filter")) this.isShowFilters = false;
  },
  created() {},
  methods: {
    groupCheckBoxClick(e, items) {
      if (!this.$attrs.value) {
        this.$emit("input", this.$refs.dTable.value);
        this.$attrs.value = this.$refs.dTable.value;
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (e) {
          this.$attrs.value.push(item);
        } else {
          const index = this.$attrs.value.findIndex((e2) => e2.Id === item.Id);

          if (index >= 0) {
            this.$attrs.value.splice(index, 1);
          }
        }
      }
    },
    groupCheckBoxBind(items) {
      const tmp = items.filter((e) =>
        this.$attrs?.value?.find((e2) => e2.Id === e.Id)
      );
      return {
        value: tmp.length === items.length,
        indeterminate: tmp.length !== items.length && tmp.length > 0,
      };
    },

    customGroup(items, groupBy, groupDesc) {
      return this.customGroupItems(items, groupBy, groupDesc);
    },
    customGroupItems(items, groupBy, groupDesc) {
      const key = groupBy[0];
      const groups = [];
      let current;

      if (this.getHeader?.dataType === "object") {
        let pName = "Id";
        if (getObjectValueByPath(items[0], key)?.Name) {
          pName = "Name";
        }
        items = sortBy(items, key + "." + pName);
      } else if (this.getHeader?.dataType === "array") {
        items = sortBy(items, key + "Text");
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const val = getObjectValueByPath(item, key, null);
        let valName = val;

        if (val && typeof val === "object" && val?.length >= 0) {
          // Массив
          valName = val.map((e) => this.getHeader.displayText(e)).join(", ");
        } else if (this.getHeader?.dataType === "Period") {
          // Период
          valName = this.$options.filters.PeriodFormat(val.DateIn, val.DateOut);
        } else if (val && val instanceof Date) {
          // Дата
          valName = val;
        } else if (val && typeof val === "object") {
          // Объект
          valName = this.getHeader?.displayText(val);
        }

        if (current !== valName) {
          current = valName;
          groups.push({
            name: valName != null ? valName : "",
            items: [],
          });
        }

        groups[groups.length - 1].items.push(item);
      }

      return groups;
    },
    getGroupValue(group) {
      const h = this.getHeader;
      if (!h) return;

      if (h.dataType === "enum") {
        if (typeof h.options[0] !== "object") {
          return h.options[group];
        } else {
          return h.options.find((e) => e.id === group).value;
        }
      } else if (h.dataType === "Date") {
        return group
          ? DataHelper.dateNormalize(group).format("DD.MM.YYYY")
          : "Не указана";
      } else if (h.dataType === "object") {
        return group || "Не указана"; // h?.displayText(group);
      } else if (h.dataType === "array") {
        return group || "Все"; // group.map((e) => h.displayText(e)).join(", ");
      } else {
        return group;
      }
    },
    getGroupTitle() {
      return this.getHeader?.text;
    },

    setOpenGroup(type) {
      const table = this.$refs.dTable;
      const keys = Object.keys(table.$vnode.componentInstance.openCache);
      switch (type) {
        case "hide":
          keys.forEach((x) => {
            table.$vnode.componentInstance.openCache[x] = false;
          });
          break;
        case "show":
          keys.forEach((x) => {
            table.$vnode.componentInstance.openCache[x] = true;
          });
          break;
        case "showFirst":
          keys.forEach((x, i) => {
            table.$vnode.componentInstance.openCache[x] = !i;
          });
          break;
        case "showLast":
          keys.forEach((x, i) => {
            table.$vnode.componentInstance.openCache[x] = keys.length - 1 === i;
          });
          break;
        case "custom": {
          keys.forEach((x) => {
            if (this.groupKeyIds.some((g) => g === x))
              table.$vnode.componentInstance.openCache[x] = true;
            else table.$vnode.componentInstance.openCache[x] = false;
          });
          break;
        }
      }
    },

    initGroup() {
      setTimeout(() => {
        if (!this?.items?.length || this.isInitGroup) return;
        this.isInitGroup = true;

        this.setOpenGroup(this.groupOpenType);
      }, 1);
    },
    filterOnlyCapsText(value, search) {
      return (
        value !== null &&
        search !== null &&
        (((typeof value === "string" || typeof value === "number") &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(search.toLocaleUpperCase()) !== -1) ||
          (typeof value === "object" &&
            this.filterObjectOrArrayText(value, search)))
      );
    },
    filterObjectOrArrayText(value, search) {
      for (const header of this.headers)
        if (
          (header.dataType === "object" || header.dataType === "array") &&
          header.displayText !== null
        )
          try {
            if (Array.isArray(value)) {
              for (const item of value) if (inText(header, item)) return true;
            } else if (inText(header, value)) return true;
          } catch (error) {
            console.log(error);
          }
      return false;
      function inText(header, item) {
        const s = header.displayText(item);
        return (
          s && s.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
        );
      }
    },
    showFilter() {
      this.$parent.isShowFilters = !this.$parent.isShowFilters;
      this.isShowFilters = this.$parent.isShowFilters;
    },
  },
};
</script>

<style lang="scss">
.base-table__column--small {
  th,
  td {
    padding: 0px 2px !important;
  }
}
</style>
<style lang="sass">
.v-data-table .disabled td
  color: rgba(0, 0, 0, 0.38) !important

.base-tabele_hidden-nodata .v-data-table__empty-wrapper
  display: none

table thead tr
  height: 64px
  & th
    white-space: nowrap
.v-data-table__wrapper td
  max-width: 300px
  overflow: hidden
.v-data-table__wrapper
  max-height: calc(100vh - 233px)
  height: 100%

.v-data-table td .table__column__actions div:not(.table__column__actions-active)
  visibility: hidden


.v-data-table tr:not(.v-data-table__expanded__content):hover div:not(.table__column__actions-active)
  visibility: visible !important

.table__column__actions
  display: inline-flex
</style>
