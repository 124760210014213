<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="
        this.type === 'create' || !dataSource.Num
          ? title
            ? title
            : 'Создание задачи'
          : (dataSource.Parent ? 'Подзадача' : 'Задача') + ' №' + dataSource.Num
      "
      :isUnsaved="isUnsaved && valid"
      @onSave="save"
      v-on="nested ? {} : { onRefresh: init }"
      class="tast-edit"
    >
      <template slot="subTitle">
        <span class="caption ml-5">
          {{
            dataSource.Done
              ? "Завершена: " + dataSource.Done
              : TaskStatus[dataSource.Status - 1] &&
                TaskStatus[dataSource.Status - 1].value
          }}
        </span>
        <span v-if="dataSource.Parent" class="caption ml-5"
          >Родительская задача:
          <a href="#" @click.prevent.stop="openParent">{{
            dataSource.Parent.Name
          }}</a></span
        >
      </template>
      <v-btn
        v-if="dataSource.Parent && !readonly"
        slot="action"
        class="mr-3"
        dark
        color="blue"
        @click="makeIndependentTask"
      >
        Сделать самостоятельной задачей
      </v-btn>
      <template slot="action">
        <v-btn
          v-if="canEditResult && !dataSource.Done"
          :disabled="isUnsaved"
          dark
          color="blue"
          @click="setResult"
        >
          Завершить
        </v-btn>
        <v-btn
          v-if="canEditResult && dataSource.Done"
          :disabled="isUnsaved"
          dark
          color="blue"
          @click="openResult"
        >
          Открыть
        </v-btn>
      </template>
      <template v-if="nested" slot="btn-save" slot-scope="scope">
        <v-btn
          class="ml-3"
          dark
          color="blue"
          slot="action"
          :disabled="!(isUnsaved && valid)"
          @click="
            () => {
              $refs.form.validate();
              scope.click();
            }
          "
        >
          Сохранить
        </v-btn>
      </template>
      <v-form v-model="valid" class="block__content task-edit__form" ref="form">
        <v-container>
          <v-row>
            <v-col>
              <v-textarea
                v-model="dataSource.Name"
                @change="changeName"
                label="Название"
                required
                maxlength="200"
                counter
                auto-grow
                rows="1"
                :rules="[rules.required]"
                :readonly="!canEditAuthor || readonly"
                v-up-first-symbol
              >
              </v-textarea>
              <v-textarea
                v-model="dataSource.Content"
                @change="changeName"
                label="Содержание"
                maxlength="1000"
                auto-grow
                rows="3"
                :readonly="!canEditAuthor || readonly"
              ></v-textarea>
              <m-autocomplete
                v-model="dataSource.Indicator"
                item-text="Name"
                item-value="Id"
                dict="AuditIndicators"
                label="Показатель оценки качества"
                :clearable="!readonly"
                :readonly="readonly"
                return-object
              ></m-autocomplete>
              <v-textarea
                v-model="dataSource.Result"
                label="Результат"
                maxlength="1000"
                rows="3"
                auto-grow
                :readonly="!canEditResult || readonly"
                v-up-first-symbol
              ></v-textarea>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="py-0">
                  <m-autocomplete
                    v-model="dataSource.Executors"
                    :items="employees"
                    label="Исполнители"
                    return-object
                    :readonly="!(canEditAuthor || canEditExecutor) || readonly"
                    :clearable="(canEditAuthor || canEditExecutor) && readonly"
                    filter="EmployeeActive"
                    dict="Employees"
                    :textCustomAdd="'Создать нового сотрудника'"
                    multiple
                  ></m-autocomplete>
                </v-col>
                <v-col class="py-0">
                  <m-select
                    v-model="dataSource.Priority"
                    :items="TaskPriority"
                    item-text="value"
                    item-value="id"
                    label="Приоритет"
                    :readonly="!canEditAuthor || readonly"
                    :clearable="false"
                  ></m-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <base-date-picker-range
                    v-model="PlannedDates"
                    label="Срок"
                    :readonly="!(canEditAuthor || canEditExecutor) || readonly"
                    :clearable="(canEditAuthor || canEditExecutor) && !readonly"
                    isPeriod
                /></v-col>
                <v-col>
                  <base-date-time-picker
                    v-model="dataSource.NotifyStartDate"
                    :label="`Напоминание ${
                      NotifyStartSent ? ' (отправлено)' : ''
                    }`"
                    :readonly="!(canEditAuthor || canEditExecutor) || readonly"
                    :clearable="(canEditAuthor || canEditExecutor) && !readonly"
                    :rules="[rules.notifyStartDate]"
                  ></base-date-time-picker
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <m-autocomplete
                    v-model="dataSource.Notify"
                    :items="employees"
                    label="Контроль"
                    return-object
                    :readonly="!canEditAuthor || readonly"
                    :clearable="canEditAuthor && !readonly"
                    multiple
                    filter="EmployeeActive"
                    dict="Employees"
                    :textCustomAdd="'Создать нового сотрудника'"
                  ></m-autocomplete
                ></v-col>
                <v-col>
                  <m-select
                    v-model="dataSource.Project"
                    :items="projects"
                    item-text="Name"
                    label="Проект"
                    return-object
                    :readonly="
                      !canEditProject || simple || dataSource.Parent || readonly
                    "
                    :clearable="
                      canEditProject &&
                      !simple &&
                      !dataSource.Parent &&
                      !readonly
                    "
                    :dict="{
                      name: 'Project',
                      update: updateProjects,
                      notItems: true,
                    }"
                  ></m-select
                ></v-col>
              </v-row>

              <v-text-field
                label="Автор"
                :value="$options.filters.PersonShortName(dataSource.Author)"
                :readonly="true"
              />

              <v-row v-if="!simple && !dataSource.Parent">
                <v-col>
                  <v-checkbox
                    label="Регулярная задача"
                    v-model="isRegular"
                    :readonly="readonly"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <m-select
                    v-model="Period"
                    :items="TaskPeriod"
                    item-text="value"
                    item-value="id"
                    label="Повторять"
                    :readonly="!(canEditAuthor || canEditExecutor) || readonly"
                    :disabled="!isRegular"
                    :clearable="(canEditAuthor || canEditExecutor) && !readonly"
                  ></m-select>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Дата следующего выполнения"
                    :value="PeriodNextDate"
                    :readonly="true"
                    :disabled="!isRegular"
                  />
                </v-col>
              </v-row>

              <base-date-time-picker
                v-if="dataSource.Done"
                v-model="dataSource.Done"
                :readonly="!canEditAuthor || readonly"
                :clearable="canEditAuthor && !readonly"
                label="Дата завершения"
              ></base-date-time-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-form>
        <base-panel class="mt-2" headerHidden>
          <m-tabs v-model="tab" v-if="!loading">
            <v-tab
              key="Children"
              v-if="
                showChild &&
                !(
                  dataSource.PeriodicityHistory &&
                  dataSource.PeriodicityHistory.length
                )
              "
            >
              Подзадачи ({{ dataSource.Children.length }})
            </v-tab>
            <v-tab key="Files">Прикрепленные файлы</v-tab>
            <v-tab key="History">История изменения</v-tab>
            <v-tab
              v-if="
                dataSource.PeriodicityHistory &&
                dataSource.PeriodicityHistory.length
              "
              key="HistoryExecution"
              >История выполнения</v-tab
            >
            <v-tab v-if="dataSource.QuestionResult" key="AuditOperation"
              >Проверка</v-tab
            >
            <v-tab v-if="dataSource.UnwishedEvent" key="UnwishedEvent"
              >Нежелательное событие</v-tab
            >
            <v-tab v-if="dataSource.Document" key="Document"
              >Локальный документ</v-tab
            >
          </m-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              key="Children"
              v-if="
                showChild &&
                !(
                  dataSource.PeriodicityHistory &&
                  dataSource.PeriodicityHistory.length
                )
              "
            >
              <task-list
                class="mt-2"
                :Tasks="dataSource.Children"
                :edit="editChild"
                :create="addChild"
                :isFromTask="false"
                :readonly="!(canEditAuthor || canEditExecutor) || readonly"
                :apiService="apiService"
                @remove="removeTask"
                @afterDelete="originObject.Children = [...dataSource.Children]"
                :current-task-id="dataSource.Id"
              ></task-list>
            </v-tab-item>
            <v-tab-item key="Files">
              <table-load-file
                class="mt-2"
                :files="dataSource.Files"
                :readonly="!canEditFiles || readonly"
                @addFile="addFile"
              />
            </v-tab-item>
            <v-tab-item key="History" class="mt-2">
              <base-list-view
                :dataSource="dataSource.History"
                :headers="historyHeaders"
                isNested
                headerHidden
                hide-default-footer
                notShowSelect
                hide-edit
              >
              </base-list-view>
            </v-tab-item>
            <v-tab-item
              key="HistoryExecution"
              v-if="
                dataSource.PeriodicityHistory &&
                dataSource.PeriodicityHistory.length
              "
              class="mt-2"
            >
              <base-list-view
                :dataSource="dataSource.PeriodicityHistory"
                :headers="periodicityHistoryHeaders"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                isNested
                headerHidden
                hide-default-footer
                notShowSelect
                hide-edit
              >
              </base-list-view>
            </v-tab-item>
            <v-tab-item key="AuditOperation" v-if="dataSource.QuestionResult">
              <check-operation-info-card
                class="mt-2"
                :QuestionResult="dataSource.QuestionResult"
                :AuditOperation="dataSource.AuditOperation"
                :reason.sync="dataSource.Reason"
                :violationDescription.sync="dataSource.ViolationDescription"
                :readonly="readonly"
              />
            </v-tab-item>
            <v-tab-item key="UnwishedEvent" v-if="dataSource.UnwishedEvent">
              <unwished-event-info-card
                class="mt-2"
                :UnwishedEvent="dataSource.UnwishedEvent"
              />
            </v-tab-item>
            <v-tab-item key="Document" v-if="dataSource.Document">
              <document-info-card
                class="mt-2"
                :document="dataSource.Document"
              />
            </v-tab-item>
          </v-tabs-items>
        </base-panel>
      </v-form>
      <base-popup
        title="Завершить задачу"
        v-model="dialogResult"
        @save="saveResult"
        :width="'50vw'"
        :height="'70vh'"
      >
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <base-date-time-picker
                  v-model="resultDate"
                  label="Дата завершения"
                ></base-date-time-picker>

                <v-textarea
                  v-model="resultText"
                  label="Результат"
                  rows="3"
                  v-up-first-symbol
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </base-popup>
    </base-detail-view>
  </v-layout>
</template>

<script>
import TaskService from "@/services/TaskService";
import ProjectService from "@/services/ProjectService";
import { mapActions } from "vuex";
import { defaultTask } from "../../data/defaultObjects";
import { TaskPriority, TaskPeriod, TaskStatus } from "../../data/enums";
import DataHelper from "../../utils/DataHelper";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import Vue from "vue";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseDateTimePicker from "../../components/base/BaseDateTimePicker";
import BaseDatePickerRange from "../../components/base/BaseDatePickerRange";
import BaseListView from "../../layouts/BaseListView.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import Permissions from "../../data/permissions";
import MSelect from "../../components/base/inputs/mSelect.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import TableLoadFile from "../../components/loadFiles/TableLoadFile.vue";
import CheckOperationInfoCard from "../../components/AuditOperation/CheckOperationInfoCard.vue";
import DocumentInfoCard from "../../components/document/DocumentInfoCard.vue";
import UnwishedEventInfoCard from "../../components/UnwishedEvent/UnwishedEventInfoCard.vue";
import moment from "moment";
import { sortBy } from "@/utils/Functions";
import mTabs from "@/components/base/tabs/mTabs";

export default {
  name: "view-TaskEdit",
  components: {
    BaseDetailView,
    BaseDateTimePicker,
    BaseDatePickerRange,
    BaseListView,
    BasePanel,
    BasePopup,
    MSelect,
    mAutocomplete,
    TableLoadFile,
    CheckOperationInfoCard,
    DocumentInfoCard,
    UnwishedEventInfoCard,
    mTabs,
    TaskList: () => import("@/components/task/TaskList"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  props: {
    // "Простая задача" - без проекта, периодичности и дочерних подзадач.
    simple: { type: Boolean, default: false },
    _apiService: { type: Object, default: null },
    title: String,
  },
  data: function () {
    return {
      // Api
      apiService: this._apiService || TaskService,
      getDefaultObject: defaultTask,
      getObjectText: (obj, isNew) => (isNew ? "новая" : "№" + obj.Num),
      loading: true,

      showChild: true,
      employees: [],
      projects: [],
      settings: null,
      dataSource: {},
      tab: null,
      dialogResult: false,
      resultDate: null,
      resultText: null,
      isPeriodicityClosing: false,
      sortBy: ["Done"],
      sortDesc: [true],

      valid: false,
      rules: {
        required: (value) => !!value || "Укажите значение.",
        notifyStartDate: (value) => {
          if (!value) {
            return true;
          }
          const val = DataHelper.toDateTime(value);
          const isCurrentDay =
            moment
              .unix(parseInt(val?.getTime() / 1000))
              .startOf("day")
              .toString() === moment().startOf("day").toString();
          return (
            !this.isUnsaved ||
            !val ||
            this.NotifyStartSent ||
            (val < new Date() && !isCurrentDay
              ? "Дата уведомления не должна быть раньше текущего дня и времени."
              : true)
          );
        },
      },
      historyHeaders: [
        {
          text: "Дата изменения",
          value: "Date",
          dataType: "Date",
          align: "center",
        },
        {
          text: "Инициатор изменения",
          value: "Employee",
          dataType: "object",
          displayText: Vue.filter("PersonShortName"),
        },
        {
          text: "Автор",
          value: "Author",
          dataType: "object",
          displayText: Vue.filter("PersonShortName"),
        },
        {
          text: "Исполнители",
          value: "Executors",
          dataType: "array",
          displayText: Vue.filter("PersonShortName"),
        },
        {
          text: "Дата завершения",
          value: "Done",
          dataType: "Date",
          align: "center",
          displayText: Vue.filter("DateShort"),
        },
      ],
      periodicityHistoryHeaders: [
        {
          text: "Начало периода выполнения",
          value: "DateIn",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
        },
        {
          text: "Конец периода выполнения",
          value: "DateOut",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
        },
        {
          text: "Дата выполнения",
          value: "Done",
          dataType: "Date",
          align: "center",
        },
        {
          text: "Периодичность выполнения",
          value: "Period",
          dataType: "enum",
          options: TaskPeriod,
          tooltip: "Статус проверки по чек-листу",
        },
        { text: "Результат выполнения", value: "Result" },
      ],
    };
  },
  computed: {
    TaskPriority: () => TaskPriority,
    TaskPeriod: () => TaskPeriod,
    TaskStatus: () => TaskStatus,
    isRegular: {
      set(val) {
        this.Period = val ? 1 : null;
      },
      get() {
        return !!this.Period;
      },
    },
    // Срок выполнения
    PlannedDates: {
      get() {
        let result = [];
        if (this.dataSource) {
          const start = this.dataSource?.PlannedPeriod?.DateIn;
          const end = this.dataSource?.PlannedPeriod?.DateOut;
          if (start && end) result = [start, end];
          else if (end) result = [end];
          else if (start) result = [start];
        }
        return result;
      },
      set(value) {
        const oldDateIn = this.dataSource.PlannedPeriod.DateIn;
        const oldDateOut = this.dataSource.PlannedPeriod.DateOut;
        if (!value || value.length === 0) {
          this.dataSource.PlannedPeriod.DateIn = null;
          this.dataSource.PlannedPeriod.DateOut = null;
        } else if (value.length === 1) {
          this.dataSource.PlannedPeriod.DateIn = value[0];
          this.dataSource.PlannedPeriod.DateOut = null;
        } else {
          this.dataSource.PlannedPeriod.DateIn = value[0];
          this.dataSource.PlannedPeriod.DateOut = value[1];
        }
        if (
          moment(
            this.dataSource.PlannedPeriod.DateIn.substr(0, 10),
            "DD.MM.YYYY"
          ) > moment() &&
          (this.dataSource.PlannedPeriod.DateIn ??
            this.dataSource.PlannedPeriod.DateOut) &&
          (this.dataSource.PlannedPeriod.DateIn !== oldDateIn ||
            this.dataSource.PlannedPeriod.DateOut !== oldDateOut)
        ) {
          this.dataSource.NotifyStartDate =
            this.dataSource.PlannedPeriod.DateIn ??
            this.dataSource.PlannedPeriod.DateOut;
          if (this.settings?.NotifyTime)
            this.dataSource.NotifyStartDate =
              this.dataSource.NotifyStartDate.substr(0, 10) +
              this.settings?.NotifyTime.substr(10);
        }
      },
    },
    // Признак отправленного уведомления о начале выполнения задачи
    NotifyStartSent() {
      return (
        this.dataSource &&
        this.originObject &&
        this.dataSource.NotifyStartSent &&
        this.dataSource.NotifyStartDate === this.originObject.NotifyStartDate
      );
    },
    // Периодичность выполнения задачи
    Period: {
      get() {
        return this.dataSource.Periodicity?.Period;
      },
      set(value) {
        if (value) this.dataSource.Periodicity = { Period: value };
        else this.dataSource.Periodicity = null;
      },
    },
    PeriodNextDate: function () {
      let date =
        DataHelper.toDate(this.dataSource?.PlannedPeriod?.DateIn) ??
        DataHelper.toDate(this.dataSource?.PlannedPeriod?.DateOut);
      if (date && this.Period) {
        switch (this.Period) {
          case 1:
            date.setDate(date.getDate() + 1);
            break;
          case 2:
            date.setDate(date.getDate() + 7);
            break;
          case 3:
            date = DataHelper.AddMonths(date, 1);
            break;
          case 4:
            date = DataHelper.AddMonths(date, 3);
            break;
          case 5:
            date = DataHelper.AddMonths(date, 6);
            break;
          case 6:
            date.setFullYear(date.getFullYear() + 1);
            break;
          default:
            return null;
        }
        return date.toLocaleDateString();
      }
      return null;
    },
    // Доступ к полям
    currentEmployeeId: function () {
      return this.USER.Employee.Id;
    },
    closed: function () {
      return !!this.originObject?.Done;
    },
    canEditAuthor: function () {
      return (
        !this.closed &&
        (this.hasPermission(Permissions.AllTasksEdit) ||
          (this.hasPermission(Permissions.TasksEdit) &&
            (this.type === "create" ||
              this.originObject?.Author?.Id === this.currentEmployeeId)))
      );
    },
    canEditExecutor: function () {
      return (
        !this.closed &&
        this.originObject?.Executors?.find(
          (e) => e.Id === this.currentEmployeeId
        )
      );
    },
    canEditResult: function () {
      return (
        this.originObject?.Executors?.find(
          (e) => e.Id === this.currentEmployeeId
        ) || this.originObject?.Author?.Id === this.currentEmployeeId
      );
    },
    canEditProject: function () {
      return this.canEditAuthor && this.hasPermission(Permissions.ProjectsEdit);
    },
    canEditFiles: function () {
      return (
        this.originObject?.Author?.Id === this.currentEmployeeId ||
        this.originObject?.Executors?.find(
          (e) => e.Id === this.currentEmployeeId
        )
      );
    },
    readonly() {
      return this.dataSource.OnlyRead;
    },
  },
  watch: {
    "dataSource.Done": function () {
      this.applyListViewItemUpdateFunc();
    },
  },
  created() {
    this.LOAD_AUDIT_INDICATORS();
  },
  async mounted() {
    // const lastFrame = this.$store.getters["frame/GET_DATA"]();
    // if (lastFrame?.meta.taskLevel === 1) this.showChild = false;

    try {
      this.employees = await this.LOAD_EMPLOYEES();
      this.settings = await this.LOAD_TASK_SETTINGS();
    } catch (error) {
      console.log(error);
    }
    await this.init();
  },
  beforeDestroy() {
    // if (!this.nested) this.$store.dispatch("frame/DESTROY_COMPONENT");
  },
  methods: {
    ...mapActions([
      "GET_USER",
      "LOAD_EMPLOYEES",
      "LOAD_TASK_SETTINGS",
      "LOAD_AUDIT_INDICATORS",
    ]),

    openParent() {
      const parent = this.dataSource.Parent;

      if (this.isNested) {
        this.$store.dispatch("frame/RUN_COMPONENT", {
          componentName: "task/TaskEdit",
          params: {
            _objectId: parent.Id,
            _type: "edit",
            title: "Подзадача" + (parent.Num ? " №" + parent.Num : ""),
            dialogHeight: "100vh",
            persistent: false,
            nested: true,
            simple: true,
          },
          meta: {
            taskLevel: 0,
          },
        });
      } else {
        this.$router.push({
          name: "TaskEdit",
          params: {
            objectId: parent.Id,
          },
        });
      }
    },
    removeTask(task) {
      this.dataSource.Children = this.dataSource.Children.filter(
        (e) => e.Id !== task.Id
      );
    },
    async makeIndependentTask() {
      try {
        await TaskService.makeIndependentTask(this.dataSource.Id);
        this.dataSource.Parent = null;
        this.originObject.Parent = null;

        this.$store.commit("frame/SET_META", { meta: { _needDelete: true } });
        this.save();
      } catch (error) {
        console.log(error);
      }
    },
    DataFromServerNormalize(data) {
      // Проект по умолчанию для новой задачи
      const projectId = Number(this.$router.currentRoute.query.projectId);
      if (this.type === "create" && projectId && !this.nested) {
        data.Project = this.projects.find((p) => p.Id === projectId);
      }
      // Даты подзадач
      for (const child of data.Children) {
        child.PlannedEndVal = DataHelper.toDate(child.PlannedEnd)?.getTime();
        child.DoneVal = DataHelper.toDateTime(child.Done)?.getTime();
        // Даты истории подзадачи
        for (const history of child.History) {
          history.DateVal = DataHelper.toDateTime(history.Date)?.getTime();
          history.DoneVal = DataHelper.toDate(history.Done)?.getTime();
        }
      }
      // Даты истории
      for (const history of data.History) {
        history.DateVal = DataHelper.toDateTime(history.Date)?.getTime();
        history.DoneVal = DataHelper.toDate(history.Done)?.getTime();
      }
      // Даты истории период. задач
      for (const history of data.PeriodicityHistory) {
        history.DoneVal = DataHelper.toDateTime(history.Done)?.getTime();
      }
      // Добавление закрытого проекта задачи в источник проектов
      if (data.Project && data.Project.Done) this.projects.push(data.Project);

      //
      if (data.UnwishedEvent)
        data.UnwishedEvent.PlaceDisplay =
          data?.UnwishedEvent?.Partition?.Name || data?.UnwishedEvent?.Place;
      return data;
    },
    async init() {
      this.projects = sortBy(await this.loadProjects(), "Name");
      this.baseInit();
      // if (!this.nested) this.$store.dispatch("frame/DESTROY_COMPONENT");
    },
    async save() {
      // if (!this.nested) this.$store.dispatch("frame/DESTROY_COMPONENT");
      return this.baseSave();
    },
    async updateProjects() {
      this.projects = sortBy(await this.loadProjects(), "Name");
    },
    async loadProjects() {
      try {
        const { data } = await ProjectService.get({
          done: false,
          author: !this.hasPermission(Permissions.AllProjectsEdit),
          responsible: !this.hasPermission(Permissions.AllProjectsEdit),
        });
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    // Редактирование подзадач
    addChild() {
      const _id = -Math.round(Math.random() * 100000);
      const lastFrame = this.$store.getters["frame/GET_DATA"]();
      const taskLevel =
        lastFrame?.meta.taskLevel >= 0 ? lastFrame?.meta.taskLevel + 1 : 1;
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: _id,
          _object: { Indicator: this.dataSource.Indicator },
          _type: "create",
          simple: true,
          title: "Создание подзадачи",
          persistent: true,
        },
        meta: {
          taskLevel,
        },
        callback: () => {
          const task = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;
          if (meta.valid !== undefined && !meta.valid) return;
          task.Id = _id;

          task.PlannedEndVal = DataHelper.toDate(task.PlannedEnd)?.getTime();
          task.DoneVal = DataHelper.toDate(task.Done)?.getTime();

          this.dataSource.Children.push(task);
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
        },
      });
    },
    editChild(task) {
      console.log("editChild TaskEdit");
      const lastFrame = this.$store.getters["frame/GET_DATA"]();
      const taskLevel = lastFrame?.meta.taskLevel
        ? lastFrame?.meta.taskLevel + 1
        : 1;
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: task.Id,
          _object: task,
          _type: "edit",
          simple: true,
          title: "Подзадача" + (task.Num ? " №" + task.Num : ""),
          dialogHeight: "100vh",
        },
        meta: {
          taskLevel,
        },
        callback: () => {
          const editedTask = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;
          if (!editedTask.Name) return; // todo: проверка полной валидации формы
          editedTask.PlannedEndVal = DataHelper.toDate(
            editedTask.PlannedEnd
          )?.getTime();
          editedTask.DoneVal = DataHelper.toDate(editedTask.Done)?.getTime();

          const index = this.dataSource.Children.findIndex(
            (t) => t.Id === task.Id
          );
          if (index >= 0) {
            // Удаление подзадачи, если она стала самостоятельной
            if (meta._needDelete) {
              this.dataSource.Children = this.dataSource.Children.filter(
                (e) => e.Id !== editedTask.Id
              );
            } else this.$set(this.dataSource.Children, index, editedTask);
          } else
            DataHelper.treeListTraverse(this.dataSource.Children, (item) => {
              const index = item.Children.findIndex(
                (t) => t.Id === editedTask.Id
              );
              if (index >= 0) {
                // Удаление подзадачи, если она стала самостоятельной
                if (meta._needDelete)
                  item.Children = item.Children.filter(
                    (e) => e.Id !== editedTask.Id
                  );
                else this.$set(item.Children, index, editedTask);
              }
            });

          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
        },
      });
    },
    // Редактирование результата
    setResult() {
      this.resultDate = DataHelper.toServerDateTime(new Date());
      this.resultText = this.dataSource.Result;
      this.dialogResult = true;
    },
    async saveResult() {
      const { data } = await TaskService.updateState(
        this.dataSource.Id,
        this.resultDate,
        this.resultText,
        this.isPeriodicityClosing
      );

      if (this.isNested) await this.save();
      else this.init();

      this.dialogResult = false;
      this.dataSource.Done = data.Done;
      this.originObject.Done = data.Done;

      if (data.Done) {
        this.dataSource.Result = data.Result;
        this.originObject.Result = data.Result;
      }
    },
    async openResult() {
      const { data } = await TaskService.updateState(
        this.dataSource.Id,
        null,
        null,
        this.isPeriodicityClosing
      );
      this.dataSource.Done = data.Done;
      this.dataSource.Result = data.Result;
      this.originObject.Done = data.Done;
      this.originObject.Result = data.Result;

      if (this.isNested) this.save();
    },
    async addFile(newFile) {
      if (this.dataSource.Files.find((e2) => e2.Name === newFile.Name)) {
        if (
          await this.$confirm(
            "Файл " + newFile.Name + " уже добавлен. Перезаписать ?"
          )
        ) {
          this.dataSource.Files = this.dataSource.Files.filter(
            (e2) => e2.Name !== newFile.Name
          );
        } else {
          return;
        }
      }
      this.dataSource.Files.push(newFile);
    },
    changeName(value) {
      if (!this.dataSource.Content)
        setTimeout(() => {
          this.$set(this.dataSource, "Content", this.dataSource.Name);
        }, 1);
    },
  },
};
</script>

<style lang="scss">
.tast-edit {
  .row {
    margin-top: 0;
  }
}

.task-edit__form {
  .col {
    max-width: 50%;
  }
}
</style>
