<template>
  <div class="block__content check-operation-info-card">
    <div
      class="check-operation-info-card__title primary--text"
      @click="
        $router.push({
          name: 'AuditOperationEdit',
          params: {
            objectId: AuditOperation.Id,
          },
        })
      "
    >
      Проверка № {{ AuditOperation.Num }} ({{
        AuditOperation.Period.DateIn.substr(0, 10)
      }}
      - {{ AuditOperation.Period.DateOut.substr(0, 10) }})
      {{ AuditOperation.Subject }}
    </div>
    <v-row class="mt-4">
      <v-col>Требование</v-col>
      <v-col>
        <v-treeview
          dense
          v-if="treeNames"
          :items="[treeNames]"
          expand-icon="$ToolArrowDown"
          item-text="Text"
          item-children="Children"
          item-key="Id"
          style="width: 100%"
          open-all
          full-width
        >
        </v-treeview>
      </v-col>
    </v-row>
    <v-row class="mt-0 pa-0">
      <v-col>Несоответствие</v-col>
      <v-col>
        <v-textarea
          :value="violationDescription"
          auto-grow
          rows="2"
          maxlength="200"
          placeholder="Введите несоответствие..."
          outlined
          @input="$emit('update:violationDescription', $event)"
          :readonly="readonly"
        ></v-textarea>
        <images-2
          v-if="QuestionResult.Images.length"
          :images="QuestionResult.Images"
          :with-add="false"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0 pa-0">
      <v-col>Причина</v-col>
      <v-col>
        <v-textarea
          :value="reason"
          auto-grow
          rows="2"
          maxlength="200"
          placeholder="Введите причину..."
          outlined
          @input="$emit('update:reason', $event)"
          :readonly="readonly"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col>Рекомендация</v-col>
      <v-col>{{ QuestionResult.Recommendation }}</v-col>
    </v-row>
  </div>
</template>
<script>
import Images2 from "../CheckList/Question/violation/Images2.vue";
export default {
  components: { Images2 },
  props: {
    QuestionResult: {
      type: Object,
      default: () => {},
    },
    AuditOperation: {
      type: Object,
      default: () => {},
    },
    reason: {
      type: String,
      default: "",
    },
    violationDescription: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treeNames: null,
    };
  },
  created() {
    const first = {
      Text: this.QuestionResult.CheckListName,
      Children: [],
    };
    const rec = (_parent, point) => {
      point.Children.push({
        Text: _parent.Num + " " + _parent.Content,
        Children: [],
      });
      for (const child of _parent.Children) {
        rec(child, point.Children[0]);
      }
    };
    rec(this.QuestionResult.Question, first);

    this.treeNames = first;
  },
};
</script>
<style lang="scss">
.check-operation-info-card {
  padding: 25px;
  font-size: 14px;
  line-height: 158%;
  color: rgb(33, 33, 33);

  &__title {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
  .row .col:first-child {
    width: 150px;
    flex-grow: unset;
    flex-basis: unset;
    font-weight: bold;
    color: #000000;
  }
  .row.mt-4 .col:last-child {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
