var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"disabledAdd":_vm.readonly,"showDelete":!_vm.readonly,"showCopy":!_vm.readonly,"selectedItems":_vm.selected,"pageEditName":"EquipmentEdit","title":"Медицинские изделия","label-text-btn-create":"Добавить мед. изделие","label-text-search":"Поиск мед. изделия"},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"item.ValidationNextDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.processDateClass(item.ValidationNextDate)},[_vm._v(" "+_vm._s(_vm._f("DateShort")(item.ValidationNextDate))+" ")])]}},{key:"item.ServiceNextDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.processDateClass(item.ServiceNextDate)},[_vm._v(" "+_vm._s(_vm._f("DateShort")(item.ServiceNextDate))+" ")])]}}])},[(!_vm.readonly)?_c('v-btn',{attrs:{"slot":"action","disabled":!_vm.isSelected,"icon":"","title":"Изменить"},on:{"click":function($event){_vm.dialog = true}},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-edit","s16":""}})],1):_vm._e(),_c('report-activator',{attrs:{"slot":"action","report":[
        'EquipmentStandartExecutionReport',
        'EquipmentsReport',
        'EquipmentsValidationReport',
        'EquipmentsServiceReport' ]},slot:"action"})],1),_c('base-popup',{attrs:{"title":"Установить","icon":_vm.UtilGetIcon('view-Equipments'),"width":'40vw',"height":'20vw',"buttonSaveText":"Применить"},on:{"save":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('base-date-picker',{attrs:{"label":"Дата последней поверки","clearable":""},model:{value:(_vm.validationDate),callback:function ($$v) {_vm.validationDate=$$v},expression:"validationDate"}}),_c('base-date-picker',{attrs:{"label":"Дата последнего обслуживания","clearable":""},model:{value:(_vm.serviceDate),callback:function ($$v) {_vm.serviceDate=$$v},expression:"serviceDate"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }