<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    pageEditName="EquipmentStandartView"
    title="Стандарты оснащения"
    :showDelete="false"
    :notShowActions="['add']"
    show-actions
    notShowSelect
  >
    <template v-slot:item.actions="{ item }">
      <report-activator
        :report="'EquipmentReport'"
        :params="{ objectId: { name: 'pId', value: item.Id } }"
      />
    </template>
  </base-list-view>
</template>

<script>
import ReportActivator from "@/components/report/ReportActivator.vue";
import EquipmentStandartsService from "../../services/EquipmentStandartsService";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-EquipmentStandarts",
  components: { BaseListView, ReportActivator },
  mixins: [ListEditMixin],
  data: () => {
    return {
      loading: true,
      apiService: EquipmentStandartsService,
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Профиль",
          value: "Profile",
          dataType: "object",
          displayText: (p) => p?.Name,
        },
        { text: "Нормативный документ", value: "NormativeDocument" },
      ],
      dataSource: [],
    };
  },
  async mounted() {
    await this.init();
  },
};
</script>
