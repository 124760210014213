var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('base-list-view',{attrs:{"dataSource":_vm._dataSource,"headers":_vm.headers,"loading":_vm.loading,"disabledAdd":_vm.readOnly,"showDelete":!_vm.readOnly,"selectedItems":_vm.selected,"pageEditName":"TaskEdit","title":_vm.title,"label-text-btn-create":"Добавить задачу","label-text-search":"Поиск задачи","group-by":"groupKey","show-expand":"","item-class":function (item) {
        return item.Status === 5
          ? 'disabled text-decoration-line-through'
          : '';
      }},on:{"onRefresh":_vm.init,"onDelete":_vm.del,"update:dataSource":function($event){_vm._dataSource=$event},"update:data-source":function($event){_vm._dataSource=$event},"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
      var group = ref.group;
      var items = ref.items;
return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.groupName(group))+" ("+_vm._s(_vm.groupTotal(items, group))+")")])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('task-table-expanded',{attrs:{"headers":headers,"Task":item}})]}}])},[(_vm.showUpdate)?_c('v-btn',{attrs:{"slot":"action","disabled":!_vm.isSelected,"icon":"","title":"Изменить"},on:{"click":function($event){_vm.dialog = true}},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-edit","s16":""}})],1):_vm._e(),_c('div',{staticClass:"align-center d-inline-flex",attrs:{"slot":"action","row":""},slot:"action"},[_c('label',{staticClass:"tasks__action-show-all v-label blue-grey--text mr-1",staticStyle:{"font-size":"14px"}},[_vm._v("Показать завершенные")]),_c('v-checkbox',{attrs:{"hide-details":""},on:{"change":_vm.init},model:{value:(_vm.showCompleted),callback:function ($$v) {_vm.showCompleted=$$v},expression:"showCompleted"}})],1)],1),_c('base-popup',{attrs:{"title":"Установить","icon":_vm.UtilGetIcon('view-Tasks'),"width":'40vw',"height":'20vw',"buttonSaveText":"Применить"},on:{"save":_vm.save},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('base-date-time-picker',{attrs:{"clearable":"","label":"Дата завершения"},model:{value:(_vm.dateDone),callback:function ($$v) {_vm.dateDone=$$v},expression:"dateDone"}}),_c('m-autocomplete',{attrs:{"items":_vm.employees,"label":"Исполнитель","return-object":"","clearable":"","filter":"EmployeeActive","dict":"Employees"},model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }