<template>
  <div class="img-viewer">
    <img
      v-img:group="1"
      ref="image"
      :src="url"
      class="img-viewer__zoomable-image"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.img-viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__zoomable-image {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #8a90a4;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}
</style>
