<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn :disabled="disabled" v-on="on" dark color="blue" class="ml-4">
        {{ text }}
      </v-btn>
    </template>
    <v-card>
      <v-card-subtitle class="text-center">{{ caption }}</v-card-subtitle>
      <v-card-text>
        <v-textarea
          v-if="hasComment"
          v-model="comment"
          :label="commentLabel"
          rows="3"
          v-up-first-symbol
        ></v-textarea>
        <v-checkbox
          v-if="fields === 'resetSigned'"
          label="Передать только несогласным"
          v-model="onlyUnsigned"
        ></v-checkbox>
        <m-autocomplete
          v-if="fields === 'employees'"
          label="Согласующие"
          v-model="employees"
          :items="employeesSource"
          :item-text="(item) => $options.filters.PersonShortName(item)"
          :multiple="true"
          :returnObject="true"
          filter="EmployeeActive"
        ></m-autocomplete>
        <slot name="extended"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="ok"> ОК </v-btn>
        <v-btn text @click="menu = false"> Отмена </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import mAutocomplete from "../../../components/base/inputs/mAutocomplete.vue";

export default {
  name: "view-MenuButton",
  components: { mAutocomplete },
  props: {
    hasComment: Boolean,
    disabled: Boolean,
    caption: String,
    text: String,
    fields: String,
    dataEmployeesSource: {
      type: Array,
      default: null,
    },
    commentLabel: {
      type: String,
      default: "Комментарий",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      menu: false,
      comment: null,
      onlyUnsigned: false,
      employees: [],
      employeesSource: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        this.comment = val;
      },
    },
    async dataEmployeesSource(newValue) {
      if (newValue) {
        this.employeesSource = await newValue;
      } else {
        try {
          this.employeesSource = await this.LOAD_EMPLOYEES();
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
  async mounted() {
    try {
      if (!this.dataEmployeesSource) {
        this.employeesSource = await this.LOAD_EMPLOYEES();
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    cancel() {
      this.clear();
      this.menu = false;
    },
    ok() {
      const ev = {
        comment: this.comment,
        resetSigned: !this.onlyUnsigned,
        employees: this.employees,
      };
      this.clear();
      this.menu = false;
      this.$emit("click", ev);
    },
    clear() {
      this.comment = null;
      this.resetSigned = false;
      this.employees = [];
    },
  },
};
</script>
