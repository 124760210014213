var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("v-treeview-node v-treeview-node--click " + (_vm.hasChildren ? '' : 'v-treeview-node--leaf'))},[_c('div',{staticClass:"v-treeview-node__root",on:{"click":_vm.edit}},[(_vm.hasChildren)?_c('v-btn',{attrs:{"title":"Свернуть/Развернуть","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.open = !_vm.open}}},[_c('m-icon',{attrs:{"icon":_vm.open ? 'tool-arrow-down' : 'tool-arrow-right',"size":"24"}})],1):_vm._e(),_vm._t("prepend",null,null,{ item: _vm.value, open: _vm.open }),_c('div',{staticClass:"v-treeview-node__label",class:{ 'ml-7': !_vm.hasChildren }},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.value.name)+" ")]},null,{ item: _vm.value, open: _vm.open })],2),_vm._t("append",null,null,{ item: _vm.value }),_vm._t("captureArea",null,null,{ item: _vm.value, open: _vm.open })],2),_c('div',{staticClass:"v-treeview-node__Children v-treeview-node__Children__draggable"},[_c('draggable',{class:{ test: !_vm.value.Children.length },attrs:{"group":_vm.group,"value":_vm.value.Children,"ghost-class":"ghost","handle":".capture-area"},on:{"input":_vm.updateValue}},[(_vm.open)?_vm._l((_vm.value.Children),function(child){return _c('treeview-node',_vm._g({key:child.Id,attrs:{"group":_vm.group,"value":child,"level":_vm.level + 1,"expand-icon":_vm.expandIcon,"isOpen":_vm.opens.has(child.Id),"opens":_vm.opens,"updateItems":_vm.updateItems,"pageEditName":_vm.pageEditName},on:{"input":_vm.updateChildValue},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
  var item = ref.item;
  var open = ref.open;
return [_vm._t("prepend",null,null,{ item: item, open: open })]}},{key:"label",fn:function(ref){
  var item = ref.item;
  var open = ref.open;
return [_vm._t("label",null,null,{ item: item, open: open })]}},{key:"append",fn:function(ref){
  var item = ref.item;
return [_vm._t("append",null,null,{ item: item })]}},{key:"captureArea",fn:function(ref){
  var item = ref.item;
  var open = ref.open;
return [_vm._t("captureArea",null,null,{ item: item, open: open })]}}],null,true)},
            _vm.$listeners['clickRow'] ? { clickRow: _vm.$listeners['clickRow'] } : {}
          ))}):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }