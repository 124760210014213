var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"analysis-tfoms__base-table analysis-tfoms__table__tree",staticStyle:{"margin-top":"20px"}},[_c('base-tree-table',{staticClass:"analysis-tfoms__table__tree-wapper",attrs:{"items":_vm.data,"headers":[
      { text: '', value: 'Name', width: '100%' },
      {
        text: 'План',
        value: 'VolumePlanValue',
        align: 'center',
        cellClass: 'td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      },
      {
        text: 'Факт',
        value: 'VolumeValue',
        align: 'center',
        cellClass: 'text-no-wrap td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      },
      {
        text: 'План',
        value: 'FinancialPlanValue',
        align: 'center',
        cellClass: 'td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      },
      {
        text: 'Факт',
        value: 'FinancialValue',
        align: 'center',
        cellClass: 'text-no-wrap',
        cellClass: 'td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      },
      {
        text: 'Штрафы',
        value: 'FineValue',
        align: 'center',
        width: '30px',
        cellClass: 'td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      },
      {
        text: 'Санкции',
        value: 'SanctionValue',
        align: 'center',
        cellClass: 'td1',
        displayText: function (e) { return this$1.$options.filters.numberSplit(e); },
      } ],"hide-default-header":"","hide-default-footer":"","notShowSelect":"","nested":"","item-class":function (item) {
        return _vm.value && _vm.value.Id === item.Id ? 'font-weight-bold' : '';
      }},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [(!scope.level)?_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-center"}),_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Объемы")]),_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("Финансовое обеспечение")]),_c('th'),_c('th'),_c('th')]),_c('tr',[_c('th'),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("План")]),_c('th',{staticClass:"text-center"},[_vm._v("Факт")]),_c('th',{staticClass:"text-center"},[_vm._v("План")]),_c('th',{staticClass:"text-center"},[_vm._v("Факт")]),_c('th',{staticClass:"text-center"},[_vm._v("Штрафы")]),_c('th',{staticClass:"text-center"},[_vm._v("Санкции")]),_c('th')])]):_vm._e()]}},{key:"item.VolumeValue",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.VolumeValue))),(item.VolumeValue > item.VolumePlanValue)?_c('m-icon',{attrs:{"color":"success","icon":'mdi-arrow-up-thin'}}):_vm._e(),(item.VolumeValue < item.VolumePlanValue)?_c('m-icon',{attrs:{"color":"error","icon":'mdi-arrow-down-thin'}}):_vm._e()]}},{key:"item.FinancialValue",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.FinancialValue))),(item.FinancialValue > item.FinancialPlanValue)?_c('m-icon',{attrs:{"color":"success","icon":'mdi-arrow-up-thin'}}):_vm._e(),(item.FinancialValue < item.FinancialPlanValue)?_c('m-icon',{attrs:{"color":"error","icon":'mdi-arrow-down-thin'}}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }