<template>
  <base-panel v-bind="$attrs" class="home__card">
    <template v-if="loading" slot="header">
      <v-toolbar-title style="width: 100%">
        <v-skeleton-loader
          type="list-item-avatar"
        ></v-skeleton-loader></v-toolbar-title
    ></template>
    <div class="block__content home__card-body">
      <v-skeleton-loader
        v-if="loading"
        type="paragraph, chip"
      ></v-skeleton-loader>

      <base-table-full
        v-else
        no-data-text="Список пуст"
        hide-default-footer
        hide-default-header
        :headers="params.headers"
        :dataSource="data"
        notShowSelect
        style="height: 244px"
        @click:row="handleClick"
        not-filter
      >
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope" />
        </template>
      </base-table-full>
      <slot name="cardAction">
        <v-btn
          v-if="params && params.moreText"
          class="mt-4"
          block
          depressed
          @click="handleMore"
          >{{ params.moreText }}</v-btn
        >
      </slot>
    </div>
  </base-panel>
</template>
<script>
import BasePanel from "@/layouts/BasePanel";
import BaseTableFull from "@/components/base/BaseTableFull";
export default {
  name: "home-card",
  components: {
    BasePanel,
    BaseTableFull,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    customHandleClick: {
      type: Function,
      default: null,
    },
  },
  data: function () {
    return {
      loading: true,
      data: [],
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler() {
        if (!this.loading) this.init();
      },
    },
    "$parent.$parent.isInit": {
      immediate: true,
      handler(val) {
        if (!val) this.loading = true;
        else {
          this.loading = false;
        }
      },
    },
    params: {
      deep: true,
      handler() {
        if (this?.params?.load) this.init();
      },
    },
  },
  methods: {
    async init() {
      this.data = (await this.params.load.get()).data;
      this.loading = false;
    },
    handleClick(item) {
      if (this.customHandleClick) {
        this.customHandleClick(item);
        return;
      }

      if (!this.params.item) {
        this.handleMore(item);
        return;
      }

      this.$router.push({
        name: this.params.item,
        params: {
          objectId: item[this.params.valueKey || "Id"],
        },
      });
    },
    handleMore(item) {
      this.$router.push({
        name: this.params.items,
        params: { ...this.params?.query, number: item?.Id ?? this.data[0]?.Id },
      });
    },
  },
};
</script>
<style lang="scss">
.home__card {
  flex: 50%;
  padding: 6px 12px;

  td {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  header .v-toolbar__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 158%;

    color: rgb(33, 33, 33);
  }
  &-body {
    height: 322px;
    padding: 0.8rem 1.5rem;
    background: white;
  }
  .v-skeleton-loader__list-item,
  .v-skeleton-loader__actions,
  .v-skeleton-loader__list-item-avatar {
    background: #fbfbfb !important;
  }
}
</style>
