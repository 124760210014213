<template>
  <v-flex class="mb-12">
    <base-panel class="base-list-view" v-bind="$attrs" :icon="getViewIcon">
      <slot name="actionBefore" slot="action" />
      <base-search
        v-if="notShowActions.indexOf('search') === -1"
        slot="action"
        v-model="searchtext"
        v-bind="$attrs"
        class="base-list-view__search"
      />
      <v-btn
        slot="action"
        v-if="!isNested && notShowActions.indexOf('refresh') === -1"
        @click="$emit('onRefresh')"
        icon
        title="Обновить"
      >
        <m-icon icon="tool-refresh"></m-icon>
      </v-btn>
      <v-btn
        slot="action"
        v-if="showDelete && notShowActions.indexOf('delete') === -1"
        @click="del"
        :disabled="!isSelected"
        icon
        title="Удалить"
        :color="'error'"
      >
        <m-icon icon="tool-delete"></m-icon>
      </v-btn>

      <v-btn
        v-if="showCopy && notShowActions.indexOf('copy') === -1"
        slot="action"
        @click="copy"
        :disabled="
          !isSelected ||
          selected.length > 1 ||
          ((typeof this.disabledCopy === 'function'
            ? this.disabledCopy(selected)
            : this.disabledCopy) &&
            $store.state.Organization.OwnerType !== 2)
        "
        icon
        title="Копировать"
      >
        <m-icon icon="tool-copy" size="18"></m-icon>
      </v-btn>

      <slot
        v-for="slot in Object.keys($slots).filter(
          (e) => e !== 'table' && e !== 'actionBefore'
        )"
        :name="slot"
        :slot="slot"
      />

      <div
        row
        slot="action"
        class="align-center d-inline-flex"
        v-if="showCompleted !== null"
      >
        <label
          class="tasks__action-show-all v-label mr-1"
          style="font-size: 14px"
          >{{ showCompletedText }}</label
        >

        <v-checkbox
          :value="showCompleted === 'all'"
          @change="$emit('update:showCompleted', $event ? 'all' : 'notdone')"
          hide-details
        ></v-checkbox>
      </div>

      <prefab-button-save
        v-if="notShowActions.indexOf('add') === -1"
        v-bind="$attrs"
        :disabled="disabledAdd"
        class="ml-4"
        @add="add"
        slot="action"
      />
      <slot
        name="table"
        v-bind:attrs="{ ...$attrs, searchText: searchtext }"
        v-bind:listeners="{ ...$listeners, 'click:row': handleClick }"
      >
        <base-table-full
          :dataSource="dataSource"
          :searchText="searchtext"
          v-bind="$attrs"
          v-model="selected"
          @click:row="handleClick"
          v-on="$listeners"
          ref="tableFull"
          :hideEdit="$attrs['hideEdit']"
          :hideDelete="$attrs['hideDelete']"
          :item-class="(e) => e.IsNotified && 'font-weight-bold'"
        >
          <template
            v-for="slot in Object.keys($scopedSlots).filter(
              (slot) => slot !== 'action'
            )"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope" />
          </template>
          <template slot="group.header.notif" slot-scope="{ items }">
            <v-btn
              v-if="items.find((e) => e.IsNotified)"
              fab
              color="primary"
              x-small
              class="ml-1 notification__point"
              title="В группе есть объект с оповещением"
              style="position: static"
            >
              {{ items.filter((e) => e.IsNotified).length }}
            </v-btn>
          </template>
        </base-table-full>
      </slot>
    </base-panel>
  </v-flex>
</template>

<script>
import { xor } from "lodash";
import { mergeDeep } from "vuetify/lib/util/helpers";
import BasePanel from "./BasePanel.vue";
import BaseSearch from "../components/base/BaseSearch";
import BaseTableFull from "../components/base/BaseTableFull";
import PrefabButtonSave from "../components/prefabs/PrefabButtonSave.vue";

import IconViewMixin from "../mixins/IconViewMixin";
// @click:row="$listeners['click:row']"

export default {
  components: { BasePanel, BaseTableFull, BaseSearch, PrefabButtonSave },
  mixins: [IconViewMixin],
  props: {
    isNested: {
      type: Boolean,
      default: false,
    },
    notShowActions: {
      type: Array,
      default: () => [],
    },
    showCopy: Boolean,
    disabledCopy: { type: [Boolean, Function], default: null },
    showDelete: {
      type: Boolean,
      default: () => true,
    },
    disabledAdd: Boolean,
    dataSource: Array,
    addInTable: Boolean,
    addParams: Object,
    pageEditName: String,
    queryParams: {
      type: Object,
      default: () => ({}),
    },
    search: String,
    selectedItems: [],
    funcHandleClickId: {
      type: Function,
      default: null,
    },

    showCompletedText: {
      type: String,
      default: "Показать завершенные",
    },
    showCompleted: {
      type: String,
      default: null,
      validator: function (value) {
        return ["all", "done", "notdone"].includes(value);
      },
    },
  },
  data: () => {
    return {
      selected: [],
      searchtext: "",
    };
  },
  computed: {
    isSelected() {
      return this.selected?.length > 0;
    },
  },
  watch: {
    selected: function (val) {
      this.$emit("update:selectedItems", val);
    },
    search: {
      immediate: true,
      handler(val) {
        this.searchtext = val;
      },
    },
    searchtext: {
      handler(val) {
        this.$emit("update:search", val);
      },
    },
    selectedItems: {
      immediate: true,
      handler(val) {
        this.selected = val;
      },
    },
  },

  methods: {
    updateItems(items) {
      items = this._.cloneDeep(items);
      if (items.length > 1) {
        throw new Error("Можно изменить только один элемент");
      }

      let item = items[0];
      if (item) {
        item = this?.$parent?.DataFromServerNormalize([item])[0];
        const itemIndex = this.dataSource.findIndex((e) => e.Id === item.Id);

        if (itemIndex !== -1) {
          const props = xor(
            Object.keys(item),
            Object.keys(this.dataSource[itemIndex])
          );
          for (const i in props) {
            const prop = props[i];

            this.$set(item, prop, this.dataSource[itemIndex][prop]);
          }
          this.$set(
            this.dataSource,
            itemIndex,
            mergeDeep(this.dataSource[itemIndex], item)
          );
        } else this.dataSource.push(item);
      }
      this.$emit("update:dataSource", this.dataSource);
    },
    handleClick(value) {
      if (this.$listeners.fetchHandle) {
        if (this.$listeners.fetchHandle(value)) return;
      }
      if (this.$listeners.customHandle) {
        this.$listeners.customHandle(value);
        return;
      }

      // Получение id строки
      const itemId = this.funcHandleClickId
        ? this.funcHandleClickId(value)
        : value.Id;

      if (itemId <= 0 || !this.pageEditName) return;

      this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
        listViewUpdateItems: this.updateItems,
        listViewInit: this.$parent?.init,
      });

      // Сделать прочитанно
      if (value.IsNotified) value.IsNotified = false;

      this.$router.push({
        name: this.pageEditName,
        params: {
          objectId: itemId,
        },
      });
    },
    add(item) {
      if (this.$listeners.customAdd) {
        this.$emit("customAdd");
      } else if (!item || item.value === "new") this.defaultAdd();
      else this.$emit("add", item);
    },
    defaultAdd() {
      if (!this.addInTable) {
        this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
          listViewUpdateItems: this.updateItems,
          listViewInit: this.$parent?.init,
        });
        this.$router.push({
          name: this.pageEditName,
          params: {
            objectId: 0,
            ...this.addParams,
          },
          query: {
            type: "create",
            ...this.queryParams,
          },
        });
      } else {
        this.$refs.tableFull.addInTable();
      }
    },
    del() {
      if (this.selected.length === 0) return;
      if (this.$listeners.onDelete) {
        const ids = this.selected.map((el) => el.Id);
        this.$emit("onDelete", ids);
        return;
      }
      for (const i in this.selected) {
        const index = this.dataSource.indexOf(this.selected[i]);
        this.dataSource.splice(index, 1);
      }
      this.selected = [];
    },
    copy() {
      this.$router.push({
        name: this.pageEditName,
        params: {
          objectId: this.selected[0].Id,
          copyId: this.selected[0].Id,
        },
        query: {
          type: "copy",
        },
      });
    },
  },
};
</script>

<style lang="scss">
.base-list-view {
  .v-data-table__wrapper {
    max-height: calc(100vh - 233px) !important;
  }
}
</style>
