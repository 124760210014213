<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    :customInplaceEdit="customInplaceEdit"
    @customDelete="customDelete"
    :editIndex.sync="editIndex"
    :defaultObject="defaultObject"
    show-actions
    :editable="(item) => !readOnly && (item.Id <= 0 || !item.IsSystem)"
    :showDelete="false"
    :disabledAdd="readOnly"
    title="Должности"
    label-text-btn-create="Добавить должность"
    label-text-search="Поиск должности"
    add-in-table
    :exclude-settings-actions="['group']"
    pageEditName="PositionEdit"
    notShowSelect
  >
    <template #item.Name="scope">
      <v-text-field
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        :value="scope.rowEditing.Name"
        @change="scope.rowEditing.Name = $event"
        v-focusInput="scope.closeEdit"
        placeholder="Введите должность"
        v-up-first-symbol
      ></v-text-field>
      <span v-else>{{ scope.item.Name }}</span>
    </template>
  </base-list-view>
</template>

<script>
import PositionService from "../../services/PositionService";
import { defaultPosition } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-Positions",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: PositionService,
      defaultObject: defaultPosition,
      headers: [
        { text: "Название", value: "Name", notGroup: true, width: "100%" },
        {
          text: "Системная",
          value: "SystemText",
          align: "center",
          cellClasss: "td5 td-minimum text-no-wrap",
        },
      ],
      dataSource: [],
      editIndex: null,
      readOnly: !this.hasPermission(Permissions.PositionsEdit),
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    elChange(event, sc) {
      sc.rowEditing.Name = event;
      console.log("отрабатывает change на элементе: ", event);
    },
    DataFromServerNormalize(data) {
      data.forEach((item) => {
        item.SystemText = item.IsSystem ? "Да" : "";
      });
      return data;
    },
    async customInplaceEdit(value) {
      if (!value) return;
      const res = (
        await PositionService[value.Id <= 0 ? "create" : "update"](value)
      ).data;
      return res;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await PositionService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
  },
};
</script>
