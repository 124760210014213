<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @onDelete="del"
    :disabledAdd="readOnly"
    title="Разделы деятельности"
    label-texttext-btn-create="Добавить раздел"
    label-search="Поиск раздела"
    :sort-by="['Name']"
    pageEditName="AuditIndicatorCategoriesEdit"
  >
  </base-list-view>
</template>

<script>
import AuditIndicatorCategoryService from "../../services/AuditIndicatorCategoryService";
import { defaultAuditIndicatorCategory } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-AuditIndicatorCategories",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: AuditIndicatorCategoryService,
      defaultObject: defaultAuditIndicatorCategory,
      headers: [
        { text: "Название", value: "Name" },
        { text: "Шаблон документа", value: "TemplateData" },
      ],
      dataSource: [],
      editIndex: null,
    };
  },
  computed: {
    readOnly: function () {
      return !this.hasPermission(Permissions.AuditIndicatorCategoriesEdit);
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
