<template>
  <bar-chart
    ref="barChart"
    :chart-data="barData"
    :options="barOptions"
    :width="600"
    :height="200"
  ></bar-chart>
</template>
<script>
import { diff } from "deep-diff";
import BarChart from "@/views/analysis/BarChart.js";
import { debounce } from "lodash";
import StatisticIndicatorHelper from "../../../views/statisticIndicator/StatisticIndicatorHelper";
export default {
  components: {
    BarChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    factColor: {
      type: String,
      default: "#5379FB",
    },
    planColor: {
      type: String,
      default: "#BBC6F0",
    },
    labelPlan: {
      type: String,
      default: "План",
    },
    labelFact: {
      type: String,
      default: "Факт",
    },
    hideDatalabel: {
      type: Boolean,
      default: false,
    },
    period: {
      type: Number,
      default: 0, // 0 - месяц, 1 - кварта, 2 - полгода
    },
    refs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      inti2: null,
      itemIndex: -1,
      barData: {
        datasets: [
          {
            label: this.labelFact,
            data: [],
            backgroundColor: this.factColor,
            borderRadius: 15,
          },
          {
            label: this.labelPlan,
            data: [],
            backgroundColor: this.planColor,
            borderRadius: 15,
          },
        ],

        labels: [],
      },
      barOptions: {
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",

          callbacks: {
            /* footer: function (tooltipItem, data) {
              return "Total: 100 planos.";
            }, */

            label: (tooltipItems, data) => {
              let label = null;

              const i = tooltipItems.datasetIndex;

              const fact = this.data[tooltipItems.index]?.fact || 0;
              const plan = this.data[tooltipItems.index]?.plan || 0;
              label =
                data.datasets[i].label +
                ": " +
                (tooltipItems.datasetIndex === 0 ? fact : plan);

              return label;
            },
          },
        },
        plugins: {
          datalabels: {
            align: "end",
            anchor: "end",

            formatter: (value, e) => {
              if (this.hideDatalabel) return "";
              else {
                if (this.isNotPercent) {
                  return value;
                } else if (e.datasetIndex === 1) {
                  return this.barData.datasets[0].data[e.dataIndex] + "%";
                }
              }
              return "";
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,

        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              barPercentage: 0.9,
              categoryPercentage: 1.0,
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                min: 0,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 25,
          },
        },
        onClick: (event, array) => {
          if (!this.$listeners.itemClick) return;
          if (array?.length > 0 && array[0]?._index >= 0) {
            const i = array[0]._index;

            if (i === this.itemIndex)
              this.barData.datasets[0].backgroundColor = this.getColors();
            else
              this.barData.datasets[0].backgroundColor =
                this.barData.datasets[0].data.map((e) =>
                  this.itemIndex === i ? "blue" : "#BBC6F0"
                );

            if (this.itemIndex !== i)
              this.barData.datasets[0].backgroundColor[i] = "blue";

            this.barData = Object.assign({}, this.barData);
            this.$emit("itemClick", i);
            this.itemIndex = i === this.itemIndex ? -1 : i;
          }
        },
      },
    };
  },
  computed: {
    computedPeriod() {
      return this.period === 0
        ? [
            "янв",
            "фев",
            "март",
            "апр",
            "май",
            "июнь",
            "июль",
            "авг",
            "сен",
            "окт",
            "ноя",
            "дек",
          ]
        : this.period === 1
        ? ["1 квартал", "2 квартал", "3 квартал", "4 квартал"]
        : ["1 полугоде", "2 полугоде"];
    },
    isNotPercent() {
      const item = this.data[0];
      return typeof item === "object" && item.length && item.length === 1;
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val, old) {
        if (diff(val, old) && typeof this.init2 === "function") this?.init2();
      },
    },
    period() {
      this.barData.labels = this.computedPeriod;
      this.init();
    },
  },
  mounted() {
    this.barData.labels = this.computedPeriod;
    this.init2 = debounce(() => this.init(), 500);
    this.init2();
  },
  methods: {
    getColors() {
      let backgroundColor = "blue";
      if (this.refs) {
        backgroundColor = this.barData.datasets[0].data.map((e) => {
          return StatisticIndicatorHelper.getColorHexByRefs(this.refs, e, true);
        });
      }
      return backgroundColor;
    },
    init() {
      if (this.data[0]?.length === 1) {
        this.barData.datasets = [
          {
            label: this.labelFact,
            data: [],
            backgroundColor: this.factColor,
            borderRadius: 15,
          },
        ];
      }

      this.barData.datasets[0].data = [];

      if (this.barData.datasets.length === 2)
        this.barData.datasets[1].data = [];

      if (this.data[0]?.length === 2) this.barData.datasets[1].data = [];

      const firstItem = this.data.find((e) => e) || 0;
      if (typeof firstItem === "object" && firstItem.length) {
        this.barOptions.tooltips = {
          mode: "index",
        };
      } else if (
        typeof firstItem === "object" &&
        (firstItem.percent || firstItem.percent === 0)
      ) {
        console.log("");
      } else {
        /* this.barOptions.tooltips = {
          mode: "index",
        }; */
      }

      for (const i in this.data) {
        const item = this.data[i];

        if (typeof item === "object" && item.length && item.length === 1) {
          this.barData.datasets[0].data.push(item[0]);
        } else if (typeof item === "object" && item.length) {
          this.barData.datasets[1].data.push(item[1]);
          this.barData.datasets[0].data.push(item[0]);
        } else if (
          typeof item === "object" &&
          (item.percent || item.percent === 0 || firstItem.percent === 0)
        ) {
          this.barData.datasets[1].data.push(Math.max(0, 100 - item.percent));
          this.barData.datasets[0].data.push(item.percent);
        } else {
          this.barData.datasets[1].data.push(Math.max(0, 100 - item));
          this.barData.datasets[0].data.push(item);
        }
      }

      // Установка ref цвета
      if (this.refs) {
        this.barData.datasets[0].backgroundColor =
          this.barData.datasets[0].data.map((e) => {
            return StatisticIndicatorHelper.getColorHexByRefs(
              this.refs,
              e,
              true
            );
          });
      }

      this.$refs.barChart.renderChart(this.barData, this.barOptions);
    },
  },
};
</script>
