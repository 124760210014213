<template>
  <div class="block__content">
    <v-card-actions>
      <h4 class="ml-5">{{ name }}</h4>
    </v-card-actions>
    <v-card-text>
      <line-chart
        style="max-width: 100%; max-height: 700px; min-height: 400px"
        ref="chart"
        :chart-data="datacollection"
        :options="options"
        :rules="rules"
      ></line-chart>
      <!--<div class="d-flex flex-no-wrap justify-center">
        <v-container style="width: 150px">
          <m-select
            :value="currentYear"
            @change="changeYear"
            :items="years"
            label="Год"
            :clearable="true"
          ></m-select>
        </v-container>
      </div>-->
    </v-card-text>
  </div>
</template>
<script>
import moment from "moment";
import LineChart from "../../views/analysis/LineChart";

export default {
  components: {
    LineChart,
  },
  props: {
    datasets: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    responsibles: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: null,
    },
    period: {
      type: Number,
      default: null,
    },
    years: {
      type: Array,
      default: () => [],
    },
    currentYear: {
      type: Number,
      default: null,
    },
    showObject: {
      type: Object,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: [
        "rgb(215,161,194)",
        "rgb(102,132,29)",
        "rgb(214,143,179)",
        "rgb(204,39,98)",
        "rgb(113,118,129)",
        "rgb(83,195,254)",
        "rgb(30,32,214)",
        "rgb(19,193,166)",
        "rgb(96,196,136)",
        "rgb(78,186,9)",
      ],
      options: {
        annotation: {
          drawTime: "afterDraw",
          annotations: [],
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "month",
                unitStepSize: 1,
                tooltipFormat: "MMM D, YYYY",
              },
              ticks: {
                callback: function (value, index, values) {
                  return value;
                },
              },
              gridLines: {
                display: false,
              },
              // про bounds https://www.chartjs.org/docs/2.9.4/axes/cartesian/time.html#scale-bounds
              bounds: "ticks",
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
              },
            },
          ],
        },
      },
      datacollection: {},
    };
  },
  watch: {
    showObject(val) {
      for (let i = 0; i < this.datacollection.datasets.length; i++) {
        const resp = this.datacollection.datasets[i]?.resp;

        if (!val || (!resp && val.Values)) {
          this.datacollection.datasets[i].hidden = !!i;
        } else if (
          resp &&
          ((this.type === 1 && val.Object?.Id === resp.Partition.Id) ||
            (this.type === 2 && val.Object?.Id === resp.Employee.Id))
        ) {
          this.datacollection.datasets[i].hidden = false;
        } else {
          this.datacollection.datasets[i].hidden = true;
        }
      }

      this.$refs.chart.renderChart(this.datacollection, this.options);
    },
    period: {
      immediate: true,
      handler: function () {
        this.options.scales.xAxes[0].time.unit = this.getUnit();
        this.options = { ...this.options };
      },
    },
    groups: {
      handler: function () {
        this.datacollection = {
          datasets: [
            {
              data: [],
              label: "Итоги",
              borderColor: "#2757FF",
              fill: false,
              borderWidth: 3,
            },
          ],
        };

        if (Object.keys(this.groups).length === 0) {
          this.options.annotation.annotations = [];
          this.$refs.chart.renderChart(this.datacollection, this.options);
          return;
        }

        // Partitions/Employee

        let tIndex = 0;
        for (const resp of this.responsibles) {
          const name =
            this.type === 1
              ? resp.Partition.Name
              : this.$options.filters.PersonShortName(resp.Employee);
          this.datacollection.datasets.push({
            data: [],
            label: name,
            fill: false,
            borderColor:
              tIndex < 15 ? this.colors[tIndex++] : this.dynamicColors(),
            borderWidth: 1,
            hidden: true,
            resp: resp,
          });
          const lastIndex = this.datacollection.datasets.length - 1;

          for (let i = 0; i < this.groups.length; i++) {
            for (let j = 0; j < this.groups[i].Values.length; j++) {
              const item = this.groups[i].Values[j];

              if (!item.Date) continue;

              if (
                (this.type === 1 && item.Object?.Id === resp.Partition.Id) ||
                (this.type === 2 && item.Object?.Id === resp.Employee.Id)
              ) {
                const _date = item.Date.split(".").reverse().join("-");

                this.datacollection.datasets[lastIndex].data.push({
                  x: moment(_date, "YYYY-MM-DD"),
                  y: item.Value,
                });
              }
            }
          }
        }
        // Partitions/Employee end

        for (let i = 0; i < this.groups.length; i++) {
          if (!this.groups[i].Values[0].Date) continue;
          const date = this.groups[i].Values[0].Date.split(".")
            .reverse()
            .join("-");

          this.datacollection.datasets[0].data.push({
            x: moment(date, "YYYY-MM-DD"),
            y: this.groups[i].Value,
          });
        }
        this.initAnnotation();
        /* setTimeout(() => {
          this.$refs.chart.renderChart(this.datacollection, this.options);
          console.log(this.$refs.chart);
        }, 3000); */
      },
    },
    rules() {
      if (this.datacollection.datasets.length) {
        this.initAnnotation();
        this.$refs.chart.renderChart(this.datacollection, this.options);
      }
    },
  },
  methods: {
    initAnnotation() {
      // обновление цвета для референсного диапазона
      const temp = [];
      for (let i = 0; i < this.rules.length; i++) {
        temp.push({
          id: `a-box-${i}`,
          type: "box",
          xScaleID: "x-axis-0",
          yScaleID: "y-axis-0",
          yMin: this.rules[i].yMin,
          yMax: this.rules[i].yMax,
          backgroundColor: this.rules[i].backgroundColor,
          borderWidth: 1,
          borderColor: this.rules[i].backgroundColor,
          drawTime: "beforeDatasetsDraw",
        });
      }
      this.options.annotation.annotations = temp;
    },
    getUnit() {
      if (this.period === 1) return "month";
      if (this.period === 2) return "month";
      if (this.period === 3) return "quarter";
      if (this.period === 4) return "year";
      if (this.period === 5) return "year";
      if (this.period === 6) return "day";
    },
    dynamicColors: function () {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    changeYear(value) {
      this.$emit("changeYear", value);
    },
  },
};
</script>
