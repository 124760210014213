var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',_vm._g({class:{ block__content: !_vm.nested, violations__recommendation: true },attrs:{"headers":_vm.headerRecommendation,"items":_vm.items,"id":"recommendation--table","hide-default-footer":"","hide-default-header":_vm.nested,"expanded":_vm.expanded,"single-expand":true,"notShowSelect":"","nested":"","no-data-text":"","showActions":"","showSettings":false},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Description",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.violationEditing && item.Id === _vm.violationEditing.Id)?_c('v-textarea',{directives:[{name:"up-first-symbol",rawName:"v-up-first-symbol"}],attrs:{"value":_vm.violationEditing.Description,"placeholder":_vm.textPlaceholder,"flat":"","background-color":"transparent","rows":"1","auto-grow":"","autofocus":"","maxlength":"500","rules":[function (v) { return !!v || 'Укажите значение.'; }]},on:{"input":_vm.changeDescription,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)},"blur":function($event){return _vm.edit(index, item)}}}):_c('span',[_vm._v(_vm._s(item.Description))])]}},{key:"item.IsChecked",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","disabled":_vm.readOnly},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.changeChecked(item, $event)}},model:{value:(item.IsChecked),callback:function ($$v) {_vm.$set(item, "IsChecked", $$v)},expression:"item.IsChecked"}})]}},{key:"item.IsTypical",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"typical-violation__change",attrs:{"on-icon":"mdi-alpha-t-box-outline","off-icon":"mdi-checkbox-blank-off-outline","hide-details":"","disabled":_vm.readOnly || !item.IsChecked,"title":_vm.getTooltip(item.IsTypical)},on:{"click":function($event){$event.stopPropagation();}},model:{value:(item.IsTypical),callback:function ($$v) {_vm.$set(item, "IsTypical", $$v)},expression:"item.IsTypical"}})]}},{key:"item.expansionAction",fn:function(ref){
var item = ref.item;
return [_vm._t("expansionAction",null,{"recm":1,"readOnly":_vm.readOnly,"item":item})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.violationEditing || item.Id === _vm.violationEditing.Id)?_c('div',{class:{
        'table__column__actions-active':
          _vm.violationEditing && _vm.violationEditing.Id === item.Id,
      },staticStyle:{"display":"flex"}},[(
          _vm.violationEditing && _vm.violationEditing.Id === item.Id && !item.IsNew
        )?_c('v-btn',{attrs:{"title":"Не сохранять изменения","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.violationEditing = null}}},[_c('m-icon',{attrs:{"icon":"mdi-close","small":""}})],1):_vm._e(),_c('v-btn',{attrs:{"color":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
            ? 'primary'
            : 'success',"disabled":_vm.readOnly || (!item.IsChecked && !_vm.simple),"icon":"","title":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
            ? 'Редактирование записи'
            : 'Cохранить изменения'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('m-icon',{attrs:{"icon":!(_vm.violationEditing && item.Id === _vm.violationEditing.Id)
              ? 'tool-edit'
              : 'mdi-check',"small":"","s16":""}})],1),_c('v-btn',{attrs:{"icon":"","title":"Удалить","disabled":_vm.readOnly || (!item.IsChecked && !_vm.simple),"color":'error'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.del(index)}}},[_c('m-icon',{attrs:{"icon":"tool-delete","s16":""}})],1)],1):_vm._e()]}},{key:"body.append",fn:function(ref){
              var headers = ref.headers;
return [_c('tr',[(!_vm.hideSelect)?_c('td'):_vm._e(),_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{staticClass:"recommendation-table__add",attrs:{"text":"","color":"blue","large":"","disabled":_vm.readOnly},on:{"click":_vm.add}},[_c('m-icon',{staticClass:"mr-3",staticStyle:{"font-size":"30px"},attrs:{"icon":"mdi-plus-circle","dark":"","left":""}}),_vm._v(" "+_vm._s(_vm.textAdd)+" ")],1)],1)])]}}],null,true)},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }