<!-- Здесь реализована таблица с 2 заголовками -->
<!-- 1-й заголовок таблицы визуальный, объявленный через v-slot:header-->
<!-- 2-й из BaseTable, который описывает имена столбцов и реализует соотв. логику-->
<template>
  <div
    class="analysis-tfoms__table analysis-tfoms__base-table analysis-tfoms__table-total"
  >
    <base-table
      :items="data"
      :headers="[
        { text: 'Период', value: 'Name', sortable: false },

        { text: 'План', value: 'FinancialPlanValue', align: 'right' },
        {
          text: 'Факт',
          value: 'FinancialValue',
          align: 'right',
          cellClass: 'text-no-wrap',
        },
        { text: 'Выполнение', value: 'FinancialValuePercent', align: 'right' },
        {
          text: 'Неоплата',
          value: 'NonPaymentValue',
          align: 'right',
          cellClass: 'red--text',
        },
        {
          text: 'Штрафы',
          value: 'FineValue',
          align: 'right',
          cellClass: 'red--text',
        },
        {
          text: 'Санкции',
          value: 'SanctionValue',
          align: 'right',
          cellClass: 'red--text',
        },
      ]"
      hide-default-footer
      notShowSelect
      not-filter
      :exclude-settings-actions="['table', 'group']"
      nested
      @click:row="clickRow"
      :item-class="
        (item) => {
          return value && value.Id === item.Id ? 'font-weight-bold' : '';
        }
      "
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-center green--text" colspan="3">
              Принято к оплате
            </th>
            <th class="text-center red--text" colspan="3">
              Финансовые санкции
            </th>
            <th></th>
          </tr>
        </thead>
      </template>
      <template slot="item.FinancialValuePercent" slot-scope="{ item }">
        {{ item.FinancialValuePercent }}%
      </template>
      <template slot="item.FineValue" slot-scope="{ item }">
        {{ item.FineValue | numberSplit }}
      </template>
      <template slot="item.SanctionValue" slot-scope="{ item }">
        {{ item.SanctionValue | numberSplit }}
      </template>

      <template slot="item.FinancialPlanValue" slot-scope="{ item }">
        {{ item.FinancialPlanValue | numberSplit }}
      </template>

      <template slot="item.NonPaymentValue" slot-scope="{ item }">
        {{ item.NonPaymentValue | numberSplit }}
      </template>

      <template slot="item.FinancialValue" slot-scope="{ item }">
        {{ item.FinancialValue | numberSplit
        }}<m-icon
          v-if="item.FinancialValue > item.FinancialPlanValue"
          color="success"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon
          v-if="item.FinancialValue < item.FinancialPlanValue"
          color="error"
          :icon="'mdi-arrow-down-thin'"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import BaseTable from "@/components/base/BaseTable";
export default {
  components: {
    BaseTable,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    clickRow(item) {
      this.$emit("input", item);
    },
  },
};
</script>
<style lang="scss"></style>
