import { debounce } from "lodash";
import tourManaget from "./tourManager.vue";
import tourApi from "./api/tourApi";

function Install(Vue, options = {}) {
  const property = options.property || "$tour";
  delete options.property;
  const router = options.router;
  delete options.router;

  const store = options.store;
  delete options.store;

  const vuetify = options.vuetify;
  delete options.vuetify;

  if (!store) {
    console.warn(
      "Module demo-hind needs store instance. Use Vue.use(DemoHint, { store })"
    );
  }

  const Ctor = Vue.extend(Object.assign({ router, store }));
  const Ctor2 = Vue.extend(
    Object.assign({ router, store, vuetify }, tourManaget)
  );

  function createTourManagerCmp(options, self) {
    if (self.tourManager) {
      self.tourManager.show = true;
      return;
    }

    options.tourCmp = self;

    const container =
      document.querySelector("[data-app=true]") || document.body;
    return new Promise((resolve) => {
      const cmp = new Ctor2(
        Object.assign(
          {},
          {
            propsData: Object.assign(
              {},
              Vue.prototype[property].options,
              options
            ),
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.value);
            },
          }
        )
      );
      self.tourManager = cmp;
      container.appendChild(cmp.$mount().$el);
    });
  }
  function createTourCmp(options) {
    const container =
      document.querySelector("[data-app=true]") || document.body;
    return new Promise((resolve) => {
      const cmp = new Ctor(
        Object.assign(
          {},
          {
            render: function () {
              return "";
            },
            data() {
              return {
                tour: null,
                tourManager: null,
                tourRedirect: null,
                isStartAuto: false,
              };
            },
            propsData: Object.assign(
              {},
              Vue.prototype[property].options,
              options
            ),
            mounted() {
              this.$watch(
                () => {
                  return this.$store.state.route;
                },
                (val, old) => {
                  if (
                    val?.meta?.normalizeFullPath &&
                    val?.meta?.normalizeFullPath ===
                      old?.meta?.normalizeFullPath
                  )
                    return;
                  this.changePath(val);
                },
                {
                  deep: true,
                  immediate: true,
                }
              );
            },
            methods: {
              getFileNameOnly(filePath) {
                return filePath.split("/").pop().split(".").shift();
              },

              // ALL THE JSON!
              async loadJson(page) {
                // const requireContext = require.context(
                //   "./demo/",
                //   false,
                //   /\.json$/
                // );

                // const json = {};
                // requireContext.keys().forEach((key) => {
                //   const obj = requireContext(key);

                //   if (page) {
                //     if (!obj.pages.find((e) => e.matched.pageName === page))
                //       return;
                //   }

                //   const simpleKey = this.getFileNameOnly(key);
                //   json[simpleKey] = obj;
                // });

                const pages = (
                  await tourApi.get(
                    this.$route.name,
                    this.$route.query?.type,
                    this.$route.params?.type,
                    this.$route.params?.objectId
                  )
                ).data;

                let autoStart = null;
                if (pages.length && !this.isStartAuto) {
                  autoStart = pages[0].TrainingTours.find((e) => e.AutoRun);
                  if (autoStart)
                    setTimeout(() => {
                      this.forgeStartDemo(autoStart);
                      this.isStartAuto = true;
                    }, 2000);
                }

                const res = this.normalize(pages, this.$route.name);
                return res;
              },
              normalize(data, routeName) {
                if (!data.length) return data;
                const res = [];
                const item = data[0];
                item.Percent = (item?.NumPerformed / item?.TotalNum) * 100 || 0;
                item.TrainingTours.forEach((item) => {
                  item.Percent =
                    (item?.LastStepNum / item?.TotalNum) * 100 || 0;
                  for (let i = 0; i < item.Steps.length; i++) {
                    const step = item.Steps[i];

                    const tmp = {};
                    for (const variable in step) {
                      if (step[variable] === null) continue;
                      tmp[variable.toLocaleLowerCase()] = step[variable];
                    }
                    item.Steps[i] = tmp;
                  }
                  item.steps = item.Steps;
                  delete item.Steps;
                });
                res.push({
                  ...item,
                });

                return res;
              },
              changePath: debounce(async function (route) {
                if (this.tour?._inited) {
                  this.tour.end();
                }

                if (this.tourManager) this.tourManager.selectPage = null;

                createTourManagerCmp(options, this);
                this.tourManager.init();

                if (
                  this.tourRedirect &&
                  this.tourRedirect.redirect.pageName === route.name
                ) {
                  setTimeout(async () => {
                    await this.forgeStartDemo(this.tourRedirect);
                    this.tourRedirect = null;
                  }, 3000);
                }
              }, 500),
              endDemo(tour) {
                localStorage.setItem(
                  this.getDemoName(tour.demo),
                  tour._current
                );
                this.tourManager.menu = true;
              },
              getDemoName(demo) {
                if (!demo.matched?.tab) {
                  return "tour." + demo.PageName;
                } else {
                  return "tour." + demo.PageName + "_" + demo?.matched?.tab;
                }
              },
              async forgeStartDemo(demo) {
                await this.loadSripts();

                this.startDemo(demo);
              },
              startDemo(demo) {
                // Instance the tour

                setTimeout(() => {
                  if (this.tour?._inited) return;
                  const tour = new window.Tour(this.normalizeTour(demo));

                  localStorage.removeItem("tour_current_step");
                  localStorage.removeItem("tour_end");

                  // Initialize the tour
                  tour.init();

                  // Start the tour
                  tour.start();

                  this.tour = tour;
                  this.tour.demo = demo;
                }, 500);
              },

              normalizeTour(tour) {
                const defaultTour = {
                  backdrop: true,
                  container: "#app",
                  orphan: true,
                  template: `<div class='popover tour' style="min-width:520px;font-family: 'Noto Sans', sans-serif !important; text-align:center;     ">
                <div class='arrow'></div>
                  <div class='d-flex justify-center popover-action-icon-wrap'>
                    <div class='popover-action-icon-hide'></div>
                    <h3 class='popover-title'>
                  </div>
                </h3>
                <div class='popover-content' style="white-space: pre-line;"></div>
                <div class='popover-navigation' style='display: flex; justify-content: space-between; border-top: solid 1px #ebebeb;'>
                  <div class='popover-navigation__step' style='align-self: center;'>Шаг <span>1/2</span></div>
                  <button type="button" data-role='end' class="v-btn theme--light v-size--default"><span class="v-btn__content">Закончить</span></button>

                  <div>
                    <button type="button" style="margin: 0 2px;" data-role='prev'  class="v-btn theme--light v-size--default">
                      <span class="v-btn__content">« Назад</span> 
                    </button>
             
                    <button type="button" data-role='next' style="cursor: pointer;  background-color:var(--v-blue-base) !important;" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"><span class="v-btn__content">Вперед »</span></button>
                  </div>
                </div>
                <div style="text-align:center;">
           
                </div>
              </div>`,
                  onHide: async (e) => {
                    try {
                      await tourApi.stepComplete(e.demo.steps[e._current].id);

                      // Полное обновленеи туров
                      if (this.tourManager) this.tourManager.init();
                    } catch (error) {
                      console.log(error);
                    }
                  },
                  onEnd: (e) => {
                    document
                      .querySelectorAll(".tour")
                      .forEach((e) => e.remove());
                    this.endDemo(e);
                  },
                  onShown: (e) => {
                    // Синий цвет кноки завершить
                    const b = document.querySelector("button[data-role='end']");
                    const maxSteps = e._options.steps?.length - 1;
                    if (e._current === maxSteps) {
                      b.classList.add("primary");
                      b.classList.add("v-btn--has-bg");
                    }

                    // Добавить иконку клика, если это reflex. И убирает кнопку некст
                    const step = e._options.steps?.[e._current];
                    const popoverContentElem =
                      document.querySelector(".popover-content");

                    const domIcon = document.querySelector(
                      ".popover-action-icon-hide"
                    );
                    if (step?.reflex) {
                      if (domIcon) {
                        const domIconWrap = document.querySelector(
                          ".popover-action-icon-wrap"
                        );
                        if (domIconWrap) {
                          domIconWrap.classList.add("py-3");
                        }
                        domIcon.classList.add("popover-action-icon");
                      }
                      const bnext = document.querySelector(
                        "button[data-role='next']"
                      );
                      bnext.classList.add("d-none");
                    }
                    // Скрыть контет если null
                    if (step.content === undefined)
                      popoverContentElem.classList.add("d-none");

                    // Установка номера этапа
                    document.querySelector(
                      ".popover-navigation__step span"
                    ).innerText =
                      e._current + 1 + " из " + e._options.steps.length;

                    // Блокирует нажатие
                    if (step.lockout)
                      window.$(step.element).attr("disabled", true);

                    // Запрет Исчезания
                    if (step.forceshow)
                      window.$(step.element).addClass("d-block");
                  },
                  onHidden: function (tour) {
                    // Разблокирует нажатие
                    const step = tour.getStep(tour._current);
                    if (step.lockout)
                      window.$(step.element).removeAttr("disabled");

                    if (step.forceshow)
                      // Запрет Исчезания
                      window.$(step.element).removeClass("d-block");
                  },
                };
                return {
                  ...defaultTour,
                  ...tour,
                };
              },

              getDemoByPath(route) {
                return new Promise((resolve, reject) => {
                  setTimeout(async () => {
                    try {
                      await import(`./demo/${route.name}.json`);

                      // a.default.pages[0].tour = {
                      //   ...defaultTour,
                      //   ...a.default.pages[0].tour,
                      // };
                      resolve(true);
                    } catch (error) {
                      resolve(false);
                    }
                  }, 100);
                });
              },
              allowShowDemo(demo) {
                return new Promise((resolve, reject) => {
                  setTimeout(() => {
                    resolve(!localStorage.getItem(this.getDemoName(demo)));
                  }, 100);
                });
              },
              async loadSripts() {
                /* Jquery */
                if (!document.querySelector("#scriptJq")) {
                  const jqScript = document.createElement("script");
                  jqScript.src = "https://code.jquery.com/jquery-3.7.0.min.js";
                  jqScript.id = "scriptJq";
                  document.body.appendChild(jqScript);

                  await new Promise((resolve, reject) => {
                    jqScript.onload = resolve;
                  });
                }

                /* Bootstrap tour */
                if (!document.querySelector("#scriptTour")) {
                  const tourScript = document.createElement("script");
                  tourScript.src =
                    "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-tour/0.12.0/js/bootstrap-tour-standalone.js";
                  tourScript.id = "scriptTour";
                  document.body.appendChild(tourScript);

                  await new Promise((resolve, reject) => {
                    tourScript.onload = resolve;
                  });
                }

                /* Bootstrap tour css */
                if (!document.head.querySelector("#cssTour")) {
                  const tourCss = document.createElement("link");
                  tourCss.href =
                    "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-tour/0.12.0/css/bootstrap-tour-standalone.min.css";
                  tourCss.id = "cssTour";
                  tourCss.rel = "stylesheet";
                  document.head.appendChild(tourCss);
                  await new Promise((resolve, reject) => {
                    tourCss.onload = resolve;
                  });
                }
              },
            },
            watch: {
              "$router.currentRoute.fullPath": (val) => {
                console.log(val);
              },
            },
            destroyed: () => {
              container.removeChild(cmp.$el);
              resolve(cmp.value);
            },
          }
        )
      );
      container.appendChild(cmp.$mount().$el);
    });
  }

  function show(message, options = {}) {
    options.message = message;
    return createTourCmp(options);
  }

  Vue.prototype[property] = {};
  Vue.prototype[property].Show = show;
  Vue.prototype[property].options = options || {};
  show();
}

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(Install);
}

export default Install;
