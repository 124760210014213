var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',_vm._g(_vm._b({ref:"select",attrs:{"items":_vm._items,"append-icon":"$ToolArrowDown","item-value":_vm.itemValue,"clearable":_vm.clearable,"search-input":_vm.search,"item-text":_vm._itemText},on:{"change":_vm.handlerChange,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticStyle:{"z-index":"100","position":"sticky","background":"white","top":"0"}},[_c('v-list-item',{attrs:{"ripple":""}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-text-field',{attrs:{"label":"Поиск","maxlength":"200","hide-details":"","prepend-icon":"mdi-database-search"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-2"})],1)]},proxy:true},(
        _vm.$attrs.hasOwnProperty('multiple') || _vm.$attrs.hasOwnProperty('chips')
      )?{key:"selection",fn:function(ref){
      var item = ref.item;
      var parent = ref.parent;
return [_vm._t("selection",function(){return [_c('v-chip',{staticClass:"primary--text",attrs:{"close":parent.multiple &&
            !(_vm.$attrs.hasOwnProperty('readonly') && _vm.$attrs['readonly']),"color":typeof _vm.chipColor === 'function' ? _vm.chipColor(item) : _vm.chipColor},on:{"click:close":function($event){return parent.selectItem(item)}}},[_vm._t("selection.text",function(){return [_c('span',{staticClass:"dynamic-text"},[_vm._v(_vm._s(parent.getText(item)))])]},null,{ item: item })],2)]},null,{ item: item, parent: parent })]}}:null,(_vm.getDict && _vm.getDict.componentEdit)?{key:"append-item",fn:function(){return [_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',{staticStyle:{"padding-bottom":"0","padding-top":"8px"}},[_c('v-btn',{attrs:{"text":"","color":"blue","large":""},on:{"click":function($event){_vm.getDict.componentEditPage
                ? _vm.$router.push({ name: _vm.getDict.componentEdit })
                : _vm.$store.dispatch('dict/START_DICT', _vm.getDict)}}},[_c('m-icon',{staticClass:"mr-3",staticStyle:{"font-size":"30px"},attrs:{"dark":"","left":"","icon":"mdi-plus-circle"}}),_vm._v(" "+_vm._s(_vm.textCustomAdd)+" ")],1)],1)],1)]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }