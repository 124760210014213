var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-panel',{attrs:{"title":"Главная","logo":require('@/assets/logo.svg')}},[_c('template',{slot:"action"},[_c('v-btn',{staticClass:"mr-4",attrs:{"title":"Обновить","icon":""},on:{"click":_vm.init}},[_c('m-icon',{attrs:{"icon":"tool-refresh","s16":""}})],1),_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){_vm.showSettings = !_vm.showSettings}}},[_vm._v("Настроить")])],1),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"margin-left":"-12px","margin-right":"-12px"}},[(_vm.isShow(8))?_c('card',{attrs:{"title":"Начало работы","icon":_vm.UtilGetIcon('view-Instructions'),"params":_vm.data.instruction}}):_vm._e(),(_vm.isShow(0))?_c('card-chart',{attrs:{"title":"Состояние качества и безопасности медицинской деятельности","icon":_vm.UtilGetIcon('view-AnalysisIndicators'),"params":_vm.data.chart}}):_vm._e(),(_vm.isShow(1))?_c('card',{attrs:{"title":"Задачи","icon":_vm.UtilGetIcon('view-Tasks'),"params":_vm.data.task},scopedSlots:_vm._u([{key:"item.PlannedEnd",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.taskStatus(item))}})]}}],null,false,2162077812)}):_vm._e(),(_vm.isShow(2))?_c('card',{attrs:{"title":"Проекты и планы мероприятий","icon":_vm.UtilGetIcon('view-Projects', 'all'),"params":_vm.data.project},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.DoneTaskPercent === 0 ? "Новый" : item.Done ? "Завершен" : "Действующий")+" ")]}},{key:"item.DoneTaskPercent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(parseFloat(item.DoneTaskPercent).toFixed(2)) + "%")+" ("+_vm._s(item.Completed)+" из "+_vm._s(item.Total)+") ")]}}],null,false,3815649773)},[_c('template',{slot:"cardAction"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'Projects',
                    params: { type: 'project' },
                  })}}},[_vm._v("Все проекты")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'Plans',
                    params: { type: 'plan' },
                  })}}},[_vm._v("Все планы")])],1)],1)],1)],2):_vm._e(),(_vm.isShow(3))?_c('card',{attrs:{"title":"Документы","icon":_vm.UtilGetIcon('view-LocalDocuments'),"params":_vm.data.document}}):_vm._e(),(_vm.isShow(4))?_c('card',{attrs:{"title":"Проверки","icon":_vm.UtilGetIcon('view-AuditOperations'),"params":_vm.data.audit},scopedSlots:_vm._u([{key:"item.DateIn",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Period.DateIn.substring(0, 10))+" - "+_vm._s(item.Period.DateOut.substring(0, 10))+" ")]}},{key:"item.Subject",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.Subject ? item.Subject : "Без предмета проверки")+" ")]}},{key:"item.Status",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:_vm.classStatus(item.Status)},[_vm._v(_vm._s(_vm._f("getEnums")(item.Status,"AuditOperationStatus"))+" ")])]}}],null,false,3211238667)}):_vm._e(),(_vm.isShow(6))?_c('card',{attrs:{"title":"Нежелательные события","icon":_vm.UtilGetIcon('view-UnwishedEvents'),"params":_vm.data.unwishedEvent},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(item.Date.substring(0, 10)))]}}],null,false,179000396)}):_vm._e(),(_vm.isShow(5))?_c('card',{attrs:{"title":"Статистические данные","icon":_vm.UtilGetIcon('view-StatisticIndicators'),"params":_vm.data.stat,"id":"statistic-indicator-values"},scopedSlots:_vm._u([{key:"item.LastValue",fn:function(ref){
                  var item = ref.item;
return [(item.LastValue || item.LastValue === 0)?_c('v-chip',{staticClass:"statistic-indicator-values__chip-group",class:_vm.StatisticIndicatorHelper.getColorByRefs(
                [item.RefValue1, item.RefValue2, item.RefValue3],
                item.LastValue,
                item.RatingType
              )},[_vm._v(_vm._s(item.LastValue))]):_vm._e()]}},{key:"item.DiffValue",fn:function(ref){
              var item = ref.item;
return [(item.DiffValue)?_c('v-chip',{class:_vm.StatisticIndicatorHelper.getColorDiff(
                item.DiffValue,
                item.RatingType
              )},[_vm._v(_vm._s((item.DiffValue > 0 ? "+" : "") + item.DiffValue)+" ")]):_vm._e(),(item.DiffValue)?_c('span',{staticClass:"statistic-indicator-values__period"},[_vm._v(_vm._s("за " + _vm.periods[item.Period]))]):_vm._e()]}},{key:"item.DateIn",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(item.DateIn.substring(0, 10)))]}}],null,false,2283560435)}):_vm._e(),(_vm.isShow(7))?_c('card',{attrs:{"title":"Мои курсы","icon":_vm.UtilGetIcon('knowledgebase-my'),"params":_vm.data.course},scopedSlots:_vm._u([{key:"item.Status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"knowledge-base__status-point__wrapper"},[_c('span',{staticClass:"knowledge-base__status-point",class:'knowledge-base__status-point--' + item.Status}),_vm._v(" Пройдено "),_c('b',[_vm._v(_vm._s(item.Completed))]),_vm._v(" из "),_c('b',[_vm._v(_vm._s(item.Total))])])]}}],null,false,3217024690)}):_vm._e(),_c('card',{attrs:{"title":"История обновлений системы","icon":_vm.UtilGetIcon('SystemUpdateHistory'),"params":_vm.data.news,"customHandleClick":_vm.data.news.handleClick},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
              var item = ref.item;
return [(!item.Read)?_c('b',[_vm._v(_vm._s(item.Title))]):_c('span',[_vm._v(_vm._s(item.Title)+" ")])]}},{key:"item.Period.DateIn",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(item.DateIn.substring(0, 10)))]}}])}),_c('card',{attrs:{"title":"Новости","params":_vm.data.news2,"icon":_vm.UtilGetIcon('SystemUpdateHistory'),"customHandleClick":_vm.data.news2.handleClick},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
              var item = ref.item;
return [(!item.Read)?_c('b',[_vm._v(_vm._s(item.Title))]):_c('span',[_vm._v(_vm._s(item.Title)+" ")])]}},{key:"item.DateIn",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(item.Period.DateIn.substring(0, 10)))]}}])})],1)],2),_c('modal-settings',{attrs:{"settings":_vm.settings},on:{"save":_vm.saveSettings},model:{value:(_vm.showSettings),callback:function ($$v) {_vm.showSettings=$$v},expression:"showSettings"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }