<template>
  <base-panel title="Начало работы" :icon="UtilGetIcon('view-Instructions')">
    <div slot="action">
      <v-checkbox v-model="openHelpView" @click="handlerCheckbox(openHelpView)">
        <template slot="label">
          Больше не показывать
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <m-icon
                class="ml-2"
                color="blue-grey lighten-2"
                icon="mdi-information-outline"
                v-bind="attrs"
                v-on="on"
              ></m-icon>
            </template>
            Показывать инструкцию при запуске программы
          </v-tooltip>
        </template>
      </v-checkbox>
    </div>
    <v-layout>
      <v-list class="local-document__select-type rounded">
        <v-subheader>Список инструкций</v-subheader>
        <v-divider></v-divider>

        <div v-for="item in dataSource" :key="item.Id">
          <v-list-item
            v-if="item.IsGroup"
            @click="toggleGroup(item.Id)"
            :class="{
              'selected-item':
                selectedGroup &&
                item.Id === selectedGroup.Id &&
                !expandedGroups.includes(item.Id),
            }"
          >
            <v-list-item-content>
              <v-list-item-title class="multi-line-title">
                {{ item.Name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>
                {{
                  expandedGroups.includes(item.Id)
                    ? "mdi-chevron-up"
                    : "mdi-chevron-down"
                }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list v-show="expandedGroups.includes(item.Id)">
            <v-list-item
              class="child-list"
              v-for="instruction in item.Instructions"
              :key="instruction.Id"
              @click="selectItem(instruction)"
              :class="{ 'selected-item': selectedItemNum === instruction.Id }"
            >
              <v-list-item-content>
                <v-list-item-title class="multi-line-title">
                  {{ instruction.Name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  instruction.Description
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list-item
            v-if="!item.IsGroup"
            @click="selectItem(item)"
            :class="{ 'selected-item': selectedItemNum === item.Id }"
          >
            <v-list-item-content>
              <v-list-item-title class="multi-line-title">
                {{ item.Name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                item.Description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <div
        v-if="selectedItem && selectedItem.WebId"
        class="content-container rounded"
      >
        <word-press-viewer
          :postId="selectedItem.WebId"
          type="instruction"
          @openInstruction="openInstruction"
          ref="wordPress"
        />
      </div>
    </v-layout>
  </base-panel>
</template>
<script>
import Api from "../services/Api";
import BasePanel from "../layouts/BasePanel.vue";
import WordPressViewer from "@/components/WordPress/WordPressViewer.vue";
// ВРЕМЕННО
// import DataHelper from "../utils/DataHelper";

export default {
  name: "view-Instructions",
  components: { BasePanel, WordPressViewer },

  data() {
    return {
      openHelpView: false,
      selectedItem: null,
      selectedItemNum: null,
      selectedGroup: null,
      expandedGroups: [], // Массив для хранения раскрытых групп
      dataSource: null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const data = await Api().get("help/GetHelpStart");

      const helpGroupsInstructions = data?.data;

      this.dataSource = helpGroupsInstructions;

      // Флаг для чекбокса
      const tmp = await Api().get("help/GetHelpStartNoMore");
      this.openHelpView = tmp.data;

      // TODO: Костыль, решает проблему перехода по элементу (item) с главной страницы (виджета)
      if (this.$route?.params?.objectId) {
        const dataHome = await Api().get("help/GetHelpStartHome");
        const item = dataHome.data.find(
          (e) => e.Id === this.$route.params.objectId
        );
        this.openInstruction(item.WebId);
      } else {
        const firstItem = this.dataSource[0];
        this.selectedItem = firstItem.IsGroup
          ? firstItem.Instructions[0]
          : firstItem;
        this.selectedItemNum = this.selectedItem.Id;
        this.selectedGroup = firstItem.IsGroup ? firstItem.Id : null;
        if (this.selectedGroup) this.expandedGroups.push(this.selectedGroup);
      }
    },
    selectItem(item) {
      if (this.$refs.wordPress) {
        this.$refs.wordPress.scrollWordPress = 0;
      }
      this.selectedItem = item;
      this.selectedItemNum = this.selectedItem.Id;
      // Нахождение группы выбранной инструкции
      this.selectedGroup = this.dataSource.find((group) =>
        group.Instructions.some(
          (instruction) => instruction.Id === this.selectedItem.Id
        )
      );
    },
    toggleGroup(groupId) {
      const index = this.expandedGroups.indexOf(groupId);
      if (index > -1) {
        this.expandedGroups.splice(index, 1); // Закрыть группу
      } else {
        this.expandedGroups.push(groupId); // Открыть группу
      }
    },
    handlerCheckbox(value) {
      return Api().put("help/UpdateHelpStartNoMore", { NoMore: value });
    },
    openInstruction(id) {
      if (this.$refs.wordPress) {
        this.$refs.wordPress.scrollWordPress = 0;
      }

      const tmp = this.dataSource.find((e) =>
        e.IsGroup
          ? e.Instructions.find((i) => i.WebId === parseInt(id, 10))
          : e.WebId === parseInt(id, 10)
      );
      this.selectedItem = tmp.IsGroup
        ? tmp.Instructions.find((i) => i.WebId === parseInt(id, 10))
        : tmp;
      this.selectedItemNum = this.selectedItem.Id;
      this.selectedGroup = tmp.IsGroup ? tmp.Id : null;
      if (this.selectedGroup) this.expandedGroups.push(this.selectedGroup);
    },
  },
};
</script>
<style lang="scss">
.local-document__select-type {
  width: 350px;
  height: 100%;
  max-height: calc(100vh - 180px) !important;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 2px 8px 30px rgb(35 52 107 / 5%);
  overflow: auto;

  .multi-line-title {
    white-space: normal; /* Позволяет тексту переноситься на новую строку */
    word-wrap: break-word; /* Разбивает длинные слова для переноса */
  }

  .v-list-item__content {
    color: #919ab5;
  }
}

.content-container {
  max-height: calc(100vh - 180px) !important;
  background-color: #ffffff;
  padding: 15px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.child-list {
  padding-left: 32px; /* Отступ слева для дочерних инструкций */
}

.selected-item {
  background-color: #e0e0e0; /* Цвет выделения для выбранного элемента */
}
</style>
