<template>
  <v-card
    height="100%"
    :ripple="false"
    color="white"
    style="border-radius: 10px"
    :elevation="elevation"
    v-on="isOpenDetail ? { click: handle } : {}"
  >
    <v-card-actions>
      <m-icon
        icon="mdi-circle"
        small
        class="mr-2"
        :class="
          StatisticIndicatorHelper.getColorDiff(
            indicator.DiffValue,
            indicator.RatingType
          ) + '--text'
        "
      ></m-icon>
      <h4 class="statistic-indicators-chart__name">
        {{ indicator.Name }}
      </h4>
      <v-spacer></v-spacer>
      <slot name="action" v-bind:item="indicator">
        <m-select
          v-if="showYear !== null"
          :value="year"
          :items="years"
          :clearable="false"
          dense
          hide-details
          style="max-width: 70px"
          @click.stop
          @change="setYear"
        ></m-select>
      </slot>
    </v-card-actions>
    <v-card-text>
      <v-container>
        <v-row
          v-if="loading === false && indicator.DynamicValues"
          class="statistic-indicators-row-chart"
        >
          <v-col cols="2" v-if="!hideInfo">
            <div class="d-flex flex-column align-end">
              <p
                style="
                  width: 100%;
                  font-size: 15px;
                  color: black;
                  text-align: end;
                "
              >
                <span v-if="indicator.LastValueDate">
                  {{ indicator.LastValueDate | DateShort }}
                </span>
                <span v-else> - </span>
              </p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="statistic-indicators-chart-title-wrapper"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      class="statistic-indicators-chart-title"
                      :class="
                        StatisticIndicatorHelper.getColorByRefs(
                          [
                            indicator.RefValue1,
                            indicator.RefValue2,
                            indicator.RefValue3,
                          ],
                          indicator.LastValue,
                          indicator.RatingType
                        ) + '--text'
                      "
                    >
                      {{ indicator.LastValue }}
                    </span>
                    <span
                      class="statistic-indicators-chart-title-label"
                      :title="indicator.Units"
                      >{{ indicator.Units }}</span
                    >
                  </div>
                </template>
                <span>
                  {{ Math.round(indicator.LastValue) }}
                  {{ indicator.Units }}
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="statistic-indicators-chart__wrap-result"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <div class="d-flex align-content-center justify-end">
                      <m-icon
                        v-if="
                          indicator.DiffValue > 0 || indicator.DiffValue < 0
                        "
                        :icon="
                          Math.sign(indicator.DiffValue) === -1
                            ? 'mdi-arrow-bottom-left'
                            : Math.sign(indicator.DiffValue) === 1
                            ? 'mdi-arrow-top-right'
                            : null
                        "
                        :class="
                          StatisticIndicatorHelper.getColorDiff(
                            indicator.DiffValue,
                            indicator.RatingType
                          ) + '--text'
                        "
                      ></m-icon>
                      <span
                        class="statistic-indicators-chart-title"
                        :class="
                          indicator.DiffValue !== null
                            ? StatisticIndicatorHelper.getColorDiff(
                                indicator.DiffValue,
                                indicator.RatingType
                              ) + '--text'
                            : ''
                        "
                      >
                        {{
                          indicator.DiffValue === null
                            ? "-"
                            : Math.abs(indicator.DiffValue)
                        }}
                      </span>
                    </div>
                    <p>
                      {{ getPeriod(indicator.Period) }}
                    </p>
                  </div>
                </template>
                <span>
                  {{
                    indicator.DiffValue === null
                      ? "-"
                      : Math.abs(Math.round(indicator.DiffValue))
                  }}
                  {{ getPeriod(indicator.Period) }}</span
                >
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="1" v-if="!hideInfo">
            <div
              style="height: 100%; width: 1px; background-color: #6e758d"
            ></div>
          </v-col>
          <v-col :cols="!hideInfo ? 9 : 12">
            <div
              class="statistic-indicators-chart__nodata text--disabled"
              v-if="!indicator.Groups.length"
              style="height: 185px"
            >
              Нет данных
            </div>
            <bar-chart
              v-else
              :chart-data="indicator.DynamicValues"
              :options="indicator.BarOptions"
              style="height: 185px"
            >
            </bar-chart>
          </v-col>
        </v-row>

        <v-skeleton-loader
          v-if="loading"
          type="list-item, image"
        ></v-skeleton-loader>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import StatisticIndicatorHelper from "./StatisticIndicatorHelper";
import DataHelper from "../../utils/DataHelper.js";
import BarChart from "../../views/analysis/BarChart.js";
import moment from "moment";
import { groupBy, debounce } from "lodash";
import MSelect from "../../components/base/inputs/mSelect.vue";

export default {
  components: {
    BarChart,
    MSelect,
  },
  props: {
    statisticIndicator: Object,
    isRefreshing: Boolean,
    showYear: {
      type: Number,
      default: null,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    hideInfo: {
      default: false,
      type: Boolean,
    },
    elevation: {
      type: Number,
      default: 1,
    },
    isOpenDetail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      years: [],
      year: this.showYear,
      loading: true,
      indicator: {},
      barData: {},
      barOptions: {
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            align: "end",
            anchor: "end",
            color: "rgb(33, 33, 33)",
            formatter: (value) => {
              return value.y || value.y === 0 ? Math.round(value.y) : "";
            },
          },
        },
        tooltips: {
          callbacks: {
            title: () => {
              return ``;
            },
            label: function (tooltipItem, data) {
              const value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return value?.x.toLocaleDateString();
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              barPercentage: 0.9,
              categoryPercentage: 1.0,
              offset: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                min: 0,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 25,
          },
        },
        onClick: (event, array) => {
          if (array?.length > 0 && array[0]?._index >= 0) {
            const i = array[0]._index;
            const item = this.indicator.DynamicValues.datasets[0].data[i];
            const t = this.statisticIndicator.Groups.find(
              (e) => e.Id === item.Id
            );
            this.$emit("itemClick", t);
          }
        },
      },
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
  },
  watch: {
    isRefreshing: {
      handler(val) {
        if (val) this.init2();
      },
    },
    showYear(val) {
      this.year = val;
      this.init2();
    },
  },

  async mounted() {
    this.init2 = debounce((update) => this.init(update), 500);
    for (let year = 2020; year <= new Date().getFullYear(); year++)
      this.years.push(year);
    await this.init2();
  },
  methods: {
    init2() {},
    addBlankValues(values, period) {
      if (!values.length) return;

      const start = values[0];
      const end = values[values.length - 1];

      const startM = moment(start.PeriodIn, "DD.MM.YYYY");
      const endM = moment(end.PeriodIn, "DD.MM.YYYY");

      const count = values.length;
      switch (period) {
        // Неделя
        case 1: {
          for (let i = 1; i <= 12 - count; i++) {
            values.unshift({
              PeriodIn: startM.subtract("weeks", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }
          break;
        }
        // Месяц
        case 2: {
          const s = startM.month();
          const e = endM.month();
          for (let i = s - 1; i >= 0; i--) {
            values.unshift({
              PeriodIn: startM.set("month", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }
          for (let i = e + 1; i <= 11; i++) {
            values.unshift({
              PeriodIn: startM.set("month", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }
          break;
        }
        // Квартал
        case 3: {
          const s = startM.quarter();
          const e = endM.quarter();

          for (let i = s - 1; i >= 1; i--) {
            values.unshift({
              PeriodIn: startM.set("quarter", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }
          for (let i = e + 1; i <= 4; i++) {
            values.unshift({
              PeriodIn: startM.set("quarter", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }
          break;
        }
        // Полугодие
        case 4: {
          break;
        }
        // Год
        case 5: {
          break;
        }
        // День
        case 6: {
          for (let i = 1; i <= 30 - count; i++) {
            values.unshift({
              PeriodIn: startM.subtract("days", i).format("DD.MM.YYYY"),
              Value: null,
            });
          }

          break;
        }

        default:
          break;
      }
    },
    setYear(e) {
      this.year = e;
      this.init2(true);
    },
    async init(update = false) {
      this.loading = true;
      this.indicator = this._.cloneDeep(this.statisticIndicator);
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      try {
        let values = [...this.statisticIndicator.Groups];
        if (update)
          values = (
            await StatisticIndicatorService.getValues(
              this.indicator.Id,
              this.getLastCount(this.indicator.Period),
              true,
              this.year
            )
          ).data;

        this.addBlankValues(values, this.indicator.Period);

        const minVal = Math.min.apply(
          null,
          values.map((e) => e.Value)
        );

        if (this.indicator.TargetType === 0) {
          this.indicator.Values = values;

          this.indicator.DynamicValues = {
            labels: values.map((e) => e.PeriodIn),
            datasets: [
              {
                data: values.map((e) => ({
                  x: DataHelper.toDate(e.PeriodIn),
                  y: e.Value,
                  Id: e.Id,
                })),
                backgroundColor: values.map((item) => {
                  return StatisticIndicatorHelper.getColorHexByRefs(
                    [
                      this.indicator.RefValue1,
                      this.indicator.RefValue2,
                      this.indicator.RefValue3,
                    ],
                    item.Value,
                    this.indicator.RatingType
                  );
                }),
                minBarLength: 5,
              },
            ],
          };

          this.indicator.BarOptions = {
            ...this.barOptions,
            scales: {
              xAxes: [
                {
                  ...this.barOptions.scales.xAxes[0],
                  type: "time",
                  distribution: "series",
                  time: {
                    unit: this.getUnit(this.indicator.Period),
                    displayFormats: {
                      month: this.indicator.Period === 4 ? "MMM YYYY" : "MMM",
                      day: "D MMM",
                      week: "DD.MM",
                      quarter: "Q [квартал] - YYYY",
                    },
                    min: new Date(
                      Math.min.apply(
                        null,
                        values.map((e) => DataHelper.toDate(e.PeriodIn))
                      )
                    ),
                    max: new Date(
                      Math.max.apply(
                        null,
                        values.map((e) => DataHelper.toDate(e.PeriodIn))
                      )
                    ),
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                    min: minVal === 0 ? minVal : minVal - 1,
                  },
                },
              ],
            },
          };
        } else {
          const temp = [];
          const groupedValues = this.groupValues(values, this.indicator.Period);

          for (const key in groupedValues) {
            temp.push({
              Date: groupedValues[key][0].PeriodIn,
              Value: groupedValues[key].find((e) => e.Value !== null)
                ? groupedValues[key].reduce(
                    (sum, item) => (sum += item.Value),
                    0
                  )
                : null,
              Id: groupedValues[key][0].Id,
            });
          }

          const minValue = Math.min.apply(
            null,
            temp.map((e) => e.Value)
          );

          this.indicator.Values = temp;

          this.indicator.DynamicValues = {
            labels: temp.map((e) => e.Value),
            datasets: [
              {
                data: temp.map((e) => ({
                  x: DataHelper.toDate(e.Date),
                  y: e.Value,
                  Id: e.Id,
                })),
                backgroundColor: temp.map((item) => {
                  return StatisticIndicatorHelper.getColorHexByRefs(
                    [
                      this.indicator.RefValue1,
                      this.indicator.RefValue2,
                      this.indicator.RefValue3,
                    ],
                    item.Value,
                    this.indicator.RatingType
                  );
                }),
                minBarLength: 5,
              },
            ],
          };

          this.indicator.BarOptions = {
            ...this.barOptions,
            scales: {
              xAxes: [
                {
                  ...this.barOptions.scales.xAxes[0],
                  type: "time",
                  distribution: "series",
                  time: {
                    unit: this.getUnit(this.indicator.Period),
                    displayFormats: {
                      month: this.indicator.Period === 4 ? "MMM YYYY" : "MMM",
                      day: "D MMM",
                      week: "DD.MM",
                      quarter: "Q - YYYY",
                    },
                    min: new Date(
                      Math.min.apply(
                        null,
                        temp.map((e) => DataHelper.toDate(e.Date))
                      )
                    ),
                    max: new Date(
                      Math.max.apply(
                        null,
                        temp.map((e) => DataHelper.toDate(e.Date))
                      )
                    ),
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                    min: minValue === 0 ? minValue : minValue - 1,
                    beginAtZero: true,
                  },
                },
              ],
            },
          };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    handle() {
      this.$router.push({
        name: "StatisticIndicatorEdit",
        params: {
          objectId: this.indicator.Id,
        },
      });
    },

    groupValues(values, period) {
      if (!values || !values.length) return [];
      let valuesByGroups = {};
      let indicatorValues = [];
      switch (period) {
        // Неделя
        case 1: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            Year: DataHelper.toDate(obj.PeriodIn).getFullYear(),
            Week: moment(DataHelper.toDate(obj.PeriodIn)).week(),
            Id: obj.Id,
          }));
          valuesByGroups = groupBy(indicatorValues, (item) => {
            return [item.Year, item.Week];
          });
          break;
        }
        // Месяц
        case 2: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            Year: DataHelper.toDate(obj.PeriodIn).getFullYear(),
            Month: DataHelper.toDate(obj.PeriodIn).getMonth(),
            Id: obj.Id,
          }));
          valuesByGroups = groupBy(indicatorValues, (item) => {
            return [item.Year, item.Month];
          });
          break;
        }
        // Квартал
        case 3: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            Quarter: moment(DataHelper.toDate(obj.PeriodIn)).quarter(),
            Year: DataHelper.toDate(obj.PeriodIn).getFullYear(),
            Id: obj.Id,
          }));

          valuesByGroups = groupBy(indicatorValues, (item) => {
            return [item.Year, item.Quarter];
          });
          break;
        }
        // Полугодие
        case 4: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            HalfYear:
              moment(DataHelper.toDate(obj.PeriodIn)).quarter() < 3 ? 1 : 2,
            Year: DataHelper.toDate(obj.PeriodIn).getFullYear(),
            Id: obj.Id,
          }));
          valuesByGroups = groupBy(indicatorValues, (item) => {
            return [item.Year, item.HalfYear];
          });
          break;
        }
        // Год
        case 5: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            Year: DataHelper.toDate(obj.PeriodIn).getFullYear(),
            Id: obj.Id,
          }));
          valuesByGroups = DataHelper.groupBy(indicatorValues, "Year");
          break;
        }
        // День
        case 6: {
          indicatorValues = values.map((obj) => ({
            ...obj,
            Day: DataHelper.toDate(obj.PeriodIn),
            Id: obj.Id,
          }));
          valuesByGroups = DataHelper.groupBy(indicatorValues, "Day");
          break;
        }

        default:
          break;
      }
      return valuesByGroups;
    },

    getPeriod(period) {
      switch (period) {
        case 1:
          return "За неделю";
        case 2:
          return "За месяц";
        case 3:
          return "За квартал";
        case 4:
          return "За полугодие";
        case 5:
          return "За год";
        case 6:
          return "За день";
        default:
          break;
      }
    },

    getUnit(period) {
      switch (period) {
        case 1:
          return "week";
        case 2:
          return "month";
        case 3:
          return "quarter";
        case 4:
          return "month";
        case 5:
          return "year";
        case 6:
          return "day";
        default:
          break;
      }
    },
    getLastCount(period) {
      switch (period) {
        case 1:
        case 2:
        case 6:
          return 12;
        case 3:
        case 4:
          return 8;
        case 5:
          return 4;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.statistic-indicators-row-chart {
  .col.col-2 {
    padding-left: 0;
    padding-right: 0;
  }
}
.statistic-indicators-chart__nodata {
  text-transform: uppercase;
  font-size: 32px;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1 !important;
  width: 100%;
  flex-direction: column;
  place-content: center;
}

.statistic-indicators-chart__name {
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.statistic-indicators-chart-title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;

  &-label {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-wrapper {
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    text-align: right;
  }
}

.statistic-indicators-chart__wrap-result {
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  text-align: right;
}

.v-card--link {
  cursor: default;
}
.v-card--link:before {
  background: none;
}
</style>
