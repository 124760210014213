<template>
  <base-popup
    title="Добавить задачи"
    :icon="UtilGetIcon('view-Tasks')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <v-form>
      <v-container>
        <v-layout column>
          <v-flex>
            <base-list-view
              title="Задачи"
              :dataSource="tasks"
              :headers="headers"
              :showDelete="false"
              :notShowActions="['add']"
              :search.sync="search"
              :selectedItems="selectedTasks"
              @input="(value) => (this.selectedTasks = value)"
              @onRefresh="loadTasks"
            >
            </base-list-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </base-popup>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
import BasePopup from "@/layouts/BasePopup.vue";
import TaskService from "@/services/TaskService";
import Vue from "vue";

export default {
  name: "view-TaskAdd",
  components: {
    BasePopup,
    BaseListView,
  },
  props: {
    // Аргумент, передающий id родительской задачи
    // Нужен  для исключения род. задачи при добавлении подзадачи
    currentTaskId: {
      type: Number,
      default: null,
    },
    Tasks: {
      type: Array,
      default: () => [],
    },
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
    typeParent: {
      default: null,
      validator: function (value) {
        return [null, "project", "document", "unwishedEvent"].includes(value);
      },
    },
  },
  data: () => {
    return {
      apiService: TaskService,
      tasks: [],
      selectedTasks: [],
      search: "",
      headers: [
        { text: "Номер", value: "Num" },
        {
          text: "Название",
          value: "Name",
        },
        {
          text: "Исполнитель",
          value: "Executor",
          dataType: "object",
          displayText: Vue.filter("PersonShortName"),
        },
        {
          text: "Срок",
          value: "PlannedPeriod",
          dataType: "Period",
          displayText: (e) => Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
        },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.search = null;
        this.loadTasks();
      }
    },
  },
  methods: {
    async save() {
      this.dialog = false;
      const ids = this.selectedTasks.map((e) => e.Id);
      const newTasks = (await TaskService.getTasksWithChildren(ids)).data;

      for (const i in newTasks) {
        this.Tasks.push(newTasks[i]);
      }
      this.selectedTasks = [];
    },
    async loadTasks() {
      try {
        const tmp =
          this.typeParent === "unwishedEvent" ? { unwishedEvent: false } : {};
        if (this.typeParent === "project") {
          tmp.project = false;
        }
        const data = (
          await TaskService.get({
            ...tmp,
            parent: false,
            links: false,
            currentTaskId: this.currentTaskId,
          })
        ).data;
        this.tasks = data
          .filter(
            (task) =>
              !this.Tasks.map((t) => t.Id).includes(task.Id) &&
              !task.Periodicity
          )
          .sort((first, second) => second.Num - first.Num);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
