<template>
  <base-table
    :headers="headerViolations"
    :items="items"
    v-on="$listeners"
    :expanded.sync="expanded"
    show-expand
    hide-default-footer
    id="violations--table"
    class="violations_violation"
    expand-icon="$ToolArrowDown"
    notShowSelect
    single-expand
    showActions
    :showSettings="false"
  >
    <template v-slot:item.Description="{ item, index }">
      <v-textarea
        v-if="violationEditing && item.Id === violationEditing.Id"
        v-model="violationEditing.Description"
        @keyup.enter="edit(index, item)"
        placeholder="Введите несоответствие"
        autofocus
        flat
        background-color="transparent"
        rows="1"
        auto-grow
        maxlength="500"
        :rules="[(v) => !!v || 'Укажите значение.']"
        v-up-first-symbol
        v-focusInput.noFocus.noEnter="edit"
        @blur="edit(index, item)"
      ></v-textarea>

      <span v-else>{{ item.Description }}</span>
    </template>
    <template v-slot:item.IsChecked="{ item }">
      <v-checkbox
        v-model="item.IsChecked"
        @click.stop
        hide-details
        :disabled="readOnly"
      ></v-checkbox>
    </template>

    <template v-slot:item.IsTypical="{ item }">
      <v-checkbox
        v-model="item.IsTypical"
        @click.stop
        on-icon="mdi-alpha-t-box-outline"
        off-icon="mdi-checkbox-blank-off-outline"
        hide-details
        :title="getTooltip(item.IsTypical)"
        :disabled="readOnly || !item.IsChecked"
        class="typical-violation__change"
      ></v-checkbox>
    </template>

    <!-- Задачи для нарушений -->
    <template v-slot:item.expansionAction="{ item }">
      <slot
        name="expansionAction"
        v-bind:readOnly="readOnly || !item.IsChecked"
        v-bind:recm="0"
        v-bind:item="item"
      ></slot>
    </template>

    <template v-slot:item.actions="{ item, index }">
      <div
        v-if="!violationEditing || item.Id === violationEditing.Id"
        style="display: flex"
        :class="{
          'table__column__actions-active':
            violationEditing && violationEditing.Id === item.Id,
        }"
      >
        <v-btn
          v-if="
            violationEditing && violationEditing.Id === item.Id && !item.IsNew
          "
          title="Не сохранять изменения"
          icon
          @click.stop.prevent="violationEditing = null"
        >
          <m-icon icon="mdi-close" small></m-icon>
        </v-btn>
        <v-btn
          @click.stop.prevent="edit(index, item)"
          :color="
            !(violationEditing && item.Id === violationEditing.Id)
              ? 'primary'
              : 'success'
          "
          :disabled="readOnly || (!item.IsChecked && !simple)"
          :title="
            !(violationEditing && item.Id === violationEditing.Id)
              ? 'Редактирование записи'
              : 'Cохранить изменения'
          "
          icon
        >
          <m-icon
            :icon="
              !(violationEditing && item.Id === violationEditing.Id)
                ? 'tool-edit'
                : 'mdi-check'
            "
            small
            s16
          ></m-icon>
        </v-btn>
        <v-btn
          @click.stop.prevent="del(index)"
          icon
          :color="'error'"
          title="Удалить"
          :disabled="readOnly || (!item.IsChecked && !simple)"
        >
          <m-icon icon="tool-delete" s16></m-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td
        :colspan="headers.length"
        class="px-0"
        style="height: 100% !important"
      >
        <recommendation-table
          :violation="item"
          :items="simple ? item.TypicalRecommendations : item.Recommendations"
          :changeTypical="changeTypical"
          nested
          :parentSelect="item.IsChecked"
          :readOnly="readOnly || (!item.IsChecked && !simple)"
          :treeNames="treeNames"
          :auditOperationNum="auditOperationNum"
          :checkListName="checkListName"
          :objectCheck="objectCheck"
          :simple="simple"
        >
          <template
            v-for="slot in Object.keys($scopedSlots).filter(
              (e) => e === 'expansionAction'
            )"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope" />
          </template>
        </recommendation-table>
        <slot v-bind="item" name="violation-expansion-td"> </slot>
      </td>
    </template>
    <!-- Выбор всех нарушений и рекомендаций -->

    <template v-slot:header.IsChecked="">
      <!--<v-checkbox hide-details @change="selectAll"></v-checkbox>-->
    </template>

    <template v-slot:body.append="{ headers }">
      <tr>
        <td :colspan="headers.length">
          <v-btn
            @click="add"
            text
            color="blue"
            large
            :disabled="readOnly"
            class="typical-violation-table__add"
          >
            <m-icon
              icon="mdi-plus-circle"
              dark
              left
              style="font-size: 30px"
              class="mr-3"
            ></m-icon>
            Добавить несоответствие
          </v-btn>
        </td>
      </tr>
    </template>
  </base-table>
</template>

<script>
import RecommendationTable from "./RecommendationTable.vue";
import BaseTable from "../../../base/BaseTable";
import { defaultTask } from "../../../../data/defaultObjects";
import DataHelper from "@/utils/DataHelper";

export default {
  name: "typical-violation-table",
  components: { RecommendationTable, BaseTable },
  inject: ["editingItems"],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    changeTypical: Boolean,
    readOnly: {
      type: Boolean,
    },
    treeNames: Object,
    auditOperationNum: [String, Number],
    checkListName: String,
    objectCheck: String,
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      violationEditing: null,
      violationEditingIndex: -1,
      headers: [
        {
          text: "Применить",
          value: "IsChecked",
          width: "1%",
          sortable: false,
        },
        {
          text: "Несоответствие / рекомендация",
          value: "Description",
          idth: "100%",
        },
        {
          text: "Фото / Задача",
          value: "expansionAction",
          width: "1%",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: "1%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    treeNamesArray() {
      const array = [];
      if (this.simple) return array;
      DataHelper.treeListTraverse(this.treeNames, (item) => {
        array.push(item.Text.trim());
      });
      return array;
    },
    headerViolations() {
      if (this.simple)
        return this.headers.filter(
          (h) => h.value !== "expansionAction" && h.value !== "IsChecked"
        );
      return this.headers;
    },
  },
  watch: {
    violationEditing: function (val) {
      this.editingItems.pop();
      if (val) this.editingItems.push(this.violationEditing);
    },
  },
  created() {
    // Open violation
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].IsChecked) {
        this.expanded.push(this.items[i]);
        break;
      }
    }
    if (this.changeTypical) {
      const typicalHead = {
        text: "Типовое",
        align: "center",
        value: "IsTypical",
        width: "1%",
        sortable: false,
      };
      this.headerViolations.push(typicalHead);
    }
  },
  methods: {
    /* selectAll(select) {
      for (const violation of this.items) {
        violation.IsChecked = select;
        for (const recommendation of violation.Recommendations) {
          recommendation.IsChecked = select;
        }
      }
    }, */
    add() {
      if (
        this.violationEditing &&
        !this.violationEditing.Description &&
        !this.simple
      )
        return;

      if (!this.simple)
        for (const violation of this.items) {
          for (const recommendation of violation.Recommendations) {
            if (!recommendation.CheckTask) {
              recommendation.CheckTask = {
                Id: -Math.round(Math.random() * 1000000),
                Task: {
                  ...defaultTask(),
                  Name: recommendation.Description + this.getTaskTitle(),
                  Content: recommendation.Description,
                  Priority: 2,
                },
              };
            }
          }
        }

      const obj = {
        Tasks: [],
        CheckTask: null,
        Description: "",
        Id: -Math.round(Math.random() * 1000000),
        Images: [],
        IsChecked: true,
        IsTypical: false,
        IsNew: true,
        Recommendations: [],
        TypicalViolationId: 0,
      };
      if (this.simple) obj.TypicalRecommendations = [];

      this.items.push(obj);

      this.expanded = [];
      this.expanded.push(this.items[this.items.length - 1]);

      this.violationEditing = this.items[this.items.length - 1];
      this.violationEditingIndex = this.items.length - 1;
    },
    del(index) {
      this.items.splice(index, 1);
      this.violationEditing = null;
      this.violationEditingIndex = -1;
    },
    edit(index, item) {
      if (!item.Description) return;
      const itemEdit = this._.cloneDeep(item);

      if (!this.violationEditing) {
        this.violationEditing = itemEdit;
        this.violationEditingIndex = index;
      } else {
        const ri = this.items.findIndex(
          (e) => e.Id === this.violationEditing.Id
        );
        this.$set(this.items, ri, { ...this.violationEditing, IsNew: false });

        this.violationEditing = null;
        this.violationEditingIndex = -1;
      }
    },
    getTooltip(isTypical) {
      if (isTypical)
        return "Типовое нарушение. При нажатии нарушение станет локальным. Локальное нарушение относится только к данной проверке.";
      else
        return "Локальное нарушение. При нажатии нарушение станет типовым (часто встречающимся). Типовое нарушение можно выбрать в других проверках";
    },
    getTaskTitle() {
      let objectCheck = "";
      if (this.objectCheck) objectCheck = ` (${this.objectCheck}).\n`;
      return objectCheck;
    },
    getContent(description, violation) {
      let text = "";
      let objectCheck = "";

      if (this.objectCheck) objectCheck = `Объект: ${this.objectCheck}.\n`;

      text =
        "Описание нарушения: " +
        violation +
        "\n" +
        "Предложенная рекомендация по устранению нарушения: " +
        description;

      let requirement = "";

      const treeNamesArrayUntitled = this.treeNamesArray.slice(1);
      for (let i = 0; i < treeNamesArrayUntitled.length; i++) {
        requirement += `${this.calculateTab(i)}${treeNamesArrayUntitled[i]}\n`;
      }

      const content =
        `Задача создана из проверки №${this.auditOperationNum} по чек-листу "${this.checkListName}".\n` +
        objectCheck +
        `Нарушено следующее требование:\n` +
        `${requirement}\n` +
        text;

      return content;
    },
    calculateTab(x) {
      if (!x) return "\t";
      let res = "";
      for (let i = 0; i <= x; i++) res += "\t";
      return res;
    },
  },
};
</script>

<style lang="scss">
.violations__recommendation td:nth-child(6) {
  width: 90px;
}

.violations__recommendation td:nth-child(5) {
  width: 72px;
}

.violations__recommendation td:nth-child(4) {
  width: 100px;
}

.violations__recommendation td:nth-child(3) {
  width: calc(100% - (74px + 72px + 100px));
}

.violations__recommendation td:nth-child(2) {
  width: 100px;
}

.violations__recommendation td:nth-child(1) {
  width: 100px;
}

#violations--table .v-input--hide-details,
#recommendation--table .v-input--hide-details {
  margin-top: 0;
}
</style>
