var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-panel',{attrs:{"title":"Начало работы","icon":_vm.UtilGetIcon('view-Instructions')}},[_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('v-checkbox',{on:{"click":function($event){return _vm.handlerCheckbox(_vm.openHelpView)}},model:{value:(_vm.openHelpView),callback:function ($$v) {_vm.openHelpView=$$v},expression:"openHelpView"}},[_c('template',{slot:"label"},[_vm._v(" Больше не показывать "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('m-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue-grey lighten-2","icon":"mdi-information-outline"}},'m-icon',attrs,false),on))]}}])},[_vm._v(" Показывать инструкцию при запуске программы ")])],1)],2)],1),_c('v-layout',[_c('v-list',{staticClass:"local-document__select-type rounded"},[_c('v-subheader',[_vm._v("Список инструкций")]),_c('v-divider'),_vm._l((_vm.dataSource),function(item){return _c('div',{key:item.Id},[(item.IsGroup)?_c('v-list-item',{class:{
            'selected-item':
              _vm.selectedGroup &&
              item.Id === _vm.selectedGroup.Id &&
              !_vm.expandedGroups.includes(item.Id),
          },on:{"click":function($event){return _vm.toggleGroup(item.Id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"multi-line-title"},[_vm._v(" "+_vm._s(item.Name)+" ")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.expandedGroups.includes(item.Id) ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)],1):_vm._e(),_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandedGroups.includes(item.Id)),expression:"expandedGroups.includes(item.Id)"}]},_vm._l((item.Instructions),function(instruction){return _c('v-list-item',{key:instruction.Id,staticClass:"child-list",class:{ 'selected-item': _vm.selectedItemNum === instruction.Id },on:{"click":function($event){return _vm.selectItem(instruction)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"multi-line-title"},[_vm._v(" "+_vm._s(instruction.Name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(instruction.Description))])],1)],1)}),1),(!item.IsGroup)?_c('v-list-item',{class:{ 'selected-item': _vm.selectedItemNum === item.Id },on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"multi-line-title"},[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.Description))])],1)],1):_vm._e()],1)})],2),(_vm.selectedItem && _vm.selectedItem.WebId)?_c('div',{staticClass:"content-container rounded"},[_c('word-press-viewer',{ref:"wordPress",attrs:{"postId":_vm.selectedItem.WebId,"type":"instruction"},on:{"openInstruction":_vm.openInstruction}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }