<template>
  <v-select
    :items="_items"
    v-bind="$attrs"
    v-on="$listeners"
    append-icon="$ToolArrowDown"
    :item-value="itemValue"
    :clearable="clearable"
    ref="select"
    :item-text="_itemText"
  >
    <template
      v-if="$attrs.hasOwnProperty('multiple') || $attrs.hasOwnProperty('chips')"
      #selection="{ item, parent }"
    >
      <slot name="selection" v-bind="{ item, parent }">
        <v-chip
          class="primary--text"
          @click:close="parent.selectItem(item)"
          :close="
            parent.multiple &&
            !($attrs.hasOwnProperty('readonly') && $attrs['readonly'])
          "
          :color="typeof chipColor === 'function' ? chipColor(item) : chipColor"
        >
          <slot name="selection.text" v-bind="{ item }">
            <span class="dynamic-text">{{ parent.getText(item) }}</span>
          </slot>
        </v-chip>
      </slot>
    </template>
    <template v-if="selectAll" #prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <m-icon
            v-if="$attrs['value']"
            :color="$attrs['value'].length > 0 ? 'primary' : ''"
            :icon="icon($attrs['value'], $attrs['items'])"
          ></m-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Выбрать все</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template
      v-slot:append-item
      v-if="getDict && getDict.componentEdit && isAddItem"
    >
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content style="padding-bottom: 0; padding-top: 8px">
          <v-btn
            text
            color="blue"
            large
            @click="
              getDict.componentEditPage
                ? $router.push({ name: getDict.componentEdit })
                : $store.dispatch('dict/START_DICT', getDict)
            "
          >
            <m-icon
              icon="mdi-plus-circle"
              dark
              left
              style="font-size: 30px"
              class="mr-3"
            ></m-icon>
            Добавить
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import selectDictMixin from "../../../mixins/selectDictMixin";

export default {
  mixins: [selectDictMixin],
  props: {
    isAddItem: {
      type: Boolean,
      default: true,
    },
    itemValue: {
      type: String,
      default: "Id",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    chipColor: {
      type: [String, Function],
      default: "#E8ECFF",
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: [Function, String],
      default: null,
    },
    itemText: {
      type: [String, Function],
      default: null,
    },
  },

  computed: {},
  mounted() {
    this.myDynamicWatcher = this.$watch(
      () => this.$refs.select.isMenuActive,
      (val) => {
        this.$store.commit("globalOverlay/SET", val);
      },
      { immediate: true }
    );
  },
  methods: {
    likesAll(selected, items) {
      return selected.length === items.length;
    },
    likesSome(selected, items) {
      return selected.length > 0 && !this.likesAll(selected, items);
    },
    icon(selected, items) {
      if (this.likesAll(selected, items)) return "mdi-close-box";
      if (this.likesSome(selected, items)) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll(this.$attrs.value, this.$attrs.items)) {
          this.$emit("input", []);
        } else {
          this.$emit("input", this.$attrs.items.slice());
        }
      });
    },
  },
};
</script>
<style lang="scss">
.dynamic-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
