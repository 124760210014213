var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-list-view',{attrs:{"title":_vm.pageType === 'my'
      ? 'Мои проверки'
      : _vm.pageType === 'external'
      ? 'Внешние проверки'
      : 'Все проверки',"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"pageEditName":"AuditOperationEdit","queryParams":_vm.pageType === 'external' ? { external: true } : {},"group-desc":"","show-actions":"","show-expand":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortByDesc,"not-show-actions":_vm.readonlyExternalChild
      ? ['add', 'delete', 'copy']
      : _vm.readOnly
      ? ['add']
      : [],"showDelete":!_vm.readOnly,"showCopy":!_vm.readOnly,"groupOpenType":"showLast","disabledCopy":function (items) { return items[0].External && _vm.$store.state.Organization.OwnerType !== 2; }},on:{"onRefresh":_vm.init,"onDelete":_vm.del},scopedSlots:_vm._u([{key:"item.Period",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.PeriodFormat(item.Period.DateIn, item.Period.DateOut))+" ")])]}},{key:"item.Status",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:_vm.classStatus(item.Status)},[_vm._v(_vm._s(_vm._f("getEnums")(item.Status,"AuditOperationStatus")))])]}},{key:"item.AnsweredYesPercent",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:(item.AnsweredYesPercent === 0
          ? item.Status === 2
            ? 'error'
            : ''
          : _vm.StatisticIndicatorHelper.getColorByRefs(
              [_vm.refValues.RefValue1, _vm.refValues.RefValue2, _vm.refValues.RefValue3],
              item.AnsweredYesPercent,
              1,
              ['error', 'warning', 'success', 'beautify_dark']
            )) + '--text'},[_vm._v(" "+_vm._s(item.CheckStatus === 3 ? "Не применимо 100%" : ("Соответствует " + (item.AnsweredYesPercent || 0) + " %"))+" ")])]}},{key:"group.header.after",fn:function(ref){
            var items = ref.items;
return [_c('span',{staticClass:"subtitle-2"},[_vm._v(" ("),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getColOperations(items))}}),_vm._v(") ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('report-activator',{attrs:{"report":item.External
          ? [
              'ExternalAuditOperationReport',
              'ExternalAuditOperationObjectsReport',
              'ExternalAuditOperationReportByIndicators' ]
          : [
              'AuditOperationReport',
              'AuditOperationObjectsReport',
              'AuditOperationReportByIndicators',
              'CorrectiveActionPlanReport',
              'CorrectiveActionPlanExecutionReport',
              'AuditOperationSummaryReport' ],"params":{ objectId: { name: 'pAuditOperation', value: item.Id } }}}),_c('v-btn',{attrs:{"icon":"","title":"Анализ"},on:{"click":function($event){$event.stopPropagation();return _vm.openAnalysis(item)}}},[_c('m-icon',{attrs:{"icon":"tool-analysis","s16":""}})],1)]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('table-expanded',{attrs:{"headers":headers,"AuditOperation":item}})]}}])},[(_vm.currentRouter.params.type !== 'my')?_c('report-activator',{attrs:{"slot":"action","report":_vm.currentRouter.params.type === 'all'
        ? ['InspectionPlanReport', 'ConsolidatedAuditReport']
        : ['ExternalInspectionPlanReport', 'ConsolidatedAuditExternalReport']},slot:"action"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }