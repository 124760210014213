<template>
  <div class="d-flex">
    <panel-filter :items="data" :value="value" @input="$emit('input', $event)">
      <template slot="panelFilterPrepend">
        <slot name="panelFilterPrepend"></slot>
      </template>
    </panel-filter>
    <div style="flex: 1" class="ml-5">
      <panel hide-icon small style="position: relative">
        <wrapper-loader v-if="loading" circle></wrapper-loader>
        <v-row>
          <v-col>
            <tfoms-finance
              v-if="dataSource.MonthValues"
              :data="dataSource.MonthValues.map((e) => [e])"
              title="Общая оценка состояния качества и безопасности медицинской деятельности"
              hideIcon
              :factColor="'#2757FF'"
              labelFact="Показатель оценки качества"
              :hideDatalabel="false"
              style="width: 100%"
              :labels="labels0"
              :subTitle="null"
              :period="queryParams.params.periodType || 0"
              @itemClick="itemClick"
              :refs="dataSource.RefValues"
              :isClickLabel="false"
              hideBorder
            >
            </tfoms-finance>
          </v-col>
          <v-col>
            <kibmd-info
              :value="dataSource.Value"
              :description="dataSource.Description"
              :year="queryParams.params.Year"
            >
            </kibmd-info>
          </v-col>
        </v-row>
      </panel>

      <v-row class="mt-4">
        <v-col>
          <panel
            title="Оценка по показателям качества"
            small
            hide-icon
            :isClickLabel="false"
            :labels="labels0"
            style="position: relative"
          >
            <wrapper-loader circle v-if="loading"></wrapper-loader>
            <template slot="titleAppend">
              <div>
                <v-checkbox
                  v-model="showAllPOK"
                  label="показать все"
                  :title="
                    !showAllPOK
                      ? 'Показать ПОК без значений'
                      : 'Скрыть ПОК без значений'
                  "
                  class="mt-0"
                ></v-checkbox>
              </div>
            </template>
            <kibmd-table2
              v-if="dataSource.IndicatorValues"
              :data="
                showAllPOK
                  ? dataSource.IndicatorValues
                  : dataSource.IndicatorValues.filter(
                      (e) => e.Value || e.Value === 0
                    )
              "
              :refs="dataSource.RefValues"
              @input="updateSelectIndicator"
              :value="
                queryParams1.params.indicatorId
                  ? dataSource.IndicatorValues.find(
                      (e) =>
                        e.Indicator.Id === queryParams1.params.indicatorId.Id
                    )
                  : null
              "
            ></kibmd-table2>
          </panel>
        </v-col>
        <v-col>
          <panel
            title="Оценка по медицинским организациям"
            small
            hide-icon
            :isClickLabel="false"
            :labels="labels3"
            style="position: relative"
          >
            <wrapper-loader circle v-if="loading"></wrapper-loader>
            <template slot="titleAppend">
              <div>
                <v-checkbox
                  v-model="showAllMO"
                  label="показать все"
                  :title="
                    !showAllMO
                      ? 'Показать МО без значений'
                      : 'Скрыть МО без значений'
                  "
                  class="mt-0"
                ></v-checkbox>
              </div>
            </template>
            <kibmd-table2
              v-if="dataSource.OrganizationValues"
              :data="
                showAllMO
                  ? dataSource.OrganizationValues
                  : dataSource.OrganizationValues.filter(
                      (e) => e.Value || e.Value === 0
                    )
              "
              :type="2"
              :refs="dataSource.RefValues"
              @input="updateSelectOrganization"
              :value="
                queryParams1.params.organizationId
                  ? dataSource.OrganizationValues.find(
                      (e) =>
                        e.Organization.Id ===
                        queryParams1.params.organizationId.Id
                    )
                  : null
              "
            ></kibmd-table2> </panel
        ></v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <KibmdTableCheck
            v-model="selectCheck"
            :data="externalIndicator.Audits"
            :labels="labels1"
            :refs="dataSource.RefValues"
            :loading="loading"
          ></KibmdTableCheck>
        </v-col>
        <v-col>
          <KibmdTableCheckList
            :data="externalIndicator.CheckLists"
            @input="
              $router.push({
                name: $event.isCheckOperation
                  ? 'CheckOperationRun'
                  : 'CheckListEdit',
                params: { objectId: $event.Id },
              })
            "
            :labels="labels2"
            :refs="dataSource.RefValues"
            :loading="loading"
          ></KibmdTableCheckList>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import OrganizationServive from "@/services/OrganizationService.js";
import { sortBy } from "@/utils/Functions";
import tfomsFinance from "@/views/analysis/tfoms/components/tfomsFinance";
import KibmdService from "@/services/KibmdService";
import kibmdTab from "../mixins/kibmdTab";
import { mapActions } from "vuex";

export default {
  name: "view-AnalysisKibmd-tab1",
  components: {
    panel: () => import("@/components/analysis/panel.vue"),
    KibmdInfo: () => import("../components/kibmdInfo.vue"),
    kibmdTable2: () => import("../components/kibmdTable2.vue"),

    panelFilter: () => import("@/components/base/panelFilter"),
    KibmdTableCheck: () => import("../components/kibmdTableCheck.vue"),
    KibmdTableCheckList: () => import("../components/kibmdTableCheckList.vue"),
    tfomsFinance,
    wrapperLoader: () => import("@/components/base/WrapperLoader.vue"),
  },
  mixins: [kibmdTab],
  props: {
    value: { type: Array, default: null },
  },
  data() {
    return {
      dataSource: {},
      selected: [[], []],
      data: [
        {
          title: "Показатели",
          items: [],
          moreText: "показателей",
        },
        {
          title: "Медицинские организации",
          items: [],
          moreText: "МО",
        },
        {
          title: "Проверки",
          items: [],
          moreText: "Проверки",
        },
      ],
      periodNum: null,
      externalIndicator: {},
      selectCheck: null,
      optionsOrganization: [],
      auditIndicators: [],
      showAllPOK: false,
      showAllMO: false,
    };
  },
  computed: {
    labels0() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
      ].filter(Boolean);
    },
    labels() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
        this.queryParams1.params?.indicatorId?.Name,
        this.queryParams1.params?.organizationId?.Name,
      ].filter(Boolean);
    },
    labels1() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
        this.queryParams1.params.indicatorId?.Name,
        this.queryParams1.params?.organizationId?.Name,
      ].filter(Boolean);
    },
    labels2() {
      return [
        ...this.labels1,
        this.selectCheck?.AuditOperation?.Num
          ? "Проверка: №" + this.selectCheck?.AuditOperation?.Num
          : null,
      ].filter(Boolean);
    },
    labels3() {
      return [
        this.queryParams?.params?.Year + " год",
        this.periodNum !== null
          ? moment().set("month", this.periodNum).format("MMMM")
          : null,
        this.queryParams1.params?.indicatorId?.Name,
      ].filter(Boolean);
    },
  },
  watch: {
    periodNum(val) {
      this.updateQueryParams({ periodNum: val === null ? null : val + 1 });
    },
    /* "queryParams1.params.indicatorId": async function () {
      this.updateOrganization();
    }, */
    async selectCheck(val, old) {
      if (val === null && val === old) return;
      this.externalIndicator.CheckLists = (
        await KibmdService.kibmd1.tab1.GetExternalAuditCheckOperations({
          ...this.queryParams.params,
          ...this.queryParams1.params,
          AuditIds: val?.AuditOperation?.Id
            ? [val?.AuditOperation?.Id].filter(Boolean)
            : [...(this.filterParams.params.selected0.AuditIds || [])],

          IndicatorsIds: this.queryParams1.params.indicatorId
            ? [this.queryParams1.params.indicatorId?.Id].filter(Boolean)
            : [
                ...(this.filterParams.params.selected0.StatisticIndicatorsIds ||
                  []),
              ],

          OrganizationsIds: this.queryParams1.params.organizationId
            ? [this.queryParams1.params.organizationId?.Id].filter(Boolean)
            : [...(this.filterParams.params.selected0.OrganizationsIds || [])],
        })
      ).data.CheckLists;
    },
  },
  async mounted() {
    setTimeout(() => {
      this.init();
      this.initFilter();
    }, 1);

    this.auditIndicators = await this.LOAD_AUDIT_INDICATORS();

    this.optionsOrganization = sortBy(
      (await OrganizationServive.relatedOrganizations.get()).data,
      "Name"
    );
    this.$watch(
      () => this.queryParams.params.Year,
      () => {
        this.initFilter();
      }
    );
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
    async updateOrganization() {
      this.externalIndicator = (
        await KibmdService.kibmd1.tab1.getExternalIndicatorAnalysisByOperation({
          ...this.queryParams.params,
          ...this.queryParams1.params,

          ...this.filterParams.params.selected0,

          IndicatorsIds: this.queryParams1.params.indicatorId
            ? [this.queryParams1.params.indicatorId?.Id].filter(Boolean)
            : [
                ...(this.filterParams.params.selected0.StatisticIndicatorsIds ||
                  []),
              ],

          OrganizationsIds: this.queryParams1.params.organizationId
            ? [this.queryParams1.params.organizationId?.Id].filter(Boolean)
            : [...(this.filterParams.params.selected0.OrganizationsIds || [])],
        })
      ).data;

      this.selectCheck = null;
    },
    itemClick(e) {
      if (e === this.periodNum) {
        this.periodNum = null;
      } else {
        this.periodNum = e;
      }
    },
    async initFilter() {
      const names = (
        await KibmdService.kibmd1.getFilterData(this.queryParams.params.Year)
      ).data;

      this.data[0].items =
        names.Indicators?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[1].items =
        names.Organizations?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];

      this.data[2].items =
        names.AuditOperations?.map((e) => ({
          id: e.Id,
          value: e.Name,
        })) || [];
    },
    updateSelectOrganization(event) {
      const item =
        event &&
        this.optionsOrganization.find((e) => e.Id === event.Organization.Id);

      this.updateQueryParams1({ organizationId: item });

      this.updateOrganization();
    },
    async updateSelectIndicator(event) {
      const item =
        event && this.auditIndicators.find((e) => e.Id === event?.Indicator.Id);

      this.updateQueryParams1({ indicatorId: item });

      this.updateQueryParams1({ organizationId: null });

      const { data } = await KibmdService.kibmd1.byPeriod({
        ...this.queryParams.params,
        ...this.filterParams.params.selected0,
        IndicatorsIds: this.queryParams1.params.indicatorId
          ? [this.queryParams1.params.indicatorId?.Id].filter(Boolean)
          : [
              ...(this.filterParams.params.selected0.StatisticIndicatorsIds ||
                []),
            ],
      });

      this.dataSource.OrganizationValues = data.OrganizationValues;

      this.updateOrganization();
    },
    async init(e) {
      this.loading = true;
      this.updateQueryParams1({ indicatorId: null });

      // Фильтры
      const t = {
        StatisticIndicatorsIds: this.value[0],
        OrganizationsIds: this.value[1],
        PartitionsIds: this.value[2],
      };

      const { data } = await KibmdService.kibmd1.get({
        ...t,
        ...this.queryParams.params,
        ...e,
      });

      this.updateSelectIndicator();

      this.dataSource = data;
      this.loading = false;
    },
  },
};
</script>
