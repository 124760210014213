<template>
  <v-app>
    <the-menu v-if="!hideMenu" />
    <v-main class="main">
      <v-container fluid style="height: 100%">
        <keep-alive>
          <router-view class="mt-1 mb-1" :key="getRoute()" />
        </keep-alive>
      </v-container>
      <v-overlay
        class="v-overlay-select"
        color="rgb(33, 33, 33, 0.3)"
        :value="overlay"
        @click="overlay = false"
        ref="selectOverlayMain"
      ></v-overlay>
      <v-overlay
        class="v-overlay-select"
        color="rgb(33, 33, 33, 0.3)"
        :value="overlayForInput"
        @click="overlayForInput = false"
        ref="selectOverlay"
        id="selectOverlay"
      ></v-overlay>

      <base-frame
        v-for="item in $store.state.frame.items.filter((e) => e.componentName)"
        :key="item.id"
        :id="item.id"
        :width="'80vw'"
        :height="item.params.dialogHeight ? item.params.dialogHeight : '100vh'"
        :color="item.params.color"
      />
    </v-main>
  </v-app>
</template>

<script>
import TheMenu from "./components/TheMenu.vue";
import { mapGetters } from "vuex";
import ruMessages from "devextreme/localization/messages/ru.json";
import ruDxRich from "./modules/devextreme/localization/messages/dx-rich.ru.json";
import { locale, loadMessages } from "devextreme/localization";
import BaseFrame from "./layouts/BaseFrame.vue";
import AuthService from "./services/AuthService";
import { getZIndex } from "./utils/Functions";
// import AuthService from "./services/AuthService";

export default {
  name: "view-App",
  components: {
    TheMenu,
    BaseFrame,
  },
  computed: {
    ...mapGetters("pageTabs", ["IS_LEAST_ONE_UNSAVED_TAB"]),
    overlay: {
      get() {
        return this.$store.state.globalOverlay.value;
      },
      set(val) {
        this.$store.commit("globalOverlay/SET", val);
      },
    },
    overlayForInput: {
      get() {
        return this.$store.state.globalOverlay.overlayForInput;
      },
      set(val) {
        if (!val) {
          this.$store.commit("globalOverlay/SET_TRY_OVERLAY_INPUT", true);
        }
      },
    },
    hideMenu() {
      return this.$route.name === "Login";
    },
    color() {
      switch (this.value) {
        case "Shop":
          return "blue-grey";
        case "Cart":
          return "teal";
        case "Favorite":
          return "brown";
        case 3:
          return "indigo";
        default:
          return "blue-grey";
      }
    },
  },
  watch: {
    overlay(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.selectOverlayMain.$el.style.zIndex =
            getZIndex(document.querySelector(".v-overlay")) + 2;
        }, 1);
      }
    },
  },

  created() {
    // AuthService.updateDevice();

    // Локализация DX
    loadMessages(ruMessages);
    loadMessages(ruDxRich);

    locale(window.navigator.language);

    // При уходе со страницы проверть есть ли не сохраненные страницы
    window.addEventListener("beforeunload", (e) => {
      if (!this.IS_LEAST_ONE_UNSAVED_TAB) return;
      e.preventDefault();
      e.returnValue = "";
    });

    // session
    this.$store.dispatch("sessionManager/START_TIMER", AuthService.session);

    // Test
    /* var t = 1;
    var rr = ["task/TaskEdit", "task/Tasks"];

    setTimeout(() => {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: rr[t],
        params: { _objectId: 39, _type: "create" },
      });
      t = t === 0 ? 1 : 0;
    }, 1000); */
  },

  methods: {
    getRoute() {
      return this.$store.state.pageTabs.tabs.find((e) => {
        return e.route === this.$route.meta.normalizeFullPath;
      })?.id;
    },
  },
};
</script>

<style lang="sass">


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,500;0,800;1,500&display=swap')

$body-font-family: 'Noto Sans'
$title-font: 'Noto Sans'


@keyframes showWithDelay
  0%
    opacity: 0

  10%
    opacity: 0

  100%
    opacity: 1


.v-overlay.v-overlay--active
  backdrop-filter: blur(10px)
  background-color: rgba(26,41,91,.3)


.menuable__content__active
  max-height: 80vh !important

.v-menu__content.theme--light.menuable__content__active
  z-index: 100

.knowledge-base__status-point__wrapper
  white-space: nowrap
  text-align: left
  width: 200px

.knowledge-base__status-point
  width: 16px
  height: 16px
  display: inline-block
  border-radius: 50%
  position: relative
  top: 4px
  margin-right: 6px

  &--1
    background-color: #C6C8D5

  &--2
    background-color: #FFC805

  &--3
    background-color: #0CA85D
  &--4
    background-color: var(--v-error-base)



.v-application
  background-color: var(--v-background-base) !important

  // Текста
  font-family: $body-font-family, sans-serif !important
  label, [class*='__label']
    color: rgba(33, 33, 33, 0.7)!important
  a
    color: rgb(33, 33, 33)!important
  [class*='text-']
    color: rgb(33, 33, 33)
  .v-treeview-node__label
    color: rgb(33, 33, 33) !important
  .title, header [class*='__title']  // To pin point specific classes of some components
    color: rgb(33, 33, 33)
    font-weight: 600
    line-height: 158%
    font-size: 18px
    letter-spacing: -0.01em
  table thead th span
    color: var(--v-blue-grey-base)
    font-weight: 500
    font-size: 14px
    line-height: 158%
    letter-spacing: -0.01em
  .caption
    font-family: $body-font-family, sans-serif !important
    color: #8A90A4
  .subtitle
    font-style: normal
    font-weight: 600
    font-size: 0.875rem
    color: var(--v-blue-grey-base)

// Кнопки
.v-btn:not(.v-btn--fab):not(.v-btn--icon):not(.v-btn--rounded):not(.btn-normale):not(.v-btn--tile)
  border-radius:10px!important
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg
  color: var(--v-blue-grey-base) !important
  background-color: var(--v-blue-grey-lighten1) !important
.v-btn
  letter-spacing: normal
  text-transform: none !important // Отключен капс

// Икноки
.theme--dark.v-btn.v-btn--disabled .v-icon
  color: var(--v-blue-grey-base) !important
button.theme--light.v-btn.v-btn--icon
  color: rgb(33, 33, 33)
.theme--light.v-icon
  color: rgb(33, 33, 33)

.theme--dark.v-btn.v-btn--disabled .m-icon
  color: var(--v-blue-grey-base) !important
// .theme--light .m-icon
//   color: rgb(33, 33, 33)
.theme--light.v-btn.v-btn--disabled .m-icon
  color: rgba(0, 0, 0, 0.26) !important

// Tabs
.theme--light.v-tabs > .v-tabs-bar
  background: transparent
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active)
  color: rgb(33, 33, 33)
.v-tab
  text-transform: none

  // Нижняя линия у полей для ввода и outlined inputs
div.v-input:not(.v-input--is-focused):not(.error--text) fieldset, div.v-input:not(.v-input--is-focused):not(.error--text) .v-input__slot::before
  border-color: var(--v-blue-grey-lighten1) !important
  border-image: none !important

.theme--light.v-label--is-disabled
  color: rgba(0, 0, 0, 0.38) !important

.theme--light.v-tabs-items
  background-color: transparent!important

.block__content>.v-data-table__wrapper
  border-radius: 10px!important
.block__content.v-treeview
  font-size: 0.875rem
  & .v-treeview-node__root
    border-bottom: solid 1px
    border-bottom-color: var(--v-blue-grey-lighten1)
  & .v-treeview-node__label
    white-space: unset

// Трее листы
.v-treeview-node__root
  min-height: 24px !important
  padding-top: 8px !important
  padding-bottom: 8px !important

// Таблицы
.table-item--active
  background-color: rgb(232, 236, 255)

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
  box-shadow: none !important
.v-data-table .v-row-group__header
  background-color: #E8ECFF !important
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td
  font-size: 0.875rem
  min-height: 24px !important


.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th
  padding: 8px !important
// Отступы
.v-input__slot
  margin-bottom: 1px !important
.v-text-field
  margin-top: 2px !important

// Global ScrollBar
/* width */
::-webkit-scrollbar
  width: 5px
/* Track */
::-webkit-scrollbar-track
  background: #E8ECFF
/* Handle */
::-webkit-scrollbar-thumb
  background: #C6C8D5
  border-radius: 2.5px
/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #2757FF

// Объзательные поля
div[required=required].v-input .v-label::after
  content: " *"
  color: red
</style>

<style lang="scss">
.container-space-between {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.block__content,
.block__content-header {
  contain: inline-size;
  border: 1px solid var(--v-blue-grey-lighten1) !important;
  border-radius: 10px !important;
  background: white !important;
  &-header {
    background: transparent !important;
    border: unset !important;
  }
}
</style>
