<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      :title="
        this.type === 'create'
          ? 'Создание Чек-лист'
          : `Чек-лист &quot;${this.dataSource.Name}&quot;`
      "
      :sub-title="dataSource.Archived ? 'В архиве' : ''"
      :isUnsaved="isUnsaved && valid"
      @onRefresh="init"
      @onSave="save"
    >
      <v-btn
        v-if="dataSource.Id > 0 && isPermissionEdit"
        slot="action"
        @click="archive"
        :disabled="!dataSource.Archived ? isUnsaved : null"
        dark
        color="blue"
        class="ml-4"
        :title="dataSource.Archived ? 'Извлечь из архива' : 'Перенести в архив'"
        :outlined="!!dataSource.Archived"
        >{{
          dataSource.Archived ? "Извлечь из архива" : "Перенести в архив"
        }}</v-btn
      >
      <v-form v-model="valid" :readonly="readonly" class="block__content">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="dataSource.Name"
                :clearable="!readonly"
                :rules="[rules.required]"
                maxlength="500"
                label="Название"
                required
                v-up-first-symbol
              >
              </v-text-field>
              <m-autocomplete
                v-model="dataSource.Indicators"
                multiple
                label="Показатель оценки качества"
                deletable-chips
                :readonly="readonly"
                :items="indicators"
                :item-text="(item) => item.Name"
                :clearable="!readonly"
                dict="AuditIndicators"
                return-object
              ></m-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                v-model="dataSource.VersionNum"
                readonly
                label="Номер версии"
              >
              </v-text-field>
              <v-textarea
                v-model="dataSource.Comment"
                label="Примечание"
                counter
                auto-grow
                rows="1"
                maxlength="500"
                v-up-first-symbol
                :clearable="!readonly"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <m-tabs v-model="tab" :slider-size="2" :with-route="false" class="mt-3">
        <v-tab key="Question">Вопросы</v-tab>
        <v-tab key="Document">Документы</v-tab>
      </m-tabs>
      <v-window v-model="tab">
        <v-window-item>
          <questions
            class="mt-2"
            :value="dataSource.Questions"
            @input="dataSource.Questions = $event"
            @save="saveItems"
            show-edit-popup
            :readonly="readonly"
            :newObject="newObject"
          />
        </v-window-item>
        <v-window-item>
          <list-document-with-template
            v-model="dataSource.Documents"
            class="mt-2"
            :show-add-in-table="true"
          ></list-document-with-template>
        </v-window-item>
      </v-window>
    </base-detail-view>
  </v-layout>
</template>

<script>
import Permissions from "../../data/permissions";
import CheckListService from "../../services/CheckListService";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { dafaultCheckList } from "../../data/defaultObjects";
import Questions from "../../components/CheckList/Question/Questions.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete";
import DataHelper from "../../utils/DataHelper";
import { mapActions } from "vuex";

export default {
  name: "view-CheckListEdit",
  components: {
    mTabs: () => import("@/components/base/tabs/mTabs"),
    listDocumentWithTemplate: () =>
      import("@/components/document/ListDocumentWithTemplate.vue"),
    BaseDetailView,
    Questions,
    mAutocomplete,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data() {
    return {
      isPermissionEdit: this.hasPermission(Permissions.CheckListsEdit),
      // Api
      apiService: CheckListService,
      getDefaultObject: dafaultCheckList,
      getObjectText: (obj, isNew) => (isNew ? "новый" : obj.Name),

      loading: true,
      valid: false,
      dataSource: dafaultCheckList(),
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      indicators: [],
      question: null,
      newObject: { IsRequired: false, TypicalViolations: [], Documents: [] },
      tab: 0,
    };
  },
  computed: {
    readonly: function () {
      return !this.isPermissionEdit || !!this.dataSource?.Archived;
    },
  },
  async mounted() {
    this.indicators = await this.LOAD_AUDIT_INDICATORS();
    this.LOAD_DOCUMENT_TEMPLATE_ALL();
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
    ...mapActions("loaderDataSources", ["LOAD_DOCUMENT_TEMPLATE_ALL"]),

    saveItems() {
      this.$nextTick(async function () {
        await this.save();
      });
    },
    async archive() {
      const { data } = await CheckListService.archive(this.dataSource.Id);
      this.dataSource.Archived = data.Archived;
      this.originObject.Archived = data.Archived;
      this.applyListViewItemUpdateFunc();
    },
    async save() {
      const oldId = this.dataSource.Id;
      const { data } = await this.baseSave();
      if (!data) return;

      if (data.Id !== oldId) {
        setTimeout(() => {
          this.$router.push({
            name: this.pageEditName ?? this.$route.name,
            params: {
              objectId: data.Id,
              isReplace: true,
            },
          });
        }, 1);

        this.updateObjectText(data, false);
        this.applyListViewItemUpdateFunc();
      }
    },
    DataFromServerNormalize(data) {
      // Add parent Id
      const val = data.Questions;
      for (const i in val) {
        val[i].ParentId = null;
        if (val[i].Children?.length !== 0) {
          DataHelper.treeAddParentId(val[i].Children, val[i].Id);
        }
      }

      return data;
    },
  },
};
</script>

<style></style>
