import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import fixInputRequiredMixin from "./plugins/fixInputRequiredMixin";
import VuetifyConfirm from "./modules/vuetify-confirm";
import VuetifyAlert from "./modules/vuetifyAlert";
import Notification from "./modules/Notification";
import DemoHint from "./modules/DemoHint";
import VuetifyInformer from "./modules/VuetifyInformer";
import store from "@/store/";
import { mapGetters } from "vuex";
import { sync } from "vuex-router-sync";
import "./filters";
import VueLodash from "vue-lodash";
import object from "lodash/object";
import lang from "lodash/lang";
import { VueMaskDirective } from "v-mask";

// import seq from "lodash/seq";

import BaseIcon from "./components/base/BaseIcon.vue";

/* Стили devextrime */
import "jquery-ui/themes/base/all.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import "../node_modules/devexpress-richedit/dist/dx.richedit.css";

import VueImg from "v-img";

// Глобальные миксы
import { getIcon } from "./utils/IconHelper";
import EnumsHelper from "./utils/EnumsHelper";

import { normalizeFullPath } from "@/utils/PageHelper";
import DataHelper from "./utils/DataHelper";

Vue.component("m-icon", BaseIcon);

Vue.use(VueImg);

Vue.use(VueLodash, {
  name: "view-custom",
  lodash: { ...object, ...lang },
});

/* Vue.directive("disable-all", {
  // When all the children of the parent component have been updated
  componentUpdated: function(el) {
    // if (!binding.value) return;
    const tags = ["input", "button", "textarea", "select"];
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].disabled = true;
        nodes[i].tabIndex = -1;
      }
    });
  }
}); */

// Возврат на страницу авторизации
router.beforeEach((to, _, next) => {
  if (!store.getters.IS_AUTH && to.name !== "Login") {
    document.location.href = "/login";
    // next({ name: "Login" });
  } else next();
});

// Работа с табами
router.afterEach((to, from) => {
  const isFromNavigation = store.state.pageTabs.isFromNavigation ?? false;
  store.commit("pageTabs/SET_FROM_NAVIGATION", false);

  const toFullPath = normalizeFullPath(to);
  const fromFullPath = normalizeFullPath(from);

  to.meta.normalizeFullPath = toFullPath;

  const activeTab = store.state.pageTabs.tabs.filter((e) => {
    return e.route === toFullPath;
  });
  const currentTab = store.state.pageTabs.tabs.find((e) => {
    return e.route === fromFullPath;
  });
  let option = null;
  DataHelper.treeListTraverse(
    router.options.routes,
    (r) => {
      if (r.name === to.name) {
        option = r;
        return true;
      }
    },
    null,
    "children"
  );

  if (activeTab.length === 0) {
    const data = {
      id: Math.floor(Math.random() * 100000),
      objectId: to.params.objectId || 0,
      objectText: "",
      name: to.name,
      route: toFullPath,
      $route: to,
      tabText:
        option?.customTabText instanceof Function
          ? option?.customTabText(to)
          : null,
      isFromNavigation,
      nameComponent: to.matched[to.matched.length - 1].components.default.name,
      pageType: to.matched[to.matched.length - 1].instances.default?.pageType,
    };

    const f = async (i) => {
      if (to.matched[to.matched.length - 1].instances.default) {
        const inst = to.matched[to.matched.length - 1].instances.default;

        window.appVue.$watch(
          () => inst.pageType,
          function (newVal, oldVal) {
            store.commit("pageTabs/UPDATE_TAB_PAGE_TYPE", {
              route: toFullPath,
              pageType: newVal,
            });
          },
          { deep: true, immediate: true }
        );
      } else {
        if (i === 50) return;
        else i++;
        setTimeout(f, 1);
      }
    };
    setTimeout(f, 1);

    if (to.params?.isReplace) {
      store.dispatch("pageTabs/REPLACE_ACTIVE_TAB", data);
    } else
      store.commit("pageTabs/ADD_TABS", { newTab: data, oldTab: currentTab });
  }
});
Vue.mixin(fixInputRequiredMixin);
Vue.mixin({
  computed: {
    ...mapGetters({ USER: "GET_USER" }),
    UtilGetIcon() {
      return getIcon;
    },
    EnumsHelper() {
      return EnumsHelper;
    },
  },
  methods: {
    isEmptyObject(someObject) {
      return !Object.keys(someObject).length;
    },
    hasPermission(permission) {
      return this.$store.state.permissions.userPermissions.has(permission);
    },
    getMediaByExternalId(picture) {
      if (picture)
        return `${process.env.VUE_APP_SERVER_URL}common/GetFile?externalId=${picture.ExternalId}`;
      return null;
    },
  },
});

Vue.config.productionTip = false;
sync(store, router);

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Да",
  buttonFalseText: "Отмена",
});

Vue.use(VuetifyAlert, { vuetify });
Vue.use(Notification, { router, store, vuetify });

Vue.use(VuetifyInformer, { vuetify });
Vue.use(DemoHint, { router, store, vuetify });

Vue.directive("mask", VueMaskDirective);

Vue.directive("focusInput", (el, binding) => {
  // this will be called for both `mounted` and `updated`
  if (el.focusInput) return;
  el.focusInput = true;
  const className = binding.arg === "tree" ? ".v-treeview-node__root" : "tr";

  const input = el.querySelector("input");
  let etr = el.closest(className);
  etr?.addEventListener("click", (e) => {
    e.preventDefault();
  });
  if (!binding?.modifiers?.noEnter) el.addEventListener("keydown", logKey);

  // Scroll to element
  setTimeout(() => {
    etr?.scrollIntoView();
  }, 500);

  function logKey(e) {
    if (e.ctrlKey && e.keyCode === 13) {
      console.log("ctrl+enter");
    } else if (e.keyCode === 13) {
      e.preventDefault();
      setTimeout(() => {
        binding.value(1);
      }, 10);
    }
  }

  if (input) {
    const f = () => {
      if (!etr) {
        etr = el.closest(className);
      }

      if (!document.body.contains(el)) {
        el.removeEventListener("keydown", logKey);
        etr.classList.remove("item-focusing");
        etr.style = "";
        clearInterval(window.focusInputIntreval);
        store.commit("globalOverlay/SET_OVERLAY_INPUT", false);
        el.focusInput = false;
        return;
      }

      if (!binding?.modifiers?.noFocus) input.focus();

      // const arr = document.querySelectorAll(
      //  ".v-overlay--active, .v-dialog__content"
      // );

      // const maxOverlayIndex = Math.max(
      //  ...[...arr].map((e) => parseInt(e?.style?.zIndex))
      // );

      const selectOverlayElem = document.getElementById("selectOverlay");

      // if (selectOverlayElem.style.zIndex < maxOverlayIndex)
      //  selectOverlayElem.style.zIndex = maxOverlayIndex + -5;
      const inputIndex = parseInt(selectOverlayElem?.style?.zIndex)
        ? parseInt(selectOverlayElem?.style?.zIndex) + 3
        : 200;

      if (!store.state.globalOverlay.overlayForInput)
        store.commit("globalOverlay/SET_OVERLAY_INPUT", true);
      if (etr.style.zIndex !== inputIndex) {
        etr.classList.add("item-focusing");
        etr.style =
          "background: white; position: sticky; z-index:" + inputIndex + ";";
      }
    };

    clearInterval(window.focusInputIntreval);
    window.focusInputIntreval = setInterval(f, 300);
    el?.__vue__?.$watch(
      () => store.state.globalOverlay.tryCloseOverlayForInput,
      (val) => {
        if (val) {
          binding.value();
          store.commit("globalOverlay/SET_TRY_OVERLAY_INPUT", false);
        }
      }
    );
  }
});

Vue.directive("up-first-symbol", {
  bind: function (el, binding, vnode) {
    const elem = el.querySelector("input") || el.querySelector("textarea");
    if (elem) {
      elem.addEventListener("change", (e) => {
        const value = vnode.componentInstance.$refs.input.value.trim();
        const firstChar = value.charAt(0).toUpperCase();
        const restOfString = value.slice(1);
        const resStr = firstChar + restOfString;

        setTimeout(() => {
          e.target.value = resStr;
          const eventEl = vnode.componentInstance.$listeners;
          if (eventEl.input) {
            vnode.componentInstance.$emit("input", resStr);
          } else if (eventEl.change) {
            vnode.componentInstance.$emit("change", resStr);
          }
        }, 1);
      });
    }
  },
});

Vue.directive("delayAddClass", {
  bind: function (el, binding, vnode) {
    const c = binding.value.class;
    const delay = binding.value.delay;
    setTimeout(() => {
      el.classList.add(c);
    }, delay);
  },
});

window.appVue = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
