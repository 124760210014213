var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"mb-12"},[_c('base-panel',_vm._b({staticClass:"base-list-view",attrs:{"icon":_vm.getViewIcon}},'base-panel',_vm.$attrs,false),[_vm._t("actionBefore",null,{"slot":"action"}),(_vm.notShowActions.indexOf('search') === -1)?_c('base-search',_vm._b({staticClass:"base-list-view__search",attrs:{"slot":"action"},slot:"action",model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}},'base-search',_vm.$attrs,false)):_vm._e(),(!_vm.isNested && _vm.notShowActions.indexOf('refresh') === -1)?_c('v-btn',{attrs:{"slot":"action","icon":"","title":"Обновить"},on:{"click":function($event){return _vm.$emit('onRefresh')}},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-refresh"}})],1):_vm._e(),(_vm.showDelete && _vm.notShowActions.indexOf('delete') === -1)?_c('v-btn',{attrs:{"slot":"action","disabled":!_vm.isSelected,"icon":"","title":"Удалить","color":'error'},on:{"click":_vm.del},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-delete"}})],1):_vm._e(),(_vm.showCopy && _vm.notShowActions.indexOf('copy') === -1)?_c('v-btn',{attrs:{"slot":"action","disabled":!_vm.isSelected ||
        _vm.selected.length > 1 ||
        ((typeof this.disabledCopy === 'function'
          ? this.disabledCopy(_vm.selected)
          : this.disabledCopy) &&
          _vm.$store.state.Organization.OwnerType !== 2),"icon":"","title":"Копировать"},on:{"click":_vm.copy},slot:"action"},[_c('m-icon',{attrs:{"icon":"tool-copy","size":"18"}})],1):_vm._e(),_vm._l((Object.keys(_vm.$slots).filter(
        function (e) { return e !== 'table' && e !== 'actionBefore'; }
      )),function(slot){return _vm._t(slot,null,{"slot":slot})}),(_vm.showCompleted !== null)?_c('div',{staticClass:"align-center d-inline-flex",attrs:{"slot":"action","row":""},slot:"action"},[_c('label',{staticClass:"tasks__action-show-all v-label mr-1",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.showCompletedText))]),_c('v-checkbox',{attrs:{"value":_vm.showCompleted === 'all',"hide-details":""},on:{"change":function($event){return _vm.$emit('update:showCompleted', $event ? 'all' : 'notdone')}}})],1):_vm._e(),(_vm.notShowActions.indexOf('add') === -1)?_c('prefab-button-save',_vm._b({staticClass:"ml-4",attrs:{"slot":"action","disabled":_vm.disabledAdd},on:{"add":_vm.add},slot:"action"},'prefab-button-save',_vm.$attrs,false)):_vm._e(),_vm._t("table",function(){return [_c('base-table-full',_vm._g(_vm._b({ref:"tableFull",attrs:{"dataSource":_vm.dataSource,"searchText":_vm.searchtext,"hideEdit":_vm.$attrs['hideEdit'],"hideDelete":_vm.$attrs['hideDelete'],"item-class":function (e) { return e.IsNotified && 'font-weight-bold'; }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots).filter(
            function (slot) { return slot !== 'action'; }
          )),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"group.header.notif",fn:function(ref){
          var items = ref.items;
return [(items.find(function (e) { return e.IsNotified; }))?_c('v-btn',{staticClass:"ml-1 notification__point",staticStyle:{"position":"static"},attrs:{"fab":"","color":"primary","x-small":"","title":"В группе есть объект с оповещением"}},[_vm._v(" "+_vm._s(items.filter(function (e) { return e.IsNotified; }).length)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'base-table-full',_vm.$attrs,false),_vm.$listeners))]},{"attrs":Object.assign({}, _vm.$attrs, {searchText: _vm.searchtext}),"listeners":Object.assign({}, _vm.$listeners, {'click:row': _vm.handleClick})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }