import moment from "moment";
import { searchTree } from "@/utils/Functions";

export default {
  props: {},
  computed: {},
  data() {
    return {
      filters: {},
    };
  },
  methods: {
    initFilter(headers, dataSource) {
      for (const i in headers) {
        if (["Id", "actions"].includes(headers[i].value)) continue;
        if (
          this.filters[headers[i].value] &&
          this.filters[headers[i].value].type
        )
          continue;
        const type =
          headers[i].dataType ??
          this.columnValueType(headers[i].value, dataSource);

        this.$set(this.filters, headers[i].value, {
          value: type === "Date" || type === "Period" ? [] : null,
          type,
        });
      }
    },
    columnValueType(val, dataSource) {
      const firstNoNullElement = dataSource.find(
        (e) => this._.get(e, val) !== null
      );
      if (dataSource.length > 0) {
        const type = typeof this._.get(firstNoNullElement, val);
        return type !== "undefined" && type ? type : "string";
      } else return "string";
    },
    itemsfilter(dataSource) {
      return dataSource.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          const filter = this._.get(this.filters, f).value;
          const dataType = this._.get(this.filters, f).type;
          // const rowItem = this._.get(d, f);

          if (dataType === "number") {
            return (
              (!filter && filter !== 0) ||
              filter.length < 1 ||
              searchTree(
                d,
                (element) =>
                  parseFloat(this._.get(element, f)) === parseFloat(filter)
              )
            );
          } else if (dataType === "Date") {
            if (!(filter instanceof Array) || filter.length === 0) return true;

            const dates = filter.map((e) => {
              return moment(e, "DD.MM.YYYY");
            });
            if (dates.length === 1) dates.push(undefined);

            return searchTree(d, (element) =>
              moment(this._.get(element, f), "DD.MM.YYYY").isBetween(
                ...dates,
                undefined,
                "[]"
              )
            );
          } else if (dataType === "Period") {
            if (!(filter instanceof Array) || filter.length === 0) return true;

            const dates = filter.map((e) => {
              return moment(e, "DD.MM.YYYY");
            });

            return searchTree(d, (element) => {
              const elem = this._.get(element, f);
              const elemDates = [
                moment(elem.DateIn, "DD.MM.YYYY"),
                elem.DateOut ? moment(elem.DateOut, "DD.MM.YYYY") : undefined,
              ];
              if (dates.length !== 1) {
                let t =
                  dates[1] && dates[1].isBetween(...elemDates, null, "[]");
                if (!t) {
                  t = dates[0].isBetween(...elemDates, null, "[]");
                }
                return t;
              } else {
                dates.push(undefined);
                let t =
                  elemDates[0] && elemDates[0].isBetween(...dates, null, "[]");
                if (!t && elemDates[1]) {
                  t = elemDates[1].isBetween(...dates, null, "[]");
                }
                return t;
              }
            });
          } else if (dataType === "string") {
            return (
              !filter ||
              filter.length < 1 ||
              searchTree(d, (element) =>
                (this._.get(element, f, null) + "")
                  ?.toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              )
            );
          } else if (dataType === "enum") {
            return (
              (!filter && !(typeof filter === "number")) ||
              searchTree(d, (element) => this._.get(element, f) === filter)
            );
          } else if (dataType === "boolean") {
            return (
              (!filter && !(typeof filter === "boolean")) ||
              searchTree(d, (element) => !!this._.get(element, f) === filter)
            );
          } else if (dataType === "object") {
            return (
              !filter ||
              !filter.length ||
              searchTree(
                d,
                (element) =>
                  this._.get(element, f) &&
                  filter.find((e) => e === this._.get(element, f)?.Id)
              )
            );
          } else if (dataType === "array") {
            return (
              !filter ||
              !filter.length ||
              searchTree(
                d,
                (element) =>
                  this._.get(element, f) &&
                  filter.find((e) =>
                    this._.get(element, f).find((item) => e === item.Id)
                  )
              )
            );
          }

          return true;
        });
      });
    },
  },
};
