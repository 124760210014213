var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{key:_vm.keyForUpdate,staticClass:"menu-tabs",attrs:{"app":"","color":_vm.bg,"flat":"","height":"50px"}},[_c('v-tabs',{ref:"tabs",staticClass:"menu-tabs__content",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.$store.state.pageTabs.tabs),function(tab){return _c('v-tooltip',{key:tab.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({ref:"tab",refInFor:true,staticClass:"menu-tabs__item",class:{ 'menu-tabs__item--main': tab.id === 1 },attrs:{"to":("" + (tab.route))}},'v-tab',attrs,false),on),[_c('div',{class:{
              'menu-tabs__icon-wrap': true,
              'menu-tabs__logo': tab.id === 1,
            }},[(tab.id !== 1)?_c('m-icon',{attrs:{"size":"16","icon":_vm.UtilGetIcon(tab.nameComponent, tab.pageType),"active":tab.route === _vm.$route.meta.normalizeFullPath}}):_c('img',{attrs:{"src":require('../assets/logo.svg')}})],1),_c('div',{staticClass:"tab-text"},[(tab.tabText)?[_vm._v(_vm._s(tab.tabText))]:[_vm._v(" "+_vm._s(tab.isUnsaved ? "*" : "")+" "+_vm._s(_vm.localizePage(tab) + (tab.objectText || tab.objectId > 0 ? ":" : ""))+" "+_vm._s(tab.objectText ? _vm.truncWithEllipsis(tab.objectText, 45) : tab.objectId > 0 ? "№" + tab.objectId : ""))]],2),(tab.id !== 1)?_c('v-btn',_vm._b({class:{
              'menu-tabs__btn-close': true,
              'menu-tabs__btn--hide': tab.route !== _vm.activeTab,
            },attrs:{"icon":""},on:{"click":function($event){return _vm.removeTab($event, { tab: tab })}}},'v-btn',{
              'x-small': _vm.$store.state.pageTabs.tabs.length >= 10,
              small: _vm.$store.state.pageTabs.tabs.length < 10,
            },false),[_c('m-icon',{attrs:{"icon":"mdi-close","small":""}})],1):_vm._e()],1)]}}],null,true)},[_c('v-tabs-slider',{attrs:{"color":"#2757FF"}}),(tab.tabText)?[_vm._v(_vm._s(tab.tabText))]:[_vm._v(" "+_vm._s(tab.isUnsaved ? "*" : "")+" "+_vm._s(_vm.localizePage(tab) + (tab.objectText || tab.objectId > 0 ? ":" : ""))+" "+_vm._s(tab.objectText ? _vm.truncWithEllipsis(tab.objectText, 60) : tab.objectId > 0 ? "№" + tab.objectId : ""))]],2)}),1),(
      _vm.GET_INDEX_CURRENT_TAB >= 0 &&
      _vm.$store.state.pageTabs.tabs[_vm.GET_INDEX_CURRENT_TAB].id !== 1
    )?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.toggleFavorite(_vm.$store.state.pageTabs.tabs[_vm.GET_INDEX_CURRENT_TAB])}}},[_c('m-icon',{attrs:{"icon":_vm.booleanFavorit ? 'mdi-star' : 'mdi-star-outline',"color":_vm.booleanFavorit ? '#ffc805' : ''}})],1):_vm._e(),_c('v-menu',{staticClass:"menu-tabs__list",attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"-56px"},attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('m-icon',{attrs:{"icon":"mdi-dots-vertical"}})],1)]}}])},[_c('v-btn',{attrs:{"block":"","depressed":"","tile":""},on:{"click":function($event){return _vm.removeTab($event, { all: true })}}},[_vm._v("Закрыть все вкладки")]),_c('v-btn',{attrs:{"block":"","depressed":"","tile":""},on:{"click":function($event){return _vm.removeTab($event, { exceptCurrent: true })}}},[_vm._v("Закрыть все вкладки кроме текущей")]),_c('v-divider'),_c('v-list',{staticClass:"menu-tabs__list-wrap"},_vm._l((_vm.$store.state.pageTabs.tabs),function(tab){return _c('v-list-item',{key:tab.id,staticClass:"menu-tabs__list-item",attrs:{"to":tab.route}},[_c('div',{staticClass:"menu-tabs__list-icon",class:{ 'menu-tabs__list-logo': tab.id === 1 }},[(tab.id !== 1)?_c('m-icon',{attrs:{"s16":"","icon":_vm.UtilGetIcon(tab.nameComponent, tab.pageType),"active":tab.route === _vm.$route.meta.normalizeFullPath}}):_c('img',{attrs:{"src":require('../assets/logo.svg')}})],1),_c('div',{staticClass:"tab-text tab-text--ellipsis"},[(tab.tabText)?[_vm._v(_vm._s(tab.tabText))]:[_vm._v(" "+_vm._s(tab.isUnsaved ? "*" : "")+" "+_vm._s(_vm.localizePage(tab) + (tab.objectText || tab.objectId > 0 ? ":" : ""))+" "+_vm._s(tab.objectText ? _vm.truncWithEllipsis(tab.objectText, 45) : tab.objectId > 0 ? "№" + tab.objectId : ""))]],2),(tab.id !== 1)?_c('v-btn',{staticClass:"menu-tabs__btn--hide",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleFavorite(tab)}}},[_c('m-icon',{attrs:{"icon":_vm.GET_FAVORITE_TAB.find(function (e) { return e.route === tab.route; })
                ? 'mdi-star'
                : 'mdi-star-outline',"color":_vm.GET_FAVORITE_TAB.find(function (e) { return e.route === tab.route; })
                ? '#ffc805'
                : ''}})],1):_vm._e(),(tab.id !== 1)?_c('v-btn',{staticClass:"menu-tabs__btn--hide",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeTab($event, { tab: tab })}}},[_c('m-icon',{attrs:{"icon":"mdi-close","small":""}})],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }