<template>
  <v-layout>
    <v-flex class="mx-1">
      <base-panel
        :loading="loading"
        v-bind="$attrs"
        v-on="$listeners"
        :icon="getViewIcon"
      >
        <v-btn
          v-if="$listeners['onRefresh']"
          slot="action"
          @click="refresh"
          class="ml-4"
          :title="tooltipRefresh"
          icon
        >
          <m-icon icon="tool-refresh" s16></m-icon>
        </v-btn>
        <slot
          v-for="slot in Object.keys($scopedSlots).filter(
            (slot) => slot === 'action'
          )"
          :name="slot"
          :slot="slot"
        />

        <template
          v-for="slot in Object.keys($scopedSlots).filter(
            (e) => e !== 'action'
          )"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope" />
        </template>

        <template slot="action">
          <slot
            name="btn-close"
            v-bind:click="
              () => {
                $store.dispatch('frame/SHOW_DIALOG', { show: false });
              }
            "
          >
            <v-btn
              v-if="$parent && ($parent.nested || $parent.simple)"
              slot="action"
              @click="$store.dispatch('frame/SHOW_DIALOG', { show: false })"
              outlined
              color="blue-grey"
              class="ml-3"
            >
              {{ closeBtnTitle }}
            </v-btn>
          </slot>
          <slot
            name="btn-save"
            v-bind:click="
              () => {
                $emit('onSave');
              }
            "
          >
            <v-btn
              v-if="showSave"
              @click="$emit('onSave')"
              :disabled="!isUnsaved || loading"
              dark
              color="blue"
              class="ml-4"
              title="Сохранить"
            >
              <m-icon icon="tool-save" left size="18"></m-icon>
              Сохранить
            </v-btn>
          </slot>
        </template>
        <slot></slot>
      </base-panel>
    </v-flex>
  </v-layout>
</template>

<script>
import BasePanel from "./BasePanel.vue";
import IconViewMixin from "../mixins/IconViewMixin";
export default {
  components: {
    BasePanel,
  },
  mixins: [IconViewMixin],
  props: {
    loading: Boolean,
    isUnsaved: Boolean,
    tooltipRefresh: {
      type: String,
      default: "Обновить",
    },
    showSave: {
      type: Boolean,
      default: () => true,
    },
    closeBtnTitle: {
      type: String,
      default: "Отмена",
    },
  },
  methods: {
    async refresh() {
      if (
        !this.isUnsaved ||
        (await this.$confirm(`Данные не сохранены! Вы хотите обновить окно?`))
      )
        this.$emit("onRefresh");
    },
  },
};
</script>

<style scoped></style>
