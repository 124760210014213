<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :min-width="355"
    :max-width="355"
    offset-y
    :nudge-top="400"
    :close-on-click="false"
    v-if="show"
    content-class="tour-manger__menu"
  >
    <demo-menu @close="menu = false" v-model="tab"></demo-menu>

    <div class="tour-manger__container">
      <v-window :value="tab" v-if="!hh">
        <v-window-item>
          <tour-pages
            v-if="!selectPage"
            v-model="menu"
            :showItems="showItems"
            @startDemo="forgeStartDemo($event)"
            :items="pages"
            :pageName="pageName"
          ></tour-pages>
        </v-window-item>
        <v-window-item> <tab3 :pageName="pageName"></tab3> </v-window-item>
        <v-window-item> <tab2 :pageName="pageName"></tab2> </v-window-item>
      </v-window>
    </div>
  </v-menu>
</template>
<script>
import { localizePageTabs } from "@/data/localization";
import demoMenu from "./components/demoMenu.vue";
import tourPages from "./components/tourPages.vue";

export default {
  components: {
    tab2: () => import("./components/tabs/tab2.vue"),
    tab3: () => import("./components/tabs/tab3.vue"),
    tourPages,
    demoMenu,
  },
  props: {
    tourCmp: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      showItems: false,
      selectPage: null,
      show: true,
      pages: [],
      tab: 0,
      hh: false,
    };
  },
  computed: {
    pageName() {
      return this.$route.params.type && this.$route.params.type !== "undefined"
        ? localizePageTabs[this.$route.name][this.$route.params.type]
        : localizePageTabs[this.$route.name];
    },
  },
  watch: {
    menu(val) {
      setTimeout(() => {
        this.showItems = val;
      }, 50);
    },
  },
  created() {
    window.tourManager = {
      showMenu: this.showMenu,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 5 попыток начать курс
    async init(colStart) {
      if (colStart === 5) return;

      if (this.$route.name === null) {
        setTimeout(() => {
          this.init((colStart || 0) + 1);
        }, 1000);
        return;
      }

      this.hh = true;
      setTimeout(() => {
        this.hh = false;
      }, 1);

      const res = await this.tourCmp.loadJson(this.$route.name);
      this.pages = res;
    },
    forgeStartDemo(demo) {
      this.menu = false;
      this.tourCmp.forgeStartDemo(demo);
    },
    showMenu() {
      this.menu = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.tour-manger {
  &__menu {
    overflow: visible;
    contain: layout style;
    border-radius: 20px;
  }

  &__container {
    height: 500px;
    overflow: hidden;
    border-radius: 20px;
  }
}
.v-menu__content {
  position: fixed;
  top: auto !important;
  bottom: 12px;
}
</style>
<style lang="scss">
.tour-manager--in-progress {
  color: #2757ff;
}

.tour-manager--finished {
  color: #00b050;
}

.tour-manager__item-back {
  cursor: pointer;

  &:hover {
    i {
      right: 8px;
    }
  }

  i {
    position: relative;
    right: 0;
    transition: all 160ms ease;
  }
}

.popover {
  background-color: #f5f6ff !important;
  .popover-title {
    background-color: inherit !important;
    border-bottom: unset;
    font-size: 16px;
  }

  .disabled {
    display: none;
  }

  &-content {
    position: relative;
    margin-bottom: 45px;
  }
  &-action-icon {
    display: flex;
    width: 49px;
    height: 49px;
    background-image: url("~@/assets/click.svg");
  }
}
</style>
