<template>
  <v-tree-select
    :items="_items"
    v-bind="$attrs"
    :value="getValue"
    item-text="Name"
    item-children="Children"
    item-key="Id"
    return-object
    expand-icon="$ToolArrowDown"
    ref="select"
    :searchInput.sync="search"
    :autocomplete="true"
    @input="input"
  >
    <template slot="prepend-item">
      <v-list-item ripple>
        <v-list-item-content class="py-0">
          <v-list-item-title>
            <v-text-field
              v-model="search"
              @click.stop
              label="Поиск"
              maxlength="200"
              hide-details
              prepend-icon="mdi-database-search"
            ></v-text-field
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <v-divider></v-divider>
      <v-list-item class="pb-2">
        <v-list-item-content style="padding-bottom: 0; padding-top: 8px">
          <v-btn
            text
            color="blue"
            large
            @click="
              getDict.componentEditPage
                ? $router.push({ name: getDict.componentEdit })
                : $store.dispatch('dict/START_DICT', getDict)
            "
          >
            <m-icon
              icon="mdi-plus-circle"
              dark
              left
              style="font-size: 30px"
              class="mr-3"
            ></m-icon>
            Добавить
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-tree-select>
</template>

<script>
import selectDictMixin from "../../../mixins/selectDictMixin";
import DataHelper from "../../../utils/DataHelper";

export default {
  mixins: [selectDictMixin],
  props: {
    value: { default: null },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    getValue() {
      if (!this.value || !this._items) return null;
      if (this.value && !this.value?.length)
        return DataHelper.findTreeById(this._items, this.value.Id)
          ? [DataHelper.findTreeById(this._items, this.value.Id)]
          : null;

      return this.value?.map((e) => DataHelper.findTreeById(this._items, e.Id));
    },
  },
  watch: {
    getValue: {
      immediate: true,
      handler(val) {
        setTimeout(() => {
          if (
            ((val && val.length === undefined) ||
              (Array.isArray(val) && val.length > 0)) &&
            !this.$refs.select.selectedItems.length
          ) {
            this.$refs.select.selectedItems = this.$refs.select.$props.multiple
              ? val
              : [val[0]];
          }
        }, 1);
      },
    },
  },
  mounted() {
    this.myDynamicWatcher = this.$watch(
      () => this.$refs.select.isMenuActive,
      (val) => {
        this.$store.commit("globalOverlay/SET", val);
      }
    );
  },
  methods: {
    input(e) {
      if (e?.length === 0) {
        this.$emit("input", null);
        return;
      }
      if (e.length && !this.$attrs.multiple) {
        this.$emit("input", e[0]);
        return;
      }
      this.$emit("input", e);
    },
  },
};
</script>

<style></style>
