var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table-full',_vm._g(_vm._b({ref:"baseTreeTable",staticClass:"base-tree-table",class:{
    'base-tree-table__nested': _vm.level,
    'mb-12': !_vm.level,
    'tree-teble-contents': _vm.treeTableContents,
    'tree-teble-contents__selected': this.$attrs.notShowSelect == undefined,
    'tree-teble-contents__noselected':
      this.$attrs.notShowSelect !== undefined,
  },attrs:{"headers":_vm.internalHeaders,"dataSource":_vm.items,"loading":_vm.loading,"searchText":_vm.searchText,"single-expand":_vm.singleExpand,"show-expand":_vm.showExpand,"item-class":_vm.getItemClass},scopedSlots:_vm._u([{key:"item.actions",fn:function(scope){return (_vm.$scopedSlots['item.actions'])?[(_vm.dragable)?_c('v-btn',{staticClass:"sortHandle handle",attrs:{"icon":"","color":"blue-grey","title":"Переместить"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('m-icon',{attrs:{"icon":"mdi-menu","small":""}})],1):_vm._e(),_vm._t("item.actions",null,null,scope)]:undefined}},_vm._l((Object.keys(_vm.$scopedSlots).filter(
      function (e) { return e !== 'header' && e !== 'item.actions'; }
    )),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"header",fn:function(scope){return [_vm._t("header",null,null,Object.assign({}, scope, {level: _vm.level}))]}},_vm._l((_vm.scopeHeaders.filter(
      function (e) { return e.dataType && e.dataType === 'object'; }
    )),function(head){return {key:'item.' + head.value,fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.columnObjectText(head, item[head.value], item))+" ")]}}}),{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [(item[_vm.childrenName] && item[_vm.childrenName].length)?_c('td',{staticClass:"base-tree-table__children",staticStyle:{"padding":"0 !important"},attrs:{"colspan":headers.length}},[_c('base-tree-table',_vm._g(_vm._b({attrs:{"headers":headers,"items":item[_vm.childrenName],"level":_vm.level + 1,"options":_vm.options,"nested":"","hide-default-header":"","hide-default-footer":"","show-expanded-tree":_vm.showExpandedTree,"hidePaddingLeft":_vm.hidePaddingLeft,"group-by":null,"dragable":_vm.dragable,"tree-table-contents":_vm.treeTableContents},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'base-tree-table',Object.assign({}, _vm.$attrs, {sortDesc: _vm.sortDesc, sortBy: _vm.sortBy}),false),_vm.$listeners))],1):(_vm.showExpandedTree)?_c('td',_vm._b({attrs:{"colspan":headers.length - _vm.level + 1}},'td',{ headers: headers, item: item },false),[_vm._t("expanded-tree",null,null,{ headers: headers, item: item })],2):_vm._e()]}},(_vm.isCustomEdit)?{key:"body.append",fn:function(ref){return [(typeof _vm.showAddInTable === 'boolean')?_c('v-btn',{staticStyle:{"position":"fixed","bottom":"16px","right":"16px","animation":"showWithDelay 2s forwards","z-index":"2"},attrs:{"color":"blue","large":"","fab":"","title":_vm.textCustomAdd,"dark":""},on:{"click":_vm.addInTable}},[_c('m-icon',{attrs:{"icon":"mdi-plus"}})],1):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"position":"fixed","bottom":"16px","right":"16px","animation":"showWithDelay 2s forwards","z-index":"2"},attrs:{"color":"blue","large":"","fab":"","title":_vm.textCustomAdd,"dark":""}},on),[_c('m-icon',{attrs:{"icon":"mdi-plus"}})],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.showAddInTable.filter(function (e) { return !e.hide; })),function(item){return _c('v-list-item',{key:item.key,on:{"click":function($event){return _vm.addInTable(item.key)}}},[(item.icon)?_c('v-list-item-icon',[_c('m-icon',{attrs:{"icon":_vm.UtilGetIcon(item.icon.name, item.icon.type)}})],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(item.text)+" ")])],1)}),1)],1)]}}:null,{key:"item.data-table-expand",fn:function(ref){
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
    var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
        (item[_vm.childrenName] && item[_vm.childrenName].length) || _vm.showExpandedTree
      ),expression:"\n        (item[childrenName] && item[childrenName].length) || showExpandedTree\n      "}],staticStyle:{"margin-left":"-5px"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();expand && expand(!isExpanded)}}},[_c('m-icon',{attrs:{"size":"24","icon":isExpanded ? 'tool-arrow-up' : 'tool-arrow-down',"color":isExpanded ? 'primary' : ''}})],1)]}}],null,true)},'base-table-full',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }