<template>
  <base-list-view
    :title="
      pageType === 'my'
        ? 'Мои проверки'
        : pageType === 'external'
        ? 'Внешние проверки'
        : 'Все проверки'
    "
    @onRefresh="init"
    @onDelete="del"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    pageEditName="AuditOperationEdit"
    :queryParams="pageType === 'external' ? { external: true } : {}"
    group-desc
    show-actions
    show-expand
    :sort-by="sortBy"
    :sort-desc="sortByDesc"
    :not-show-actions="
      readonlyExternalChild
        ? ['add', 'delete', 'copy']
        : readOnly
        ? ['add']
        : []
    "
    :showDelete="!readOnly"
    :showCopy="!readOnly"
    groupOpenType="showLast"
    :disabledCopy="
      (items) => items[0].External && $store.state.Organization.OwnerType !== 2
    "
  >
    <report-activator
      v-if="currentRouter.params.type !== 'my'"
      slot="action"
      :report="
        currentRouter.params.type === 'all'
          ? ['InspectionPlanReport', 'ConsolidatedAuditReport']
          : ['ExternalInspectionPlanReport', 'ConsolidatedAuditExternalReport']
      "
    />
    <template slot="item.Period" slot-scope="{ item }">
      <span>
        {{ PeriodFormat(item.Period.DateIn, item.Period.DateOut) }}
      </span>
    </template>
    <template slot="item.Status" slot-scope="{ item }">
      <span :class="classStatus(item.Status)">{{
        item.Status | getEnums("AuditOperationStatus")
      }}</span>
    </template>
    <template slot="item.AnsweredYesPercent" slot-scope="{ item }">
      <span
        :class="
          (item.AnsweredYesPercent === 0
            ? item.Status === 2
              ? 'error'
              : ''
            : StatisticIndicatorHelper.getColorByRefs(
                [refValues.RefValue1, refValues.RefValue2, refValues.RefValue3],
                item.AnsweredYesPercent,
                1,
                ['error', 'warning', 'success', 'beautify_dark']
              )) + '--text'
        "
      >
        {{
          item.CheckStatus === 3
            ? "Не применимо 100%"
            : `Соответствует ${item.AnsweredYesPercent || 0} %`
        }}
      </span>
    </template>
    <template #group.header.after="{ items }">
      <span class="subtitle-2">
        (<span v-html="getColOperations(items)"></span>)
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <report-activator
        :report="
          item.External
            ? [
                'ExternalAuditOperationReport',
                'ExternalAuditOperationObjectsReport',
                'ExternalAuditOperationReportByIndicators',
              ]
            : [
                'AuditOperationReport',
                'AuditOperationObjectsReport',
                'AuditOperationReportByIndicators',
                'CorrectiveActionPlanReport',
                'CorrectiveActionPlanExecutionReport',
                'AuditOperationSummaryReport',
              ]
        "
        :params="{ objectId: { name: 'pAuditOperation', value: item.Id } }"
      />
      <v-btn icon title="Анализ" @click.stop="openAnalysis(item)">
        <m-icon icon="tool-analysis" s16></m-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <table-expanded :headers="headers" :AuditOperation="item" />
    </template>
  </base-list-view>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import ListEditMixin from "@/mixins/ListEditMixin";
import AuditOperationService from "@/services/AuditOperationService";
import BaseListView from "../../layouts/BaseListView.vue";
import { AuditOperationKinds, AuditOperationStatus } from "../../data/enums";
import ReportActivator from "../../components/report/ReportActivator.vue";
import TableExpanded from "../../components/AuditOperation/TableExpanded";
import Permissions from "../../data/permissions";

import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import AnalysisSettingsService from "../../services/AnalysisSettingsService";

export default {
  name: "view-AuditOperations",
  components: {
    BaseListView,
    ReportActivator,
    TableExpanded,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      readOnly: !this.hasPermission(Permissions.AuditOperationsEdit),
      apiService: AuditOperationService,
      loading: true,
      items: [{ title: "План корректирующих мероприятий" }],
      headers: [
        { text: "Номер", value: "Num", notGroup: true, width: "0%" },
        {
          text: "Предмет проверки",
          value: "Subject",
          notGroup: true,
          width: "100%",
        },
        {
          text: "Период",
          value: "Period",
          align: "center",
          cellClass: "td5 text-no-wrap",
          dataType: "Period",
        },
        {
          text: "Вид",
          value: "Kind",
          dataType: "enum",
          options: AuditOperationKinds,
          align: "center",
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: AuditOperationStatus,
          align: "center",
        },
        {
          text: "Год",
          value: "year",
          showGroupName: false,
          defaultGroup: true,
        },
        {
          text: "Результат",
          value: "AnsweredYesPercent",
          align: "center",
          showGroupName: false,
          notGroup: true,
          cellClass: "text-no-wrap",
        },
      ],
      sortBy: ["Period", "Num"],
      sortByDesc: [false, false],
      dataSource: [],
      refValues: { RefValue1: 0, RefValue2: 0, RefValue3: 0 },
    };
  },
  computed: {
    pageType() {
      return this.currentRouter.params?.type ?? "my";
    },
    readonlyExternalChild() {
      return (
        this.currentRouter.params.type === "external" &&
        this.$store.state.Organization.OwnerType === 1
      );
    },
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
  },
  async mounted() {
    this.refValues = (await AnalysisSettingsService.show()).data;
    this.headers.push({
      text: "Внешняя проверка",
      value:
        this.$store.state.Organization.OwnerType === 2
          ? "Organization"
          : "Owner",
      dataType: "object",
      align: "center",
      displayText: (e) =>
        e
          ? this.$store.state.Organization.OwnerId === e?.Id
            ? "-"
            : e?.Name
          : "-",
      notGroup: true,
      sortable: false,
      disabled:
        this.currentRouter.params.type === "all" ||
        this.currentRouter.params.type === "my",
    });
  },
  created() {
    this.init();
  },
  methods: {
    PeriodFormat(DateIn, DateOut) {
      return Vue.filter("PeriodFormat")(DateIn, DateOut);
    },
    classStatus(status) {
      switch (status) {
        case 1:
        case 2:
          return "text-pending";
        case 3:
          return "text-success";
        default:
          return "";
      }
    },
    getColOperations(items) {
      const types = [
        { name: "Плановых", col: 0 },
        { name: "Внеплановых", col: 0 },
        { name: "Пользовательских", col: 0 },
      ];
      for (let i = 0; i < items.length; i++) {
        types[items[i].Kind].col++;
      }
      return types
        .filter((e) => e.col > 0)
        .map((e) => e.name + " - " + "<b>" + e.col + "</b>")
        .join(", ");
    },
    DataFromServerNormalize(data) {
      if (!data.length) return data;
      // Добавление года
      data.forEach((item) => {
        item.year = moment(item.Period.DateIn, "DD.MM.YYYY HH:mm:ss").year();
      });
      return data;
    },
    /* async customDelete(selected) {
      if (
        !(await this.$confirm(
          `Вы действительно хотите удалить ${selected.length} объектов?`
        ))
      )
        return;

      if (selected.find((e) => e.Status !== 0)) {
        this.$alert("Удаление возможно только НОВЫХ проверок");
        return;
      }

      var ids = selected.map((el) => el.Id);
      for (let i in selected) {
        let index = this.AuditOperations.findIndex(
          (e) => e.Id === selected[i].Id
        );
        this.AuditOperations.splice(index, 1);
        selected.splice(i, 1);
      }
      this.del(ids);
    }, */

    async init() {
      try {
        this.loading = true;
        const res = await AuditOperationService.get(
          null,
          null,
          [],
          null,
          this.pageType === "my",
          true,
          this.pageType === "external" ? true : null
        );
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    openAnalysis({ Id }) {
      this.$router.push({
        name: "AnalysisAudit",
        params: {
          objectId: Id,
        },
      });
    },
  },
};
</script>
<style scoped>
.text-success {
  color: #0ca85d;
}
.text-pending {
  color: #ffc805;
}
</style>
