<template>
  <div class="block__content document-info-card">
    <div
      class="document-info-card__title primary--text"
      @click="
        $router.push({
          name: 'LocalDocumentEdit',
          params: {
            objectId: document.Id,
          },
        })
      "
    >
      Документ №{{ document.Num }} {{ document.Name }} ({{
        document.Date | DateOnly
      }})
    </div>

    <v-row class="mt-0 pa-0">
      <v-col>Собственник</v-col>
      <v-col>{{ document.OwnerName }}</v-col>
    </v-row>
    <v-row v-if="document.ActivitySection" class="mt-0 pa-0">
      <v-col>Раздел деятельности</v-col>
      <v-col> {{ document.ActivitySection.Name }} </v-col>
    </v-row>
    <v-row v-if="document.Type" class="mt-0 pa-0">
      <v-col>Тип</v-col>
      <v-col> {{ document.Type.Name }} </v-col>
    </v-row>
    <v-row class="mt-0 pa-0">
      <v-col>Статус</v-col>
      <v-col>
        {{ document.Status | getEnums("OrganizationDocumentStatus") }}
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      treeNames: null,
    };
  },
  created() {},
};
</script>
<style lang="scss">
.document-info-card {
  padding: 25px;
  font-size: 14px;
  line-height: 158%;
  color: rgb(33, 33, 33);

  &__title {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
  .row .col:first-child {
    width: 200px;
    flex-grow: unset;
    flex-basis: unset;
    font-weight: bold;
    color: #000000;
  }
  .row.mt-4 .col:last-child {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
