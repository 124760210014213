var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-list-view',{attrs:{"dataSource":_vm.dataSource,"headers":_vm.headers,"loading":_vm.loading,"disabledAdd":_vm.readOnly,"showDelete":!_vm.readOnly,"show-actions":"","pageEditName":"UnwishedEventEdit","title":"Нежелательные события","label-text-btn-create":"Добавить событие","label-text-search":"Поиск события","group-by":"DateYear","group-desc":"","groupOpenType":"showLast","sort-by":['Date'],"sort-desc":""},on:{"onRefresh":_vm.init,"onDelete":_vm.del},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var items = ref.items;
return [_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(group)+" ("+_vm._s(items.length)+")")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('report-activator',{attrs:{"report":"UnwishedEventCardReport","params":{ objectId: { name: 'pUnwishedEvent', value: item.Id } }}})]}},{key:"item.Done",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Done ? item.Done.substr(0, 10) : "")+" ")]}},{key:"item.Type.Owner",fn:function(scope){return [(scope.item.Shared)?[_c('span',[_vm._v(" "+_vm._s(scope.item.OwnerName !== scope.item.Type.Owner.Name ? scope.item.Type.Owner.Name : "-")+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('m-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"icon":"mdi-check-circle-outline","small":"","color":"blue"}},'m-icon',attrs,false),on))]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s('Опубликовано')}})])]:_c('span',[_vm._v(" "+_vm._s(scope.item.OwnerName !== scope.item.Type.Owner.Name ? scope.item.Type.Owner.Name : "-")+" ")])]}}])},[_c('report-activator',{attrs:{"slot":"action","report":"UnwishedEventsReport"},slot:"action"}),_c('div',{staticClass:"align-center d-inline-flex",attrs:{"slot":"action","row":""},slot:"action"},[_c('label',{staticClass:"tasks__action-show-all v-label blue-grey--text mr-1",staticStyle:{"font-size":"14px"}},[_vm._v("Скрыть завершенные")]),_c('v-checkbox',{attrs:{"hide-details":""},on:{"change":_vm.init},model:{value:(_vm.hideCompleted),callback:function ($$v) {_vm.hideCompleted=$$v},expression:"hideCompleted"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }