<template>
  <div>
    <base-tree-table
      editable
      notShowSelect
      show-expand
      class="statistic-indicator-values"
      ref="listView"
      expand-icon="mdi-comment-arrow-right"
      options="Values"
      hide-default-header
      hide-default-footer
      hidePaddingLeft
      :items="value2"
      :headers="headers"
      :show-add-in-table="false"
      :show-actions="true"
      :single-expand="false"
      :fetch-delete="del"
      :hideDelete="
        (item) => {
          return item.IndicatorId ? true : dataSource.OnlyRead ? true : false;
        }
      "
      :hideEdit="(item) => !item.IndicatorId"
      :disabledDelete="
        (item) => {
          return (
            readonlyItemValue(item) ||
            (!(item.IndicatorId && item.Value === null) &&
              !(item.Values && item.Values.every((e) => e.Value === null)))
          );
        }
      "
      :disabledEdit="(item) => readonlyItemValue(item)"
      :item-class="
        (item) => (value && item.Id === value.Id ? 'table-item--active' : '')
      "
      @click:row="customHandle"
      @afterEdit="afterEdit"
      not-filter
    >
      <template v-slot:header="{ props: { headers }, level }">
        <thead v-if="level === 0">
          <tr>
            <th :colspan="headers.length">
              <div class="d-flex align-center">
                <base-input-year
                  :value="showYear"
                  @input="$emit('update:showYear', $event)"
                  :periodStartYear="getPeriodStartYear()"
                  :periodEndYear="getPeriodEndYear()"
                  :currentYear="currentYear"
                ></base-input-year>
                <v-btn
                  v-if="dataSource.Id > 0 && !readonly"
                  color="primary"
                  dark
                  style="margin-left: auto; color: white"
                  :disabled="!!(disabledAddPeriod || dataSource.OnlyRead)"
                  @click="$emit('createValues')"
                >
                  <m-icon icon="tool-add-item" color="white" left size="20" />
                  Добавить период
                </v-btn>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template
        slot="item.data-table-expand"
        slot-scope="{ isExpanded, expand, item }"
      >
        <v-btn icon @click="expand(!isExpanded)">
          <m-icon
            v-if="item.Date"
            :icon="item.Comment ? 'mdi-comment-eye' : 'mdi-comment-eye-outline'"
          ></m-icon>

          <m-icon
            v-else
            :icon="isExpanded ? '$ToolArrowUp' : '$ToolArrowDown'"
          ></m-icon>
        </v-btn>
      </template>

      <template slot="item.Comment" slot-scope="scope">
        <div style="width: 16px" :style="type === 0 ? 'margin-left: 1rem' : ''">
          <m-icon
            v-if="scope.item.Comment"
            small
            color="#5F647B"
            :title="scope.item.Comment"
            icon="mdi-message-reply-text"
          ></m-icon>
        </div>
      </template>

      <template slot="item.Date" slot-scope="scope">
        <div
          style="color: #5f647b"
          :style="type === 0 ? 'min-width: 130px;' : 'min-width: 230px;'"
        >
          <span
            v-if="
              scope.item.Object && scope.item.Object.Type === 'organization'
            "
            class="pl-4"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-badge
                    bordered
                    bottom
                    color="blue"
                    dot
                    offset-x="15"
                    offset-y="9"
                  >
                  </v-badge>
                </span>
              </template>
              Другие МО
            </v-tooltip>
          </span>
          <span v-if="scope.item.Object"> {{ scope.item.Object.Name }}</span>
          <span v-else-if="scope.item.OwnerName">
            {{ scope.item.OwnerName }}</span
          >

          <div
            class="pl-4"
            v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
          >
            <base-date-picker
              v-model="scope.rowEditing.Date"
              label="Дата"
              required
              :allowed-dates="
                (val) => {
                  return allowDates(
                    scope.rowEditing.Date || scope.rowEditing.PeriodIn,
                    val
                  );
                }
              "
            ></base-date-picker>
          </div>

          <span v-if="scope.item && scope.item.Values && scope.item.PeriodIn">
            <strong
              v-html="
                StatisticIndicatorHelper.getPeriodText(
                  scope.item.PeriodIn,
                  period
                )
              "
            >
            </strong>
          </span>
        </div>
      </template>

      <template slot="item.DiffValue" slot-scope="scope">
        <div
          v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        ></div>
        <div style="width: 75px; max-width: 75px" v-else>
          <span v-if="scope.item.Date">
            {{ scope.item.Date.substr(0, 10) }}
          </span>
          <span v-else-if="scope.item.Values">
            ({{ scope.item.Values.length }})
          </span>
          <span v-else>-</span>
        </div>
      </template>

      <template slot="item.chart" slot-scope="scope">
        <div v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id">
          <div v-if="type2 === 0">
            <v-text-field
              :value="scope.rowEditing.Value"
              @input="inputNumberLimit(scope.rowEditing, 'Value', $event)"
              type="number"
              v-focusInput="scope.closeEdit"
              clearable
            >
            </v-text-field>
          </div>
          <div v-else-if="type2 === 1">
            <div class="statistic-indicator-values-calc">
              <div class="statistic-indicator-values-calc__inner">
                <div class="statistic-indicator-values-calc__left">
                  <div class="pr-2">
                    <div
                      style="
                        height: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <m-icon
                        :title="dataSource.PhenomenonDescription"
                        color="rgba(0, 0, 0, 0.38)"
                        icon="mdi-information-outline"
                      ></m-icon>
                    </div>
                    <div
                      style="
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <m-icon
                        color="rgba(0, 0, 0, 0.38)"
                        :title="dataSource.EnvironmentDescription"
                        icon="mdi-information-outline"
                      ></m-icon>
                    </div>
                  </div>
                  <div>
                    <div>
                      <v-text-field
                        :value="scope.rowEditing.PhenomenonValue"
                        @input="
                          scope.rowEditing.PhenomenonValue =
                            $event && $event != '-' ? parseFloat($event) : null
                        "
                        placeholder="0"
                        outlined
                        hide-details
                        rows="1"
                        style="width: 120px"
                        v-focusInput.noFocus="scope.closeEdit"
                        clearable
                      ></v-text-field>
                    </div>
                    <hr class="my-1" />
                    <div>
                      <v-text-field
                        :value="scope.rowEditing.EnvironmentValue"
                        @input="
                          scope.rowEditing.EnvironmentValue =
                            $event && $event != '-' ? parseFloat($event) : null
                        "
                        placeholder="0"
                        outlined
                        hide-details
                        rows="1"
                        style="width: 120px"
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="statistic-indicator-values-calc__center">
                  <span>X</span>
                </div>
                <div class="statistic-indicator-values-calc__right d-flex">
                  {{ dataSource.BaseIndicatorValue }}
                  <span class="mx-1">=</span>
                  {{
                    computedIndicatorValue(
                      scope.rowEditing.PhenomenonValue,
                      scope.rowEditing.EnvironmentValue
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="!(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)"
          :style="
            (scope.item && scope.item.Values) || type === 0 ? '' : 'width: 50%'
          "
        >
          <v-progress-linear
            :value="
              (scope.item.Value || scope.item.Value === 0 ? 2 : 0) +
                (scope.item.Value / getMaxValue(scope.item)) * 90 || 0
            "
            :buffer-value="scope.item.Value || scope.item.Value === 0 ? 88 : 90"
            background-color="rgba(198, 200, 213, 0.3)"
            height="15"
            :color="
              StatisticIndicatorHelper.getColorByRefs(
                refs,
                scope.item.Value,
                RatingType
              ) || '#C6C8D5'
            "
            :ref="'progress' + scope.item.Id"
            class="statistic-indicator-values__progress"
            >{{
              scope.item.Value || scope.item.Value === 0
                ? Number(scope.item.Value.toFixed(2))
                : "Нет данных"
            }}</v-progress-linear
          >
        </div>
      </template>
    </base-tree-table>
  </div>
</template>
<script>
import BaseInputYear from "../base/BaseInputYear.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import { compact, union } from "lodash";
import BaseDatePicker from "../base/BaseDatePicker.vue";
import * as moment from "moment";
import BaseTreeTable from "../../components/base/BaseTreeTable.vue";

export default {
  components: {
    BaseDatePicker,
    BaseTreeTable,
    BaseInputYear,
  },
  mixins: { ListEditMixin },
  props: {
    value: { type: Object, default: null },
    value2: {
      type: Array,
      default: () => [],
    },
    period: {
      type: Number,
      default: 1,
    },
    type: {
      type: Number,
      default: null,
    },
    type2: {
      type: Number,
      default: null,
    },
    refs: {
      type: Array,
      default: () => [],
    },
    RatingType: {
      type: Number,
      default: 0,
    },
    maxGroups: {
      type: Number,
      default: 0,
    },
    showYear: {
      type: Number,
      default: null,
    },
    showObject: {
      type: Object,
      default: null,
    },
    dataSource: {
      type: Object,
      default: null,
    },
    readonlyItemValue: {
      type: Function,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Коммент",
          value: "Comment",
          cellClass: "td-minimum td-4",
        },
        {
          text: "Дата",
          value: "Date",
          cellClass: "td-minimum text-no-wrap text-right td-5",
          align: "right ",
        },
        { text: "", value: "chart", width: "100%", cellClass: "td-2" },
        {
          text: "Динамика",
          value: "DiffValue",
          cellClass: "td-minimum text-no-wrap td-3",
          align: "center",
        },
      ],
      periods: ["", "неделю", "месяц", "квартал", "полгода", "год", "день"],
      rowEditing: null,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
    disabledAddPeriod() {
      const periodStartYear = moment(
        this.dataSource.PeriodIn,
        "DD.MM.YYYY"
      ).year();
      const periodEndYear = moment(
        this.dataSource.PeriodOut,
        "DD.MM.YYYY"
      ).year();

      // Проверяется не меньше ли текущий год выбрынного года
      if (!(this.currentYear < this.showYear)) {
        // Блокируется кнопка если:
        // 1) выбранный год выходит за пределы периода сбора данных
        if (this.showYear < periodStartYear || this.showYear > periodEndYear) {
          return true;
        }
        // 2) выбранный год входит в период сбора данных и достигнуто максимальное количество периодов
        else if (
          (this.showYear >= periodStartYear ||
            this.showYear === periodEndYear) &&
          this.dataSource.Groups.length === this.sumIndexPeriod()
        ) {
          return true;
        } else {
          return false;
        }
      }
      // 3) если выбранный год больше текущего года
      else {
        return true;
      }
    },
    readonly() {
      return !!this.dataSource?.Archived;
    },
  },
  updated() {
    this.updateProgress();
  },
  methods: {
    updateProgress() {
      Object.keys(this.$refs).forEach((el2) => {
        const el = this.$refs[el2]?.$el;
        if (!el) return;
        const w = parseFloat(el.getAttribute("aria-valuenow")) || 0;
        if (w || w === 0) {
          const e2 = el.querySelector(".v-progress-linear__content");

          if (e2) e2.style.left = w + 1 + "%";
        }
      });
    },
    customHandle(value) {
      this.$emit("input", value && value?.Id === this.value?.Id ? null : value);
    },
    computedIndicatorValue(phenomenonValue, environmentValue) {
      if (!environmentValue) return "?";
      if (Number(phenomenonValue) === 0 && Number(environmentValue) === 0)
        return 0;
      const r = Number(
        (
          (phenomenonValue / environmentValue) *
          this.dataSource.BaseIndicatorValue
        ).toFixed(2)
      );
      return (r >= 0 || r < 0) && r !== Infinity ? r : "?";
    },
    getMaxValue(item) {
      if (item.Values) {
        return this.maxGroups;
      } else {
        const items = compact(
          this.value2.map((e) => {
            return e.Values?.map((e2) => e2.Value) || [e.Value];
          })
        );
        return Math.max(...union(...items));
      }
    },
    async del(item) {
      let res;
      if (item.Values) {
        if (!(await this.$confirm(`Вы действительно хотите удалить период ?`)))
          return;
        res = await StatisticIndicatorService.deleteGroup(
          this.dataSource.Id,
          item.PeriodIn
        );
        this.$emit("refresh");
      } else {
        if (
          !(await this.$confirm(`Вы действительно хотите удалить показатель ?`))
        )
          return;
        res = await StatisticIndicatorService.deleteValue(item.Id);
        this.$emit("refresh");
      }
      return !!res;
    },
    allowDates(val, val2) {
      return (
        StatisticIndicatorHelper.getPeriod(val, this.period, 0)?.format(
          "DD.MM.YYYY"
        ) ===
        StatisticIndicatorHelper.getPeriod(
          moment(val2).format("DD.MM.YYYY"),
          this.period,
          0
        )?.format("DD.MM.YYYY")
      );
    },
    async saveComment() {
      await StatisticIndicatorService.updateValue(this.rowEditing);
      this.rowEditing = null;
    },
    isEditItem(item) {
      return this.rowEditing && this.rowEditing?.Id === item.Id;
    },
    addValue(v) {
      StatisticIndicatorService.createValue({
        IndicatorId: 4,
        Date: "03.04.2022",
        Value: 3,
      });
    },
    async afterEdit(old, item) {
      await StatisticIndicatorService.updateValue(item);
      this.$emit("refresh");
    },
    inputNumberLimit(source, property, event, limit = 10) {
      source[property] = null;
      const integerPast = Math.abs(Math.trunc(event));
      const lengthNumber = integerPast.toString().length;

      if (lengthNumber > limit) {
        this.$nextTick(() => {
          const limitNumber = integerPast.toString().substring(0, limit);
          source[property] = limitNumber;
        });
        return;
      }
      source[property] = event ? parseFloat(event) : null;
    },
    getPeriodStartYear() {
      return moment(this.dataSource.PeriodIn, "DD.MM.YYYY").year();
    },
    getPeriodEndYear() {
      return moment(this.dataSource.PeriodOut, "DD.MM.YYYY").year();
    },
    sumIndexPeriod() {
      const periodStartYear = moment(
        this.dataSource.PeriodIn,
        "DD.MM.YYYY"
      ).year();
      const periodEndYear = moment(
        this.dataSource.PeriodOut,
        "DD.MM.YYYY"
      ).year();

      let periodStartDate =
        this.showYear === periodStartYear
          ? this.dataSource.PeriodIn
          : `01.01.${this.showYear}`;

      let count = 0;

      // Переменные для расчета:
      // 1) если выбранный год не равен году окончания сбора данных
      let startYear = periodStartYear;
      const yearNext = this.showYear + 1;

      // 2) если выбранный год равен году окончания сбора данных
      const periodEndDate = moment(
        this.dataSource.PeriodOut,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD");

      let datePeriodStart;
      let isBefore;

      do {
        count++;
        periodStartDate = StatisticIndicatorHelper.getPeriod(
          periodStartDate,
          this.dataSource.Period,
          1
        );

        // Вывод года если выбранный год не равен году окончания сбора данных
        startYear = moment(periodStartDate).year();

        // Вывод даты если выбранный год равен году окончания сбора данных
        datePeriodStart = moment(periodStartDate, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );

        // Сравнивание дат если выбранный год равен году окончания сбора данных
        isBefore = moment(datePeriodStart).isBefore(periodEndDate);
      } while (
        this.showYear !== periodEndYear ? startYear < yearNext : isBefore
      );
      return count;
    },
  },
};
</script>
<style lang="scss">
.statistic-indicator-values {
  .td-fixed-width-actions {
    width: 36px;
    min-width: 36px !important;
    padding: 0 !important;
  }

  &-calc {
    text-align: center;
    padding-top: 41px;
    padding-bottom: 41px;

    fieldset {
      border-color: #5f647b !important;
    }

    &__label {
      font-size: 14px;
      line-height: 158%;
      color: #8a90a4;
    }
    &__name {
      margin-top: 50px;
      font-size: 14px;
      line-height: 158%;
      color: #5f647b;
    }

    &__body {
      margin-top: 38px;
      display: flex;
      justify-content: center;
    }

    &__inner {
      max-width: 436px;
      display: flex;
    }

    &__left {
      display: flex;
      justify-content: end;
      flex: 1;
    }
    &__center {
      line-height: 158%;
      color: #5f647b;
      margin: 0 12px;
      align-self: center;
    }

    &__right {
      align-self: center;
      .v-input__slot {
        min-height: 40px !important;
      }
    }
  }

  &__progress {
    overflow: inherit;
    .v-progress-linear__content {
      color: rgb(95, 100, 123);
      justify-content: unset;
      width: 10px;
      white-space: nowrap;
    }
  }

  table {
    tr {
      cursor: pointer;
    }
    td {
      border-bottom: unset !important;
      height: unset !important;
    }
    thead {
      .v-btn {
        span {
          color: white;
        }
      }
    }
  }

  td.td-2 {
    width: 100% !important;
    overflow: inherit;
    strong {
      //color: #5f647b;
    }
    .v-progress-linear {
      width: 79%;
    }

    .v-progress-linear__determinate {
      border-radius: 3px;
    }
  }

  td.td-5 {
    padding: 0 !important;
  }

  td.td-4 {
    padding: 0 !important;
  }

  td.td-3 {
    padding: 0 !important;

    font-weight: 500;
    font-size: 12px;
    line-height: 158%;
    /* or 19px */

    /* 8A90A4 */

    color: #8a90a4;
  }

  td:nth-child(2) {
    width: 0;
  }

  td:nth-child(2) {
    width: 0;
  }

  .statistic-indicator-values__period {
    margin-left: 6px;
    color: rgb(33, 33, 33);
  }
  .v-row-group__header {
    background-color: unset !important;
  }
  .v-chip {
    height: 24px;
    border-radius: 10px;
    color: #fbfbfb;
    justify-content: center;
    padding: 0 8px !important;

    &.statistic-indicator-values__chip-group {
      text-align: center;
      width: 104px;
      background: #8a90a4;
    }

    &.beautify {
      background-color: #5ec2bb !important;
    }
    &.success {
      background-color: #0ca85d !important;
    }
    &.warnig {
      background-color: #f7ff7d !important;
      color: rgb(33, 33, 33);
    }
    &.error {
      background-color: #fe7d86 !important;
    }
    &.grey {
      background-color: rgba(33, 33, 33, 0.7) !important;
    }
  }
}
</style>
