<template>
  <div>
    <v-autocomplete
      :items="_items"
      v-bind="$attrs"
      v-on="$listeners"
      append-icon="$ToolArrowDown"
      :item-value="itemValue"
      :clearable="clearable"
      @change="handlerChange"
      ref="select"
      :search-input.sync="search"
      :item-text="_itemText"
    >
      <template #prepend-item>
        <div style="z-index: 100; position: sticky; background: white; top: 0">
          <v-list-item ripple>
            <v-list-item-content class="py-0">
              <v-list-item-title>
                <v-text-field
                  v-model="search"
                  @click.stop
                  label="Поиск"
                  maxlength="200"
                  hide-details
                  prepend-icon="mdi-database-search"
                ></v-text-field
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </div>
      </template>
      <template
        v-if="
          $attrs.hasOwnProperty('multiple') || $attrs.hasOwnProperty('chips')
        "
        #selection="{ item, parent }"
      >
        <slot name="selection" v-bind="{ item, parent }">
          <v-chip
            class="primary--text"
            @click:close="parent.selectItem(item)"
            :close="
              parent.multiple &&
              !($attrs.hasOwnProperty('readonly') && $attrs['readonly'])
            "
            :color="
              typeof chipColor === 'function' ? chipColor(item) : chipColor
            "
          >
            <slot name="selection.text" v-bind="{ item }">
              <span class="dynamic-text">{{ parent.getText(item) }}</span>
            </slot>
          </v-chip>
        </slot>
      </template>
      <template v-slot:append-item v-if="getDict && getDict.componentEdit">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content style="padding-bottom: 0; padding-top: 8px">
            <v-btn
              text
              color="blue"
              large
              @click="
                getDict.componentEditPage
                  ? $router.push({ name: getDict.componentEdit })
                  : $store.dispatch('dict/START_DICT', getDict)
              "
            >
              <m-icon
                dark
                left
                class="mr-3"
                icon="mdi-plus-circle"
                style="font-size: 30px"
              ></m-icon>
              {{ textCustomAdd }}
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import selectDictMixin from "../../../mixins/selectDictMixin";

export default {
  mixins: [selectDictMixin],
  props: {
    chipSelect: Boolean,
    fetchClear: {
      type: Function,
      default: null,
    },
    itemValue: {
      type: [String, Function],
      default: "Id",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    chipColor: {
      type: [String, Function],
      default: "#E8ECFF",
    },
    filter: {
      type: [Function, String, Array],
      default: null,
    },
    itemText: {
      type: [String, Function],
      default: null,
    },
    textCustomAdd: { type: String, default: "Добавить" },
  },

  data: () => ({
    chipSelectItem: null,
    search: "",
  }),

  mounted() {
    this.myDynamicWatcher = this.$watch(
      () => this.$refs.select.isMenuActive,
      (val) => {
        this.$emit("menuActive", {
          value: val,
          elem: this.$refs.select,
        });
        if (!val) this.search = null;
        this.$store.commit("globalOverlay/SET", val);
      }
    );
  },
  methods: {
    async chipClose(parent, item) {
      if (this.fetchClear) {
        const res = await this.fetchClear(item);
        if (!res) return;
      }
      parent.selectItem(item);
    },
    clickChip(event, item) {
      if (!this.chipSelect) return;
      event.stopPropagation();
      this.chipSelectItem = item;
      this.$emit("chipChange", item);
    },
    handlerChange(val) {
      if (!this.chipSelect) return;
      if (!this.chipSelectItem) {
        this.chipSelectItem = val[0];
      } else if (!val || !val.length) this.chipSelectItem = null;
      this.$emit("chipChange", this.chipSelectItem);
    },
  },
};
</script>
<style lang="scss">
.v-autocomplete__content {
  max-height: 80vh !important;
}

.dynamic-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
