<template>
  <div class="analysis-tfoms__header pl-10 justify-space-between align-center">
    <div>
      <selectYear v-model="year" />
      <div class="analysis-tfoms__header-unit">ЕД. ИЗМЕРЕНИЯ</div>
      <div>
        <m-select
          class="analysis-tfoms__header-input"
          v-model="rub"
          :items="optionsRub"
          :clearable="false"
          return-object
          hide-details
        ></m-select>
      </div>
      <div>
        <m-select
          class="analysis-tfoms__header-input"
          v-model="unit"
          :items="optionsUnit"
          :clearable="false"
          return-object
          hide-details
        ></m-select>
      </div>
      <div class="analysis-tfoms__header-unit mt-2">ФИНАНСОВЫЕ САНКЦИИ</div>
      <div>
        <m-select
          class="analysis-tfoms__header-input"
          v-model="dateType"
          :items="optionsDateType"
          :clearable="false"
          return-object
          hide-details
        ></m-select>
      </div>
    </div>

    <tfoms-info
      :value="dataSource.PopulationCount"
      :diff="dataSource.PopulationDynamic"
      :date="dataSource.LastMonth"
      :disabled="!dataSource.LastMonth"
    ></tfoms-info>

    <tfoms-info3
      title="ФИНАНСОВОЕ ОБЕСПЕЧЕНИЕ"
      :sub-title="rub.text"
      :value="dataSource.FinancePlanValue"
      :value2="dataSource.FinancePercent"
      :value3="dataSource.FinanceValue"
      :diff="3"
      color="green"
      is-percent
    ></tfoms-info3>
    <tfoms-info2
      title="НЕОПЛАТА"
      sub-title="Выполнение плана за год"
      :value="dataSource.NonPaymentVolumeValue"
      :value2="dataSource.NonPaymentValue"
      :diff="203"
      color="error"
      :labelRub="rub.text"
      :labelUnit="unit.text"
    ></tfoms-info2>
    <tfoms-info2
      title="ШТРАФЫ"
      sub-title="Выполнение плана за год"
      :value="dataSource.FineVolumeValue"
      :value2="dataSource.FineValue"
      :diff="23"
      color="error"
      :labelRub="rub.text"
      :labelUnit="unit.text"
    ></tfoms-info2>
  </div>
</template>
<script>
export default {
  name: "view-tfoms-header",
  components: {
    selectYear: () => import("@/components/analysis/selectYear.vue"),
    mSelect: () => import("@/components/base/inputs/mSelect"),
    tfomsInfo: () => import("./info.vue"),
    tfomsInfo2: () => import("./info2.vue"),
    tfomsInfo3: () => import("./info3.vue"),
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      year: 2024,

      unit: { id: 0, value: "Случаи", text: "Случаев", alpha: 1 },
      optionsUnit: [
        { id: 0, value: "Случаи", text: "Случаев", alpha: 1 },
        { id: 1, value: "Тыс. случаев", text: "Тыс. случаев", alpha: 1000 },
      ],
      dateType: {
        id: 2,
        value: "По дате экспертизы",
        text: "По дате экспертизы",
      },
      optionsDateType: [
        { id: 2, value: "По дате экспертизы", text: "По дате экспертизы" },
        {
          id: 4,
          value: "По дате закрытия случаев",
          text: "По дате закрытия случаев",
        },
      ],
      rub: { id: 1, value: "Тыс. руб.", text: "Тыс. руб.", alpha: 1000 },
      optionsRub: [
        { id: 0, value: "Рублей", text: "Рублей", alpha: 1 },
        { id: 1, value: "Тыс. руб.", text: "Тыс. руб.", alpha: 1000 },
      ],
    };
  },
  watch: {
    unit: {
      immediate: true,
      handler(val) {
        this.$emit("update:unit", val);
      },
    },
    rub: {
      immediate: true,
      handler(val) {
        this.$emit("update:rub", val);
      },
    },
    year(val) {
      this.$emit("update:year", val);
    },
    dateType(val) {
      this.$emit("update:dateType", val.id);
    },
  },
};
</script>
<style lang="scss">
.analysis-tfoms__header {
  display: flex;

  &-unit {
    font-size: 14px;
    color: var(--v-blue-grey-lighten3);
    border-bottom: solid 1px #c6c8d5;
  }

  &-unit ~ div div.v-input {
    margin-top: 0 !important;
  }
  &-input {
    width: 230px;
    padding-top: 0;
    height: 24px;

    .v-input__control,
    .v-select__slot {
      height: 24px;
    }

    .v-select__selection {
      color: var(--v-primary-base);
      margin-bottom: 0;

      font-size: 14px;
    }
  }
}
</style>
