<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @onDelete="del"
    :disabledAdd="readOnly"
    :showDelete="!readOnly"
    pageEditName="AuditIndicatorEdit"
    title="Показатели оценки качества"
    label-text-btn-create="Добавить"
    label-text-search="Поиск показателя"
  >
    <template #action>
      <v-btn
        @click="dialogAddTemplates = true"
        :disabled="readOnly"
        dark
        color="blue"
        class="ml-4"
      >
        <m-icon icon="tool-add-item" left size="20"></m-icon>
        Шаблоны
      </v-btn>
      <v-btn
        @click="dialogSettings = true"
        :disabled="readOnly"
        dark
        color="blue"
        class="ml-4"
      >
        Настройки
      </v-btn>
    </template>
    <base-popup
      title="Создание показателей оценки качества на основе шаблонов"
      :icon="UtilGetIcon('view-AuditIndicators')"
      v-model="dialogAddTemplates"
      :width="'80vw'"
      :height="'80vh'"
      @save="addTemplates()"
    >
      <base-list-view
        @input="(value) => (this.selectedTemplates = value)"
        :dataSource="templates"
        :headers="headers"
        title="Шаблоны показателей оценки качества"
        label-text-search="Поиск показателя"
        :showDelete="false"
        :notShowActions="['add']"
        :search.sync="search"
        :selectedItems="selectedTemplates"
      >
      </base-list-view>
    </base-popup>
    <analysis-settings-edit
      v-model="dialogSettings"
      :width="'70vw'"
      :height="'80vh'"
    />
  </base-list-view>
</template>

<script>
import AuditIndicatorService from "../../services/AuditIndicatorService";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import AnalysisSettingsEdit from "../../views/system/AnalysisSettingsEdit.vue";

export default {
  name: "view-AuditIndicators",
  components: { BaseListView, BasePopup, AnalysisSettingsEdit },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: AuditIndicatorService,
      headers: [
        {
          text: "Название",
          value: "Name",
          notGroup: true,
          customSort: "NumWithString",
        },
        {
          text: "Раздел деятельности",
          value: "ActivitySection",
          dataType: "object",
          displayText: (c) => c?.Name,
          customSort: "NumWithString",
        },
        {
          text: "Кол. чек-листов",
          value: "CheckListCount",
          align: "center",
        },
      ],
      dataSource: [],
      templates: [],
      selectedTemplates: [],
      dialogAddTemplates: false,
      dialogSettings: false,
      readOnly: !this.hasPermission(Permissions.AuditIndicatorsEdit),
      search: "",
      selectedIndicators: "",
    };
  },
  watch: {
    dialogAddTemplates() {
      this.search = null;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const data = (await AuditIndicatorService.get(null, null, true)).data;
        this.dataSource = data.filter((el) => !el.IsSystem);
        this.templates = data.filter((el) => el.IsSystem);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    // Создание показателей на основе шаблонов
    async addTemplates() {
      if (this.selectedTemplates.length) {
        this.dialogAddTemplates = false;
        this.loading = true;
        const templateIds = this.selectedTemplates.map((t) => t.Id);
        await AuditIndicatorService.createOnTemplates(templateIds);
        await this.init();
        this.selectedTemplates = [];
      }
    },
  },
};
</script>
