var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"v-treeview v-treeview-draggable",class:_vm.themeClassName,attrs:{"value":_vm.localValue,"group":_vm.group,"ghost-class":"ghost","options":{ disabled: _vm.disabled },"handle":".capture-area"},on:{"input":_vm.updateValue}},_vm._l((_vm.value),function(item){return _c('draggable-tree-view-node',_vm._g({key:item.Id,attrs:{"group":_vm.group,"value":item,"expand-icon":_vm.expandIcon,"isOpen":_vm.opens.has(item.Id),"opens":_vm.opens,"updateItems":_vm.updateItems,"pageEditName":_vm.pageEditName},on:{"input":_vm.updateItem},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("prepend",null,null,{ item: item, open: open })]}},{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("label",null,null,{ item: item, open: open })]}},{key:"append",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("append",null,null,{ item: item, open: open })]}},{key:"captureArea",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_vm._t("captureArea",null,null,{ item: item, open: open })]}}],null,true)},_vm.$listeners['clickRow'] ? { clickRow: _vm.$listeners['clickRow'] } : {}))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }