<template>
  <v-card
    class="pl-4 pr-4 pb-4 pt-2"
    style="background: transparent; box-shadow: unset"
  >
    <v-card-actions>
      <h4 class="mb-5">Нежелательные события</h4>
    </v-card-actions>
    <v-row>
      <v-col lg="6" sm="12">
        <v-card height="100%">
          <v-card-actions class="d-flex flex-no-wrap justify-center">
            <div class="d-flex flex-no-wrap justify-center">
              <v-container style="width: 180px">
                <m-select
                  :value="period"
                  :itemValue="'id'"
                  @change="changePeriod"
                  :items="Period"
                  :item-text="(item) => item.value"
                  :clearable="false"
                  return-object
                  single-line
                  dense
                  class="pa-0 ma-0"
                ></m-select>
              </v-container>
            </div>
          </v-card-actions>
          <v-card-text>
            <div v-if="loading === false && dataSource.length">
              <bar-chart
                :chart-data="barData"
                :options="barOptions"
                style="height: 20vh"
              >
              </bar-chart>
              <div
                v-if="period === 1 || period === 0"
                class="d-flex flex-no-wrap justify-center"
              >
                <v-container style="width: 120px">
                  <m-select
                    :value="year"
                    @input="$emit('changeYear', $event)"
                    :items="years"
                    :clearable="false"
                  ></m-select>
                </v-container>
              </div>
            </div>

            <div
              v-if="loading === true"
              class="d-flex flex-no-wrap justify-center align-items-center"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6" sm="12">
        <v-card height="100%">
          <v-card-actions>
            <h4>Виды нежелательных событий ({{ periodText }})</h4>
          </v-card-actions>
          <v-card-text class="pt-1">
            <m-tabs
              v-if="loading === false && unwishedEventTypes.length"
              v-model="tab"
              :slider-size="2"
              :with-route="false"
              class="mb-2"
            >
              <v-tab key="tab1">Вид НС</v-tab>
              <v-tab key="tab2">Подразделение</v-tab>
            </m-tabs>

            <v-window
              :value="tab"
              v-if="loading === false && unwishedEventTypes.length"
            >
              <v-window-item>
                <base-tree-table
                  v-if="unwishedTypes"
                  :headers="typeHeaders"
                  :items="unwishedTypes"
                  :loading="loading"
                  hide-default-footer
                  notShowSelect
                  :showSettings="false"
                  treeTableContents
                  not-filter
                ></base-tree-table>
              </v-window-item>
              <v-window-item>
                <base-tree-table
                  v-if="unwishedPartitions"
                  :headers="typeHeaders2"
                  :items="unwishedPartitions"
                  :loading="loading"
                  hide-default-footer
                  notShowSelect
                  :showSettings="false"
                  treeTableContents
                  not-filter
                ></base-tree-table>
              </v-window-item>
            </v-window>

            <div v-if="loading === false && !unwishedEventTypes.length">
              <span>Нежелательных событий нет</span>
            </div>
            <div
              v-if="loading === true"
              class="d-flex flex-no-wrap justify-center align-items-center"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import UnwishedEventService from "../../services/UnwishedEventService";
import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import BarChart from "../../views/analysis/BarChart.js";
import mSelect from "@/components/base/inputs/mSelect";
import { Period } from "../../data/enums";
import moment from "moment";
import { groupBy, reduce } from "lodash";

export default {
  name: "view-UnwishedEventsChart",
  components: {
    BarChart,
    mSelect,
    BaseTreeTable: () => import("../../components/base/BaseTreeTable.vue"),
    mTabs: () => import("../../components/base/tabs/mTabs"),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    year: Number,
    period: Number,
  },
  data() {
    return {
      apiService: UnwishedEventService,
      dataSource: [],
      barData: {},
      barOptions: {},
      years: [],
      initialYear: 2019,
      unwishedEventTypes: [],
      isSelected: false,
      itemsByYears: [],
      periodText: "",
      typeHeaders: [
        {
          value: "Item1",
          text: "Вид / Подразделение",
          dataType: "object",
          displayText: (e) => e?.Name,
        },

        {
          value: "Item2",
          text: "Количество",
          align: "center",
        },
      ],
      typeHeaders2: [
        {
          value: "Item3",
          text: "Подразделение / Вид",
          dataType: "object",
          displayText: (e) => e?.Name,
        },

        {
          value: "Item2",
          text: "Количество",
          align: "center",
        },
      ],
      tab: null,
    };
  },
  computed: {
    unwishedTypes() {
      const result = reduce(
        groupBy(this.unwishedEventTypes, (e) => e.Item1.Id),
        function (array, children, key) {
          console.log(" children ", children);
          array.push({
            Item1: children[0]?.Item1?.Name
              ? children[0]?.Item1
              : { Name: '"без вида"' },
            Item2: children.reduce((sum, val) => (sum += val.Item2), 0),
            Item3: "",
            Children: children.map((e) => ({
              ...e,
              Item1: e.Item3?.Name ? e.Item3 : { Name: '"другое место"' },
            })),
            Id: key,
          });

          return array;
        },
        []
      );
      const total = result.reduce((sum, item) => sum + item.Item2, 0);

      result.push({
        Item1: { Name: "Итого" },
        Item2: total,
        Item3: "",
        Children: [],
        Id: "total",
      });

      return result;
    },
    unwishedPartitions() {
      return reduce(
        groupBy(this.unwishedEventTypes, (e) => e.Item3?.Id),
        function (array, children, key) {
          array.push({
            Item1: "",
            Item2: children.reduce((sum, val) => (sum += val.Item2), 0),
            Item3: children[0]?.Item3?.Name
              ? children[0]?.Item3
              : { Name: '"другое место"' },
            Children: children.map((e) => ({
              ...e,
              Item3: e.Item1?.Name ? e.Item1 : { Name: '"без вида"' },
            })),
            Id: key,
          });

          return array;
        },
        []
      );
    },
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
    Period: () => {
      return Period;
    },
    totalCount() {
      return this.unwishedEventTypes.reduce(
        (sum, item) => (sum += item.Item2),
        0
      );
    },
  },
  watch: {
    async items(value) {
      if (value) {
        await this.init();
      }
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.years = [];
      this.unwishedEventTypes = [];
      for (
        let year = this.initialYear;
        year <= new Date().getFullYear();
        year++
      )
        this.years.push(year);
      await this.loadEvents();
      await this.loadEventsByYear();
    },
    DataFromServerNormalize(data) {
      for (const value of data) {
        value.GroupedTypes = groupBy(value.Types, "Id");
      }

      return data;
    },
    changePeriod(item) {
      this.$emit("changePeriod", item.id);
    },
    async loadEventsByYear() {
      const res = (await UnwishedEventService.getByPeriods(this.year, 2)).data;
      if (!res.length) return;

      if (this.period === 2) {
        this.periodText = `за все время`;
        const temp = [];
        for (const d of res) {
          for (const e of d.Types) temp.push({ ...e.Item1, count: e.Item2 });
        }
        const groupedEvents = groupBy(temp, "Id");

        const obj = [];
        for (const d in groupedEvents) {
          obj.push({
            Item1: {
              Name: groupedEvents[d][0].Name,
            },
            Item2: groupedEvents[d].reduce(
              (sum, value) => sum + value.count,
              0
            ),
          });
        }

        this.unwishedEventTypes = obj
          .filter((o) => o.Item2 !== 0)
          .sort((x, y) => x.Item1.Name.localeCompare(y.Item1.Name));
      } else {
        this.periodText = `${this.year} г.`;
        const t = res.find((r) => r.PeriodNum === this.year);
        if (t)
          this.itemsByYears = t.Types.filter((t) => t.Item2 !== 0).sort(
            (x, y) => x.Item1.Name.localeCompare(y.Item1.Name)
          );
        this.unwishedEventTypes = this.itemsByYears;
      }
    },

    async loadEvents() {
      this.dataSource = this._.cloneDeep(this.items);
      if (this.dataSource.length) {
        this.barData = {
          labels: this.dataSource.map((e) => this.getPeriodText(e.PeriodNum)),
          datasets: [
            {
              data: this.dataSource.map((e) => e.Count),
              label: "Итого",
              backgroundColor: this.dataSource.map(() => "#E8ECFF"),
              minBarLength: 5,
            },
          ],
        };

        this.barOptions = {
          legend: {
            display: false,
          },
          tooltips: {
            mode: "label",
          },
          plugins: {
            datalabels: {
              align: "end",
              anchor: "end",
              color: "#2757FF",
              formatter: Math.round,
            },
          },
          maintainAspectRatio: false,
          onClick: this.handle,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                barPercentage: 0.9,
                categoryPercentage: 1.0,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  display: false,
                  min: 0,
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 25,
            },
          },
        };
        this.dataSource = this.DataFromServerNormalize(this.items);
      }
    },
    getPeriodText(periodNum) {
      switch (this.period) {
        case 0:
          return moment(new Date(2021, periodNum - 1, 1)).format("MMMM");
        case 1:
          return `${periodNum} квартал`;
        case 2:
          return periodNum;
        default:
          break;
      }
    },
    async handle(event, array) {
      this.isSelected = !this.isSelected;
      const selectedIndex = array[0]._index;
      if (this.isSelected) {
        if (array.length > 0) {
          this.barData = Object.assign({}, this.barData, {
            datasets: [
              {
                ...this.barData.datasets[0],
                backgroundColor: this.barData.datasets[0].data.map(
                  () => "#BBC6F0"
                ),
              },
            ],
          });
          const backgroundColorModified = [
            ...this.barData.datasets[0].backgroundColor,
          ];
          backgroundColorModified[selectedIndex] = "blue";
          this.barData = Object.assign({}, this.barData, {
            datasets: [
              {
                ...this.barData.datasets[0],
                backgroundColor: backgroundColorModified,
              },
            ],
          });
          this.unwishedEventTypes = this.dataSource[
            selectedIndex
          ]?.Types.filter((t) => t.Item2 !== 0).sort((x, y) =>
            x.Item1.Name.localeCompare(y.Item1.Name)
          );

          if (this.period !== 2)
            this.periodText = `${this.getPeriodText(
              this.dataSource[selectedIndex].PeriodNum
            )} ${this.year} г.`;
          else {
            this.periodText = `${this.dataSource[selectedIndex].PeriodNum} г.`;
          }
        }
      } else {
        await this.loadEventsByYear();
        this.barData = Object.assign({}, this.barData, {
          datasets: [
            {
              ...this.barData.datasets[0],
              backgroundColor: this.barData.datasets[0].data.map(
                () => "#BBC6F0"
              ),
            },
          ],
        });
        if (this.period !== 2) this.periodText = `${this.year} г.`;
        else {
          this.periodText = `за все время`;
        }
      }
    },
  },
};
</script>

<style scoped></style>
