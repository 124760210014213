<template>
  <div title="">
    <div
      :class="{
        'block__content-header_fixed': fixedHeader,
        'base-panel__header-fill': fillHeader,
      }"
    >
      <v-toolbar
        flat
        class="block__content-header"
        :class="{
          'header--together': headerTogether,
        }"
        v-if="!headerHidden"
        :height="fixedHeader ? 50 : headerHeight"
      >
        <slot name="header">
          <slot name="icon">
            <img v-if="logo.length > 1" :src="logo" class="mr-4" />
            <m-icon
              v-else-if="!(icon === null)"
              class="mr-4"
              :icon="icon"
              active
            ></m-icon>
          </slot>
          <slot name="title">
            <v-toolbar-title>
              {{ title }}
              <slot name="subTitle">
                <span v-if="subTitle" class="caption ml-5">{{ subTitle }}</span>
              </slot>
            </v-toolbar-title>
          </slot>
        </slot>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          height="2"
        ></v-progress-linear>
        <v-spacer v-if="!notSpacerAction"></v-spacer>

        <slot name="action"></slot>
      </v-toolbar>
      <div v-if="tabs">
        <m-tabs :value="tab" @change="$emit('update:tab', $event)">
          <v-tab v-for="(tab, i) in tabs" :key="i">{{ tab }}</v-tab>
        </m-tabs>
      </div>
    </div>

    <div
      class="base-panel__body pb-2 fill-height"
      :class="{
        'pt-2': !headerTogether && !headerHidden,
        'header--together': headerTogether,
      }"
    >
      <slot> No slot content defined. </slot>
    </div>
  </div>
</template>

<script>
import mTabs from "@/components/base/tabs/mTabs";
export default {
  components: {
    mTabs,
  },
  props: {
    headerTogether: Boolean,
    headerHidden: {
      type: Boolean,
      default: false,
    },
    title: String,
    loading: {
      type: Boolean,
      default: false,
    },
    subTitle: String,
    notSpacerAction: Boolean,
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    fixedActions: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
      validator: function (value) {
        return typeof value === "string" && value.length > 1;
      },
    },
    logo: {
      type: String,
      default: "",
    },
    headerHeight: {
      type: String,
      default: "40px",
    },
    tabs: {
      type: Array,
      default: null,
    },
    tab: {
      type: Number,
      default: 0,
    },

    fillHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass">
.block__content-header_fixed
  top: 50px
  position: sticky
  position: -webkit-sticky
  z-index: 3
  background-color: var(--v-background-base)

.base-panel__header-fill
  padding: 15px 0
  border-radius: 10px
  background-color: white

.header--together
  border-bottom: none !important
  border-bottom-left-radius: unset !important
  border-bottom-right-radius: unset !important
.header--together .block__content
  border-top-left-radius: unset !important
  border-top-right-radius: unset !important
</style>
